import React from "react";
import TopNav from "../../TopNavBar";
import { connect } from "react-redux";
import { oneSignalSubscription } from "../../../reduxStore/actions/common";
import { useEffect, useState } from "react";

const PublicLayout = props => {
  // useEffect(() => {
  //   window.firebase.analytics().logEvent(window.location.pathname);
  // }, [window.location.pathname]);
  return (
    <div>
      <TopNav />
      <div className="container-fluid p-0 public-layout-container">
        {props.children}
      </div>
    </div>
  );
};

// export default PublicLayout;
const mapStateToProps = props => ({
  userSubscribed: props.commonReducers
});

const mapDispatchToProps = dispatch => ({
  oneSignalSubscription: data => dispatch(oneSignalSubscription(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
