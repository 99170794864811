import React, { Component } from "react";
import { connect } from "react-redux";

import { loginUser, dismissMessage } from "../../reduxStore/actions/auth";

import PublicLayout from "../../containers/Layout/PublicLayout";
import LoginForm from "../../containers/LoginForm";

import LogoImg from "../../assets/images/group-3@3x.png";

import Label from "../../components/label";
import Anchor from "../../components/anchor";
import APP_CONSTANTS from "../../constants";
import appRoutes from "../../routes/appRoutes";

import { NavLink } from "react-router-dom";
import {logFirebaseEvent} from "../../utils/common";

class LoginPage extends Component {
  constructor() {
    super();
    console.log(localStorage.getItem("emails"));
  }
  componentDidMount() {
    logFirebaseEvent("Login Page");
    window.scrollTo(0, 0);
  }
  onLoginFormSubmit = formValues => {
    this.props.loginUser({
      email: formValues.userName,
      password: formValues.password,
      deviceType: "2",
      appVersion: "100",
      deviceToken: "e873e0ba-6f46-44f3-b970-ea262fed1c6b"
    });

    var emails = "";
    if (localStorage.getItem("emails") != null) {
      emails = JSON.parse(localStorage.getItem("emails"));
      if (!emails.includes(formValues.userName)) {
        emails.push(formValues.userName);
        localStorage.setItem("emails", JSON.stringify(emails));
      }
      console.log(localStorage);
    } else {
      localStorage.setItem("emails", JSON.stringify([formValues.userName]));
    }

    if (this.props.auth.error) {
      setTimeout(() => {
        this.props.dismissMessage();
      }, 3000);
    }
  };
  render() {
    return (
      <PublicLayout>
        <div className="login-page-container container d-flex align-items-center justify-content-center">
          <div className="lg-page-inner-container pb-4 pl-0 pr-0">
            <div className="lp-header ">
              <div className="col-md-12">Patient Login</div>
            </div>
            <div className="row lp-content-container d-flex justify-content-center align-items-center">
              <div className="col-md-12 logo-img-container d-flex justify-content-center align-items-center">
                <img src={LogoImg} alt="logo-img" />
              </div>
              <div className="col-md-12 d-flex justify-content-center align-items-center lg-form-container">
                <LoginForm
                  onFormSubmit={this.onLoginFormSubmit}
                  serverErrorMsg={this.props.auth.message}
                />
              </div>
            </div>
            <div className="text-center pb-2">
              <Label title="Don't have an account?" className="font-14 gray" />
              <NavLink
                to={appRoutes.signUp}
                className="insta-link-orange ml-1 font-15 text-decoration-none"
                href="#"
              >
                Sign Up
              </NavLink>
            </div>
            <div className="text-center w-100 p-0 lg-as-doctor">
              <Anchor
                title="Login as Doctor"
                className="text-white font-15"
                href={APP_CONSTANTS.doctorURL}
              />
            </div>
          </div>
        </div>
      </PublicLayout>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  loginUser: data => dispatch(loginUser(data)),
  dismissMessage: () => dispatch(dismissMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
