/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Draggable from "react-draggable";
import _ from "lodash";

import APP_CONSTANTS from "../../constants";

import Button from "../../components/button";
import callTune from "../../assets/audios/elegant_sms.mp3";

import appRoutes from "../../routes/appRoutes";

import { getBookingDetailsApiRoute } from "../../apiRoutes/index";

//connecting to our signaling server
import socket from "../../utils/socket";

import { replaceNewLineWithBrTag } from "../../utils/common";
import {logFirebaseEvent} from "../../utils/common";

import MakeAPICall from "../../apiCalls/common";
import {
  rejectCallApiRoute,
  acceptCallApiRoute,
  checkBookingApiRoute,
  connectCallApiRoute
} from "../../apiRoutes/index";
import {
  bookingStatus,
  saveAppointmentData,
  saveVideoCallStatus,
  saveVideoCallTimerValue,
  saveCallWaitTime,
  saveWaitIfCallEnd,
  saveRequestAcceptedStatus,
  notificationNumber,
  saveIsDoctorCalling,
  saveCallSessionData,
  saveIsDirectCallPMS,
  saveHomePageDataForQueueInfo
} from "../../reduxStore/actions/common";

import CustomModal from "../../components/customModal";

import { getBrowserDetails } from "../../utils/common";

const conn = socket;
let connectedUser;
var yourConn = null;
let stream;
let configuration = APP_CONSTANTS.turnServerInfo;

class SocketHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      apiCall1: "",
      connectionLostModel: false,
      callFromDoc: false,
      appointmentId:
        this.props.commonReducers.appointmentData !== null
          ? this.props.commonReducers.appointmentData._id
          : "",
      sessionId:
        this.props.commonReducers.callSessionData !== null
          ? this.props.commonReducers.callSessionData
          : "",

      modalBodyContent: "",
      oneButton: "",
      doctorWillCallSoonModal: false,
      doctorWillCallSoonText: "",
      modalOkayButton: () => {},
      modalCancelButton: () => {},
      modalOkayText: "",
      modalCancelText: "",
      showVideoModal: false,
      hideCallBtn: true, // hide call button after 5 minute
      localVideo: null, // used for video call
      remoteVideo: null, // used for video call
      patientData: props.auth ? props.auth[0] : null, // used for video call,
      dependentsData: props.auth && props.auth[0] && props.auth[0].dependents ? props.auth[0].dependents : [], // it will be used to match the socket notication (child id for notification)
      videoAllow: true, // local video allowed to user
      audioAllow: true, // local audio allowed to user

      showVideoContainer: false, // Video container - draggable show and hide,
      videoContainerMinimize: true, // small size video container
      videoContainerPosition: { x: 105, y: 0 }, // video container default position

      activeDrags: 0, // for dragging video call container
      deltaPosition: {
        // for dragging video call container
        x: 0,
        y: 0
      },
      controlledPosition: {
        // for dragging video call container
        x: -400,
        y: 200
      },
      timer: null, // timer function
      timernewvalue: this.props.commonReducers.callTimerVal
        ? this.props.commonReducers.callTimerVal
        : 0, // for video call pause,
      videoTimerValue: null, // video timer value,
      timernew1: null, // for storing video timer interval

      doctorEndedCall: false, // when doctor ends call then there is no device issue on doctor side
      doctorPoorConnection: false, // doctor side connection issue - pop-up shown and handle on click to end call from patient side

      waitIfDoctorCallEnd: false, // we will wait until we get the fonal notice from server with other details

      isDirectCall: false, // when doctor call from PMS
      showMissedCallPopup: false,
      missedCallFromDoctorPMS: false, // when patient missed a call from doctor - Main use for PMS but not sure if falg === 6 activate some were else as well

      isCallConnected: false, // default is call connected is false,
      idsToMatch:[],
      doctorOffline: false, // doctor is default online
      timerForDoctorInternetLoss: null // timer for internet lost
    };
  }

  componentDidMount() {
    console.log("Auth : ", this.props.auth);
    yourConn = new RTCPeerConnection(configuration);
    this.handleSocketEvents();
    this.setState({
      timerForDoctorInternetLoss: setInterval(
        this.checkInternetConnetionInLoop,
        1000
      )
    });

    // If user is using safari browser then this will enable the audo audio playing on safari (safari special requiement)
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      console.error("User is using safari");
      document.body.addEventListener('click', this.unlockAudioForSafari);
      document.body.addEventListener('touchstart', this.unlockAudioForSafari);
    }
  }

  unlockAudioForSafari() {
    const sound = new Audio('../../assets/audios/elegant_sms.mp3');

    const promise = sound.play();

    // console.error("Sound Promise: ", promise);

    if (promise !== undefined) {
      promise.then(() => {
        console.error("Audio ring working fine");
        sound.pause();
      }).catch(error => console.error);
    }

    document.body.removeEventListener('click', this.unlockAudioForSafari);
    document.body.removeEventListener('touchstart', this.unlockAudioForSafari);
  }

  componentWillUnmount() {
    this.doCleanUponCallLeaving();
  }

  componentWillUpdate(props, state) {
    let oldAppointmentId = props.commonReducers.appointmentData
      ? props.commonReducers.appointmentData._id
      : null;
    let newAppointmentId = state.appointmentId;
    if (oldAppointmentId !== newAppointmentId && yourConn === null) {
      console.log("Now creating connection");
      yourConn = new RTCPeerConnection(configuration);
      if (this.state.timerForDoctorInternetLoss === null) {
        this.setState({
          timerForDoctorInternetLoss: setInterval(
            this.checkInternetConnetionInLoop,
            1000
          )
        });
      }
    }
  }

  checkInternetConnetionInLoop = () => {
    if (navigator.onLine) {
      this.setState({
        doctorOffline: false
      });
    } else {
      this.setState({
        doctorOffline: true
      });
      clearInterval(this.state.timerForDoctorInternetLoss);
    }
  };

  // handle socket code here
  handleSocketEvents = () => {
    let _this = this;

    conn.on("connect", function() {
      console.log("patient connect");

      console.log("Emit event from patient done");
      conn.emit('join', "PATIENTS");

      _this.setState({
        connectionLostModel: false
      });
      let appointmentId = _this.props.commonReducers.appointmentData && _this.props.commonReducers.appointmentData._id ? _this.props.commonReducers.appointmentData._id : _this.state.appointmentId;
      
      if (_this.props.commonReducers.appointmentData || appointmentId) {
        let dataToSend = {
          type: "videoConnectAttempt",
          appointmentId: appointmentId,
          from: "patient"
        };
        console.log("Sending attempt data: ", dataToSend);
        send(dataToSend);
      }
      setTimeout(_this.registerPatientSocket, 5000);
      // if (_this.props.auth && _this.props.auth[0]) {
      //   let _id = _this.props.auth[0]._id;
      //   if (_id.length > 0) {
      //     setTimeout(_this.registerPatientSocket(_id), 5000);
      //   }
      // }
    });

    conn.on("login", data => {
      console.log("User logged in success: ", data);
    });

    conn.on("message", msg => {
      console.log("patient message", msg);
      let data = "";
      try {
        data = JSON.parse(msg);
      } catch (err) {
        data = msg;
      }
      console.log(
        "CHecking appointment id here : ",
        data.appointmentId,
        _this.state.appointmentId
      );
      if (data.appointmentId === _this.state.appointmentId)
        _this.handleAllMsg(data);
    });

    conn.on("disconnect", function() {
      console.log("patient disconnect - trying to reconnect now until 4 count");
      // _this.setState({
      //   connectionLostModel: true
      // });
    });

    conn.on("reconnect_attempt", function(data) {
      console.log("Reconnecting to server now: ", data);
      if (data === 4) {
        _this.setState({
          connectionLostModel: true
        });
      }
    });

    conn.on("registrationError", this.socketRegistrationError);

    conn.on("webNotification", async data => {
      console.log("common web notifications :  ", data);
      let flagNo = data.data.data.flag;
      let appointmentId = _this.props.commonReducers.appointmentData && _this.props.commonReducers.appointmentData._id ? _this.props.commonReducers.appointmentData._id : _this.state.appointmentId;
      const id =  data.data.data.patientId;
      if (data && data.data.data.flag === 35) {
        // READY_FOR_APPOINTMENT_FROM_BOOKING
        if (this.state.idsToMatch.includes(id)) {
          this.setState({ doctorWillCallSoonModal: true , doctorWillCallSoonText: replaceNewLineWithBrTag(data.data.contents.en)});

          setTimeout(() => {
            this.setState({ doctorWillCallSoonModal: false });
          }, 30_000);
        }
      } else if (data && data.data.data.flag === 34) {
        if (this.state.idsToMatch.includes(id)) {
          this.callCheckBookingApi();
        }
      } else if (
        flagNo === 15 ||
        flagNo === 1 ||
        flagNo === 25 ||
        flagNo === 29 ||
        flagNo === 4 ||
        flagNo === 5 ||
        flagNo === 6 ||
        flagNo === 24 ||
        flagNo === 28 ||
        flagNo === 17 ||
        flagNo === 32 ||
        flagNo === 18
      ) {
        // Please add flag here as well when you add notifications
        if (
          data &&
          data.data.data.appointmentId && // this.props.commonReducers.appointmentData !== null &&
          // this.props.commonReducers.appointmentData._id ===
          (appointmentId === data.data.data.appointmentId ||
            (data.data.data.patientId &&
              data.data.data.patientId === this.state.patientData._id) ||
            (data.data.data.patientId &&
              this.state.dependentsData.indexOf(data.data.data.patientId) !==
                -1) ||
            (data.data.data.patientData &&
              data.data.data.patientData._id === this.state.patientData._id) ||
            (data.data.data.patientData &&
              this.state.dependentsData.indexOf(
                data.data.data.patientData._id !== -1
              )))
        ) {
          if (data.data.data.flag === 15) {
            // reset video call timer value
            await this.props.saveVideoCallTimerValue(0);

            // if any doctor accepted the consult request
            console.log("web notifications doctor accepted a call :  ", data);
            this.saveBookingStatus(data.data.data.appointmentId);
            await this.props.saveRequestAcceptedStatus(true);
            this.setState({
              //callFromDoc: true,
              isCallConnected: false,
              doctorOffline: false, // doctor is default online
              timerForDoctorInternetLoss: null, // timer for internet lost
              appointmentId: data.data.data.appointmentId
              // modalCancelButton: this.okayDoctorAccepted,
              // oneButton: true,
              // modalCancelText: "Okay",
              // modalBodyContent: (
              //   <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-center">
              //     <img
              //       style={{ marginLeft: "0px" }}
              //       src={data.data.data.doctorDetails.profilePicture.original}
              //       alt="patient-img"
              //       className="requested-user-profile-pic"
              //     />
              //     <p className="requested-patient-name text-center w-100 mt-3 mb-0">
              //       {"Dr. " + data.data.data.doctorDetails.userName} accepted
              //       your consult request
              //     </p>
              //     <p className="requested-patient-name text-center w-100 mt-1 mb-0">
              //       You will be receiving a video call within 5 minutes
              //     </p>
              //   </div>
              // )
            });
          }
          // when the doctor calls the user
          else if (data.data.data.flag === 1) {
            this.playAudio();

            this.setState({
              sessionId: data.data.data.sessionId,
              isDirectCall: data.data.data.directCall,
              isCallConnected: false,
              doctorOffline: false, // doctor is default online
              timerForDoctorInternetLoss: null // timer for internet lost
            });

            if (
              data.data.data.directCall &&
              data.data.data.directCall === true
            ) {
              await this.props.saveIsDirectCallPMS(true);
            }

            // save doctor calling state
            await this.props.saveIsDoctorCalling(true);
            // save session details into redux
            await this.props.saveCallSessionData(data.data.data.sessionId);

            this.setState({
              callFromDoc: true,
              appointmentId: data.data.data.appointmentId,
              modalOkayButton: this.acceptCallFromDoc,
              modalCancelButton: this.rejectCallFromDoc,
              oneButton: false,
              modalCancelText: "Reject",
              modalOkayText: "Accept",
              modalBodyContent: (
                <div className="row p-0 m-0 text-center">
                  <div className="col-sm-12">
                    <img
                      src={data.data.data.callingFrom.profilePicture.original}
                      alt="doc-img"
                      className="requested-user-profile-pic ml-0 mr-0"
                    />
                  </div>
                  <div className="col-sm-12 pt-2">
                    <p
                      className="requested-patient-name mt-2 mb-0"
                      dangerouslySetInnerHTML={{
                        __html: replaceNewLineWithBrTag(data.data.contents.en)
                      }}
                    ></p>
                  </div>
                </div>
              )
            });
          }
          // if call gets end from doctor or admin do this
          else if (data.data.data.flag === 25 || data.data.data.flag === 29) {
            this.pauseAudio();
            // when the doctor click the end call button the patient will wait
            this.handleDoctorCallEnd(data);
          }
          // doctor ended the call
          else if (data.data.data.flag === 4 || data.data.data.flag === 5) {
            // call this when our autotimeout is not being called
            this.pauseAudio();
            this.handleDoctorCallEnd(data);
            if (this.props.commonReducers.waitIfCallEnd === true) {
              this.handleLeave(data);
            }
          } else if (data.data.data.flag === 6) {
            this.setState({
              callFromDoc: false,
              showVideoContainer: false
            });
            this.pauseAudio();

            // only show this missed call popup in case of PMS direct call
            if (
              this.state.isDirectCall === true ||
              (this.props.commonReducers.appointmentData !== null &&
                this.props.commonReducers.appointmentData.directCall === true)
            ) {
              // patient missed the doctor call
              this.setState({
                missedCallFromDoctorPMS: true,
                //showMissedCallPopup: true,
                isDirectCall: false
              });
              this.handleDocMissedCall();
            }
          }
          // admin removed patient from the queue so we will now just do cleanup and move to the request consult screen
          else if (data.data.data.flag === 28) {
            this.pauseAudio();
            console.log("Patient removed from queue by admin");
            await this.goToConsultReqPage();
          }
          // if no doctor is available and consult is cancelled after 45 mins
          else if (data.data.data.flag === 17) {
            this.setState({
              callFromDoc: true,
              modalCancelButton: this.goToConsultReqPage,
              oneButton: true,
              modalCancelText: "Okay",
              modalBodyContent: (
                <p
                  className="requested-patient-name text-center mt-2 mb-0"
                  dangerouslySetInnerHTML={{
                    __html: replaceNewLineWithBrTag(data.data.contents.en)
                  }}
                ></p>
              )
            });
          }
          // just close the video call now and clean the timers here
          else if (data.data.data.flag === 32) {
            this.handleLeave(data, false);
          }
        }

        if (
          (data.data.data.flag === 24 || data.data.data.flag === 18) &&
          ((data.data.data.patientId &&
            data.data.data.patientId === this.state.patientData._id) ||
            (data.data.data.patientData &&
              data.data.data.patientData._id === this.state.patientData._id))
        ) {
          // patient receipt ready so increament the notification popup || Admin sent notification
          this.handlePushNotification();
        }
      }
    });

    conn.on("PATIENT_HOME_PAGE_DATA", (data) => {
      _this.handleHomePageData(data, _this.state.patientData, _this.state.dependentsData);
    });

    if (this.props.commonReducers.isCallAccepted === true) {
      this.setState({ showVideoModal: true, callFromDoc: false });
      // this.startLocalVideo();
    }
  };

  // do cleanup when call end and leaved
  async doCleanUponCallLeaving() {
    this.setState({
      doctorOffline: false, // doctor is default online
      timerForDoctorInternetLoss: null // timer for internet lost
    });
    await this.resetTimerState();
    await this.resetVideoCallReduxState();
    await this.bookingStatus(0);
    await this.props.saveWaitIfCallEnd(false);
    await this.props.saveRequestAcceptedStatus(false);
    await this.props.saveIsDoctorCalling(false);
    await this.props.saveIsDirectCallPMS(false);
    await this.props.saveCallSessionData(null);
  }

  registerPatientSocket = () => {
    if (this.props.auth && this.props.auth[0]) {
      let _id = this.props.auth[0]._id;
      if (_id.length > 0) {
        send({
          type: "patientLogin",
          name: _id
        });
      }
    }
  };

  handlePushNotification = async () => {
    console.log("Now increasing push notificaiton number ", this.props.commonReducers.totalNotification);
    await this.props.notificationNumber({
      totalNotification: this.props.commonReducers.totalNotification !== null || this.props.commonReducers.totalNotification !== undefined ? this.props.commonReducers.totalNotification + 1 : 1
    });
  }

  socketRegistrationError = data => {
    console.log("Socket was not registered: trying again ", data);
    setTimeout(this.registerPatientSocket, 5000);
  };

  // TODO - Check the patient Data here before you update V4 backend upgrades
  handleHomePageData = (data, patientData, dependentData) => {
    console.log("Got the home page data here: ", data, patientData, dependentData);
    if (patientData) {
      const idsToMatch = [patientData._id, ...dependentData];
      this.setState({idsToMatch});
      console.log("Ids to match are here: ", idsToMatch);
      const filterData = _.filter(data.queue, function (o) {
        return idsToMatch.indexOf(o.patientId) !== -1;
      });
      console.log("Got the filterData : ", filterData);
      if (filterData.length && filterData[0]) {
        this.props.saveHomePageDataForQueueInfo({
          patientQueueWaitTime: filterData[0].waitTime,
          onlineDocCount: data.onlineDoctorCount
        });
      } else {
        console.log("Got the else part: ", data.queue.length / data.onlineDoctorCount, data.queue.length, data.onlineDoctorCount)
        const waitTime = (Math.floor(data.queue.length / data.onlineDoctorCount) * 5) + 5;
        this.props.saveHomePageDataForQueueInfo({
          patientQueueWaitTime: waitTime,
          onlineDocCount: data.onlineDoctorCount
        });
      }
    }
  }

  // communicate with the socket here
  handleAllMsg = data => {
    console.log("patient in handling all msg : ", data);

    switch (data.type) {
      //when somebody wants to call us
      case "offer":
        console.log("Offer : ", data.offer, data.name);
        this.handleOffer(data.offer, data.name);
        break;
      case "answer":
        console.log("Answer : ", data.answer);
        // this.handleAnswer(data.answer);
        break;
      //when a remote peer sends an ice candidate to us
      case "candidate":
        console.log("HandleCandidate: ", data.candidate);
        this.handleCandidate(data.candidate);
        break;
      case "leave":
        console.log("Handle leave: ", data);
        this.doctorEndedCall();
        // this.handleLeave(data);
        break;
      // handle video timer pause play if the doctor have paused the video
      case "videoTimerStatus":
        if (data.timerStatus === "pause") {
          this.stopVideoTimer();
        } else if (data.timerStatus === "play") {
          this.startVideoTimer();
        }
        break;
      default:
        console.log("Nothing matched");
        break;
    }
  };

  // reset time states to avoid conflicts
  resetTimerState = async () => {
    await this.stopVideoTimer();
    this.setState({
      timer: null, // timer function
      timernewvalue: 0, // for video call pause,
      videoTimerValue: null, // video timer value,
      videoContainerMinimize: true, // small size video container
      videoContainerPosition: { x: 105, y: 0 }, // video container default position,
      doctorEndedCall: false // set doctor connection flag is okay
    });
  };

  // connect call api
  connectCallApi = () => {
    logFirebaseEvent("Video Call Screen");
    let formData = new FormData();
    formData.append("userType", "Patient");
    formData.append("appointmentId", this.state.appointmentId);
    formData.append("sessionId", this.state.sessionId);

    let apiCallObj = {
      reqType: "PUT",
      reqURL: connectCallApiRoute,
      dataObj: formData,
      headers: {},
      successCallback: async res => {
        //console.log("This data is saved inredux : ", res);
        this.setState({
          isCallConnected: true
        });
      },
      failureCallback: err => {
        //console.log("Got the error : ", err);
      }
    };
    this.setState({ apiCall1: apiCallObj });
  };

    callCheckBookingApi = () => {
      let apiCallObj = {
        reqType: "GET",
        reqURL: checkBookingApiRoute,
        successCallback: async res => {
          // we used to have bookingStatus in booking api, but from now on we will be dependant on appointment status
          
          // if (res.data.data.bookingStatus) {
          //   await this.props.saveAppointmentData({
          //     _id: res.data.data.appointmentId
          //   });
          //   this.props.history.push(appRoutes.consultationDetails);
          // }

          if (res.data.data.appointmentData && res.data.data.appointmentData.length){
            let appointment =  res.data.data.appointmentData[0];
  
            if (appointment.status === 0) {
              await this.props.saveAppointmentData({
                _id: appointment.appointmentId
              });
              this.props.history.push(appRoutes.booking);
            } else {
              await this.props.saveAppointmentData({
                _id: appointment.appointmentId
              });
              this.props.history.push(appRoutes.consultationDetails);
            }
          }
        
        },
        failureCallback: err => {
          // this.setState({
          //   showErrorModal: true
          // });
        },
        showPopUp: false,
      };
      this.setState({ apiCall: apiCallObj });
    };

  // check if user have a current booking so save its data
  saveBookingStatus = async bookingID => {
    let _this = this;

    this.callBookingStatusAPI(bookingID, function(res) {
      if (res !== false) {
        _this.bookingStatus(res.data.data.status);
        _this.props.saveAppointmentData(res.data.data);
      }
    });
  };

  // check if user have a current booking
  callBookingStatusAPI = (bookingID, callback) => {
    console.log("Booking Details API Route Called");
    let apiCallObj = {
      reqType: "GET",
      reqURL: getBookingDetailsApiRoute,
      dataObj: {},
      params: { booking_id: bookingID },
      headers: {},
      successCallback: async res => {
        console.log("Call booking api success: ", res);
        callback(res);
      },
      failureCallback: err => {
        console.log("Got the error ----------: ", err);

        callback(false);
      },
      showPopUp: false
    };
    this.setState({ apiCall: apiCallObj });
  };

  bookingStatus = async data => {
    await this.props.bookingStatus(data);
  };

  okayDoctorAccepted = () => {
    this.setState({
      callFromDoc: false
    });
  };

  // accept call from the doctor
  acceptCallFromDoc = async () => {
    this.pauseAudio();

    let _this = this;

    // when the patient tries to accept and the doctor have already called him and he had missed it so before proceeding we will check the booking status
    _this.callBookingStatusAPI(_this.state.appointmentId, async function(res) {
      if (res !== false && res.data.data.status <= 3) {
        if (_this.state.isDirectCall) {
          _this.props.history.push(appRoutes.consultationDetails);
          await _this.props.saveRequestAcceptedStatus(true);
        }

        // get the browser details
        let browserDetails = getBrowserDetails();

        var formData = new FormData();
        formData.append("appointmentId", _this.state.appointmentId);
        //appointmentId as sessionId for now
        // formData.append("sessionId", _this.state.appointmentId); // removed it for now for testing
        formData.append("sessionId", _this.state.sessionId);
        formData.append("device_model", browserDetails.device_model);
        formData.append("os_version", browserDetails.os_version);
        formData.append("app_version", browserDetails.app_version);
        formData.append("browser_name", browserDetails.browser_name);
        let apiCallObj = {
          reqType: "PUT",
          reqURL: acceptCallApiRoute,
          dataObj: formData,
          params: {},
          headers: {},
          successCallback: async res => {
            _this.setState({
              showVideoModal: true,
              callFromDoc: false
            });
            // _this.startLocalVideo();
            await _this.props.saveVideoCallStatus(true);
            await _this.props.saveCallWaitTime(null);

            let appointmentId = _this.state.appointmentId;

            send({
              type: "initiateVideoCall",
              directCall: _this.state.isDirectCall,
              data: {
                from: "patient",
                appointmentId: appointmentId,
                type: "initiateVideoCall",
                directCall: _this.state.isDirectCall
              },
              from: "patient"
            });

            setTimeout(()=>{
              console.log("set timeout",_this.state.isDirectCall);
              send({
                type: "initiateVideoCall",
                directCall: _this.state.isDirectCall,
                data: {
                  from: "patient",
                  appointmentId: appointmentId,
                  type: "initiateVideoCall",
                  directCall: _this.state.isDirectCall
                },
                from: "patient"
              });
            },3000);

            setTimeout(()=>{
              console.log("set timeout",_this.state.isDirectCall);
              send({
                type: "initiateVideoAgain",
                directCall: _this.state.isDirectCall,
                data: {
                  from: "patient",
                  appointmentId: appointmentId,
                  type: "initiateVideoAgain",
                  directCall: _this.state.isDirectCall
                },
                from: "patient"
              });
            },8000);

            // _this.connectCallApi(); // removed one time call is I don't think it's right way to call
          },
          failureCallback: err => {
            console.log("Got the error : ", err);
          },
          showPopUp: false
        };
        _this.setState({
          apiCall: apiCallObj
        });
      } else {
        _this.setState({ callFromDoc: false });
        _this.goToConsultReqPage();
      }
    });
  };

  // reject call from the doctor
  rejectCallFromDoc = async () => {

    logFirebaseEvent("User Declined Call");

    this.pauseAudio();

    let _this = this;

    // when the patient tries to accept and the doctor have already called him and he had missed it so before proceeding we will check the booking status
    _this.callBookingStatusAPI(_this.state.appointmentId, function(res) {
      if (res !== false && res.data.data.status <= 3) {
        var formData = new FormData();
        formData.append("appointmentId", _this.state.appointmentId);
        //appointmentId as sessionId for now
        // formData.append("sessionId", _this.state.appointmentId); // change here
        formData.append("sessionId", _this.state.sessionId);

        let apiCallObj = {
          reqType: "PUT",
          reqURL: rejectCallApiRoute,
          dataObj: formData,
          params: {},
          headers: {},
          successCallback: res => {
            _this.setState({
              callFromDoc: false
            });
          },
          failureCallback: err => {
            //console.log("Got the error : ", err);
          },
          showPopUp: false
        };
        _this.setState({
          apiCall: apiCallObj
        });
        _this.goToConsultReqPage();
      } else {
        _this.setState({ callFromDoc: false });
        _this.goToConsultReqPage();
      }
    });
  };

  // when the doctor ends the call
  docEndsCall = async () => {
    // confirm whether consult is successfully or not
    this.setState({
      callFromDoc: true,
      modalOkayButton: this.goToConsultSummaryOnEndCall,
      modalCancelButton: this.showDescriptionPopup,
      oneButton: false,
      modalCancelText: "No",
      modalOkayText: "Yes",
      modalBodyContent: (
        <p className="text-center mb-0">Was this consult successful?</p>
      ),
      isCallConnected: false, // default false
      doctorOffline: false, // doctor is default online
      timerForDoctorInternetLoss: null // timer for internet lost
    });
  };

  // if consult successful then go the summary and review page
  goToConsultSummaryOnEndCall = async () => {
    this.setState({
      callFromDoc: false,
      modalBodyContent: ""
    });
    await this.resetVideoCallReduxState();
    this.props.history.push(
      appRoutes.consultSummary + "/" + this.state.appointmentId
    );
    this.doCleanUponCallLeaving();
  };

  // otherwise show description page and the bring the user back to request consult page
  showDescriptionPopup = () => {
    this.setState({
      callFromDoc: false
    });

    this.setState({
      callFromDoc: true,
      modalCancelButton: this.goToConsultReqPage,
      oneButton: true,
      modalCancelText: "Okay",
      modalBodyContent: (
        <p className="text-center mb-0">
          Consults are considered unsuccessful <br />
          if you experienced a connection issue.
          <br />
          <br />
          Please call Instant Consult Support
          <br />
          on 1300 003 310 and we will assist in
          <br />
          resolving this matter for you promptly.
          <br />
        </p>
      )
    });
  };

  // do the clearn up and move to the consult request page
  goToConsultReqPage = async () => {
    this.setState({
      callFromDoc: false,
      modalBodyContent: "",
      showVideoContainer: false, // hide draggable video container
      missedCallFromDoctorPMS: false, // if pop-up opens due to PMS,
      showMissedCallPopup: false
    });
    await this.doCleanUponCallLeaving();
    this.props.saveAppointmentData(null);
    this.props.history.push(appRoutes.requestAConsult);
  };

  handleOffer = (offer, name) => {
    connectedUser = name;
    let _this = this;
    this.startLocalVideo(() => {
      console.log("Now starting local video in patient");
      // yourConn.setRemoteDescription(new RTCSessionDescription(offer));

      // New with safari
      yourConn.setRemoteDescription(offer).then(() => {
        console.log("Remote description is set");
      }).catch((e) => {
        console.log("error in handleAnswer(): ", e);
      });

      //create an answer to an offer
      // yourConn.createAnswer(
      //   function(answer) {
      //     yourConn.setLocalDescription(answer);

      //     let appointmentId = _this.props.commonReducers.appointmentData && _this.props.commonReducers.appointmentData._id ? _this.props.commonReducers.appointmentData._id : _this.state.appointmentId;
      //     console.log(
      //       "Checking appointment Id : ",
      //       appointmentId
      //     );
      //     send({
      //       type: "answer",
      //       answer: answer,
      //       appointmentId: appointmentId,
      //       from: "patient"
      //     });
      //   },
      //   function(error) {
      //     alert("Error when creating an answer");
      //   }
      // );

      // create an anser to an offer - safari support
      yourConn.createAnswer().then((desc) => {
        yourConn.setLocalDescription(desc).then(() => {
          let appointmentId = _this.props.commonReducers.appointmentData && _this.props.commonReducers.appointmentData._id ? _this.props.commonReducers.appointmentData._id : _this.state.appointmentId;
          console.log(
            "Checking appointment Id : ",
            appointmentId
          );
          send({
            type: "answer",
            answer: desc,
            appointmentId: appointmentId,
            from: "patient"
          });
        }).catch((e) => {
          console.log("Error in setLocalDescription : ", e);
        });
      }).catch((e) => {
        console.log("Something went wrong in create answer: ", e);
      })
    });
  };

  //when we got an answer from a remote user
  handleAnswer = answer => {
    yourConn.setRemoteDescription(new RTCSessionDescription(answer));
  };

  //when we got an ice candidate from a remote user
  handleCandidate = candidate => {
    // // setTimeout(() => {
    //   yourConn.addIceCandidate(new RTCIceCandidate(candidate));
    // // }, 5000);

    // Safari support
    yourConn.addIceCandidate(candidate).catch(e => {
      console.log("Failure during addIceCandidate(): " + e.name);
    });
  };

  // if doctor ended a call then there is no device issue from doctor side
  doctorEndedCall = () => {
    this.setState({
      doctorEndedCall: true,
      isCallConnected: false,
      doctorOffline: false, // doctor is default online
      timerForDoctorInternetLoss: null // timer for internet lost
    });
  };

  // start the video call
  startLocalVideo = callback => {
    let _this = this;
    _this.setState({
      showVideoContainer: true
    });
    // navigator.getUserMedia =
    //   navigator.getUserMedia ||
    //   navigator.webkitGetUserMedia ||
    //   navigator.mozGetUserMedia;

    navigator.mediaDevices.getUserMedia(
      { video: this.state.videoAllow, audio: this.state.audioAllow }).then((myStream) => {
        stream = myStream;

        // //displaying local video stream on the page
        // let video = document.querySelector("#localVideo");
        // video.load();
        // video.srcObject = new MediaStream(stream);
        // video.onloadedmetadata = function(e) {
        //   video.play();
        // };

        // displaying local video stream on the page - safari support
        const localVideo = document.querySelector('#localVideo');
        console.log('Received local stream');
        let localStream = myStream;
        localVideo.srcObject = myStream;
        localStream.getTracks().forEach(track => yourConn.addTrack(track, localStream));
        console.log('Adding Local Stream to peer connection');

        // setup stream listening
        // yourConn.addStream(stream);

        // safari support
        //when a remote user adds stream to the peer connection, we display it
        yourConn.ontrack = (e) => {
          console.log("--------- On Add Stream ---------------");
          const remoteVideo = document.querySelector('video#remoteVideo');
          if (remoteVideo.srcObject !== e.streams[0]) {
            remoteVideo.srcObject = new MediaStream(e.streams[0]);
            console.log('Received remote stream');
            _this.startVideoTimer();
          }
        };

        // //when a remote user adds stream to the peer connection, we display it
        // yourConn.onaddstream = e => {
        //   let video2 = document.querySelector("#remoteVideo");
        //   video2.load();
        //   video2.srcObject = new MediaStream(e.stream);
        //   video2.onloadedmetadata = function(e) {
        //     video2.play();
        //     _this.startVideoTimer();
        //   };
        // };

        // Setup ice handling
        yourConn.onicecandidate = event => {
          console.log("patient Asking for iceCandidate: ", event);
          if (event.candidate) {

            let appointmentId = _this.props.commonReducers.appointmentData && _this.props.commonReducers.appointmentData._id ? _this.props.commonReducers.appointmentData._id : _this.state.appointmentId;
            console.log(
              "Appointment Id before candidate ans ------------------------------- : ",
              appointmentId
            );

            send({
              type: "candidate",
              candidate: event.candidate,
              appointmentId: appointmentId,
              from: "patient"
            });
          }
        };

        yourConn.oniceconnectionstatechange = () => {
          console.log(
            "Inside on Ice connection state change ",
            yourConn.iceConnectionState
          );
          if (yourConn.iceConnectionState === "connected") {
            //when a remote user adds stream to the peer connection, we display it
            console.log(
              "Both users are connected ",
              _this.state.isCallConnected
            );
            if (_this.state.isCallConnected === false) {
              _this.connectCallApi();
            }
          } else if (yourConn.iceConnectionState === "disconnected") {
            console.log("Disconnected");
            if (_this.state.doctorEndedCall === false) {
              console.log("Doctor disconnected so increse count");
              if (_this.state.doctorOffline === false) {
                _this.addIssueWithDoctorDeviceOnCall();
              } else {
                _this.addIssueWithPatientDeviceOnCall();
              }
              // if(_this.props.commonReducers.appointmentData){
              //   let dataToSend = {
              //     type: "videoConnectAttempt",
              //     appointmentId: _this.props.commonReducers.appointmentData._id,
              //     from: "doctor"
              //   };
              //   console.log("Sending attempt data: ", dataToSend);
              //   send(dataToSend);
              // }
              _this.setState({
                doctorPoorConnection: true,
                doctorEndedCall: true
              });
            }
          }
        };

        callback();
      }).catch(e => console.log('getUserMedia() error: ' + e));;
      // ,
      // function(error) {
      //   console.log(error);
      // }
    // );
  };

  // show wait screen whent the doctor call end event receievd
  handleDoctorCallEnd = async data => {
    await this.props.saveWaitIfCallEnd(true);

    //if we don't get the other event till 5 second then call handle leave automatically
    // setTimeout(() => {
    //   // call this when we have not received event from the server even after 30 secs
    //   if (this.props.commonReducers.waitIfCallEnd !== false) {
    //     this.handleLeave(data);
    //   }
    // }, 30000);
  };

  //handle video call end
  handleLeave = async (data, isConsultCompleted = true) => {
    console.log("Now hanging up call");
    logFirebaseEvent("Post Consult Queueing Page");
    this.setState({
      showVideoContainer: false // hide draggable video container
    });

    if (stream && stream.getTracks()) {
      stream.getTracks().forEach(track => {
        console.log("Stopping video now");
        track.stop();
      });
    }

    // handle video timer reset upon window close
    this.resetTimerState();

    connectedUser = null;
    // remoteVideo.src = null;
    let remoteVideoEle = document.querySelector("#remoteVideo");
    if (remoteVideoEle) remoteVideoEle.srcObject = null;

    let localVideoEle = document.querySelector("#localVideo");
    if (localVideoEle) localVideoEle.srcObject = null;

    if (yourConn !== null) {
      // yourConn.close();
      yourConn.onicecandidate = null;
      yourConn.onaddstream = null;
      yourConn.oniceconnectionstatechange = null;
      yourConn = null;
    }

    this.setState({
      isCallConnected: false,
      doctorOffline: false, // doctor is default online
      timerForDoctorInternetLoss: null // timer for internet lost
    });

    if (isConsultCompleted === true) {
      this.setState({
        showVideoModal: false,
        callFromDoc: true,
        modalCancelButton: this.docEndsCall,
        oneButton: true,
        modalCancelText: "Okay",
        modalBodyContent: (
          <div className="row p-0 m-0 text-center">
            <div className="col-sm-12">
              <p
                className="requested-patient-name mb-0"
                dangerouslySetInnerHTML={{
                  __html: replaceNewLineWithBrTag(data.data.contents.en)
                }}
              ></p>
            </div>
          </div>
        ),
        isCallConnected: false
      });
    }

    this.props.saveWaitIfCallEnd(false);
  };

  // handle Microphone voice to mute and unmute
  handleMicrophone = status => {
    if (stream) {
      stream.getTracks().forEach(track => {
        console.log("Tracks are here : ", track.kind === "audio");
        if (track.kind === "audio") {
          track.enabled = status;
          this.setState({
            audioAllow: status
          });
        }
      });
    } else {
      console.log("Video not found");
    }
  };

  // handle Microphone video to display and hide
  handleVideo = status => {
    if (stream) {
      stream.getTracks().forEach(track => {
        console.log("Tracks are here : ", track.kind === "audio");
        if (track.kind === "video") {
          track.enabled = status;
          this.setState({
            videoAllow: status
          });
        }
      });
    } else {
      console.log("Video not found");
    }
  };

  // handle video container size
  handleWindowSize = state => {
    this.setState({
      videoContainerMinimize: state,
      videoContainerPosition: state ? { x: 105, y: 0 } : { x: 0, y: 0 }
    });
  };

  // Video Call timer when peer video connected
  startVideoTimer = () => {
    if (this.state.timernew1 === null) {
      console.log("TImer start");
      let startTimer = this.state.timernewvalue;
      startTimer++;
      let d = Number(startTimer),
        h = Math.floor(d / 3600),
        m = Math.floor((d % 3600) / 60),
        s = Math.floor((d % 3600) % 60),
        hDisplay = h > 0 ? h + (h === 1 ? " hour : " : " hours : ") : "",
        mDisplay = m > 0 ? m + (m === 1 ? " min : " : " mins : ") : "",
        sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";

      this.setState({
        videoTimerValue: hDisplay + mDisplay + sDisplay
      });

      this.props.saveVideoCallTimerValue(startTimer);

      this.setState({
        timernew1: setInterval(async () => {
          if (this.state.timernew1 !== null) {
            startTimer++;
            let d = Number(startTimer),
              h = Math.floor(d / 3600),
              m = Math.floor((d % 3600) / 60),
              s = Math.floor((d % 3600) % 60);
            let hDisplay =
              h > 0 ? h + (h === 1 ? " hour : " : " hours : ") : "";
            let mDisplay = m > 0 ? m + (m === 1 ? " min : " : " mins : ") : "";
            let sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
            this.setState({
              videoTimerValue: hDisplay + mDisplay + sDisplay,
              timernewvalue: startTimer
            });
            this.props.saveVideoCallTimerValue(startTimer);
          }
        }, 1000)
      });
    }
  };

  // pause video call timer
  stopVideoTimer = async () => {
    console.log("timer paused");
    console.log("Interval ID:", this.state.timernew1);
    clearInterval(this.state.timernew1);
    this.setState({
      timernew1: null
    });
  };

  resetVideoCallReduxState = async () => {
    await this.props.saveCallWaitTime(null);
    await this.props.saveVideoCallStatus(false);
    await this.props.saveVideoCallTimerValue(0);
    await this.props.saveIsDirectCallPMS(false);
  };

  closeMissedCallPopup = async () => {
    this.setState({
      showMissedCallPopup: false,
      doctorPoorConnection: false,
      missedCallFromDoctorPMS: false
    });

    await this.resetVideoCallReduxState();
    await this.goToConsultReqPage();
  };

  //handle missed call
  handleDocMissedCall = () => {
    this.resetTimerState();

    //? I (Vicky) Don't think it's really necessary to handle end call by patient. We can have a timeout in backend and it will handle automatically.
    // let endCallBodyData = new FormData();
    // endCallBodyData.append("appointmentId", this.state.appointmentId);
    // endCallBodyData.append("sessionId", this.state.sessionId);

    // endCallBodyData.append("callStatus", "MISSED");

    // let apiCallObj = {
    //   reqType: "PUT",
    //   reqURL: endCallByPatient,
    //   dataObj: endCallBodyData,
    //   params: {},
    //   headers: {},
    //   successCallback: async res => {},
    //   failureCallback: async err => {},
    //   showPopUp: false
    // };
    // this.setState({
    //   apiCall: apiCallObj
    // });
  };

  // handle poor connection call disconnect event
  handleDocPoorConnection = callStatus => {
    this.resetTimerState();

    if (stream && stream.getTracks()) {
      stream.getTracks().forEach(track => {
        console.log("Stopping video now");
        track.stop();
      });
    }

    connectedUser = null;
    // remoteVideo.src = null;
    let remoteVideoEle = document.querySelector("#remoteVideo");
    if (remoteVideoEle) remoteVideoEle.srcObject = null;

    let localVideoEle = document.querySelector("#localVideo");
    if (localVideoEle) localVideoEle.srcObject = null;

    // yourConn.close();
    yourConn.onicecandidate = null;
    yourConn.onaddstream = null;
    yourConn.oniceconnectionstatechange = null;
    yourConn = null;
    this.setState({
      isCallConnected: false,
      doctorOffline: false, // doctor is default online
      timerForDoctorInternetLoss: null // timer for internet lost
    });

    //? I (Vicky) Don't think we need  to create endCallByPatient API as we can have timeout in backend and it will automatically handle this for us. So, we don't have to be depend on Patient to call this API
    // let endCallBodyData = new FormData();
    // endCallBodyData.append("appointmentId", this.state.appointmentId);
    // // endCallBodyData.append("sessionId", this.state.appointmentId);
    // endCallBodyData.append("sessionId", this.state.sessionId);

    // endCallBodyData.append("callStatus", callStatus); // even if the consult gets disconnected we will treat this consult as successfull

    // let apiCallObj = {
    //   reqType: "PUT",
    //   reqURL: endCallByPatient,
    //   dataObj: endCallBodyData,
    //   params: {},
    //   headers: {},
    //   successCallback: async res => {
    //     this.setState({
    //       doctorPoorConnection: false
    //     });
    //     await this.resetVideoCallReduxState();
    //     await this.goToConsultReqPage();
    //   },
    //   failureCallback: async err => {
    //     this.setState({
    //       doctorPoorConnection: false
    //     });
    //     await this.resetVideoCallReduxState();
    //     await this.goToConsultReqPage();
    //   },
    //   showPopUp: false
    // };

    // do not call end consult api in this case, only call this in PMS direct call case\
    if (
      this.props.commonReducers.appointmentData !== null &&
      (this.props.commonReducers.appointmentData.directCall === true ||
        this.state.isDirectCall === true)
    ) {
      // this.setState({ apiCall: apiCallObj });
    } else {
      // else just close the popup
      this.setState({
        doctorPoorConnection: false
      });
    }
  };

  // Pause Audio of doctor calling
  pauseAudio = () => {
    const audioEl = document.getElementsByClassName("callAudioPatient")[0];
    audioEl.pause();
  };

  // Play Audio of doctor calling
  playAudio = () => {
    const audioEl = document.getElementsByClassName("callAudioPatient")[0];
    audioEl.play();
  };

  // if patient disconnected somehow or remote video is not connected until 10 sec
  addIssueWithPatientDeviceOnCall = () => {
    if (this.props.commonReducers.appointmentData) {
      console.log(
        "@@@@@@@@@@@@@@@@ Sending Video Connect Attempt from iceCandidate @@@@@@@@@@@@@@@@@@"
      );
      let dataToSend = {
        type: "videoConnectAttempt",
        appointmentId: this.props.commonReducers.appointmentData._id,
        from: "patient"
      };
      console.log("Sending attempt data: ", dataToSend);
      send(dataToSend);
    }
  };

  addIssueWithDoctorDeviceOnCall = () => {
    if (this.props.commonReducers.appointmentData) {
      console.log(
        "@@@@@@@@@@@@@@@@ Sending Video Connect Attempt from iceCandidate @@@@@@@@@@@@@@@@@@"
      );
      let dataToSend = {
        type: "videoConnectAttempt",
        appointmentId: this.props.commonReducers.appointmentData._id,
        from: "doctor"
      };
      console.log("Sending attempt data: ", dataToSend);
      send(dataToSend);
    }
  };

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { deltaPosition, controlledPosition } = this.state;

    let drText = "";

    if (
      this.props.commonReducers.appointmentData !== null &&
      this.props.commonReducers.appointmentData.doctorId !== undefined
    ) {
      drText =
        drText +
        "You have missed Dr. " +
        this.props.commonReducers.appointmentData.doctorId.firstName +
        " " +
        this.props.commonReducers.appointmentData.doctorId.lastName +
        "'s call.";
    }

    return (
      <div className="socket-handler">
        {this.state.callFromDoc ? (
          <CustomModal
            openModal={this.state.callFromDoc}
            modalBodyContent={this.state.modalBodyContent}
            okayText={this.state.modalOkayText}
            cancelText={this.state.modalCancelText}
            title="Instant Consult"
            useOneButton={this.state.oneButton}
            okayEvent={this.state.modalOkayButton}
            cancelEvent={this.state.modalCancelButton}
            backdrop="static"
          />
        ) : (
          ""
        )}
        <MakeAPICall apiParam={this.state.apiCall} />
        <MakeAPICall apiParam={this.state.apiCall1} />
        {this.state.showVideoModal ? (
          <Draggable
            defaultPosition={this.state.videoContainerPosition}
            handle="strong"
            {...dragHandlers}
          >
            <div
              className={
                this.state.showVideoContainer
                  ? this.state.videoContainerMinimize
                    ? "box no-cursor video-container video-container-small"
                    : "box no-cursor video-container video-container-full"
                  : "d-none position-relative"
              }
            >
              <div className="position-relative h-100">
                <div className="float-left">
                  {/*
                    {this.props.commonReducers.waitIfCallEnd === false &&
                    this.props.commonReducers.appointmentData &&
                    (this.props.commonReducers.appointmentData.isBulkBilled ===
                      undefined ||
                      this.props.commonReducers.appointmentData.isBulkBilled ===
                        false) && <p>{this.state.videoTimerValue}</p>}
                 */}
                </div>
                <strong className="cursor float-right cursor-pointer p-1">
                  <div>
                    <Button
                      id="maximiseVideo"
                      title="Maximise Video"
                      className={
                        this.state.videoContainerMinimize
                          ? "video-zoom-action mr-2"
                          : "d-none"
                      }
                      clickEvent={() => this.handleWindowSize(false)}
                    />
                    {/* <i
                      className={
                        this.state.videoContainerMinimize
                          ? "fas fa-window-maximize mr-2 cursor-pointer"
                          : "d-none"
                      }
                      onClick={() => this.handleWindowSize(false)}
                    ></i> */}
                    <Button
                      id="minimizeVideo"
                      title="Minimise Video"
                      className={
                        !this.state.videoContainerMinimize
                          ? "video-zoom-action mr-2"
                          : "d-none"
                      }
                      clickEvent={() => this.handleWindowSize(true)}
                    />
                    {/* <i
                      className={
                        !this.state.videoContainerMinimize
                          ? "fas fa-compress-arrows-alt mr-2 cursor-pointer"
                          : "d-none"
                      }
                      onClick={() => this.handleWindowSize(true)}
                    ></i> */}
                    <i
                      className={
                        !this.state.videoContainerMinimize
                          ? "d-none"
                          : "fas fa-arrows-alt cursor-pointer"
                      }
                    ></i>
                  </div>
                </strong>
                <div className="clearfix w-100"></div>
                {this.props.commonReducers.waitIfCallEnd === true && (
                  <div
                    style={{ position: "absolute", top: 0, left: 0 }}
                    className="col-md-12 h-100 d-flex align-items-center justify-content-center"
                  >
                    <p
                      className="text-center mb-0"
                      style={{ fontSize: "25px" }}
                    >
                      Please Wait....
                    </p>
                  </div>
                )}
                <div
                  className={
                    this.props.commonReducers.waitIfCallEnd === false
                      ? "row p-0 m-0"
                      : "d-none"
                  }
                >
                  <div className="col-sm-4 p-2">
                    <video
                      autoPlay={true}
                      id="localVideo"
                      muted
                      className="w-100"
                    ></video>
                  </div>
                  <div className="col-sm-8 p-2">
                    <video
                      autoPlay={true}
                      id="remoteVideo"
                      className="w-100"
                    ></video>
                  </div>
                  {/* <div className="control-buttons col-sm-12 text-center d-flex flex-row justify-content-center">
                  <div
                    className={
                      this.state.audioAllow
                        ? "float-left ml-2 video-call-btn mircophone"
                        : "d-none"
                    }
                    onClick={() => this.handleMicrophone(false)}
                  >
                    <i className="fas fa-microphone"></i>
                  </div>
                  <div
                    className={
                      !this.state.audioAllow
                        ? "float-left ml-2 video-call-btn mircophone"
                        : "d-none"
                    }
                    onClick={() => this.handleMicrophone(true)}
                  >
                    <i className="fas fa-microphone-slash"></i>
                  </div>
                  <div
                    className={
                      this.state.videoAllow
                        ? "float-left ml-2 video-call-btn video-on-btn"
                        : "d-none"
                    }
                    onClick={() => this.handleVideo(false)}
                  >
                    <i className="fas fa-video"></i>
                  </div>
                  <div
                    className={
                      !this.state.videoAllow
                        ? "float-left ml-2 video-call-btn video-on-btn"
                        : "d-none"
                    }
                    onClick={() => this.handleVideo(true)}
                  >
                    <i className="fas fa-video-slash"></i>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </Draggable>
        ) : (
          ""
        )}
        {/* <CustomModal
          openModal={this.state.doctorDisconnected}
          modalBodyContent="There is an issue with doctor device. Please contact Instant Consult support team"
          okayText="Okay"
          cancelText="Okay"
          title="Doctor Disconnected"
          useOneButton={true}
          okayEvent={this.handleDisconnectedCallFromDoc}
          cancelEvent={this.handleDisconnectedCallFromDoc}
          backdrop="static"
        /> */}

        {/* Connection Lost Model */}
        <CustomModal
          openModal={this.state.connectionLostModel}
          modalBodyContent={
            <p>
              Waiting for connection to server. <br /> Ensure you are connected
              to the internet. <br /> Please contact Instant Consult Support{" "}
              <br /> on 1300 003 310 if this issue persists.
            </p>
          }
          okayText="Okay"
          cancelText="Okay"
          title="Connection Lost"
          useOneButton={true}
          okayEvent={() => console.log("Clicked")}
          cancelEvent={() => console.log("Cancel clicked")}
          backdrop="static"
        />

        {/* Poor Connection On Doctor side - Ending call from patient */}
        <CustomModal
          openModal={this.state.doctorPoorConnection}
          modalBodyContent={
            <p>
              This consult has ended due to poor connection. <br /> Please
              contact our support team on 1300 003 310 <br /> so we can assist.
            </p>
          }
          okayText="Okay"
          cancelText="Okay"
          title="Poor Connection"
          useOneButton={true}
          okayEvent={() => this.handleDocPoorConnection("COMPLETE")}
          cancelEvent={() => this.handleDocPoorConnection("COMPLETE")}
          backdrop="static"
        />

        <CustomModal
          openModal={this.state.doctorWillCallSoonModal}
          modalBodyContent={
            <p
              className="p-2"
              dangerouslySetInnerHTML={{
                __html: this.state.doctorWillCallSoonText
              }}
            ></p>
          }
          okayText="Okay"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          okayEvent={() => this.setState({ doctorWillCallSoonModal: false })}
          cancelEvent={() => this.setState({ doctorWillCallSoonModal: false })}
          backdrop="static"
        />

        {/* Missed call pop-up when doctor call from PMS */}
        <CustomModal
          openModal={this.state.missedCallFromDoctorPMS}
          modalBodyContent={
            <p>
              If you were not alerted of the practitioner's call, <br /> there
              is an issue with your device. <br /> <br /> Please call our
              support team on 1300 003 310 and we will assist in connecting you
              with a practitioner promptly.
            </p>
          }
          okayText="Okay"
          cancelText="Okay"
          title="Missed Call"
          useOneButton={true}
          okayEvent={() => this.goToConsultReqPage()}
          cancelEvent={() => this.goToConsultReqPage()}
          backdrop="static"
        />

        <CustomModal
          modalBodyContent={
            <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-center">
              <p className="requested-patient-name text-center w-100 mt-3 mb-0">
                {drText}
              </p>
              <p className="requested-patient-name text-center w-100 mt-3 mb-0">
                If you were not alerted of the doctor's call,
                <br />
                there is an issue with your device.
                <br />
                <b />
                Please call our support team on 1300 003 310 and we will assist
                in connecting you with a doctor promptly.
              </p>
            </div>
          }
          cancelText="Okay"
          title="Missed Call"
          useOneButton={true}
          cancelEvent={this.closeMissedCallPopup}
          openModal={this.state.showMissedCallPopup}
          backdrop="static"
        />

        <audio className="callAudioPatient d-none" loop>
          <source src={callTune}></source>
        </audio>
      </div>
    );
  }
}

// export default SocketHandler;

// // get the store
const mapStateToProps = props => ({
  auth: props.auth.user,
  commonReducers: props.commonReducers
});

const mapDispatchToProps = dispatch => ({
  bookingStatus: data => dispatch(bookingStatus(data)),
  saveAppointmentData: data => dispatch(saveAppointmentData(data)),
  saveVideoCallStatus: data => dispatch(saveVideoCallStatus(data)),
  saveVideoCallTimerValue: data => dispatch(saveVideoCallTimerValue(data)),
  saveCallWaitTime: data => dispatch(saveCallWaitTime(data)),
  saveWaitIfCallEnd: data => dispatch(saveWaitIfCallEnd(data)),
  saveRequestAcceptedStatus: data => dispatch(saveRequestAcceptedStatus(data)),
  notificationNumber: data => dispatch(notificationNumber(data)),
  saveIsDoctorCalling: data => dispatch(saveIsDoctorCalling(data)),
  saveCallSessionData: data => dispatch(saveCallSessionData(data)),
  saveIsDirectCallPMS: data => dispatch(saveIsDirectCallPMS(data)),
  saveHomePageDataForQueueInfo: data => dispatch(saveHomePageDataForQueueInfo(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SocketHandler));

function send(message) {
  //attach the other peer username to our messages
  if (connectedUser) {
    message.name = connectedUser;
  }
  console.log("patient got the connection here: ", conn);
  conn.send(JSON.stringify(message));
}
