const AUD = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD"
});

export function replaceNewLineWithBrTag(str) {
  if (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }

  return "";
}

export function logFirebaseEvent(eventName) {
  //console.log("firebase event logged")
  if (
    window.location.origin.indexOf("staging-patient") >= 0 ||
    window.location.origin.indexOf("rebuild-dev-patient") >= 0 ||
    window.location.origin.indexOf("localhost") >= 0
  ) {
    console.log("Using Dev Mode");
  } else {
    window.firebase.analytics().logEvent(eventName);

    // send facebook pixel events
    try {
      const trackingName = eventName ? eventName.replace(/\s+/g, "") : "";

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", trackingName, {
            content_name: eventName
          });
        }
      }
    } catch {
      console.log("Facebook event not triggered");
    }
  }
}

export function getConsultDuraionInRedableFormat(duration) {
  if (duration) {
    let _seconds = Math.floor((duration / 1000) % 60),
      _minutes = Math.floor((duration / (1000 * 60)) % 60),
      _hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    let hours = _hours < 10 ? "0" + _hours : _hours;
    let minutes = _minutes < 10 ? "0" + _minutes : _minutes;
    let seconds = _seconds < 10 ? "0" + _seconds : _seconds;

    let res = "";

    if (_hours !== 0) {
      res = res + hours;

      if (_hours === 1) {
        res = res + "hr ";
      } else {
        res = res + "hrs ";
      }
    }

    if (_minutes !== 0) {
      res = res + minutes;

      if (_minutes === 1) {
        res = res + "min ";
      } else {
        res = res + "mins ";
      }
    } else {
      res = res + " 0min ";
    }

    if (_seconds !== 0) {
      res = res + seconds;

      if (_seconds === 1) {
        res = res + "sec ";
      } else {
        res = res + "secs ";
      }
    }

    return res;
  }

  return "";
}

export function getFormattedAddress(addressObj) {
  if (!addressObj) return "";

  let address = "";
  if (addressObj.unitNumber !== "" && addressObj.unitNumber !== null) {
    address = addressObj.unitNumber + "/";
  }
  address +=
    addressObj.streetNumber +
    " " +
    addressObj.streetName +
    ", " +
    addressObj.suburb +
    " " +
    addressObj.state +
    " " +
    addressObj.postCode;

  return address;
}

export function getBrowserDetails() {
  var browserData = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iPhone", value: "iPhone", version: "OS" },
      { name: "iPad", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" }
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" }
    ],
    inits: function() {
      var agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function(string, data) {
      var i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return { name: "unknown", version: 0 };
    }
  };

  let browserDetails = browserData.inits();

  return {
    device_model: browserDetails.os.name,
    os_version: browserDetails.os.version,
    app_version: browserDetails.browser.version,
    browser_name: browserDetails.browser.name
  };
}

export function formatName(str) {
  let res = "";

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "'" && i !== str.length) {
      res = res + str[i] + str[i + 1].toUpperCase();
      i++;
    } else if (str[i] === "-" && i !== str.length) {
      res = res + str[i] + str[i + 1].toUpperCase();
      i++;
    } else {
      res = res + str[i];
    }
  }

  return res;
}

export function getFormattedMoney(amount) {
  return AUD.format(amount);
}

export function linkify(text) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function(url) {
    return (
      '<a href="' +
      url +
      '" target="_blank" class="primary-color">' +
      url +
      "</a>"
    );
  });
}

export const IHIStates = {
  NOT_LOADED: "Verify My IHI Number",
  FAILED: "IHI Number Could Not Be Generated",
  SUCCESS: "IHI Number Generated Successfully"
};
