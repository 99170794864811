import {inboxApiRoute, inboxFavApiRoute, inboxDelApiRoute} from "../../apiRoutes";
import React, {useRef, useCallback, useState, useEffect} from "react";
import { CircularProgress } from "@material-ui/core";
import Label from "../../components/label";
import InstaButton from "../../components/button";
import DefaultImage from "../../assets/images/default-user-img.png";
import IconButton from "../../components/iconButton";
import LogoSmall from "../../assets/images/logo-small-white.png";
import Modal from "../../components/modal";
import moment from "moment";
import { logFirebaseEvent } from "../../utils/common";
import common from "../../apiCalls/common";

export default function StickyHeadTable(props) {
  const observer = useRef();
  const [pageNo, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [limit] = useState(5);
  const [skipPrevData, setSkipPrevData] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [watchRowUpdate, setWatchRowUpdate] = useState(false);
  // Data to be appended
  const [rows, setRows] = useState([]);
  const [appendRows, setAppendRows] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [rowData, setRowData] = useState(null);
  const [rowIndex, setRowIndex] = useState(null);

  const lastElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(data => {
      if (data) {
        if (data[0].isIntersecting && nextPage && loading) {
          setLoading(false);
          if (loading) {
            setPageNumber(pageNo => pageNo + 1);
            let skipCalculate = limit * pageNo;
            setSkipPrevData(skipCalculate);
            getInboxMessages(limit, skipCalculate);
          }
          setTimeout(() => {
            setLoading(true);
          }, 2000);
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  const openAttachment = (value) => {
    logFirebaseEvent("Inbox Document Opened")
    window.open(value);
  };

  const getInboxMessages = (limitPage, skipPage) => {
    let params = null;
    if(props.filterByDate&&props.onlyFav&&props.searchKeyword!=="")
    {
      params = {
        limit: limitPage,
        skip: skipPage,
        startDate: props.startDate,
        endDate: props.endDate,
        isFavourite: true,
        searchParam: props.searchKeyword
      }
    }
    else if(props.filterByDate&&props.onlyFav) {
      params = {
        limit: limitPage,
        skip: skipPage,
        startDate: props.startDate,
        endDate: props.endDate,
        isFavourite: true
      }
    }
    else if(props.filterByDate&&props.searchKeyword!=="") {
      params = {
        limit: limitPage,
        skip: skipPage,
        startDate: props.startDate,
        endDate: props.endDate,
        searchParam: props.searchKeyword
      }
    }
    else if(props.onlyFav&&props.searchKeyword!=="") {
      params = {
        limit: limitPage,
        skip: skipPage,
        isFavourite: true,
        searchParam: props.searchKeyword
      }
    }
    else if(props.searchKeyword!=="") {
      params = {
        limit: limitPage,
        skip: skipPage,
        searchParam: props.searchKeyword
      }
    }
    else if(props.filterByDate) {
      params = {
        limit: limitPage,
        skip: skipPage,
        startDate: props.startDate,
        endDate: props.endDate
      }
    }
    else if(props.onlyFav) {
      params = {
        limit: limitPage,
        skip: skipPage,
        isFavourite: true
      }
    }
    else {
      params = {
        limit: limitPage,
        skip: skipPage,
      }
    }
    let apiCallParam = {
      reqType: "GET",
      reqURL: inboxApiRoute,
      dataObj: {},
      params,
      headers: {},
      successCallback: res => {
        const messageLength = res.data.data && res.data.data.messages ? res.data.data.messages.length : 0;
        if (skipPage === 0) {
          setRows(res.data.data.messages);
        } else {
          setRows(prev=>prev.concat(res.data.data.messages));
        }
        let currentRowCount = res.data.data.count - (pageNo * skipPage);
        if (currentRowCount > 0) {
          setNextPage(true);
        } else {
          setNextPage(false);
          setDataLoaded(true);
          setProgressBar(false);
        }
        if (!messageLength) {
          setDataLoaded(true);
          setNextPage(false);
          setProgressBar(false);
        }
        if(res.data.data.count > 0)
          props.setFiltersVisibility(true);
        else
          props.setFiltersVisibility(false);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    props.makeApiCall(apiCallParam);
  };

  const resetPagination = () => {
    setPageNumber(1);
    setNextPage(false);
    setLoading(true);
    setProgressBar(true);
    setSkipPrevData(0);
    setDataLoaded(false);
  };

  const onFavClick = (event, value, rowIndex) => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: inboxFavApiRoute+"/"+value._id,
      dataObj: {isFavourite: !value.isFavourite},
      params: {},
      headers: {},
      successCallback: res => {
        rows[rowIndex]["isFavourite"] = !value.isFavourite;
        if(props.onlyFav === true)
          delete rows[rowIndex];
        setWatchRowUpdate(!watchRowUpdate);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    props.makeApiCall(apiCallParam);
  };

  const openDeleteModal = (value, rowIndex) => {
    setDeleteModal(true);

    setRowData(value);
    setRowIndex(rowIndex);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onDeleteClick = () => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: inboxDelApiRoute+"/"+rowData._id,
      dataObj: {isDeleted: true},
      params: {},
      headers: {},
      successCallback: res => {
        delete rows[rowIndex];
        setWatchRowUpdate(!watchRowUpdate);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    props.makeApiCall(apiCallParam);
    setDeleteModal(false);
  };

  useEffect(()=> {
    //resetPagination();
    setRows(rows);
  }, [watchRowUpdate]);

  useEffect(()=> {
    resetPagination();
    setRows([]);
    getInboxMessages(limit, 0);
  }, [props.onlyFav, props.filterByDate, props.searchKeyword]);

  return (
    <ul className="list-group list-group-flush border">
      {
        rows && (
          rows.map((data, index) => {
            var title, img;
            if (data.doctorId && data.type !== "Consult Receipt") {
              title = "Dr " + data.doctorId.firstName + " " + data.doctorId.lastName;
              img = data.doctorId.profilePic.original;
            }
            else {
              title = "From Instant Consult";
              img = LogoSmall
            }
            if (rows.length === index + 1) {
              return (
                <li
                  className="list-group-item no-hover-li"
                  ref={lastElementRef}
                  key={index}
                >
                  <div className="col-sm-12 ml-3 mt-2 p-0">
                    <Label title={moment(data.createdAt).format("Do MMM YYYY h:mm A")} className="small secondary-font" />
                  </div>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="pt-2 fav-btn">
                          <i className={data.isFavourite ? 'fas fa-heart insta-link-orange cursor-pointer' : 'far fa-heart cursor-pointer'} onClick={(event) => onFavClick(event, data, index)} />
                        </div>
                        <div className="col-sm-3 consult-note-dropdown-group p-0" onClick={() => openAttachment(data.url)}>
                          {data.type.includes("Other") ? (
                            <i className="other-attachment fas fa-paperclip cursor-pointer" />
                          ) : (
                            <div className="consult-note-pdf-btn cursor-pointer">
                              PDF
                            </div>
                          )}
                          <InstaButton
                            id="caret"
                          title={(data.type === "Medical Certificate" && data.isForCarer === true) ? "Carer's Certificate" : data.type}
                            className="gray small text-left m-0 consult-note-btn font-12"
                          />
                        </div>
                        <div className="col-sm-4 pl-3 offset-sm-1">
                          <img
                            src={img}
                            alt={DefaultImage}
                            className="img-thumbnail inbox-img rounded-circle border-0 p-0 "
                          />
                          <Label
                            title={title}
                            className="p-2 secondary-font font-15"
                          />
                        </div>
                        <div className="col-sm-3 text-right">
                          <IconButton
                            title="Delete"
                            icon="fas fa-trash"
                            className="font-12 small red custom-i-color"
                            clickEvent={() => { openDeleteModal(data, index) }}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li className="list-group-item no-hover-li" key={index}>
                    <div className="col-sm-12 ml-3 mt-2 p-0">
                      <Label title={moment(data.createdAt).format("Do MMM YYYY h:mm A")} className="small secondary-font" />
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="pt-2 fav-btn">
                          <i className={data.isFavourite ? 'fas fa-heart insta-link-orange cursor-pointer' : 'far fa-heart cursor-pointer'} onClick={(event) => onFavClick(event, data, index)} />
                        </div>
                        <div className="col-sm-3 consult-note-dropdown-group p-0" onClick={() => openAttachment(data.url)}>
                          {data.type.includes("Other") ? (
                            <i className="other-attachment fas fa-paperclip cursor-pointer" />
                          ) : (
                            <div className="consult-note-pdf-btn cursor-pointer">
                              PDF
                            </div>
                          )}
                          <InstaButton
                            id="caret"
                            // title={data.type}
                            title={(data.type === "Medical Certificate" && data.isForCarer === true) ? "Carer's Certificate" : data.type}
                            className="gray small text-left m-0 consult-note-btn font-12"
                          />
                        </div>
                        <div className="col-sm-4 pl-3 offset-sm-1">
                          <img
                            src={img}
                            alt={DefaultImage}
                            className="img-thumbnail inbox-img rounded-circle border-0 p-0 "
                          />
                          <Label
                            title={title}
                            className="p-2 secondary-font font-15"
                          />
                        </div>
                        <div className="col-sm-3 text-right">
                          <IconButton
                            title="Delete"
                            icon="fas fa-trash"
                            className="font-12 small red custom-i-color"
                            clickEvent={() => { openDeleteModal(data, index) }}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
          })
        )
      }
        <li className={progressBar ? "list-group-item text-center list-border-none p-4" : 'd-none'}>
          <CircularProgress disableShrink className="primary-loader" />
        </li>
        <li className={dataLoaded ? "list-group-item text-center p-4" : 'd-none'}>
          <Label title="No data found" className="w-100 text-center" />
        </li>
      <Modal
          modalBodyContent="Do you want to delete this message?"
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={onDeleteClick}
          cancelEvent={closeDeleteModal}
          openModal={deleteModal}
      />
      </ul>
  );
}

