import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

const IconButton = props => (
  <Button
    type={props.type}
    color="primary"
    className={
      "insta-button-primary insta-button-icon " +
      (props.disabled ? "disabled " : "") +
      (props.className || "")
    }
    onClick={props.clickEvent}
    disabled={props.disabled}
  >
    <i className={"insta-icon-btn " + props.icon}></i>{" "}
    <span className="insta-btn-span"> {props.title}</span>
  </Button>
);

export default IconButton;

IconButton.defaultProps = {
  title: "Submit",
  className: "",
  type: "button",
  disabled: false,
  icon: ""
};

IconButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string
};
