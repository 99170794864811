import React, { Component } from "react";
import Label from "../../components/label";
import SearchInput from "../../components/searchInput";
import ICDateInput from "../../components/form/dateInput";
import ConsultList from "../../containers/ConsultList";
import MakeAPICall from "../../apiCalls/common";
import Modal from "../../components/modal";
import moment from "moment";
import {logFirebaseEvent} from "../../utils/common";

class ConsultHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateErrorPopUp: false,
      dateEmptyPopUp: false,
      apiCall: "",
      startDate: null,
      endDate: null,
      filterByDate: false,
      searchKeyword: ""
    };
  }
  componentDidMount() {
    logFirebaseEvent("Consult History List Page");
    window.scrollTo(0, 0);
  }
  componentApiCall = data => {
    this.setState({
      apiCall: data
    });
  };
  filterByDate = () => {
    if (this.state.filterByDate === true) {
      this.setState({
        startDate: "",
        endDate: ""
      });
    }
    if (moment(this.state.startDate).isAfter(this.state.endDate)) {
      this.setState({
        dateErrorPopUp: true
      });
    } else if (this.state.startDate === "" || this.state.endDate === "") {
      this.setState({
        dateEmptyPopUp: true
      });
    } else {
      this.setState({
        filterByDate: !this.state.filterByDate
      });
    }
  };
  closeDateErrorPopUp = () => {
    this.setState({
      dateErrorPopUp: false,
      dateEmptyPopUp: false
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    )
      if (this.state.filterByDate === true)
        this.setState({
          filterByDate: false
        });
  }

  render() {
    return (
      <div className="row rounded p-3 notification-page">
        {/* Top Filters */}
        <div className="col-sm-3 p-3">
          <Label title="Consult History" className="big" />
        </div>

        <div className="col-sm-4 offset-sm-5 pt-3 text-right">
          <SearchInput
            className=""
            placeholder="Search"
            value={this.state.searchKeyword}
            onChange={e => this.setState({ searchKeyword: e.target.value })}
          />
        </div>
        <div className="border-bottom mt-2 mb-2 w-100" />

        <div className="col-sm-3 offset-sm-5">
          <ICDateInput
            id="date"
            name="date"
            label="Start Date"
            defaultDate={this.state.startDate}
            handleChange={date => this.setState({ startDate: moment(date).startOf("day").toISOString() 
          })}
            filterOn={this.state.filterByDate ? "" : this.state.filterByDate}
          />
        </div>
        <div className="col-sm-3">
          <ICDateInput
            id="date"
            name="date"
            label="End Date"
            defaultDate={this.state.endDate}
            handleChange={date => this.setState({ endDate: moment(date).endOf("day").toISOString() })}
            filterOn={this.state.filterByDate ? "" : this.state.filterByDate}
          />
        </div>
        {this.state.filterByDate ? (
          <div className="mt-2 col-sm-1 date-activated-btn">
            <button type="button" onClick={this.filterByDate}>
              <i className="fas fa-times" />
            </button>
          </div>
        ) : (
          <div className="mt-2 col-sm-1 date-deactivated-btn">
            <button type="button" onClick={this.filterByDate}>
              <i className="fas fa-arrow-right fa-2x" />
            </button>
          </div>
        )}

        <div className="col-sm-12 custom-li mt-3 pl-0 pr-0">
          <ConsultList
            filterByDate={this.state.filterByDate}
            searchKeyword={this.state.searchKeyword}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            makeApiCall={this.componentApiCall}
            history={this.props.history}
            clear={this.state.clear}
          />
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Start Date cannot be greater than End Date"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeDateErrorPopUp}
          openModal={this.state.dateErrorPopUp}
        />
        <Modal
          modalBodyContent="Please select both Start Date and End Date before proceeding"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeDateErrorPopUp}
          openModal={this.state.dateEmptyPopUp}
        />
      </div>
    );
  }
}

export default ConsultHistory;
