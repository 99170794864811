import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

const InstaButton = props => (
  <Button
    type={props.type}
    color="primary"
    className={
      "insta-button-primary " +
      (props.disabled ? "disabled " : "") +
      props.className
    }
    onClick={props.clickEvent}
    disabled={props.disabled}
  >
    {props.title}
  </Button>
);

export default InstaButton;

InstaButton.defaultProps = {
  title: "Submit",
  className: "",
  type: "button",
  disabled: false
};

InstaButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool
};
