import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import Button from "../../components/button";
import Error from "../../components/error";

const CovidHotspotForm = props => {
  const [showSelectInput, setShowSelectInput] = useState(false);

  const generateOptions = () => {
    let menuItems = [];
    props.covidHotSpots.map((val, index) => {
      return menuItems.push(
        <MenuItem style={{ fontSize: "14px" }} key={index} value={val.name}>
          {val.name}
        </MenuItem>
      );
    });
    return menuItems;
  };

  const formik = useFormik({
    initialValues: {
      selectedRadioOption: "Patient declared they are living in a COVID-19 impacted area:",
      selectedCovidLocation: ""
    },
    validationSchema: Yup.object({
      selectedCovidLocation: Yup.string().required("Please select COVID-19 hot spot")
    }),
    onSubmit: values => {
      if (
        values.selectedRadioOption ===
        "Patient declared they are living in a COVID-19 impacted area:"
      ) {
        var hotspotIndex = props.covidHotSpots.findIndex(x => x.name ===values.selectedCovidLocation);
        var areaType = props.covidHotSpots[hotspotIndex].type
        var isAreaHotspot = false
        if(areaType == "medicare") {
          isAreaHotspot = true
        }
        props.onFormSubmit(
          values.selectedRadioOption + " " + values.selectedCovidLocation,
          true,
          isAreaHotspot
        );
      } 
    }
  });
  return (
    <form
      className="d-flex flex-column text-left"
      onSubmit={formik.handleSubmit}
    >
      <FormControl component="fieldset" className="ICRadioBtn">
        <p className="label-text">I declare I am living in:</p>
            <FormControl
              className="ic-select w-100"
              name="selectedCovidLocation"
            >
              <InputLabel
                style={{ position: "inherit", fontSize: "14px" }}
                id="covid-hot-spot-label"
              >
                Please select your area
              </InputLabel>
              <Select
                labelId="covid-hot-spot-label"
                className="mb-2 mt-0"
                value={formik.values.selectedCovidLocation}
                displayEmpty
                onChange={event => {
                  formik.setFieldValue(
                    "selectedCovidLocation",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.selectedCovidLocation &&
                  formik.errors.selectedCovidLocation
                    ? true
                    : false
                }
                helperText={
                  formik.touched.selectedCovidLocation &&
                  formik.errors.selectedCovidLocation
                    ? formik.errors.selectedCovidLocation
                    : ""
                }
              >
                {props.covidHotSpots && props.covidHotSpots.length
                  ? generateOptions()
                  : ""}
              </Select>
            </FormControl>
      </FormControl>
      <br />
      <div className="d-flex flex-row align-items-center justify-content-center">
        <Button className="mt-2" title="Submit" type="submit" />
      </div>
    </form>
  );
};

export default CovidHotspotForm;

CovidHotspotForm.defaultProps = {
  onFormSubmit: () => {},
  covidHotSpots: []
};

CovidHotspotForm.propTypes = {
  onFormSubmit: PropTypes.func,
  covidHotSpots: PropTypes.array
};
