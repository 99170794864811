import React, { Component, Fragment } from "react";
import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import { connect } from "react-redux";
import {
  dependentProfileListAPI,
  addExistingPatientAsDependentAPI
} from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import { updateUserData } from "../../reduxStore/actions/auth";
import CustomModal from "../../components/customModal";
import { logFirebaseEvent } from "../../utils/common";
import FamilyProfileList from "../../containers/FamilyProfile/FamilyProfileList";
import SearchDependentEmailForm from "../../containers/FamilyProfile/SearchDependentEmailForm";
import IconButton from "../../components/iconButton";
import Button from "../../components/button";
import Loader from "../../components/loader";

class FamilyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      apiLoading: false,
      isAddDependentModalOpen: false,
      isExistingACModalOpen: false,
      isDependentSuccessModelOpen: false,
      isSuccessDependentSaveModelOpen: false,
      dependents: [],
      errorModelContent: "",
      isErrorDependentModelOpen: false
    };
  }
  componentDidMount() {
    logFirebaseEvent("Edit Family Profile Page");
    window.scrollTo(0, 0);

    this.getFamilyProfileList();
  }

  getFamilyProfileList = () => {
    let apiCallObj = {
      reqType: "GET",
      reqURL: dependentProfileListAPI,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          dependents: res.data.data.dependents ? res.data.data.dependents : []
        });
      },
      failureCallback: err => {
        console.log(err);
      }
    };

    this.setState({
      apiCall: apiCallObj
    });
  };

  okayEditProfileSuccess = () => {
    const { history } = this.props;
    history.push(appRoutes.myProfile);
  };

  openAddDependentModal = () => {
    this.setState({
      isAddDependentModalOpen: true
    });
  };

  gotoCreateNewAC = () => {
    this.setState({
      isAddDependentModalOpen: false
    });
    const { history } = this.props;
    history.push(appRoutes.addFamilyProfile);
  };

  existingAC = () => {
    this.setState({
      isAddDependentModalOpen: false,
      isExistingACModalOpen: true
    });
  };

  closeExistingEmailSearchModal = () => {
    this.setState({
      isExistingACModalOpen: false
    });
  };

  saveDependentDeatails = formValue => {
    this.setState({
      isExistingACModalOpen: false,
      apiLoading: true
    });

    let dataToSend = {
      parentId: this.props.auth.user[0]._id,
      email: formValue.email,
      password: formValue.password
    };

    let tmpStore = this.props.auth;
    delete tmpStore.auth;

    let apiCallObj = {
      reqType: "POST",
      reqURL: addExistingPatientAsDependentAPI,
      dataObj: dataToSend,
      params: {},
      headers: {},
      successCallback: async res => {
        console.log("Got the responce from ------ ", res);
        tmpStore.user[0] = res.data.data.updateParentData;
        await this.props.updateUserData(tmpStore);
        this.setState({
          isSuccessDependentSaveModelOpen: true,
          apiLoading: false
        });
        this.getFamilyProfileList();
      },
      failureCallback: err => {
        console.log(err);
        let errorMessage = err && err.message ? err.message : "Email Address / Password Incorrect";
        if(err.type === "WRONG_PASSWORD" || err.type === "INVALID_DEPENDENT_CREDENTIALS") {
          errorMessage = (
            <Fragment>
              <Label className="w-100 font-14" title="Email Address / Password Incorrect" />
              <Label className="mb-0 pb-0 w-100 font-14" title="Call 1300 003 310 if you require assistance"/>
            </Fragment>
          );
        } else if (err.type === "INVALID_AGE_FOR_DEPENDENT") {
          let messages = err.message?.split("\n") || [];
          if (messages.length) {
            errorMessage = (
              <Fragment>
                {
                  messages.map((message, index) => {
                    return <Label className={index ? "mb-0 pb-0 w-100 font-14" : "w-100 font-14"} title={message}/>
                  })
                }
              </Fragment>
            );
          } else {
            errorMessage = (
              <Fragment>
                <Label className="w-100 font-14" title="This account is could not be added"/>
                <Label className="mb-0 pb-0 w-100 font-14" title="Please Call 1300 003 310 for assistance"/>
              </Fragment>
            );
          }
        }
        this.setState({
          apiLoading: false,
          isErrorDependentModelOpen: true,
          errorModelContent: errorMessage
        });
      },
      showPopUp: false,
      showErrorRes: false
    };
    this.setState({
      apiCall: apiCallObj
    });
  };

  doAPICall(apiObj) {
    this.setState({
      apiCall: apiObj
    });
  }

  toggleLoading(isLoading) {
    this.setState({
      apiLoading: isLoading
    });
  }

  render() {
    return (
      <div
        className="container
        h-100 bg-white pb-5 mb-5"
      >
        <Loader loading={this.state.apiLoading} />
        <div className="row">
          <div className="col-sm-12 mt-4">
            <NavLink to={appRoutes.myProfile}>
              <i title="" className="fa fa-arrow-left primary-color" />
              <Label
                title="Back to My Profile"
                className="cursor-pointer font-15 on-hover-orange pl-2"
              />
            </NavLink>
          </div>

          <div className="col-sm mt-4 pb-3">
            <Label title="Family Profile" className="big" />
          </div>
          <div className="col-sm mt-4 pb-3 text-right">
            <IconButton
              className=""
              title="Add Dependent"
              icon="fas fa-plus"
              clickEvent={this.openAddDependentModal}
            />
          </div>
          <div className="border-bottom w-100" />
          {this.state.dependents.map((value, index) => {
            return (
              <div className="card w-100 mt-4" key={index}>
                <FamilyProfileList dependent={value} dependentIndex={index} doAPICall={(apiCallObj) => this.doAPICall(apiCallObj)} toggleLoading={(isLoading) => this.toggleLoading(isLoading)} />
              </div>
            );
          })}
          {this.state.dependents.length === 0 && (
            <Fragment>
              <div className="col-sm-12 text-center bg-light mt-4">
                <Label className="w-100 p-3" title="No Dependent Found" />
              </div>
            </Fragment>
          )}
        </div>
        <CustomModal
          openModal={this.state.isAddDependentModalOpen}
          cancelEvent={() => {
            this.setState({
              isAddDependentModalOpen: false
            });
          }}
          title="Add Dependent"
          modalBodyContent={
            <div className="row justify-content-center">
                <Button title="New Account" clickEvent={this.gotoCreateNewAC} />
              
                <Button title="Existing Account" clickEvent={this.existingAC} className="ml-3"/>
            </div>
          }
          noFooterButtons={true}
        />

        <CustomModal
          openModal={this.state.isExistingACModalOpen}
          title="Existing Account"
          modalBodyContent={
            <div className="row">
              <SearchDependentEmailForm
                closeModal={this.closeExistingEmailSearchModal}
                onFormSubmit={this.saveDependentDeatails}
              />
            </div>
          }
          noFooterButtons={true}
        />

        <CustomModal
          openModal={this.state.isSuccessDependentSaveModelOpen}
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            this.setState({
              isSuccessDependentSaveModelOpen: false
            });
          }}
          cancelText="Okay"
          modalBodyContent={<Label title="Your dependent has been successfully added to your Family Profile" />}
        />

        <CustomModal
          openModal={this.state.isErrorDependentModelOpen}
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            this.setState({
              isErrorDependentModelOpen: false
            });
          }}
          cancelText="Okay"
          modalBodyContent={this.state.errorModelContent}
        />
        <MakeAPICall apiParam={this.state.apiCall} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(updateUserData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyProfile);
