import React, { Component } from "react";

import PublicLayout from "../../containers/Layout/PublicLayout";
import ForgotPasswordForm from "../../containers/ForgotPasswordForm";

import { forgotPasswordApi } from "../../apiCalls/auth";

import LogoImg from "../../assets/images/group-3@3x.png";

import {logFirebaseEvent} from "../../utils/common";

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMsg: "",
      errorMsg: ""
    };
  }
  onFormSubmit = async formValues => {
    let res = await forgotPasswordApi(formValues);
    this.setState({
      successMsg: res.successMsg,
      errorMsg: res.errorMsg
    });

    setTimeout(() => {
      this.setState({
        successMsg: "",
        errorMsg: ""
      });
    }, 3000);
  };

  componentDidMount() {
    logFirebaseEvent("Forgot Password Page");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <PublicLayout>
        <div className="login-page-container container d-flex align-items-center justify-content-center">
          <div className="lg-page-inner-container frgt-pass-form-width">
            <div className="lp-header row">
              <div className="col-md-12">Forgot Password</div>
            </div>
            <div className="row lp-content-container d-flex justify-content-center align-items-center">
              <div className="col-md-12 logo-img-container d-flex justify-content-center align-items-center">
                <img src={LogoImg} alt="logo-img" />
              </div>
              <div className="col-md-12 d-flex justify-content-center align-items-center lg-form-container mt-4 pt-1">
                <ForgotPasswordForm
                  onFormSubmit={this.onFormSubmit}
                  serverErrorMsg={this.state.errorMsg}
                  successMsg={this.state.successMsg}
                />
              </div>
            </div>
          </div>
        </div>
      </PublicLayout>
    );
  }
}

export default ForgotPasswordPage;
