import moment from "moment";
import momentTz from "moment-timezone";

export function getFormattedDateTime(data) {
  return momentTz(data)
    .tz("Australia/Brisbane")
    .format("DD-MM-YYYY H:mm:ss");
}

export function getFormattedDate(data) {
  return momentTz(data)
    .tz("Australia/Brisbane")
    .format("DD-MM-YYYY");
}

export function getFormattedTime(data) {
  return momentTz(data)
    .tz("Australia/Brisbane")
    .format("H:mm:ss");
}

export function getFormattedTimeV2(data) {
  return momentTz(data)
    .tz("Australia/Brisbane")
    .format("H:mm");
}

export function getFormattedTimeV3(data) {
  return momentTz(data)
    .tz("Australia/Brisbane")
    .format("hh:mm A");
}

export function getFormattedMonthDate(date) {
  if (!date) return "";

  let da = date.split("-");
  da[0] = parseInt(da[0]);
  da[1] = parseInt(da[1]) - 1;
  da[2] = parseInt(da[2]);

  let dt = new Date(da[2], da[1], da[0]);

  return moment(dt)
    .format("Do MMM YYYY")
    .toString();
}

export function getFormattedMonthDateV2(date) {
  return moment(date, "DD-MM-YYYY");
}

export function getFormattedMonthDateV3(date) {
  return momentTz(date)
    .tz("Australia/Brisbane")
    .format("Do MMMM YYYY");
}

export function getCurrDateInMoment() {
  return moment(new Date());
}

export function getCurrDateInMomentBrisbane() {
  return momentTz(new Date())
    .tz("Australia/Brisbane")
    .format("DD-MM-YYYY");
}

export function converToUtc(date) {
  let dt = moment(date);
  return new Date(
    dt.get("year"),
    dt.get("month"),
    dt.get("date"),
    0,
    0,
    0
  ).toISOString();
}

export function getWeekDay(date) {
  return momentTz(date)
    .tz("Australia/Brisbane")
    .format("dddd");
}

export function convertToMomentDateTime(date) {
  return moment(date);
}

export function getFutureDateByDays(days) {
  return momentTz(new Date())
    .add(days, "day")
    .tz("Australia/Brisbane");
}

export function getPastDateByDays(days) {
  return momentTz(new Date())
    .subtract(days, "day")
    .tz("Australia/Brisbane");
}
