import React, { Component } from "react";
import Label from "../../components/label";
import ICButton from "../../components/button";
import NotificationList from "../../containers/NotificationsList";
import Modal from "../../components/modal";
import { connect } from "react-redux";
import {updateNotificationApiRoute} from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import { notificationNumber } from "../../reduxStore/actions/common";
import {logFirebaseEvent} from "../../utils/common";

class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        clearNotificationPopUp: false,
        apiCall: "",
        clearButtonVisibility: false
    };
  }

  clearNotificationPopUp = () => {
    const cValue = this.state.clearNotificationPopUp;
    this.setState({
      clearNotificationPopUp: !cValue
    });
  };

  closeClearNotificationPopUp = () => {
    this.setState({
      clearNotificationPopUp: false
    });
  };

  okayClearNotificationPopUp = () => {
      this.clearAllNotifications();
      this.closeClearNotificationPopUp();
  };

  componentDidMount() {
    logFirebaseEvent("Notifications Page");
    window.scrollTo(0, 0);
  }

  componentApiCall = data => {
        this.setState({
            apiCall: data
        })
    };

  setClearButton = (bool) => {
      this.setState({
          clearButtonVisibility: bool
      })
  };

    clearAllNotifications = () => {
        let apiCallObj = {
            reqType: "PUT",
            reqURL: updateNotificationApiRoute,
            dataObj: {
                status:2
            },
            params: {},
            headers: {},
            successCallback: res => {
              this.notificationNumber(res.data.data.unReadCount);
                this.setState({
                    clear: true,
                });
            },
            failureCallback: err => {
                console.log("Got the error : ", err);
            },
            showPopUp: false
        };
        this.setState({ apiCall: apiCallObj });
  };

  notificationNumber = async (data) => {
    await this.props.notificationNumber({
      totalNotification: data
    });
  }

  render() {
    return (
      <div className="row rounded p-3 notification-page">
        {/* Top Filters */}
        <div className="col-sm-3 p-3">
          <Label title="Notifications" className="big" />
        </div>

        <div className="col-sm-2 offset-sm-7 pt-3 pr-0 text-right">
            <ICButton
                title="Clear"
                clickEvent={this.clearNotificationPopUp}
                className="gray small gray-outline"
                // disabled={!this.state.clearButtonVisibility}
            />
        </div>

        <div className="w-100 border-bottom"></div>

        <div
          className="col-sm-12 custom-li mt-3 pl-0 pr-0"
        >
          <NotificationList  updateNotificationNumber={this.notificationNumber} setClearButton={() => this.setClearButton} makeApiCall={this.componentApiCall} history={this.props.history} clear={this.state.clear}/>
        </div>
          <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Are you sure you want to clear all the notifications?"
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={this.okayClearNotificationPopUp}
          cancelEvent={this.closeClearNotificationPopUp}
          openModal={this.state.clearNotificationPopUp}
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
    auth: state.auth,
    ...state.modal
});

const mapDispatchToProps = dispatch => ({
  notificationNumber: (data) => dispatch(notificationNumber(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
