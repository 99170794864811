import { combineReducers } from "redux";
import auth from "./reducers/auth";
import { LOGOUT } from "./constants/auth";
import commonReducers from "./reducers/common"

const appReducer = combineReducers({
  auth,
  commonReducers
});

const rootReducer = (_state, action) => {
  let state = _state;
  //here we will reset the whole redux state when the user will logout
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
