import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

import appRoutes from "../../routes/appRoutes";

import Error from "../../components/error";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import SuccessMsg from "../../components/successMsg";

import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import { Fragment } from "react";

const ForgotPasswordForm = props => {
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(7, "Email must be of minimum length 7")
        .email("Please provide a valid email address")
        .required("Please enter the email address")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });
  return (
    <form
      className="d-flex flex-column align-items-center"
      onSubmit={formik.handleSubmit}
    >
      <Label
        title="A link will be sent to your email address so you can reset your password"
        className="gray"
      />
      <Label
        title="Email Address"
        className="gray text-left w-75 mt-2 ex-small"
        required={true}
      />
      <ICInput
        id="email"
        name="email"
        type="text"
        label=""
        className="w-75"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email ? true : false}
        helperText={
          formik.touched.email && formik.errors.email ? formik.errors.email : ""
        }
      />
      <br />
      <Button className="mt-2" title="Submit" type="submit" />
      <br />
      <NavLink to={appRoutes.login} className="text-decoration-none" href="#">
        <Label
          title="Back to Login"
          className="cursor-pointer ml-2
         on-hover-orange font-14 gray"
        />
      </NavLink>
      <br/>
      {
        props.serverErrorMsg && (
          <Fragment>
            <Label title="Please contact Instant Consult Support on 1300 003 310" className="w-100 text-center error mb-0 font-14"/>
            <Label title="if this issue persists" className="w-100 text-center error font-14"/>
          </Fragment>
        )
      }
      <SuccessMsg>{props.successMsg}</SuccessMsg>
    </form>
  );
};

export default ForgotPasswordForm;

ForgotPasswordForm.defaultProps = {
  serverErrorMsg: "",
  successMsg: ""
};

ForgotPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
  successMsg: PropTypes.string
};
