import React, { Component } from "react";
import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import { connect } from "react-redux";
import MakeAPICall from "../../apiCalls/common";
import { editDependentProfileAPI, registerDependentAccount } from "../../apiRoutes";
import { updateUserData } from "../../reduxStore/actions/auth";
import Modal from "../../components/modal";
import moment from "moment";
import { formatName, logFirebaseEvent } from "../../utils/common";
import Loader from "../../components/loader";
import EditDependentProfileForm from "../../containers/FamilyProfile/EditDependentProfileForm";

class AddFamilyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientID: undefined,
      apiCall: "",
      iserrorMsgModalOpen: false,
      errorMessage: "Something went wrong!",
      isSuccessModelOpen: false
    };
  }
  componentDidMount() {
    logFirebaseEvent("Add Family Profile Page");
    window.scrollTo(0, 0);
  }

  onFormSubmit = (formValues, forIHI) => {
    return new Promise((resolve, reject) => {
      this.setState({
        apiLoading: true
      });
      const patientID = this.state.patientID;
      const parentData = this.props.auth.user[0];
      // let hashString = '';
      // let childAccounts = parentData.dependents ? parentData.dependents.length + 1 : 1;
      // console.log("childAccounts: ", childAccounts);
      // for (let i=0; i < childAccounts; i++) {
      //   hashString += '#';
      // }

      const formData = new FormData();
      formData.append("parentId", parentData._id);
      if (patientID) {
        formData.append("dependentId", formatName(patientID));
      }
      formData.append("firstName", formatName(formValues.firstName));
      formData.append("middleName", formatName(formValues.middleName));
      formData.append("lastName", formatName(formValues.lastName));
      // formData.append("email", hashString + parentData.email);
      const tmpDate = moment(formValues.dateOfBirth).format("DD-MM-YYYY");
      if (tmpDate != "Invalid date" && formValues.dateOfBirth.length > 10) {
        formValues.dateOfBirth = tmpDate;
      }
      formData.append("dateOfBirth", formValues.dateOfBirth);
      formData.append("gender", formValues.gender);
      // formData.append("phoneNumber", hashString +parentData.phoneNumber);
      // formData.append("streetNumber", parentData.address.streetNumber);
      // formData.append("streetName", parentData.address.streetName);
      // formData.append("suburb", parentData.address.suburb);
      // formData.append("state", parentData.address.state);
      // formData.append("postCode", parentData.address.postCode);
      formData.append("medicareNumber", formValues.medicareNumber);
      formData.append("IHINumber", formValues.IHINumber);
      formData.append(
        "refNumerOnMedicareCard",
        formValues.refNumerOnMedicareCard
      );
      formData.append("medicalInfo", "");
      formData.append("profilePic", formValues.profilePic);
      // formData.append("appVersion", "100");
      // formData.append("deviceType", "2");
      // formData.append("deviceToken", "e873e0ba-6f46-44f3-b970-ea262fed1c6b");
      formData.append("isFile", formValues.isProfilePic.toString());
      // formData.append("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone.toString());
      // formData.append("DVANumber", formValues.DVANumber);

      let tmpStore = this.props.auth;
      delete tmpStore.auth;
      let apiCallObj = {
        reqType: patientID ? "PUT" : "POST",
        reqURL: patientID ? editDependentProfileAPI : registerDependentAccount,
        dataObj: formData,
        params: {},
        headers: {},
        successCallback: async res => {
          console.log("Child account Registered: ", res.data.data);
          const patientID =
            res.data.data.dependents[res.data.data.dependents.length - 1];
          if (!this.state.patientID) {
            this.setState({
              patientID
            });
            tmpStore.user[0] = res.data.data;
            await this.props.updateUserData(tmpStore);
            this.setState({
              isSuccessModelOpen: !forIHI,
              apiLoading: false
            });
          } else {
            this.setState({
              isSuccessModelOpen: !forIHI,
              apiLoading: false
            });
          }
          resolve(patientID);
        },
        failureCallback: err => {
          console.log(err);
          this.setState({
            apiLoading: false
          });
          // if(err && err.message) {
          //   this.setState({
          //     iserrorMsgModalOpen: true,
          //     errorMessage: err.message
          //   });
          // }

          reject();
        },
        showPopUp: false
      };
      this.setState({
        apiCall: apiCallObj
      });
    });
  };

  // okayEditProfileSuccess = () => {
  //   const { history } = this.props;
  //   history.push(appRoutes.familyProfile);
  // };

  render() {
    return (
      <div
        className="container
        h-100 bg-white pb-5 mb-5"
      >
        <Loader loading={this.state.apiLoading} />
        <div className="row">
          <div className="col-sm-12 mt-4">
            <NavLink to={appRoutes.familyProfile}>
              <i title="" className="fa fa-arrow-left primary-color" />
              <Label
                title="Back to Family Profile"
                className="cursor-pointer font-15 on-hover-orange pl-2"
              />
            </NavLink>
          </div>

          <div className="col-sm-12 mt-4 pb-3">
            <Label title="Add Dependent Profile" className="big" />
          </div>
          <div className="border-bottom mb-4 w-100" />
          <EditDependentProfileForm
            dependentID={this.state.patientID}
            onFormSubmit={this.onFormSubmit}
            dependentDetails={null}
            isPatientConsultedBefore={false}
          />
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Your dependent has been successfully added to your Family Profile"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            this.setState({
              isSuccessModelOpen: false
            });
            this.props.history.goBack();
          }}
          openModal={this.state.isSuccessModelOpen}
        />
        <Modal
          modalBodyContent={this.state.errorMessage}
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            this.setState({
              iserrorMsgModalOpen: false
            });
          }}
          openModal={this.state.iserrorMsgModalOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(updateUserData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFamilyProfile);
