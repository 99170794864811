export const NOTIFICATION_NUMBER = "NOTIFICATION_NUMBER";
export const ONESIGNAL_SUBSCRIPTION = "ONESIGNAL_SUBSCRIPTION";
export const BOOKING_STATUS = "BOOKING_STATUS";
export const APPOINTMENT_ID = "APPOINTMENT_ID";
export const APPOINTMENT_DATA = "APPOINTMENT_DATA";
export const VIDEO_CALL_STATUS = "VIDEO_CALL_STATUS";
export const VIDEO_CALL_TIMER_VALUE = "VIDEO_CALL_TIMER_VALUE";
export const CALL_WAIT_TIME = "CALL_WAIT_TIME";
export const WAIT_IF_CALL_END = "WAIT_IF_CALL_END";
export const IS_REQUEST_ACCEPTED = "IS_REQUEST_ACCEPTED";
export const IS_CALL_MISSED = "IS_CALL_MISSED";
export const IS_DOCTOR_CALLING = "IS_DOCTOR_CALLING";
export const CALL_SESSION_DATA = "CALL_SESSION_DATA";
export const IS_DIRECT_CALL = "IS_DIRECT_CALL";
export const TOTAL_PATIENT_CONSULTS = "TOTAL_PATIENT_CONSULTS";
export const PATIENT_QUEUE_WAIT_TIME = "PATIENT_QUEUE_WAIT_TIME";
export const SCHEDULED_BOOKING = "SCHEDULED_BOOKING";