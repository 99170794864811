import React, { Fragment } from "react";
import PropTypes from "prop-types";

const PharmacyListDialog = ({
  pharmacyList,
  selectPharmacy,
  isNotShowPatient
}) => {
  return (
    pharmacyList.length > 0 && (
      <div
        className="custom-dropdown-search-list-pin-popup-prescription custom-dropdown-search-list-prescription"
        style={{ overflow: "auto", whiteSpace: "nowrap" }}
      >
        <ul>
          {pharmacyList.map((item, index) => {
            return (
              <li key={index} onClick={() => selectPharmacy(item)}>
                {item.PharmacyName && (
                  <div className="item-container">
                    <span className="prescription-search-name">
                      {item.PharmacyName}&nbsp;&nbsp;
                    </span>

                    <span className="prescription-search-desc">
                      {item.PharmacyAddress1 && (
                        <Fragment>{item.PharmacyAddress1},&nbsp;</Fragment>
                      )}
                      {item.PharmacyAddress2 && (
                        <Fragment>{item.PharmacyAddress2},&nbsp;</Fragment>
                      )}
                    </span>

                    <span
                      className="prescription-search-desc"
                      style={{ textTransform: "uppercase" }}
                    >
                      {item.Suburb}&nbsp;
                    </span>

                    <span className="prescription-search-desc">
                      {item.State}&nbsp;{item.Postcode}&nbsp;
                    </span>
                  </div>
                )}
                {item.patientName && !isNotShowPatient && (
                  <div className="item-container">
                    <span className="patient-search-name1">
                      <i>Send to Patient</i>&nbsp;&nbsp;
                      <b>{item.patientName}</b>&nbsp;&nbsp;
                    </span>

                    <span className="prescription-search-desc">
                      {item.unitNumber && <span>{item.unitNumber}/</span>}
                      {item.streetNumber && (
                        <span>{item.streetNumber}&nbsp;</span>
                      )}
                      {item.streetName && (
                        <span>{item.streetName},&nbsp;&nbsp;</span>
                      )}
                    </span>

                    <span
                      className="prescription-search-desc"
                      style={{ textTransform: "uppercase" }}
                    >
                      {item.suburb}&nbsp;
                    </span>

                    <span className="prescription-search-desc">
                      {item.state}&nbsp;{item.postCode}&nbsp;
                    </span>
                  </div>
                )}
                {item.chemistWarehouse && (
                  <div className="item-container">
                    <span className="chemist-warehouse">
                      <b>{item.chemistWarehouse}</b>
                    </span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default PharmacyListDialog;

PharmacyListDialog.propTypes = {
  selectPharmacy: PropTypes.func,
  pharmacyList: PropTypes.array,
  isNotShowPatient: PropTypes.bool
};
