import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import {logFirebaseEvent} from "../../../utils/common";

export default function FAQsList(props) {
  logFirebaseEvent("FAQs Page");
  return (
    <div className="faq-list">
      {props.faqList.map((data, index) => {
        return (
          <ExpansionPanel key={data._id}>
            <ExpansionPanelSummary
              expandIcon={<i className="fas fa-angle-down"></i>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="faq-query">{data.query}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className="faq-ans text-justify">
                {data.ans}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
}
