import React, { Component } from "react";
import Label from "../../components/label";
import { consultCostsApiRoute } from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import {logFirebaseEvent} from "../../utils/common";

class ConsultCosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      consultCostList: []
    };
  }

  componentDidMount() {
    logFirebaseEvent("Consult Cost Page");
    window.scrollTo(0, 0);
    this.getConsultCosts();
  }

  getConsultCosts() {
    let apiCallObj = {
      reqType: "GET",
      reqURL: consultCostsApiRoute,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          consultCostList: res.data.data
        });
      },
      failureCallback: err => {
        console.log(err);
      },
      showPopUp: false
    };

    this.setState({
      apiCall: apiCallObj
    });
  }

  render() {
    {
      return (
        <div className="row rounded p-3 notification-page">
          <div className="col-sm-12 ml-3 mt-3">
            <Label title="Consult Costs" className="big" />
            <div className="notification-text">
              <p className="font-16 semi-bold-font mb-0">
                Consults are bulk-billed with a valid Medicare Card for patients under 12 months of age only*
              </p>
              {/* <p className="font-14">
                *patients under 12 months of age, homeless or living in a COVID-19 impacted area
              </p> */}
              <div className="notification-space" />
              <p className="font-14">
                If you are not eligible* for a bulk-billed consult, the following private fees are applicable:
              </p>
            </div>
          </div>
          <div className="w-100 border-bottom mt-2" />
          <div className="mt-4 w-100">
            {this.state.consultCostList.map((data, index) => {
              return (
                <div className="w-100" key={index}>
                  <div className="col-sm-11 ml-3 mt-3 ">
                    <Label
                      title={
                        data.consultMode + " " + data.consultDuration + " mins"
                      }
                      className="secondary-font custom-cost-width font-17 primary-color"
                    />
                    <Label
                      title={"$" + data.consultPrice.toFixed(2)}
                      className="secondary-font font-17"
                    />
                  </div>
                  <div className="col-sm-11 ml-3">
                    <Label
                      title={
                        data.description
                      }
                      className="gray font-14"
                    />
                    {/* {data.consultDuration > 10 && data.consultMode == "<" ? (
                      <Label
                        title={
                          "Consult Duration " +
                          "> " +
                          data.consultDuration / 2 +
                          " mins" +
                          "\xa0\xa0" +
                          data.consultMode +
                          " " +
                          data.consultDuration +
                          " mins"
                        }
                        className="gray font-14"
                      />
                    ) : (
                      <Label
                        title={
                          "Consult Duration" +
                          " " +
                          data.consultMode +
                          " " +
                          data.consultDuration +
                          " mins"
                        }
                        className="gray font-14"
                      />
                    )} */}
                  </div>
                  <div className="w-100 pt-2 pb-2 border-bottom" />
                </div>
              );
            })}
          </div>
          <MakeAPICall apiParam={this.state.apiCall} />
        </div>
      );
    }
  }
}

export default ConsultCosts;
