import React from "react";
import PropTypes from "prop-types";

const ATag = props => (
  <a
    href={props.href}
    className={"link insta-link-default " + props.className}
    disabled={props.disabled}
    target={props.target ? props.target : ""}
  >
    {" "}
    {props.title}{" "}
  </a>
);

export default ATag;

ATag.defaultProps = {
  title: "Link",
  className: "",
  disabled: false
};

ATag.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
