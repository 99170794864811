import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Error from "../../components/error";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import SuccessMsg from "../../components/successMsg";

import Label from "../../components/label";
import { NavLink } from "react-router-dom";

const RecoverPasswordForm = props => {
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
        password: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase")
        .required("Please enter the password"),
      confirmPassword: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .required("Please enter the confirm password")
        .oneOf([Yup.ref("password")], "Both Passwords should match")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });
  return (
    <form
      className="d-flex flex-column align-items-center"
      onSubmit={formik.handleSubmit}
    >
      <Label
        title="Enter Password"
        className="gray text-left w-75 mt-2 ex-small"
        required={true}
      />
      <ICInput
        id="password"
        name="password"
        type="password"
        label=""
        className="w-75"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && formik.errors.password ? true : false}
        helperText={
          formik.touched.password && formik.errors.password ? formik.errors.password : ""
        }
      />
      <br />
      <Label
        title="Confirm Password"
        className="gray text-left w-75 mt-2 ex-small"
        required={true}
      />
      <ICInput
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label=""
        className="w-75"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.confirmPassword}
        error={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : ""
        }
      />
      <br />
      <Button className="mt-2" title="Submit" type="submit" />
      <br />
      <Error>{props.serverErrorMsg}</Error>
      <SuccessMsg>{props.successMsg}</SuccessMsg>
    </form>
  );
};

export default RecoverPasswordForm;
