import React, { Fragment, PureComponent } from "react";
import Label from "../../../components/label";
import { connect } from "react-redux";

import appRoutes from "../../../routes/appRoutes";
import {
  homeApiRoute1,
  cancelConsultRequestApiRoute,
  getBookingDetailsApiRoute
} from "../../../apiRoutes/index";
import MakeAPICall from "../../../apiCalls/common";

import {
  bookingStatus,
  saveAppointmentData,
  saveCallWaitTime,
  saveRequestAcceptedStatus,
  saveIsDoctorCalling
} from "../../../reduxStore/actions/common";

import Loader from "../../../components/loader";
import ICButton from "../../../components/button";
import Modal from "../../../components/modal";
import CustomModal from "../../../components/customModal";

import statusOrangeImg from "../../../assets/images/status-orange-circle.png";
import statusGreyImg from "../../../assets/images/status-grey-circle.png";
import statusLoaderGif from "../../../assets/images/grey_loader.gif";
import statusGreyLineImg from "../../../assets/images/status-img-grey-line.png";

import {logFirebaseEvent} from "../../../utils/common";

class ConsultationDetails extends PureComponent {
  constructor(props) {
    super(props);
    console.log("checking propssssss",props)
    this.state = {
      apiCall: null,
      apiCall1: null,
      isProcessing: false,
      queuing: true,
      accepted: false,
      consulted: false,
      bookingStatus: 0,
      consultID: "",
      positionInQueue: 1, // not using it anymore. Not sure when deleted.
      natureOfConsult: null,
      photosAndDocs: [],
      additionalInfo: "No Additional Information",
      cancelReqPopUp: false,
      isReqActivated: false,
      photos: [],
      docs: [],
      homeDataInterval: null,
      timer: null, // timer function,
      showMissedCallPopup: false,
      fiveMinTimerInterval: null,
      showErrorModal: false,
      avgWaitTime: "Average Wait Time: " + (props.commonReducers && props.commonReducers.patientQueueWaitTime ? props.commonReducers.patientQueueWaitTime : '5') + " " + "mins",
      textIdx: false,
      is5minTimerTimeout: false
    };
  }

  componentDidMount() {

    logFirebaseEvent("Consult Queueing Accepted Page");

    this.getConsultDetails();
    // this.callHomePageDataApi();

    this.timeout = setInterval(() => {
      this.setState({
        textIdx: !this.state.textIdx
      });
    }, 2500);

    if (
      this.props.commonReducers.isRequestAccepted === true &&
      this.props.commonReducers.isDoctorCalling !== true &&
      this.props.commonReducers.isDirectCall !== true
    ) {
      this.startTimer();
    }
  }

  async componentDidUpdate(prevProps) {
    // start the 5 min timer if the doctor accept the consult request
    if (
      this.props.commonReducers.isRequestAccepted === true &&
      prevProps.commonReducers.callWaitTime === null &&
      this.props.commonReducers.callWaitTime === null &&
      this.props.commonReducers.isDoctorCalling !== true &&
      this.props.commonReducers.isDirectCall !== true
    ) {
      this.startTimer();
    }

    if (
      this.props.commonReducers.isDoctorCalling === true ||
      this.props.commonReducers.isDirectCall === true
    ) {
      this.stopWaitTime();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  stopWaitTime = () => {
    clearInterval(this.state.fiveMinTimerInterval);
    this.setState({
      fiveMinTimerInterval: null
    });
  };

  clearStatesDuringCancelAndRedirect = async () => {
    this.stopWaitTime();
    clearInterval(this.state.homeDataInterval);

    this.setState({
      isProcessing: false,
      fiveMinTimerInterval: null,
      homeDataInterval: null
    });
    await this.props.saveRequestAcceptedStatus(false);
    await this.props.saveCallWaitTime(null);
    await this.props.bookingStatus(0);
    await this.props.saveIsDoctorCalling(false);
    await this.props.saveAppointmentData(null);
    // go to consult request page
    this.props.history.push(appRoutes.requestAConsult);
  };

  // get the consult data
  getConsultDetails = () => {
    console.log({ConsultationDetails: this.props.commonReducers.appointmentData})
    if (
      this.props.commonReducers.appointmentData &&
      this.props.commonReducers.appointmentData._id
    ) {
      console.log({appData: this.props.commonReducers.appointmentData})
      let apiCallObj = {
        reqType: "GET",
        reqURL: getBookingDetailsApiRoute,
        params: {
          booking_id:
            this.props.commonReducers.appointmentData &&
            this.props.commonReducers.appointmentData._id
              ? this.props.commonReducers.appointmentData._id
              : ""
        },
        successCallback: res => {
          let dt = res.data.data;

          // if the consult is in queuing, accepted, in progress and on the call
          if (dt.status <= 4 && dt.status !== 0) {
            let images = [];
            let pdfs = [];
            if (dt.consultImgs && dt.consultImgs.length > 0) {
              dt.consultImgs.forEach(doc => {
                let pic = doc.original.split(".");

                if (pic[pic.length - 1] === "pdf") {
                  pdfs.push(doc);
                } else {
                  images.push(doc);
                }
              });
            }
            if (dt.consultDocs && dt.consultDocs.length > 0) {
              dt.consultDocs.forEach(doc => {
                let pic = doc.original.split(".");

                if (pic[pic.length - 1] === "pdf") {
                  pdfs.push(doc);
                } else {
                  images.push(doc);
                }
              });
            }

            this.setState({
              additionalInfo: dt.consultInfo
                ? dt.consultInfo
                : "No Additional Information",
              photos: images,
              docs: pdfs,
              natureOfConsult: dt.consultId,
              consultID: dt.appointmentId
            });
          } else {
            console.log("clearing: " + dt.status)
            this.clearStatesDuringCancelAndRedirect();
          }
        },
        failureCallback: err => {
          // this.setState({
          //   showErrorModal: true
          // });
        },
        showPopUp: false
      };
      this.setState({ apiCall: apiCallObj });
    } else {
      this.clearStatesDuringCancelAndRedirect();
    }
  };

  // call this api to get the patient queue position
  callHomePageDataApi = () => {
    this.setState({
      homeDataInterval: setInterval(() => {
        if (this.props.commonReducers.isCallAccepted === false) {
          let apiCallObj = {
            reqType: "GET",
            reqURL: homeApiRoute1,
            successCallback: res => {
              var awt =
                res.data.data.positionInQueue / res.data.data.totalDoctor;
                awt = Math.ceil(awt);
                let avgTime = "";
                if (awt === Infinity) {
                  avgTime = "Average Wait Time: N/A";
                } else {
                  let avg = awt * 5;
                  if (avg == 0) {
                    avg = 5;
                  }
                  avgTime = "Average Wait Time: " + avg + " " + "mins";
                }
                this.setState({
                  positionInQueue: res.data.data.positionInQueue,
                  avgWaitTime: avgTime
                });
              // }
            },
            failureCallback: err => {
              //console.log("Got the error : ", err);
            },
            showPopUp: false
          };
          this.setState({ apiCall1: apiCallObj });
        }
      }, 3000)
    });
  };

  cancelReqButton = () => {
    logFirebaseEvent("User Cancelled Consult Request")
    this.setState({
      cancelReqPopUp: true
    });
  };

  callCancelRequestAPI = () => {
    let apiCallObj = {
      reqType: "PUT",
      reqURL: cancelConsultRequestApiRoute,
      dataObj: {
        appointmentId: this.props.commonReducers.appointmentData
          ? this.props.commonReducers.appointmentData._id
          : ""
      },
      params: {},
      headers: {},
      successCallback: async res => {},
      failureCallback: async err => {},
      showPopUp: false
    };
    this.setState({ apiCall: apiCallObj });
  };

  //cancel consult request
  okayCancelReqPopUp = async () => {
    this.callCancelRequestAPI();
    await this.clearStatesDuringCancelAndRedirect();
  };

  closeCancelReqPopUp = () => {
    this.setState({
      cancelReqPopUp: false
    });
  };

  // since 5 min time wait if over show missed call popup
  cancelRequestTimeout = () => {
    this.stopWaitTime();
    if (this.state.is5minTimerTimeout !== true) {
      this.setState({
        showMissedCallPopup: true,
        is5minTimerTimeout: true
      });

      this.callCancelRequestAPI();
    }
  };

  closeMissedCallPopup = () => {
    this.setState({
      cancelReqPopUp: false,
      showMissedCallPopup: false,
      is5minTimerTimeout: false
    });

    this.okayCancelReqPopUp();
  };

  // 5 minute timer
  startTimer = () => {
    let fiveMinutes = 60 * 5;

    let start =
        this.props.commonReducers.callWaitTime !== undefined &&
        this.props.commonReducers.callWaitTime !== null
          ? this.props.commonReducers.callWaitTime
          : Date.now(),
      diff,
      minutes,
      seconds,
      _this = this;

    this.props.saveCallWaitTime(start);

    this.setState({
      fiveMinTimerInterval: setInterval(() => {
        // get the number of seconds that have elapsed since
        // startTimer() was called
        diff = fiveMinutes - (((Date.now() - start) / 1000) | 0);

        if (diff > 0) {
          // does the same job as parseInt truncates the float
          minutes = (diff / 60) | 0;
          seconds = diff % 60 | 0;

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          _this.setState({
            timer: minutes + ":" + seconds // set minute and seconds
          });
        } else if (diff <= 0) {
          _this.setState({
            timer: "00:00"
          });

          // if doctor have not called patient yet
          if (_this.props.commonReducers.isDoctorCalling !== true) {
            //_this.cancelRequestTimeout();
          }
        }
      }, 1000)
    });
  };

  openUrl = url => {
    window.open(url, "_blank");
  };

  render() {
    let drText = "";

    if (
      this.props.commonReducers.appointmentData !== null &&
      this.props.commonReducers.appointmentData.doctorId !== undefined
    ) {
      drText =
        drText +
        "You have missed Dr. " +
        this.props.commonReducers.appointmentData.doctorId.firstName +
        " " +
        this.props.commonReducers.appointmentData.doctorId.lastName +
        "'s call.";
    }
    return (
      <div className="container p-0 consult-details-container">
        {this.state.isProcessing && <Loader />}
        <div className={this.state.isProcessing ? "disableContainer" : ""}>
          <div className="status-bar">
            <div className="col-sm-12 d-flex flex-row justify-content-center">
              <div style={{ maxWidth: "225px" }} className="text-center">
                <img
                  src={
                    this.props.commonReducers.bookingStatus === 0
                      ? statusOrangeImg
                      : statusGreyImg
                  }
                  alt="icon"
                />
                <img
                  src={
                    this.props.commonReducers.bookingStatus === 0
                      ? statusLoaderGif
                      : statusGreyLineImg
                  }
                  className={
                    this.props.commonReducers.bookingStatus === 0
                      ? "active-line"
                      : ""
                  }
                  alt="icon"
                />
                <img
                  src={
                    this.props.commonReducers.bookingStatus === 2
                      ? statusOrangeImg
                      : statusGreyImg
                  }
                  alt="icon"
                />
                <img
                  src={
                    this.props.commonReducers.bookingStatus === 2
                      ? statusLoaderGif
                      : statusGreyLineImg
                  }
                  className={
                    this.props.commonReducers.bookingStatus === 2
                      ? "active-line"
                      : ""
                  }
                  alt="icon"
                />
                <img src={statusGreyImg} alt="icon" />
              </div>
            </div>
            <div className="col-sm-12 d-flex flex-row justify-content-center">
              <div style={{ maxWidth: "302px" }} className="w-100 row">
                <Label className="col-sm-4 text-center" title="Queuing" />

                <Label className="col-sm-4 text-center" title="Accepted" />

                <Label className="col-sm-4 text-center" title="Consulted" />
              </div>
            </div>

            <div className="col-sm-12 mt-3 text-center">
              {this.props.commonReducers.isCallAccepted === false &&
                this.props.commonReducers.isDoctorCalling === false &&
                this.props.commonReducers.isRequestAccepted === false && (
                  <ICButton
                    title="Cancel Request"
                    clickEvent={this.cancelReqButton}
                  />
                )}
            </div>
            <div className="col-sm-12 mt-3 row">
              <Label
                className="secondary-font font-17 col-sm-4"
                title={"Consult ID: " + this.state.consultID}
              />
              {this.props.commonReducers.isDoctorCalling === true &&
                this.props.commonReducers.isRequestAccepted === true && (
                  <div className="d-flex flex-row justify-content-end align-items-baseline col-sm-8">
                    <p className="m-0 insta-label secondary-font font-17 text-right">
                      Please Wait
                      <br />
                      Do not exit this page
                    </p>
                  </div>
                )}
              {this.props.commonReducers.isCallAccepted === false &&
                this.props.commonReducers.isDoctorCalling === false &&
                this.props.commonReducers.isRequestAccepted === true &&
                this.state.timer !== null && (
                  <div className="d-flex flex-row justify-content-end align-items-baseline col-sm-8">
                    <Fragment>
                      <Label
                        title="Doctor will call you within:"
                        className="m-0 secondary-font font-17"
                      />
                      &nbsp;
                      <Label
                        title={this.state.timer + " mins"}
                        className="secondary-font font-17 p-0 m-0"
                      />
                    </Fragment>
                  </div>
                )}
              {this.props.commonReducers.isRequestAccepted === false && (
                // <Label
                //   className="secondary-font font-17 float-right col-sm-8 text-right"
                //   title={"Position in Queue: " + this.state.positionInQueue}
                //             />
                <Fragment>
                  <Label
                    className={
                      this.state.textIdx
                        ? "waiting-text secondary-font font-17 float-right col-sm-8 text-right"
                        : "d-none"
                    }
                    title="Please Wait"
                  />
                  <Label
                    className={
                      !this.state.textIdx
                        ? "waiting-text secondary-font font-17 float-right col-sm-8 text-right"
                        : "d-none"
                    }
                    // title={this.state.avgWaitTime}
                    title={
                      `Average Wait Time: ${this.props.commonReducers && this.props.commonReducers.patientQueueWaitTime ? (this.props.commonReducers.patientQueueWaitTime > 45 ? '45' : this.props.commonReducers.patientQueueWaitTime) : '5'} mins`
                    }
                  />
                </Fragment>
              )}
            </div>
          </div>
          <div className="consult-details-content-container">
            {this.state.natureOfConsult !== null && (
              <Fragment>
                <div className="col-sm-12 mt-5">
                  <Label
                    title="Nature of Consult"
                    className="font-19 secondary-font"
                  />
                </div>
                <div className="col-sm-12">
                  <img
                    src={this.state.natureOfConsult.consultPic.original}
                    alt="consult-img"
                    className="nature-cnslt-img"
                  />
                  <Label
                    title={this.state.natureOfConsult.consultName}
                    className="pl-1 mb-0 font-19 primary-color"
                  />
                </div>
              </Fragment>
            )}

            <div className="col-sm-12 mt-4">
              <Label
                title="Images/Documents"
                className="font-19 secondary-font mb-0"
              />
            </div>
            <div className="col-sm-12 row mt-2 patient-attached-documents">
              {this.state.photos.length === 0 &&
                this.state.docs.length === 0 && (
                  <Label title="No Images/Documents" className="font-14" />
                )}
              {this.state.photos.length > 0 &&
                this.state.photos.map((element, index) => {
                  return (
                    <div className="float-left position-relative" key={index}>
                      <img
                        src={element.original}
                        alt="no-img-instant-consult"
                        className="mt-2 mr-2 img-thumbnail att-fixed-size"
                      />
                      <i
                        className="fas fa-eye att-delete-btn cursor-pointer"
                        onClick={() => {
                          this.openUrl(element.original);
                        }}
                      />
                      <div className="clearfix" />
                    </div>
                  );
                })}

              {this.state.docs.length > 0 &&
                this.state.docs.map((element, index) => {
                  return (
                    <div className="float-left position-relative" key={index}>
                      <iframe
                        title="pdfFrame"
                        src={element.original}
                        alt="no-img-instant-consult"
                        className="mt-2 mr-2 img-thumbnail att-fixed-size"
                      />
                      {/* <a
                        style={{ height: "33px" }}
                        className="insta-button-primary  btn btn-primary"
                        href={element.original}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Document {index + 1}
                      </a> */}
                      <i
                        className="fas fa-eye att-delete-btn cursor-pointer"
                        onClick={() => {
                          this.openUrl(element.original);
                        }}
                      />
                      <div className="clearfix" />
                    </div>
                  );
                })}
            </div>
            <br />
            <div className="col-sm-12 mt-0">
              <Label
                title="Additional Information"
                className="font-19 secondary-font mb-0"
              />
            </div>
            <div className="col-sm-12 row">
              <Label
                title={this.state.additionalInfo}
                className="font-14 ml-3"
              />
            </div>
            <br />
            <br />
          </div>
        </div>
        <Modal
          modalBodyContent="Are you sure you want to cancel your Consult Request?"
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={this.okayCancelReqPopUp}
          cancelEvent={this.closeCancelReqPopUp}
          openModal={this.state.cancelReqPopUp}
          backdrop="static"
        />
        <CustomModal
          modalBodyContent={
            <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-center">
              <p className="requested-patient-name text-center w-100 mt-3 mb-0">
                {drText}
              </p>
              <p className="requested-patient-name text-center w-100 mt-3 mb-0">
                If you were not alerted of the doctor's call,
                <br />
                there is an issue with your device.
                <br />
                <b />
                Please call our support team on 1300 003 310 and we will assist
                in connecting you with a doctor promptly.
              </p>
            </div>
          }
          cancelText="Okay"
          title="Missed Call"
          useOneButton={true}
          cancelEvent={this.closeMissedCallPopup}
          openModal={this.state.showMissedCallPopup}
          backdrop="static"
        />

        <CustomModal
          modalBodyContent={
            <p className="requested-patient-name text-center w-100 mt-3 mb-0">
              Some error occured. Please refresh the page.
            </p>
          }
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            window.location.reload(true);
          }}
          openModal={this.state.showErrorModal}
          backdrop="static"
        />

        <MakeAPICall apiParam={this.state.apiCall} />
        <MakeAPICall apiParam={this.state.apiCall1} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({
  bookingStatus: data => dispatch(bookingStatus(data)),
  saveAppointmentData: data => dispatch(saveAppointmentData(data)),
  saveCallWaitTime: data => dispatch(saveCallWaitTime(data)),
  saveRequestAcceptedStatus: data => dispatch(saveRequestAcceptedStatus(data)),
  saveIsDoctorCalling: data => dispatch(saveIsDoctorCalling(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationDetails);
