const checkIsMedicareCheckDigitValid = cardNum => {
  let num = "";
  if (typeof cardNum === "number") {
    if (cardNum.toString().length !== 10) {
      return false;
    } else {
      num = cardNum.toString();
    }
  } else if (typeof cardNum === "string") {
    num = cardNum.replace(/\s+/g, "");
    if (num.length !== 10) return false;
  } else {
    return false;
  }

  const sum =
    num[0] * 1 +
    num[1] * 3 +
    num[2] * 7 +
    num[3] * 9 +
    num[4] * 1 +
    num[5] * 3 +
    num[6] * 7 +
    num[7] * 9;

  const remainder = sum % 10;
  if (remainder !== parseInt(num[8])) return false;

  return true;
};

export { checkIsMedicareCheckDigitValid };
