import React, { Component } from "react";
import PublicLayout from "../../containers/Layout/PublicLayout";
import SignUpForm from "../../containers/SignUpForm";

import Label from "../../components/label";
import appRoutes from "../../routes/appRoutes";

import { NavLink } from "react-router-dom";
import Modal from "../../components/modal";
import TwoLineBtnModal from "../../components/modalTwoLineBtn";

import MakeApiCall from "../../apiCalls/common";
import { constApiRoute, registerApiRoute } from "../../apiRoutes";

import { formatName, logFirebaseEvent } from "../../utils/common";
import { STATE_LIST } from "../../constants/constants";

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpPopUp: true,
      // stateList: [],
      apiCall: "",
      registerSuccess: false,
      isOpenErrorModal: false,
      errorModalBodyContent: "",
      constants: {}
    };
  }

  onSignUpFormSubmit = formValues => {
    var formData = new FormData();
    formData.append("firstName", formatName(formValues.firstName));
    formData.append("middleName", formatName(formValues.middleName));
    formData.append("lastName", formatName(formValues.lastName));
    formData.append("email", formValues.email);
    formData.append("dateOfBirth", formValues.dateOfBirth);
    formData.append("gender", formValues.gender);
    formData.append("phoneNumber", formValues.phoneNumber);
    formData.append("streetNumber", formValues.streetNumber);
    formData.append("streetName", formValues.streetName);
    formData.append("suburb", formValues.suburb);
    formData.append("state", formValues.state);
    formData.append("postCode", formValues.postCode);
    formData.append("medicareNumber", formValues.medicareNumber);
    formData.append(
      "refNumerOnMedicareCard",
      formValues.refNumerOnMedicareCard
    );
    formData.append("medicalInfo", formValues.medicalInfo);
    formData.append("marketing", formValues.marketing);
    if (formValues.profilePic !== null) {
      formData.append("profilePic", formValues.profilePic);
    }

    formData.append("password", formValues.password);
    formData.append("deviceType", formValues.deviceType);
    formData.append("deviceToken", formValues.deviceToken);
    formData.append("appVersion", formValues.appVersion);
    formData.append("isFile", formValues.isProfilePic.toString());
    formData.append("timeZone", formValues.timeZone);
    if (formValues.ihiInfo) formData.append("ihiInfo", formValues.ihiInfo);

    for (const key in formValues.ihiInfo) {
      if (key === "operator") {
        for (const operatorKey in formValues.ihiInfo[key]) {
          formData.append(
            `ihiInfo[${key}][${operatorKey}]`,
            formValues.ihiInfo[key][operatorKey]
          );
        }
      } else {
        formData.append(`ihiInfo[${key}]`, formValues.ihiInfo[key]);
      }

    }
    // formData.append("DVANumber", formValues.DVANumber);

    let apiCallObj = {
      reqType: "POST",
      reqURL: registerApiRoute,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          registerSuccess: true
        });
      },
      failureCallback: err => {
        // if (err.statusCode === 400 && err.type === "USER_ALREADY_REGISTERED") {
        //   this.setState({
        //     isOpenErrorModal: true,
        //     errorModalBodyContent: err.message || "Email already exists"
        //   });
        // }

        console.error(err);
      },
      showPopUp: false
    };

    this.setState({
      apiCall: apiCallObj
    });
  };

  signUpPopUp = () => {
    this.setState({
      signUpPopUp: false
    });
  };

  openDialPad = () => {
    document.location.href = "tel:1300 003 310";
  };

  regSuccessPopUp = () => {
    const { history } = this.props;
    history.push(appRoutes.login);
    logFirebaseEvent("Successful Registration");
  };

  getConstants = () => {
    console.log("Now getting constants");
    let apiCallObj = {
      reqType: "GET",
      reqURL: constApiRoute,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: res => {
        console.log("Constants: ", res.data.data);
        this.setState({
          constants: res.data.data
        });
      },
      failureCallback: err => {
        console.log(err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallObj
    });
  };

  componentDidMount() {
    logFirebaseEvent("Sign Up Page");
    window.scrollTo(0, 0);
    // let apiCallObj = {
    //   reqType: "GET",
    //   reqURL: stateListApiRoute,
    //   dataObj: {},
    //   params: {},
    //   headers: {},
    //   successCallback: res => {
    //     this.setState({
    //       stateList: res.data.data
    //     });
    //   },
    //   failureCallback: err => {
    //     console.log(err);
    //   },
    //   showPopUp: false
    // };
    // this.setState({
    //   apiCall: apiCallObj
    // });
    this.getConstants();
  }

  render() {
    return (
      <PublicLayout>
        <div className="custom-margin-already-account pb-5 container-fluid">
          <div className="text-center w-100">
            <Label title="Already have an account?" className="font-14 gray" />
            <NavLink
              to={appRoutes.login}
              className="insta-link-orange ml-1 font-15 text-decoration-none"
              href="#"
            >
              Login
            </NavLink>
          </div>
          <div className="sp-container container mt-4 bg-white">
            <div className="row">
              <div className="col-12 p-0">
                <div className="sp-header">
                  <div className="col-sm-12">Sign Up</div>
                </div>
              </div>
              <SignUpForm
                states={STATE_LIST}
                onFormSubmit={this.onSignUpFormSubmit}
                constants={this.state.constants}
              />
            </div>
          </div>
          <TwoLineBtnModal
            modalBodyContent="Do you already have an Instant Consult account?</br>
                                          If you do, login to your existing account and</br>
                                          do NOT create a new account.</br>
                                          Contact Instant Consult Support</br>
                                          on 1300 003 310 if you require any assistance."
            okayTextLine1="I am a new user"
            okayTextLine2="Continue"
            title="Instant Consult"
            okayEvent={this.signUpPopUp}
            cancelEvent={this.openDialPad}
            cancelTextLine1="I have an existing account"
            cancelTextLine2="Call 1300 003 310"
            openModal={this.state.signUpPopUp}
          />
          <Modal
            modalBodyContent="Successful Registration"
            cancelText="Okay"
            title="Instant Consult"
            useOneButton={true}
            cancelEvent={this.regSuccessPopUp}
            openModal={this.state.registerSuccess}
          />
          <Modal
            modalBodyContent={this.state.errorModalBodyContent}
            cancelText="Okay"
            title="Instant Consult"
            useOneButton={true}
            cancelEvent={() => this.setState({ isOpenErrorModal: false })}
            openModal={this.state.isOpenErrorModal}
          />
        </div>
        <MakeApiCall apiParam={this.state.apiCall} />
      </PublicLayout>
    );
  }
}

export default SignUpPage;
