import React from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import Button from "../../components/button";
import ICInput from "../../components/form/input";

const AddCouponForm = props => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      couponText: ""
    },
    validationSchema: Yup.object({
      couponText: Yup.string().required("Please enter Coupon")
    }),
    onSubmit: values => {
      props.onAddSubmit(values);
      formik.resetForm();
    }
  });

  return (
    <form
      className="container d-flex flex-column mr-4 pr-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">
          Coupon:<span className="error">*</span>{" "}
        </label>
        <div className="col-sm-9">
          <ICInput
            name="couponText"
            type="text"
            value={formik.values.couponText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.couponText && formik.errors.couponText
                ? true
                : false
            }
            helperText={
              formik.touched.couponText && formik.errors.couponText
                ? formik.errors.couponText
                : ""
            }
          />
        </div>
      </div>
      <br />
      <p className="mb-0 d-flex justify-content-center">
        <Button title="Save" type="submit" />
        <Button
          className="insta-button-primary ml-3 mr-3"
          title="Cancel"
          type="button"
          clickEvent={() => {
            formik.resetForm();
            props.cancelEvent();
          }}
        ></Button>
      </p>
    </form>
  );
};

export default AddCouponForm;

AddCouponForm.defaultProps = {
  onAddSubmit: () => {},
  cancelEvent: () => {}
};

AddCouponForm.propTypes = {
  onAddSubmit: PropTypes.func,
  cancelEvent: PropTypes.func
};
