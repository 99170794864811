import React, { Component } from "react";
import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import { connect } from "react-redux";
// import { stateListApiRoute } from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import { editDependentProfileAPI } from "../../apiRoutes";
import { updateUserData } from "../../reduxStore/actions/auth";
import Modal from "../../components/modal";
import moment from "moment";
import { formatName, logFirebaseEvent } from "../../utils/common";
import Loader from "../../components/loader";
import EditFamilyProfileForm from "../../containers/FamilyProfile/EditDependentProfileForm";

class EditFamilyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      // stateList: "",
      editProfileSuccess: false,
      editSuccessPopUp: false,
      dependentDetails: this.props.location.state.dependentDetails
        ? props.location.state.dependentDetails
        : null,
      isPatientConsultedBefore: this.props.location.state
        .isPatientConsultedBefore
        ? true
        : false,
      editFailurePopUp: false,
      editFailurePopUpMsg: ""
    };
  }
  componentDidMount() {
    logFirebaseEvent("Edit Family Profile Page");
    window.scrollTo(0, 0);
  }

  onFormSubmit = formValues => {
    this.setState({
      apiLoading: true
    });
    console.log("Form Values: ", formValues);
    const formData = new FormData();
    formData.append("dependentId", this.state.dependentDetails._id);
    formData.append("firstName", formatName(formValues.firstName));
    formData.append("middleName", formatName(formValues.middleName));
    formData.append("lastName", formatName(formValues.lastName));
    const tmpDate = moment(formValues.dateOfBirth).format("DD-MM-YYYY");
    if (tmpDate != "Invalid date" && formValues.dateOfBirth.length > 10) {
      formValues.dateOfBirth = tmpDate;
    }
    formData.append("dateOfBirth", formValues.dateOfBirth);
    formData.append("gender", formValues.gender);
    formData.append("medicareNumber", formValues.medicareNumber);
    formData.append(
      "refNumerOnMedicareCard",
      formValues.refNumerOnMedicareCard
    );
    formData.append("IHINumber", formValues.IHINumber);
    formData.append("profilePic", formValues.profilePic);
    formData.append("isFile", formValues.isProfilePic.toString());

    let apiCallObj = {
      reqType: "PUT",
      reqURL: editDependentProfileAPI,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          editSuccessPopUp: true,
          apiLoading: false
        });
      },
      failureCallback: err => {
        console.log(err);
        this.setState({
          apiLoading: false
          // editFailurePopUp: true,
          // editFailurePopUpMsg: err && err.message ? err.message : "Something went wrong"
        });
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallObj
    });
  };

  okayEditProfileSuccess = () => {
    const { history } = this.props;
    history.push(appRoutes.familyProfile);
  };

  render() {
    return (
      <div
        className="container
        h-100 bg-white pb-5 mb-5"
      >
        <Loader loading={this.state.apiLoading} />
        <div className="row">
          <div className="col-sm-12 mt-4">
            <NavLink to={appRoutes.familyProfile}>
              <i title="" className="fa fa-arrow-left primary-color" />
              <Label
                title="Back to Family Profile"
                className="cursor-pointer font-15 on-hover-orange pl-2"
              />
            </NavLink>
          </div>

          <div className="col-sm-12 mt-4 pb-3">
            <Label title="Edit Dependent Profile" className="big" />
          </div>
          <div className="border-bottom mb-4 w-100" />
          {this.state.dependentDetails && (
            <EditFamilyProfileForm
              onFormSubmit={this.onFormSubmit}
              dependentDetails={this.state.dependentDetails}
              isPatientConsultedBefore={this.state.isPatientConsultedBefore}
            />
          )}
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Profile Updated Successfully"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.okayEditProfileSuccess}
          openModal={this.state.editSuccessPopUp}
        />
        <Modal
          modalBodyContent={this.state.editFailurePopUpMsg}
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            this.setState({
              editFailurePopUp: false
            });
          }}
          openModal={this.state.editFailurePopUp}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(updateUserData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFamilyProfile);
