import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FAQsList from "./FAQsPage";
import ContactUs from "./ContactUs";
import { connect } from "react-redux";
import {logFirebaseEvent} from "../../utils/common";

class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  componentDidMount() {
    logFirebaseEvent("Help Page");
    window.scrollTo(0, 0);
  }

  useStyles = makeStyles({
    root: {
      flexGrow: 1,
      maxWidth: 500
    }
  });

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <div className="row rounded p-3 help-page">
        <div className="col-sm-12">
          <Paper square>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
            >
              <Tab label="FAQs" />
              <Tab label="Contact Us" />
            </Tabs>
            <Typography
              component="div"
              role="tabpanel"
              hidden={this.state.value !== 1}
              id={`simple-tabpanel-1`}
              aria-labelledby={`simple-tab-1`}
            >
              {this.state.value === 1 && <Box p={3}>{<ContactUs email={this.props.auth.user[1].email} supportNumber={this.props.auth.user[1].phoneNumber}/>}</Box>}
            </Typography>
            <Typography
              component="div"
              role="tabpanel"
              hidden={this.state.value !== 0}
              id={`simple-tabpanel-1`}
              aria-labelledby={`simple-tab-1`}
            >
              {this.state.value === 0 && <Box p={3}>{<FAQsList faqList={this.props.auth.user[1].patientFaqs}/>}</Box>}
            </Typography>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ...state.modal
});

export default connect(mapStateToProps)(HelpPage);
