import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import PublicLayout from "../../containers/Layout/PublicLayout";
import SignUpForm from "../../containers/SignUpForm";

import Label from "../../components/label";
import appRoutes from "../../routes/appRoutes";

import { NavLink } from "react-router-dom";
import Modal from "../../components/modal";
import Loader from "../../components/loader";
import CustomModal from "../../components/customModal";
import TwoLineBtnModal from "../../components/modalTwoLineBtn";

import MakeApiCall from "../../apiCalls/common";
import { selectPharmacyAPI, getDrugDataFromPrescriptionAPI, requestSupportToSelectPharmacyAPI } from "../../apiRoutes";

import { formatName } from "../../utils/common";
import { logFirebaseEvent } from "../../utils/common";

import PharmacySelectForm from "../../containers/PharmacySelectForm/index";
import ChemistWarehouseInfo from "../../containers/PharmacySelectForm/ChemistWarehouseInfo";

import ContactUsFormForPrescription from "../../containers/ContactUsFormForPrescription";


class SelectPharmacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiCall: "",
            prescriptionSentToPharSuccessModel: false,
            errorDuringSentPharmacyModel: false,
            errorModelText: "",
            token: props.match.params.token,
            selectedTab: 0,
            selectedPharmacy: {},
            isConfirmPharmacyPopUpOpen: false,
            isLoading: false,
            drugData: null,
            supportRequestSuccessModel: false,
            isLinkInvalid: true,
            linkInvalidText: ""
        };
    }

    componentDidMount() {
        logFirebaseEvent("Sign Up Page");
        window.scrollTo(0, 0);
        this.getDrugDetails();
    }

    getDrugDetails() {
        this.setState({
            isLoading: true,
        });

        const dataToSend = {
            token: this.state.token
        };


        let apiCallObj = {
            reqType: "GET",
            reqURL: getDrugDataFromPrescriptionAPI,
            dataObj: {},
            params: dataToSend,
            headers: {},
            successCallback: res => {
                console.log("Got the drug data: ", res);
                const isSelectedPharmacy = res.data.data.isPharmacySetByPatient || res.data.data.isSupportRequiredByPatient;
                console.log("isSelectedPharmacy -> ", isSelectedPharmacy);
                this.setState({
                    isLoading: false,
                    drugData: res.data.data,
                    isLinkInvalid: isSelectedPharmacy,
                    linkInvalidText: isSelectedPharmacy ? "The link has expired" : ""
                });
            },
            failureCallback: err => {
                console.error("Hey here the error: ", err);
                this.setState({
                    errorModelText: err && err.message ? err.message : "Please try again. <br/> Please contact Instant Consult Support <br/> on 1300 003 310 if this issue persists.",
                    errorDuringSentPharmacyModel: true,
                    isLoading: false,
                    isLinkInvalid: true,
                    linkInvalidText: "Could not able to load correctly. Please reload the page."
                });
            },
            showPopUp: false,
            showErrorRes: false
        };

        this.setState({
            apiCall: apiCallObj
        });

    }

    closeWindow() {
        window.close();
    }

    closeSuccessModel() {
        this.setState({
            prescriptionSentToPharSuccessModel: false
        });
        this.props.history.push(appRoutes.login);
    }

    handlePharmacySubmission(selectedPharmacy) {
        console.log("Patient has selected this pharmacy: ", selectedPharmacy);
        this.setState({
            selectedPharmacy: selectedPharmacy,
            isConfirmPharmacyPopUpOpen: true
        });
    }

    // handle tab change
    handleChange = (event, newValue) => {
        this.setState({ selectedTab: newValue });
    };

    handleConfirmPharmacy = () => {
        console.log("Patient has selected the pharmacy: ", this.state.selectedPharmacy);
        this.setState({
            isLoading: true,
            isConfirmPharmacyPopUpOpen: false
        });

        const selectedPharmacy = this.state.selectedPharmacy;
        const dataToSend = {
            token: this.state.token
        };

        dataToSend.pharmacyName = selectedPharmacy.pharmacyName;
        dataToSend.pharmacyAddress1 = selectedPharmacy.pharmacyAddress;
        dataToSend.pharmacyAddress2 = selectedPharmacy.pharmacyAddress1;
        dataToSend.pharmacyAddress3 = selectedPharmacy.pharmacyAddress2;
        dataToSend.pharmacyEmail = selectedPharmacy.pharmacyEmail;
        dataToSend.pharmacyNumber = selectedPharmacy.pharmacyNo;
        dataToSend.isPatient = selectedPharmacy.isPatient;
        dataToSend.isChemistWarehouse = selectedPharmacy.isChemistWarehouse;

        if (selectedPharmacy.isChemistWarehouse) {
            dataToSend.pharmacyName = ChemistWarehouseInfo.pharmacyName;
            dataToSend.pharmacyAddress1 = ChemistWarehouseInfo.pharmacyAddress1;
            dataToSend.pharmacyAddress2 = ChemistWarehouseInfo.pharmacyAddress2;
            dataToSend.pharmacyAddress3 = ChemistWarehouseInfo.pharmacyAddress3;
            dataToSend.pharmacyEmail = ChemistWarehouseInfo.pharmacyEmail;
            dataToSend.pharmacyNumber = ChemistWarehouseInfo.pharmacyNumber;
        }


        let apiCallObj = {
            reqType: "POST",
            reqURL: selectPharmacyAPI,
            dataObj: dataToSend,
            params: {},
            headers: {},
            successCallback: res => {
                console.log("Pharmacy Set Successfully: ", res);
                this.setState({
                    errorDuringSentPharmacyModel: false,
                    isLoading: false,
                    prescriptionSentToPharSuccessModel: true
                });
            },
            failureCallback: err => {
                console.error("Hey here the error: ", err);
                this.setState({
                    errorModelText: err && err.message ? err.message : "Please try again. <br/> Please contact Instant Consult Support <br/> on 1300 003 310 if this issue persists.",
                    errorDuringSentPharmacyModel: true,
                    isLoading: false
                });
            },
            showPopUp: false,
            showErrorRes: false
        };

        this.setState({
            apiCall: apiCallObj
        });
    };

    submitContactUsForm(formVal) {
        console.log("Contact us form submitted");
        this.setState({
            isLoading: true,
        });

        const dataToSend = {
            token: this.state.token,
            postCode: formVal.postCode,
            pharmacyName: formVal.pharmacyName,
            pharmacyAddress: formVal.pharmacyAddress
        }

        let apiCallObj = {
            reqType: "POST",
            reqURL: requestSupportToSelectPharmacyAPI,
            dataObj: dataToSend,
            params: {},
            headers: {},
            successCallback: res => {
                console.log("Pharmacy Set Successfully: ", res);
                this.setState({
                    errorDuringSentPharmacyModel: false,
                    isLoading: false,
                    supportRequestSuccessModel: true
                });
            },
            failureCallback: err => {
                console.error("Hey here the error: ", err);
                this.setState({
                    errorModelText: err && err.message ? err.message : "Please try again. <br/> Please contact Instant Consult Support <br/> on 1300 003 310 if this issue persists.",
                    errorDuringSentPharmacyModel: true,
                    isLoading: false
                });
            },
            showPopUp: false,
            showErrorRes: false
        };

        this.setState({
            apiCall: apiCallObj
        });

    }

    closeSupportRequestModel() {
        this.setState({
            isLoading: false,
            supportRequestSuccessModel: false
        });
        this.props.history.push(appRoutes.login);
    }

    render() {
        return (
            <PublicLayout>

                <div className="pb-5 container select-pharmacy-page mt-5">
                    <Loader loading={this.state.isLoading} />
                    {
                        this.state.isLinkInvalid === false ? (
                            <Paper square className="p-2">
                                <Tabs
                                    value={this.state.selectedTab}
                                    onChange={this.handleChange}
                                    variant="fullWidth"
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    aria-label="icon label tabs example"
                                >
                                    <Tab label="Nominate Pharmacy" />
                                    <Tab label="I cannot find my pharmacy" />
                                </Tabs>
                                <Typography
                                    component="div"
                                    role="tabpanel"
                                    hidden={this.state.selectedTab !== 0}
                                    id={`simple-tabpanel-1`}
                                    aria-labelledby={`simple-tab-1`}
                                >
                                    {this.state.selectedTab === 0 &&
                                        <div className="sp-container container">
                                            {
                                                this.state.drugData !== null && (
                                                    <div className="row">
                                                        <div className="col-sm-12 p-2">
                                                            <Label title="At the time your prescription was generated, it was ineligible for an eToken." className="nominate-pharmacy-text w-100" />
                                                            <p className="insta-label nominate-pharmacy-text w-100">
                                                                Please nominate a pharmacy you would like to collect your prescription for <span className="semi-bold-font">{this.state.drugData.urlObjectId.drugName}</span> from.
                                                            </p>
                                                            {/* <Label title={`Please nominate a pharmacy you would like to collect your prescription for ${this.state.drugData.urlObjectId.drugName} from.`} className="nominate-pharmacy-text w-100" /> */}
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="row">
                                                <PharmacySelectForm handlePharmacySubmission={(selectedPharmcy) => this.handlePharmacySubmission(selectedPharmcy)} />
                                            </div>
                                        </div>
                                    }
                                </Typography>
                                <Typography
                                    component="div"
                                    role="tabpanel"
                                    hidden={this.state.selectedTab !== 1}
                                    id={`simple-tabpanel-1`}
                                    aria-labelledby={`simple-tab-1`}
                                >
                                    {this.state.selectedTab === 1 && <div>
                                        <p className="insta-label nominate-pharmacy-text pt-2 pl-3 mb-0">
                                            Please insert your nominated pharmacy name and physical address you would like to collect your prescription for <span className="semi-bold-font">{this.state.drugData.urlObjectId.drugName}</span> from.
                                        </p>
                                        {/* <Label title="Please insert your nominated pharmacy name and physical address you would like to collect your prescription from." className="nominate-pharmacy-text pt-2 pl-3" /> */}
                                        <ContactUsFormForPrescription onFormSubmit={(formVal) => this.submitContactUsForm(formVal)} />
                                    </div>}
                                </Typography>
                            </Paper>
                        ) : (
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Label title={this.state.linkInvalidText} />
                                </div>
                            </div>
                        )
                    }



                    <Modal
                        modalBodyContent="Thank You! <br/> You will receive a notification to confirm your nominated pharmacy has received your prescription."
                        cancelText="Okay"
                        title="Instant Consult"
                        useOneButton={true}
                        cancelEvent={() => this.closeSuccessModel()}
                        openModal={this.state.prescriptionSentToPharSuccessModel}
                    />

                    <Modal
                        modalBodyContent="Thank You! <br/> You will receive a notification to confirm your nominated pharmacy has received your prescription."
                        cancelText="Okay"
                        title="Instant Consult"
                        useOneButton={true}
                        cancelEvent={() => this.closeSupportRequestModel()}
                        openModal={this.state.supportRequestSuccessModel}
                    />

                    <CustomModal
                        okayEvent={() => this.handleConfirmPharmacy()}
                        okayText="Confirm"
                        cancelText="Cancel"
                        title="Confirm Pharmacy Details"
                        cancelEvent={() => {
                            this.setState({
                                isConfirmPharmacyPopUpOpen: false
                            });
                        }}
                        openModal={this.state.isConfirmPharmacyPopUpOpen}
                        modalBodyContent={
                            <div className="row text-left">
                                <div className="col-sm-4">
                                    <Label title="Pharmacy Name" className="semi-bold-font" />
                                </div>
                                <div className="col-sm-8">
                                    <Label title={this.state.selectedPharmacy.isChemistWarehouse ? "Chemist Warehouse Online" : this.state.selectedPharmacy.pharmacyName} />
                                </div>
                                <div className="col-xs-12 p-2 d-block d-sm-none"></div>
                                <div className="col-sm-4">
                                    <Label title="Pharmacy Address" className="semi-bold-font" />
                                </div>
                                <div className="col-sm-8">
                                    <Label title={this.state.selectedPharmacy.isChemistWarehouse ? "chemistwarehouse.com.au" : this.state.selectedPharmacy.pharmacyAddress + (this.state.selectedPharmacy.pharmacyAddress1 ? ` ${this.state.selectedPharmacy.pharmacyAddress1}` : '')} />
                                    <Label title={this.state.selectedPharmacy.isChemistWarehouse ? '' : `${this.state.selectedPharmacy.pharmacyAddress2}`} />
                                </div>
                            </div>
                        }
                        backdrop="static"
                    />

                    <Modal
                        modalBodyContent={this.state.errorModelText}
                        cancelText="Okay"
                        title="Instant Consult"
                        useOneButton={true}
                        cancelEvent={() => this.setState({ errorDuringSentPharmacyModel: false })}
                        openModal={this.state.errorDuringSentPharmacyModel}
                    />
                </div>
                <MakeApiCall apiParam={this.state.apiCall} />
            </PublicLayout>
        );
    }
}

export default withRouter(SelectPharmacy);
