import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import setAuthToken from "../utils/setAuthToken";
import Modal from "../components/modal";

import DashboardLayout from "../containers/Layout/Dashboard";
import LoginPage from "../pages/Login";
import ForgotPasswordPage from "../pages/ForgotPassword";
import RecoverPassword from "../pages/RecoverPassword";
import SignUpPage from "../pages/SignUp";
import SelectPharmacy from "../pages/SelectPharmacy";
import UnsubscribeEmailPage from "../pages/UnsubscribeEmail/UnsubscribeEmailPage";

import RequestAConsult from "../pages/RequestAConsult";
import Notifications from "../pages/Notifications";
import Inbox from "../pages/Inbox";

import appRoutes from "./appRoutes";
import ConsultHistory from "../pages/ConsultHistory";
import ConsultHistoryDetails from "../pages/ConsultHistoryDetails";
import Profile from "../pages/Profile";
import SavedCards from "../pages/Saved Cards";
import ConsultCosts from "../pages/ConsultCosts";
import AddNewCard from "../pages/AddNewCard";
import EditProfile from "../pages/EditProfile";
import EmergencyContacts from "../pages/EmergencyContacts";
import HelpPage from "../pages/Help";
import LegalPage from "../pages/Legal";
import BlockedDoctors from "../pages/BlockedDoctors";
import changePasswordPage from "../pages/ChangePassword";
import consultationDetails from "../pages/RequestAConsult/ConsultationDetails";
import booking from "../pages/RequestAConsult/Booking";

import doctorCallReceived from "../pages/call/DoctorCallReceived";
import ConsultSummaryPage from "../pages/AfterCallViews/consultSummary";

import FamilyProfile from "../pages/FamilyProfile/FamilyProfileListPage";
import AddFamilyProfilePage from "../pages/FamilyProfile/AddFamilyProfilePage";
import EditFamilyProfile from "../pages/FamilyProfile/EditFamilyProfilePage";
import APP_CONSTANTS from "../constants";
import DeleteAccountInstructions from "../pages/DeleteAccountInstructions";


// we will use this components for the restricted routes which can be accessed after login only
const RestrictedRoute = props => (
  <Fragment>
    {props.isLoggedIn === true ? (
      <Route exact path={props.path} component={props.component}/>
    ) : (
      <Redirect to={appRoutes.login} from={props.path}/>
    )}
  </Fragment>
);

// We will use this component for public routes which cannot be access after login
const PublicRoute = props => (
  <Fragment>
    {(props.isLoggedIn === false || props.isPublic) ? (
      <Route exact path={props.path} component={props.component}/>
    ) : (
      <Redirect to={appRoutes.requestAConsult} from={props.path}/>
    )}
  </Fragment>
);

//Detects mobile device
const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

//Detects desktop browser
const getDesktopBrowser = () => {
  var isBrave = false;
  try {
    isBrave = navigator.brave.isBrave();
    if (isBrave) return false;
  } catch (err) {
  }
  if (/Edge/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent)) {
    return false;
  }
  if (/edge/.test(navigator.userAgent) || /edg/.test(navigator.userAgent)) {
    return false;
  }
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) || (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor));
  //var isFirefox = typeof InstallTrigger !== "undefined";
  if (isChrome) return true;

  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') != -1) {
    return true
  }
  else return false;
};

var osType;

const detectBrowserAndServe = () => {
  let os = getMobileOperatingSystem();
  if (os === "Android") {
    osType = 1;
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.instantconsult&hl=en_AU";
    //window.location.href = "https://instantconsult-new.s3-ap-southeast-2.amazonaws.com/App/InstantConsultAndroid.apk"
  } else if (os === "iOS") {
    osType = 2;
    window.location.href =
      "https://apps.apple.com/au/app/instant-consult/id1388965848";
  } else if (os === "Windows Phone") {
    osType = 3;
    window.location.href = "http://www.WindowsPhoneexample.com";
  } else {
  }
};

class AppRoutes extends PureComponent {
  render() {
    let compatibleDesktopBrowser = getDesktopBrowser();
    const auth = this.props.auth;

    //set the token even after page refreshes
    setAuthToken(auth.accessToken ? auth.accessToken : false);

    if (window.location.pathname.includes("deleteAccountInstructions") || window.location.pathname == "/recover" || window.location.pathname.includes("/select-pharmacy") || window.location.pathname.includes("/marketing/unsubscribe")) {
      compatibleDesktopBrowser = true;
      osType = 0;
    } else {
      detectBrowserAndServe();
    }

    if (osType === 1 || osType === 2 || osType === 3) {
      return (
        <div>
          <Modal
            modalBodyContent="You must download the App</br>to use the Instant Consult service"
            cancelText="Okay"
            title="Download the App"
            useOneButton={true}
            okayEvent={detectBrowserAndServe}
            cancelEvent={detectBrowserAndServe}
            openModal={true}
          />
        </div>
      );
    } else if (!compatibleDesktopBrowser) {
      return (
        <div>
          <Modal
            modalBodyContent="This web browser is not compatible.</br>
                We recommend using Google Chrome.</br>
                </br>
                Alternatively, you can download the</br>
                Instant Consult app onto your phone.</br>
                </br>
                Please call Instant Consult Support on 1300 003 310</br>
                if you require any assistance."
            cancelText="Okay"
            title="Instant Consult"
            useOneButton={true}
            okayEvent={detectBrowserAndServe}
            cancelEvent={detectBrowserAndServe}
            openModal={true}
          />
        </div>
      );
    } else {
      return (
        <Fragment>
          <Router>
            <Switch>
              <Redirect exact from="/" to={appRoutes.login}/>
              <PublicRoute
                path={appRoutes.login}
                component={LoginPage}
                isLoggedIn={auth.isLoggedIn}
              />
              <PublicRoute
                path={appRoutes.forgotPassword}
                component={ForgotPasswordPage}
                isLoggedIn={auth.isLoggedIn}
              />
              <PublicRoute
                path={appRoutes.unsubscribe}
                component={UnsubscribeEmailPage}
                isLoggedIn={auth.isLoggedIn}
                isPublic={true}
              />
              <PublicRoute
                path={appRoutes.deleteAccountInstructions}
                component={DeleteAccountInstructions}
                isLoggedIn={auth.isLoggedIn}
                isPublic={true}
              />
              <PublicRoute
                path={appRoutes.signUp}
                component={SignUpPage}
                isLoggedIn={auth.isLoggedIn}
              />
              <PublicRoute
                path={appRoutes.recover}
                component={RecoverPassword}
                isLoggedIn={auth.isLoggedIn}
              />
              <PublicRoute
                path={appRoutes.selectPharmacy + "/:token"}
                component={SelectPharmacy}
                isLoggedIn={false}
              />
              <Redirect
                exact
                from="/Dashboard"
                to={appRoutes.requestAConsult}
              />
              <Route path="/Dashboard">
                <Switch>
                  <DashboardLayout>
                    <RestrictedRoute
                      path={appRoutes.requestAConsult}
                      component={RequestAConsult}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.notifications}
                      component={Notifications}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.inbox}
                      component={Inbox}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.consultHistory}
                      component={ConsultHistory}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.consultDetails}
                      component={ConsultHistoryDetails}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.myProfile}
                      component={Profile}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.familyProfile}
                      component={FamilyProfile}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.savedCards}
                      component={SavedCards}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.consultCosts}
                      component={ConsultCosts}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.addNewCard}
                      component={AddNewCard}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.editProfile}
                      component={EditProfile}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.addFamilyProfile}
                      component={AddFamilyProfilePage}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.editFamilyProfile}
                      component={EditFamilyProfile}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.editEmergencyContacts}
                      component={EmergencyContacts}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.helpPage}
                      component={HelpPage}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.legalPage}
                      component={props => (
                        <LegalPage
                          privacyPolicyURL={
                            auth.user && auth.user.length > 1
                              ? auth.user[1].privacyPolicy
                              : APP_CONSTANTS.privacyPolicyURL
                          }
                          terms={
                            auth.user && auth.user.length > 1
                              ? auth.user[1].terms
                              : APP_CONSTANTS.tAcURL
                          }
                        />
                      )}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.blockedDoctors}
                      component={BlockedDoctors}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.changePassword}
                      component={changePasswordPage}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.consultationDetails}
                      component={consultationDetails}
                      isLoggedIn={auth.isLoggedIn}
                    /> 
                    <RestrictedRoute
                      path={appRoutes.booking}
                      component={booking}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.patientCallReceived}
                      component={doctorCallReceived}
                      isLoggedIn={auth.isLoggedIn}
                    />
                    <RestrictedRoute
                      path={appRoutes.consultSummary + "/:appointmentId"}
                      component={ConsultSummaryPage}
                      isLoggedIn={auth.isLoggedIn}
                    />
                  </DashboardLayout>
                </Switch>
              </Route>{" "}
              {/* <Route component={Error404} path="/404" /> */}
              <Redirect from="*" to="/404"/>
            </Switch>
          </Router>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(AppRoutes);
