import React, { Component } from "react";
import Label from "../../components/label";
import EditProfileForm from "../../containers/EditProfileForm";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import { connect } from "react-redux";
// import { stateListApiRoute } from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import { editProfileApiRoute } from "../../apiRoutes";
import { updateUserData } from "../../reduxStore/actions/auth";
import Modal from "../../components/modal";
import moment from "moment";
import { formatName, logFirebaseEvent } from "../../utils/common";
import { STATE_LIST } from "../../constants/constants";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      // stateList: "",
      editProfileSuccess: false,
      editSuccessPopUp: false,
      totalConsultCount: props.totalConsultCount
    };
  }
  componentDidMount() {
    logFirebaseEvent("Edit Profile Page");
    window.scrollTo(0, 0);

    // let apiCallObj = {
    //   reqType: "GET",
    //   reqURL: stateListApiRoute,
    //   dataObj: {},
    //   params: {},
    //   headers: {},
    //   successCallback: res => {
    //     this.setState({
    //       stateList: res.data.data
    //     });
    //   },
    //   failureCallback: err => {
    //     console.log(err);
    //   },
    //   showPopUp: false
    // };
    //
    // this.setState({
    //   apiCall: apiCallObj
    // });
  }

  onFormSubmit = formValues => {
    this.setState({
      apiLoading: true
    });
    const formData = new FormData();
    let isDisplayNameChange =
      this.state.totalConsultCount === 0 ||
      this.state.totalConsultCount === undefined;
    formData.append("isDisplayNameChange", isDisplayNameChange);
    formData.append("firstName", formatName(formValues.firstName));
    formData.append("middleName", formatName(formValues.middleName));
    formData.append("lastName", formatName(formValues.lastName));
    formData.append("email", formValues.email);
    const tmpDate = moment(formValues.dateOfBirth).format("DD-MM-YYYY");
    if (tmpDate !== "Invalid date" && formValues.dateOfBirth.length > 10) {
      formValues.dateOfBirth = tmpDate;
    }
    formData.append("dateOfBirth", formValues.dateOfBirth);
    formData.append("gender", formValues.gender);
    formData.append("phoneNumber", formValues.phoneNumber);
    formData.append("streetNumber", formValues.streetNumber);
    formData.append("streetName", formValues.streetName);
    formData.append("suburb", formValues.suburb);
    formData.append("state", formValues.state);
    formData.append("postCode", formValues.postCode);
    formData.append(
      "IHINumber",
      (formValues.IHINumber || "").replaceAll(" ", "")
    );
    formData.append("medicareNumber", formValues.medicareNumber);
    formData.append(
      "refNumerOnMedicareCard",
      formValues.refNumerOnMedicareCard
    );
    formData.append("medicalInfo", formValues.medicalInfo);
    formData.append("profilePhoto", formValues.profilePic);
    formData.append("isFile", formValues.isProfilePic.toString());
    // formData.append("DVANumber", formValues.DVANumber);

    let tmpStore = this.props.auth;
    delete tmpStore.auth;
    let apiCallObj = {
      reqType: "PUT",
      reqURL: editProfileApiRoute,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: res => {
        tmpStore.user[0].firstName = formatName(formValues.firstName);
        tmpStore.user[0].middleName = formatName(formValues.middleName);
        tmpStore.user[0].lastName = formatName(formValues.lastName);
        tmpStore.user[0].dateOfBirth = res.data.data.dateOfBirth;
        tmpStore.user[0].gender = formValues.gender;
        tmpStore.user[0].phoneNumber = formValues.phoneNumber;
        if (formValues.streetNumber.includes("/")) {
          let arr = formValues.streetNumber.split("/");
          tmpStore.user[0].address.unitNumber = arr[0];
          tmpStore.user[0].address.streetNumber = arr[1];
        } else {
          tmpStore.user[0].address.unitNumber = "";
          tmpStore.user[0].address.streetNumber = formValues.streetNumber;
        }
        tmpStore.user[0].address.streetName = formValues.streetName;
        tmpStore.user[0].address.suburb = formValues.suburb;
        tmpStore.user[0].address.state = formValues.state;
        tmpStore.user[0].address.postCode = formValues.postCode;
        tmpStore.user[0].medicareNumber = formValues.medicareNumber;
        tmpStore.user[0].IHIInfo = { IHINumber: formValues.IHINumber };
        tmpStore.user[0].refNumerOnMedicareCard =
          formValues.refNumerOnMedicareCard;
        // tmpStore.user[0].DVANumber = formValues.DVANumber;
        tmpStore.user[0].profilePic = {
          ...(res.data.data.profilePic || {})
        };
        console.log({ tmpStore });
        //delete tmpStore.auth;
        this.props.updateUserData(tmpStore);
        this.setState({
          editSuccessPopUp: true,
          apiLoading: false
        });
      },
      failureCallback: err => {
        console.log(err);
        this.setState({
          apiLoading: false
        });
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallObj
    });
  };

  okayEditProfileSuccess = () => {
    const { history } = this.props;
    history.push(appRoutes.myProfile);
  };

  render() {
    return (
      <div
        className="container
        h-100 bg-white pb-5 mb-5"
      >
        <div className="row">
          <div className="col-sm-12 mt-4">
            <NavLink to={appRoutes.myProfile}>
              <i title="" className="fa fa-arrow-left primary-color" />
              <Label
                title="Back to My Profile"
                className="cursor-pointer font-15 on-hover-orange pl-2"
              />
            </NavLink>
          </div>

          <div className="col-sm-12 mt-4 pb-3">
            <Label title="Edit Profile" className="big" />
          </div>
          <div className="border-bottom mb-4 w-100" />
          <EditProfileForm
            onFormSubmit={this.onFormSubmit}
            userDetails={this.props.auth.user[0]}
            stateList={STATE_LIST}
            isEditNamesDisabled={this.state.totalConsultCount !== 0}
          />
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Profile Updated Successfully"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.okayEditProfileSuccess}
          openModal={this.state.editSuccessPopUp}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  totalConsultCount: state.commonReducers.totalPatientConsultCount
});

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(updateUserData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
