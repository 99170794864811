import React, { Fragment, PureComponent } from "react";
import Label from "../../../components/label";
import DefaultPic from "../../../assets/images/default-user-img.png";
import {
  // getBookingApiRoute,
  cancelConsultRequestApiRoute,
  getBookingDetailsApiRoute
} from "../../../apiRoutes/index";
import {
  bookingStatus,
  saveAppointmentData,
  saveCallWaitTime,
  saveRequestAcceptedStatus,
  saveIsDoctorCalling
} from "../../../reduxStore/actions/common";import MakeAPICall from "../../../apiCalls/common";
import IconButton from "../../../components/iconButton";
import appRoutes from "../../../routes/appRoutes";
import Loader from "../../../components/loader";
import Modal from "../../../components/modal";
import CustomModal from "../../../components/customModal";
import moment from "moment";
import { connect } from "react-redux";
import { logFirebaseEvent } from "../../../utils/common";
class Booking extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: null,
      isProcessing: false,
      consultID: "",
      natureOfConsult: null,
      photosAndDocs: [],
      additionalInfo: "No Additional Information",
      photos: [],
      docs: [],
      showErrorModal: false,
      bookingTime: "",
      showBookingCancellationModal: false,
      cancelBookingText: "",
      isBooking: false
    };
  }

  componentDidMount() {
    logFirebaseEvent("Booking Info Page");
    this.getConsultDetails();

  }

  clearStatesDuringCancelAndRedirect = async () => {
    await this.props.saveCallWaitTime(null);
    await this.props.bookingStatus(0);
    await this.props.saveIsDoctorCalling(false);
    await this.props.saveAppointmentData(null);
    // go to consult request page
    this.props.history.push(appRoutes.requestAConsult);
  };

  closeModal = () => {
    this.setState({
      modal: false
    });
  };

    // get the consult data
    getConsultDetails = () => {
      if (
        this.props.commonReducers.appointmentData &&
        this.props.commonReducers.appointmentData._id
      ) {
        let apiCallObj = {
          reqType: "GET",
          reqURL: getBookingDetailsApiRoute,
          params: {
            booking_id:
              this.props.commonReducers.appointmentData &&
              this.props.commonReducers.appointmentData._id
                ? this.props.commonReducers.appointmentData._id
                : ""
          },
          successCallback: res => {
            let dt = res.data.data;

            if (dt && dt.status === 0) {
              // if the consult is in queuing, accepted, in progress and on the call
              let images = [];
              let pdfs = [];
              if (dt.consultImgs && dt.consultImgs.length > 0) {
                dt.consultImgs.forEach(doc => {
                  let pic = doc.original.split(".");

                  if (pic[pic.length - 1] === "pdf") {
                    pdfs.push(doc);
                  } else {
                    images.push(doc);
                  }
                });
              }
              if (dt.consultDocs && dt.consultDocs.length > 0) {
                dt.consultDocs.forEach(doc => {
                  let pic = doc.original.split(".");

                  if (pic[pic.length - 1] === "pdf") {
                    pdfs.push(doc);
                  } else {
                    images.push(doc);
                  }
                });
              }

              this.setState({
                patientId: dt.patientId,
                additionalInfo: dt.consultInfo
                  ? dt.consultInfo
                  : "No Additional Information",
                photos: images,
                docs: pdfs,
                natureOfConsult: dt.consultId,
                consultID: dt.appointmentId,
                bookingTime: dt.bookingTime,
                isBooking: true
              });
            } else {
              this.props.history.push(appRoutes.requestAConsult);
            }
          },
          failureCallback: err => {
            // this.setState({
            //   showErrorModal: true
            // });
          },
          showPopUp: false
        };
        this.setState({ apiCall: apiCallObj });
      } else {
        this.clearStatesDuringCancelAndRedirect();
      }
    };
  

  cancelBooking = booking => {
    let patientName = this.state.patientId
      ? this.state.patientId.firstName
      : "";

    this.setState({
      showBookingCancellationModal: true,
      cancelBookingText:
        patientName.length > 0
          ? "Are you sure you want to cancel " +
            patientName +
            "'s booking at " +
            moment(this.state.bookingTime)
              .format("hh:mm a")
              .toLocaleUpperCase() +
            "?"
          : "Are you sure you want to cancel your booking at " +
            moment(this.state.bookingTime)
              .format("hh:mm a")
              .toLocaleUpperCase() +
            "?"
    });
  };

  dismissBookingCancellationModal = () => {
    this.setState({
      showBookingCancellationModal: false
    });
  };

  callCancelRequestAPI = () => {
    let apiCallObj = {
      reqType: "PUT",
      reqURL: cancelConsultRequestApiRoute,
      dataObj: {
        appointmentId: this.props.commonReducers.appointmentData
          ? this.props.commonReducers.appointmentData._id
          : ""
      },
      params: {},
      headers: {},
      successCallback: async res => {
        this.setState({
          modalTitle: "Instant Consult",
          modalBodyContent: "Booking Cancelled",
          modalSingleButton: true,
          testConnectionLoader: false,
          modalCloseButtonTitle: "Okay",
          modalClassName: "",
          isBooking: false,
          showBookingCancellationModal: false
        });

        this.clearStatesDuringCancelAndRedirect();
      },
      failureCallback: err => {
        console.log("booking cancel fail", err);
        this.setState({
          modal: true,
          testConnectionLoader: false,
          modalTitle: "Booking Cancellation Failure",
          modalBodyContent: err.message,
          modalSingleButton: true,
          cancelModal: this.closeModal,
          modalCloseButtonTitle: "Okay",
          modalClassName: "",
          bookingToCancel: null,
          showBookingCancellationModal: false
        });
      },
      showPopUp: false
    };
    this.setState({ apiCall: apiCallObj });
  };

  openUrl = url => {
    window.open(url, "_blank");
  };

  render() {
    return (
      <div className="container p-0 consult-details-container consultation-details">
        {this.state.isProcessing && <Loader />}
        {this.state.isBooking && (
          <div className="consult-details-content-container mt-4 w-100">
            <Label title="Booking Details" className="w-100 pb-3 big" />
            <div className={this.state.isProcessing ? "disableContainer" : ""}>
              <div className="consult-details-content-container">
                <div className="row">
                  <div className="row flex w-100">
                    <img
                      src={
                        this.state.patientId &&
                        this.state.patientId.profilePic &&
                        this.state.patientId.profilePic.original
                          ? this.state.patientId.profilePic.original
                          : DefaultPic
                      }
                      alt="patient_url"
                      className="column m-2"
                      style={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        height: "100px",
                        width: "100px"
                      }}
                    />
                    <div className="ml-4 column my-auto flex-grow-1">
                      <Label
                        className="secondary-font"
                        title={
                          this.state.patientId
                            ? this.state.patientId.firstName +
                              " " +
                              this.state.patientId.lastName
                            : "-"
                        }
                      />
                      <br />
                      <Label className="secondary-font" title="UIN: " />
                      <Label
                        className="gray patient-info pl-1"
                        title={
                          this.state.patientId
                            ? this.state.patientId.patientId
                            : "-"
                        }
                      />
                    </div>
                    <IconButton
                      title="Cancel Booking"
                      className="font-15 m-auto column align-middle"
                      clickEvent={this.cancelBooking}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>

              <>
                <p className="insta-label secondary-font font-10 m-0 italic mt-3">
                {`You have a booking today at ${moment(this.state.bookingTime)
                    .format("hh:mm a")
                    .toLocaleUpperCase()} for ${this.state.patientId.firstName + " " + this.state.patientId.lastName}`}
                </p>
                <p
                  className="insta-label secondary-font font-10 m-0 italic mt-3"
                > Please have the app open at this time and wait for the doctor to video call you through the app</p>
                <p
                  className="insta-label secondary-font font-10 m-0 mt-3"
                >If they cannot connect with you through the app, they will phone call you on a private number</p>

                <div className="pt-3">
                  <Label
                    className="font-19 secondary-font mb-0"
                    title="Booking Details"
                  />
                  <br />
                  <Label
                    className="secondary-font m-0"
                    title={
                      "Date: " +
                      moment(this.state.bookingTime).format("dddd DD/MM/YYYY")
                    }
                  />
                  <br />
                  <Label
                    className="secondary-font m-0"
                    title={
                      "Time: " +
                      moment(this.state.bookingTime)
                        .format("hh:mm a")
                        .toLocaleUpperCase()
                    }
                  />
                </div>
                {this.state.natureOfConsult !== null && (
                  <Fragment>
                    <div className="col-sm-12 mt-3 px-0">
                      <Label
                        title="Nature of Consult"
                        className="font-19 secondary-font"
                      />
                    </div>
                    <div className="col-sm-12 px-0">
                      <img
                        src={this.state.natureOfConsult.consultPic.original}
                        alt="consult-img"
                        className="nature-cnslt-img"
                      />
                      <Label
                        title={this.state.natureOfConsult.consultName}
                        className="pl-1 mb-0 font-19 primary-color"
                      />
                    </div>
                  </Fragment>
                )}
                <br />
                <div className="col-sm-12 mt-3 p-0">
                  <Label
                    title="Images/Documents"
                    className="font-19 secondary-font mb-0"
                  />
                </div>
                <div className="col-sm-12 p-0 row mt-2 patient-attached-documents">
                  {this.state.photos.length === 0 &&
                    this.state.docs.length === 0 && (
                      <Label title="No Images/Documents" className="font-14" />
                    )}
                  {this.state.photos.length > 0 &&
                    this.state.photos.map((element, index) => {
                      return (
                        <div
                          className="float-left position-relative"
                          key={index}
                        >
                          <img
                            src={element.original}
                            alt="no-img-instant-consult"
                            className="mt-2 mr-2 img-thumbnail att-fixed-size"
                          />
                          <i
                            className="fas fa-eye att-delete-btn cursor-pointer"
                            onClick={() => {
                              this.openUrl(element.original);
                            }}
                          />
                          <div className="clearfix" />
                        </div>
                      );
                    })}

                  {this.state.docs.length > 0 &&
                    this.state.docs.map((element, index) => {
                      return (
                        <div
                          className="float-left position-relative"
                          key={index}
                        >
                          <iframe
                            title="pdfFrame"
                            src={element.original}
                            alt="no-img-instant-consult"
                            className="mt-2 mr-2 img-thumbnail att-fixed-size"
                          />
                          {/* <a
                        style={{ height: "33px" }}
                        className="insta-button-primary  btn btn-primary"
                        href={element.original}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Document {index + 1}
                      </a> */}
                          <i
                            className="fas fa-eye att-delete-btn cursor-pointer"
                            onClick={() => {
                              this.openUrl(element.original);
                            }}
                          />
                          <div className="clearfix" />
                        </div>
                      );
                    })}
                </div>
                <br />
                <div className="col-sm-12 p-0 mt-3">
                  <Label
                    title="Additional Information"
                    className="font-19 secondary-font mb-0"
                  />
                </div>
                <div className="col-sm-12 p-0 row">
                  <Label
                    title={this.state.additionalInfo}
                    className="font-14 ml-3"
                  />
                </div>
                <br />
                <br />
              </>
            </div>
          </div>
        )}

        <CustomModal
          modalBodyContent={
            <p className="requested-patient-name text-center w-100 mt-3 mb-0">
              Some error occured. Please refresh the page.
            </p>
          }
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            window.location.reload(true);
          }}
          openModal={this.state.showErrorModal}
          backdrop="static"
        />

        <Modal
          openModal={this.state.showBookingCancellationModal}
          modalBodyContent={this.state.cancelBookingText || ""}
          okayText="Cancel Booking"
          cancelText="Dismiss"
          title="Booking Cancellation"
          okayEvent={this.callCancelRequestAPI}
          cancelEvent={this.dismissBookingCancellationModal}
        />
        <Modal
          modalBodyContent={this.state.modalBodyContent}
          cancelText={this.state.modalCloseButtonTitle}
          okayText={this.state.modalOkayButtonTitle}
          title={this.state.modalTitle}
          useOneButton={this.state.modalSingleButton}
          cancelEvent={this.state.cancelModal}
          okayEvent={this.state.okayModal}
          openModal={this.state.modal}
          className={this.state.modalClassName}
          backdrop="static"
        />

        <MakeAPICall apiParam={this.state.apiCall} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({
  bookingStatus: data => dispatch(bookingStatus(data)),
  saveAppointmentData: data => dispatch(saveAppointmentData(data)),
  saveCallWaitTime: data => dispatch(saveCallWaitTime(data)),
  saveRequestAcceptedStatus: data => dispatch(saveRequestAcceptedStatus(data)),
  saveIsDoctorCalling: data => dispatch(saveIsDoctorCalling(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
