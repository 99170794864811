import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

//import appRoutes from "../../routes/appRoutes";
import APP_CONSTANTS from "../../constants/index";
import Label from "../../components/label";
import ICCheckbox from "../../components/form/checkbox";
import Anchor from "../../components/anchor";
import MedicareLogo from "../../assets/images/medicare-logo.png";
import Button from "../../components/button";
//import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { getCurrDateInMomentBrisbane } from "../../utils/dateTimeHelper";
import { logFirebaseEvent } from "../../utils/common";

const ConsentPageForm = props => {
  let sigPad = {};
  const [signature, setSignature] = useState(true);
  const [isPatientIsLessThen18, setIsPatientIsLessThen18] = useState(false);
  const [isPatientLessThen18Error, setIsPatientLessThen18Error] = useState(
    false
  );

  const formik = useFormik({
    initialValues: {
      cb: false,
      claimatDeclarationCB: false,
      cbOptional: false,
      cbConcession: false,
      signatureImg: null
    },
    validationSchema: Yup.object({
      cb: Yup.boolean().oneOf([true]),
      claimatDeclarationCB: Yup.boolean().oneOf([true])
    }),
    onSubmit: values => {
      trim();
      let isGuardianRequired = isPatientIsLessThen18
        ? values.cbOptional === true
          ? false
          : true
        : false;
      if (sigPad && sigPad.isEmpty()) {
        setSignature(false);
      } else if (isGuardianRequired) {
        setIsPatientLessThen18Error(true);
        setSignature(true);
      } else {
        setSignature(true);
        props.onFormSubmit(values);
      }
    }
  });

  const clearSig = () => {
    sigPad.clear();
    //console.log("on click:", this.state.trimmedDataURL);
  };

  const trim = () => {
    formik.values.signatureImg = sigPad
      .getTrimmedCanvas()
      .toDataURL("image/png");
  };

  const checkPatientAge = useCallback(() => {
    const years = moment().diff(
      moment(props.selectedUserForConsult.dateOfBirth, "DD-MM-YYYY"),
      "years",
      true
    );
    return years < 18;
  });

  const guardingOptionalCheckbox = () => {
    let isLessThen18 = checkPatientAge();
    if (isLessThen18 && formik.values.cbOptional !== true) {
      setIsPatientLessThen18Error(true);
    } else {
      setIsPatientLessThen18Error(false);
    }
  };

  useEffect(() => {
    logFirebaseEvent("Medicare Signature Consent Page");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.selectedUserForConsult) {
      let isLessThen18 = checkPatientAge();
      if (isLessThen18) {
        setIsPatientIsLessThen18(true);
      }
    }
  }, [checkPatientAge, props.selectedUserForConsult]);

  useEffect(() => {
    if (formik.touched.cbOptional) {
      guardingOptionalCheckbox();
    }
  }, [
    formik.touched.cbOptional,
    formik.values.cbOptional,
    guardingOptionalCheckbox
  ]);

  return (
    <div
      className="container
        h-100 bg-white pb-5 mb-5"
    >
      <div className="row">
        <div className="col-sm-12 mt-4 pb-3">
          <Label title="Consent" className="big" />
        </div>
        <div className="border-bottom w-100" />
        <div className="col-sm-1 mt-4">
          <img
            src={MedicareLogo}
            alt="medicare-logo"
            className="medicare-logo-size"
          />
        </div>
        <div className="col-sm-12 mt-4">
          <Anchor
            title="Click here to read the Privacy Notice"
            className="primary-color text-decoration-none font-14 w-100"
            href={APP_CONSTANTS.privacyNoticeURL}
            target="_blank"
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="col-sm-12 row">
          <div className="col-sm-12 mt-sm-3">
            <Label
              title="According to the information provided, you are eligible for a bulk-billed consult"
              className="font-14"
            />
          </div>
          <div className="col-sm-12 mt-sm-1">
            <Label title="Claimant Declaration" className="font-14 bold-font" />
          </div>
          <div className="col-sm-12 mt-sm-2 float-left">
            <div className="position-relative d-flex flex-row align-items-start consent-page-checkbox-container claimant-declaration-cb">
              <ICCheckbox
                id="claimatDeclarationCB"
                name="claimatDeclarationCB"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.claimatDeclarationCB}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
              <div>
                <p className="insta-label font-14">
                  I hereby claim benefits for the professional service to which
                  this claim relates and I understand that it is an offence
                  under the
                  <span className="font-italic">
                    &nbsp;Health Insurance Act 1973
                  </span>
                  &nbsp; and the{" "}
                  <span className="font-italic">
                    Dental Benefits Act 2008 &nbsp;
                  </span>
                  to make a false statement relating to Medicare benefits and am
                  liable to pay the expenses for these services if the
                  information I provide is incorrect
                </p>
              </div>
            </div>
            <div className="col-sm-12 p-0 m-0">
              {formik.errors.claimatDeclarationCB &&
              formik.touched.claimatDeclarationCB ? (
                <Label title="Please accept" className="small error p-0 m-0" />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="clearfix" />
          <div className="col-sm-12 float-left">
            <div className="position-relative d-flex flex-row align-items-center consent-page-checkbox-container">
              <ICCheckbox
                id="cb"
                name="cb"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cb}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
              <Label
                title="I assign my right to benefits to the Practitioner who rendered the service(s)"
                className="font-14"
              />
              {formik.errors.cb && formik.touched.cb ? (
                <Label
                  title="Please accept"
                  className="small cb-accept-err error"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="clearfix" />
          <div className="col-sm-4 mt-3 ml-3 m-0 border border-dark">
            <div className="d-flex flex-row align-items-center sign-canvas-cross-container">
              <i
                onClick={e => {
                  clearSig();
                }}
                className="fas fa-times"
              ></i>
              <Label className="font-12 sign-here-label" title="sign here" />
            </div>

            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 350,
                height: 100,
                className: "sig-canvas"
              }}
              ref={ref => {
                sigPad = ref;
              }}
            />
            {!signature ? (
              <Label
                title="Please enter your signature"
                className="small error sig-err-pos"
              />
            ) : (
              ""
            )}
            <Label
              className="font-12 date-label-consent"
              title={getCurrDateInMomentBrisbane()}
            />
          </div>
          <div className="clearfix" />
          <div className="col-sm-12">
            <Label
              className="font-14 mb-0"
              title={
                props.selectedUserForConsult.firstName +
                " " +
                props.selectedUserForConsult.lastName
              }
            />
          </div>

          {/* If selected patient age is less then 18 then only show the tickbox and it will be required */}
          {isPatientIsLessThen18 && (
            <div className="col-sm-12 mt-sm-3 float-left">
              <div className="position-relative d-flex flex-row align-items-center consent-page-checkbox-container">
                <ICCheckbox
                  id="cbOptional"
                  name="cbOptional"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cbOptional}
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <Label
                  title={
                    "I am the guardian and signing on behalf of " +
                    props.selectedUserForConsult.firstName +
                    " " +
                    props.selectedUserForConsult.lastName +
                    " (optional)"
                  }
                  className="font-14"
                />
              </div>
              {isPatientLessThen18Error ? (
                <Label
                  title="Please accept"
                  className="small error cb-accept-err"
                />
              ) : (
                ""
              )}
            </div>
          )}
          <div className="col-sm-12 float-left">
            <div className="position-relative d-flex flex-row align-items-center consent-page-checkbox-container">
              <ICCheckbox
                id="cbConcession"
                name="cbConcession"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cbConcession}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
              <Label
                title={"I am a concession card holder (optional)"}
                className="font-14"
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="mt-3 ml-3">
            <Button title="Submit" type="submit" />
            {/* <NavLink
              to={appRoutes.myProfile}
              className="text-decoration-none insta-link-secondary"
              href="#"
            ></NavLink> */}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ConsentPageForm);
