import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CustomModalTwoLineBtn = props => (
  <Modal
    isOpen={props.openModal}
    toggle={props.cancelEvent}
    backdrop={props.backdrop || true}
    className={"custom-modal-container " + props.className}
  >
    <ModalHeader toggle={props.cancelEvent}>{props.title}</ModalHeader>
    <ModalBody>
      <div dangerouslySetInnerHTML={{ __html: props.modalBodyContent }}></div>
    </ModalBody>
    <ModalFooter>
      {!props.useOneButton && (
        <React.Fragment>
          <button className="insta-button-primary h-auto pt-2 pb-2" onClick={props.okayEvent}>
            <span>{props.okayTextLine1}</span>
                <div className="border-bottom border-div mt-1 mb-1"></div>
            <span>{props.okayTextLine2}</span>
          </button>
          &nbsp;&nbsp;
        </React.Fragment>
      )}
      <button className="insta-button-primary h-auto pt-2 pb-2" onClick={props.cancelEvent}>
        <span>{props.cancelTextLine1}</span>
                <div className="border-bottom border-div mt-1 mb-1"></div>
            <span>{props.cancelTextLine2}</span>
      </button>
    </ModalFooter>
  </Modal>
);

export default CustomModalTwoLineBtn;

CustomModalTwoLineBtn.defaultProps = {
  okayText: "OKAY",
  cancelText: "CANCEL",
  modalBodyContent: "",
  openModal: false,
  className: "",
  useOneButton: false
};

CustomModalTwoLineBtn.propTypes = {
  okayText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.string,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  className: PropTypes.string,
  useOneButton: PropTypes.bool
};
