import React, { Component } from "react";
import Label from "../../components/label";
import ChangePasswordForm from "../../containers/ChangePasswordForm";
import { changePasswordApiRoute } from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import {logFirebaseEvent} from "../../utils/common";
// import APP_CONSTANTS from "../../constants/index";
import appRoutes from "../../routes/appRoutes";

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: ""
    };
  }
  onChangePasswordform = formValues => {
    var formData = new FormData();
    formData.append("oldPassword", formValues.oldPassword);
    formData.append("newPassword", formValues.newPassword);
    let apiCallParam = {
      reqType: "PUT",
      reqURL: changePasswordApiRoute,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: res => {
        //console.log(res);
        // this.props.history.push(appRoutes.requestAConsult);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: true,
      redirect: appRoutes.requestAConsult
    };
    this.setState({
      apiCall: apiCallParam
    });
  };

  componentDidMount() {
    logFirebaseEvent("Change Password Page");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="row rounded p-3 bank-edit-page">
        <div className="col-sm-12 p-3">
          <Label title="Change Password" className="big" />
        </div>
        <div className="w-100 border"></div>
        <div className="col-sm-12 mt-4">
          <ChangePasswordForm onFormSubmit={this.onChangePasswordform} />
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
      </div>
    );
  }
}

export default ChangePasswordPage;
