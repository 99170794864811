import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Error from "../../components/error";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import SuccessMsg from "../../components/successMsg";

import Label from "../../components/label";

const ChangePasswordForm = props => {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase")
        .required("Please enter the password"),
      newPassword: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase")
        .required("Please enter the password"),
      confirmPassword: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .required("Please enter the confirm password")
        .oneOf([Yup.ref("newPassword")], "Both Passwords should match")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
      formik.values.oldPassword = "";
      formik.values.newPassword = "";
      formik.values.confirmPassword = "";
    }
  });
  return (
    <form className="row" onSubmit={formik.handleSubmit}>
      <div className="col-sm-6 offset-sm-3 mt-1">
        <Label
          title="Old Password"
          className="gray text-left w-100 mt-2 small"
          required={true}
        />
        <ICInput
          id="oldPassword"
          name="oldPassword"
          type="password"
          label=""
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.oldPassword}
          error={
            formik.touched.oldPassword && formik.errors.oldPassword
              ? true
              : false
          }
          helperText={
            formik.touched.oldPassword && formik.errors.oldPassword
              ? formik.errors.oldPassword
              : ""
          }
        />
      </div>

      <div className="col-sm-6 offset-sm-3 mt-3">
        <Label
          title="New Password"
          className="gray text-left w-100 mt-2 small"
          required={true}
        />
        <ICInput
          id="newPassword"
          name="newPassword"
          type="password"
          label=""
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          error={
            formik.touched.newPassword && formik.errors.newPassword
              ? true
              : false
          }
          helperText={
            formik.touched.newPassword && formik.errors.newPassword
              ? formik.errors.newPassword
              : ""
          }
        />
      </div>

      <div className="col-sm-6 offset-sm-3 mt-3">
        <Label
          title="Confirm Password"
          className="gray text-left w-100 mt-2 small"
          required={true}
        />
        <ICInput
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label=""
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          error={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? true
              : false
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? formik.errors.confirmPassword
              : ""
          }
        />
      </div>

      <div className="col-sm-12 text-center mt-3">
        <Button className="mt-2" title="Submit" type="submit" />
      </div>

      <Error>{props.serverErrorMsg}</Error>
      <SuccessMsg>{props.successMsg}</SuccessMsg>
    </form>
  );
};

export default ChangePasswordForm;

ChangePasswordForm.defaultProps = {
  serverErrorMsg: "",
  successMsg: ""
};

ChangePasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
  successMsg: PropTypes.string
};
