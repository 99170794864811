// import socketIOClient from 'socket.io-client';
import io from "socket.io-client";


import APP_Constant from "../constants";

// const socket = io({ path: '/bridge' });
// const socket = socketIOClient(, {
//     agent: false,
//     origins: '*:*',
//     secure: true,
//     rememberUpgrade: true,
//     transports: ['websocket', 'pooling'],
//     rejectUnauthorized: false,
//     upgrade: true,
//   });

const socket = io(APP_Constant.apiUrl, {
  transports: ["websocket", "polling"], // use WebSocket first, if available,
  agent: false,
  origins: '*:*',
  secure: true,
  rememberUpgrade: true,
  rejectUnauthorized: false,
  upgrade: true,
});

// socket.on("connect", function () {
//   console.log("patient connect via main socket");

//   console.log("Emit event from patient done");
//   socket.emit('join', "PATIENTS");
// });

export default socket;
