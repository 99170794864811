import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Label from "../../components/label";
import Button from "../../components/button";
import ICInput from "../../components/form/input";

const ContactUsFormForPrescription = props => {

    const formik = useFormik({
        initialValues: {
            pharmacyName: "",
            pharmacyAddress: ""
        },
        validationSchema: Yup.object({
            pharmacyName: Yup.string().trim().required("Please enter the pharmacy name"),
            pharmacyAddress: Yup.string().trim().required("Please enter the pharmacy address")
        }),
        onSubmit: values => {
            props.onFormSubmit(values);
        }
    });


    return (
        <form
            className="row p-0 m-0"
            onSubmit={formik.handleSubmit}
            id="ContactusFormForPrescription"
        >

            <div className="col-sm-12 mt-2">
                <Label
                    title="Pharmacy Name"
                    className="font-15 gray"
                    required={true}
                />
                <ICInput
                    id="pharmacyName"
                    name="pharmacyName"
                    type="text"
                    label=""
                    placeholder="Pharmacy Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pharmacyName}
                    error={
                        formik.touched.pharmacyName && formik.errors.pharmacyName
                            ? true
                            : false
                    }
                    helperText={
                        formik.touched.pharmacyName && formik.errors.pharmacyName
                            ? formik.errors.pharmacyName
                            : ""
                    }
                />
            </div>

            <div className="col-sm-12 mt-3">
                <Label
                    title="Physical Address"
                    className="font-15 gray"
                    required={true}
                />
                <ICInput
                    id="pharmacyAddress"
                    name="pharmacyAddress"
                    type="text"
                    label=""
                    placeholder="Pharmacy Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pharmacyAddress}
                    error={
                        formik.touched.pharmacyAddress && formik.errors.pharmacyAddress
                            ? true
                            : false
                    }
                    helperText={
                        formik.touched.pharmacyAddress && formik.errors.pharmacyAddress
                            ? formik.errors.pharmacyAddress
                            : ""
                    }
                />
            </div>

            <div className="col-sm-12 mt-4 text-center">
                <Button title="Submit" type="submit" />
            </div>
        </form>
    );
};


export default ContactUsFormForPrescription;
