import React from "react";
import PropTypes from "prop-types";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

const SearchInput = props => (
  <InputGroup className={"insta-search-input " + props.className}>
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <i className="fas fa-search"></i>
      </InputGroupText>
    </InputGroupAddon>
    <Input {...props} />
  </InputGroup>
);

export default SearchInput;

SearchInput.defaultProps = {
  className: ""
};

SearchInput.propTypes = {
  className: PropTypes.string
};
