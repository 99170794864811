export default function detectAudioVideoStatus(callback) {
    detectWebcam(function (hasWebcam) {        
        if (hasWebcam) {          
          checkAudioVideoPermission(callback);
        } else {
          callback([false, null]);
        }
    });
  }
  
  function detectWebcam(callback) {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then(devices => {
      callback(devices.some(device => 'videoinput' === device.kind));
    })
  }

  function checkAudioVideoPermission(callback) {
    //var promise = navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        /* use the stream */
        setTimeout(function () {
          stream.getTracks().forEach(track => {
            track.stop();   
            callback([null, true]);
          });
        }, 3000);
      })
      .catch(function (err) {        
        // setAudioVideoStatusModelOpen(true);    
        callback([null, false])    ;
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(stream => {
            // Code for success
            checkAudioVideoPermission();
          }).catch(err => {
            console.log("Not supported");            
          })
      });
  }
