import React from "react";
import TextField from "@material-ui/core/TextField";

const ICInput = props => (
  <TextField
    inputProps={{
      min: props.min,
      max: props.max
    }}
    InputLabelProps={{
      shrink: true
    }}
    {...props}
    autoComplete="new-password"
    className={"ic-input " + props.className}
  />
);

export default ICInput;
