import React, {Component} from "react";
import Label from "../../components/label";
import DefaultImage from "../../assets/images/default-user-img.png";
import Modal from "../../components/modal";
import {blockDoctorApiRoute, blockedDoctorsListApiRoute} from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import Anchor from "../../components/anchor"

class BlockedDoctors extends Component {
  constructor(props) {
    super(props);
    this.state =
        {
          unblockDoctorPopUp: false,
          apiCall: "",
          blockedDoctorsList: [],
          rowIndex: null,
          rowData: null
        };
  }

  unblockDoctorPopUp = (data, index) => {
    this.setState({
      rowData: data,
      rowIndex: index
    });
    const cValue = this.state.unblockDoctorPopUp;
    this.setState({
      unblockDoctorPopUp: !cValue
    });
  };

  closeUnblockDoctorPopUp = () => {
    this.setState({
      unblockDoctorPopUp: false
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBlockedDoctorsList()
  }

  getBlockedDoctorsList() {
    let apiCallObj = {
      reqType: "GET",
      reqURL: blockedDoctorsListApiRoute,
      dataObj: {},
      params: {limit: 10, skip: 0},
      headers: {},
      successCallback: res => {
        this.setState({
          blockedDoctorsList: res.data.data
        });
      },
      failureCallback: err => {
        console.log(err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallObj,
    });
  }

  handleBlockDoctor = () => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: blockDoctorApiRoute,
      dataObj: {},
      params: {doctorId:this.state.rowData, isBlocked: false},
      headers: {},
      successCallback: res => {
        let tmpList = [...this.state.blockedDoctorsList];
        tmpList.splice(this.state.rowIndex, 1);
        this.setState({
          blockedDoctorsList: tmpList,
          unblockDoctorPopUp: false
        })
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallParam,
      unblockDoctorPopUp: false
    })
  };

  render() {
    return (
      <div
        className="container
        h-100 bg-white add-attachment-model font-12 pb-5 mb-5"
      >
        <div className="row">
          <div className="col-sm-12 mt-4 pb-3 ml-3">
            <Label title="Blocked Doctors" className="big" />
          </div>
          <div className="border-bottom w-100" />
          {this.state.blockedDoctorsList.length < 1 ?
              <div className="offset-sm-5 mt-5">
                <Label title="No Data Found" className="offset-sm-5"/>
              </div>
              :
              this.state.blockedDoctorsList.map((data, index) => {
                  const title = "Dr "+data.doctorId.firstName + " " + data.doctorId.lastName;
                  return(
                      <div className="col-sm-5 p-0 mt-2 ml-2" key={index}>
                        <div className="card m-4">
                          <div className="card-body p-0">
                            <div className="col-sm-12 circle-checkbox-margin">
                              <div className="row p-0">
                                <div className="col-sm-2 ml-3 mt-4 p-0">
                                  <img
                                      src={data.doctorId.profilePic.original}
                                      alt={DefaultImage}
                                      className="img-thumbnail blocked-doctor-img rounded-circle border-0 p-0 "
                                  />
                                </div>
                                <div className="col-sm-8 ml-3 dr-text-style p-0 mt-4">
                                  <Label
                                      title={title}
                                      className="secondary-font gray"
                                  />
                                  <div className="mt-2 pb-4">
                                    {[...Array(parseInt(data.doctorId.avgRating))].map((e, i) => <i className="fa fa-star dr-start-rating" key={i} />)}
                                    {[...Array(5-(parseInt(data.doctorId.avgRating)))].map((e, i) => <i className="fa fa-star dr-no-rating" key={i} />)}
                                      {/*<InstaButton*/}
                                      {/*  title="Unblock"*/}
                                      {/*  clickEvent={this.unblockDoctorPopUp.bind(this, data.doctorId._id, index)}*/}
                                      {/*  className="link insta-link-default cursor-pointer primary-color float-right font-14 p-0 text-right"*/}
                                      {/*/>*/}
                                      <div className="p-0" onClick={this.unblockDoctorPopUp.bind(this, data.doctorId._id, index)}>
                                        <Anchor title="Unblock" 
                                        className="float-right font-14 cursor-pointer insta-link-orange"
                                        />
                                      </div>
                                  </div>
                                </div>
                                {/*<div className="col-sm-12">*/}
                                {/*  <InstaButton*/}
                                {/*    title="Unblock"*/}
                                {/*    clickEvent={this.unblockDoctorPopUp.bind(this, data.doctorId._id, index)}*/}
                                {/*    className="link insta-link-default cursor-pointer primary-color float-right font-14 p-0 text-right"*/}
                                {/*  />*/}
                                {/*</div>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  );
                })
          }
        </div>
        <Modal
          modalBodyContent="Do you want to unblock this Practitioner?"
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={this.handleBlockDoctor}
          cancelEvent={this.closeUnblockDoctorPopUp}
          openModal={this.state.unblockDoctorPopUp}
        />
        <MakeAPICall apiParam={this.state.apiCall}/>
      </div>
    );
  }
}

export default BlockedDoctors;
