/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { Component } from "react";
import ICButton from "../../components/button";
import ICIconBtn from "../../components/iconButton";
import ICInput from "../../components/form/input";
import APP_CONSTANTS from "../../constants";
import { connect } from "react-redux";
import socket from "../../utils/socket";

//connecting to our signaling server
// const conn = new WebSocket('ws://10.0.0.62:9090');
const conn = socket;
let connectedUser;
var yourConn;
var stream;

class CallRequestReceive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localVideo: null,
      remoteVideo: null,
      patientData: props.auth ? props.auth.user[0] : null,
      videoAllow: true,
      audioAllow: true
    };
  }

  componentDidMount() {
    console.log("Got the user data : ", this.props.auth.user[0]);

    // connected to server now
    // conn.onopen = function () {
    //     console.log("Connected to the signaling server");
    // };

    // conn.onmessage = (msg) => {
    //     this.handleAllMsg(msg);
    // };
    conn.emit("Now connected from doctor");

    // conn.on("message",  (msg) => {
    //     this.handleAllMsg(msg);
    // });

    conn.onerror = function(err) {
      console.log("Got error", err);
    };

    if (this.props.auth && this.props.auth.user[0])
      this.loginBtn(this.props.auth.user[0]._id);
  }

  handleAllMsg = msg => {
    // console.log("Got message", msg);

    // let data = ''
    // try {
    //     data = JSON.parse(msg.data);
    // } catch (err) {
    //     console.log("Data not converted");
    // }

    let data = "";
    try {
      data = JSON.parse(msg);
    } catch (err) {
      console.log("Data not converted");
    }

    console.log("Data got to handle : ", data);

    switch (data.type) {
      case "login":
        console.log("Login : ", data.success);
        this.handleLogin(data.success);
        break;
      //when somebody wants to call us
      case "offer":
        console.log("Offer : ", data.offer, data.name);
        this.handleOffer(data.offer, data.name);
        break;
      case "answer":
        console.log("Anser : ", data.answer);
        this.handleAnswer(data.answer);
        break;
      //when a remote peer sends an ice candidate to us
      case "candidate":
        console.log("HandleCandidate: ", data.candidate);
        this.handleCandidate(data.candidate);
        break;
      case "leave":
        console.log("Handle leave");
        this.handleLeave();
        break;
      default:
        break;
    }
  };

  handleLogin = success => {
    if (success === false) {
      alert("Ooops...try a different username");
    } else {
      // loginPage.style.display = "none";
      // callPage.style.display = "block";

      //**********************
      //Starting a peer connection
      //**********************

      //getting local video stream
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      navigator.getUserMedia(
        { video: this.state.videoAllow, audio: this.state.audioAllow },
        function(myStream) {
          stream = myStream;

          //displaying local video stream on the page
          var video = document.querySelector("#localVideo");

          video.srcObject = new MediaStream(stream);
          video.onloadedmetadata = function(e) {
            video.play();
          };

          //using Google public stun server
          var configuration = APP_CONSTANTS.turnServerInfo;

          yourConn = new RTCPeerConnection(configuration);

          // setup stream listening
          yourConn.addStream(stream);

          //when a remote user adds stream to the peer connection, we display it
          yourConn.onaddstream = function(e) {
            // remoteVideo.src = window.URL.createObjectURL(e.stream);
            var video = document.querySelector("#remoteVideo");

            video.srcObject = new MediaStream(e.stream);
            video.onloadedmetadata = function(e) {
              video.play();
            };
          };

          // Setup ice handling
          yourConn.onicecandidate = function(event) {
            if (event.candidate) {
              send({
                type: "candidate",
                candidate: event.candidate
              });
            }
          };
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  startVideoAgain = () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    navigator.getUserMedia(
      { video: this.state.videoAllow, audio: this.state.audioAllow },
      function(myStream) {
        stream = myStream;

        //displaying local video stream on the page
        var video = document.querySelector("#localVideo");

        video.srcObject = new MediaStream(stream);
        video.onloadedmetadata = function(e) {
          video.play();
        };

        //using Google public stun server
        var configuration = APP_CONSTANTS.turnServerInfo;

        yourConn = new RTCPeerConnection(configuration);

        // setup stream listening
        yourConn.addStream(stream);

        //when a remote user adds stream to the peer connection, we display it
        yourConn.onaddstream = function(e) {
          // remoteVideo.src = window.URL.createObjectURL(e.stream);
          var video = document.querySelector("#remoteVideo");

          video.srcObject = new MediaStream(e.stream);
          video.onloadedmetadata = function(e) {
            video.play();
          };
        };

        // Setup ice handling
        yourConn.onicecandidate = function(event) {
          if (event.candidate) {
            send({
              type: "candidate",
              candidate: event.candidate
            });
          }
        };
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleCallEvent = () => {
    console.log("Now calling to patient");
    var callToUsername = this.state.patientData._id;

    if (callToUsername.length > 0) {
      connectedUser = callToUsername;

      // create an offer
      yourConn.createOffer(
        function(offer) {
          send({
            type: "offer",
            offer: offer
          });

          yourConn.setLocalDescription(offer);
        },
        function(error) {
          alert("Error when creating an offer : ", error);
        }
      );
    }
  };

  handleOffer = (offer, name) => {
    console.log("Now handeling offer");
    connectedUser = name;
    console.log("ConnectedUser : ", connectedUser);

    console.log("Checking connection : ", yourConn);
    if (yourConn) {
      yourConn.setRemoteDescription(new RTCSessionDescription(offer));

      //create an answer to an offer
      yourConn.createAnswer(
        function(answer) {
          console.log("Now creating an answer : ", answer);
          yourConn.setLocalDescription(answer);

          send({
            type: "answer",
            answer: answer
          });
        },
        function(error) {
          alert("Error when creating an answer");
        }
      );
    }
  };

  //when we got an answer from a remote user
  handleAnswer = answer => {
    console.log("Now answering a call here in patient");
    yourConn.setRemoteDescription(new RTCSessionDescription(answer));
  };

  //when we got an ice candidate from a remote user
  handleCandidate = candidate => {
    console.log("Handle candidate in a patient");
    yourConn.addIceCandidate(new RTCIceCandidate(candidate));
    console.log("Starting peer video now");
  };

  hangUpCall = () => {
    console.log("Now hanging up call");
    send({
      type: "leave"
    });

    this.handleLeave();
  };

  handleLeave = () => {
    console.log("Now hanging up call");
    stream.getTracks().forEach(track => {
      console.log("Stopping video now");
      track.stop();
    });
    connectedUser = null;
    // remoteVideo.src = null;
    let remoteVideoEle = document.querySelector("#remoteVideo");
    remoteVideoEle.srcObject = null;

    let localVideoEle = document.querySelector("#localVideo");
    localVideoEle.srcObject = null;

    // yourConn.close();
    yourConn.onicecandidate = null;
    yourConn.onaddstream = null;
  };

  loginBtn = patientId => {
    // let name = this.state.patientData._id;
    let name = patientId;

    if (name.length > 0) {
      send({
        type: "login",
        name: name
      });
    }
  };

  // handle Microphone voice to mute and unmute
  handleMicrophone = status => {
    if (stream) {
      stream.getTracks().forEach(track => {
        console.log("Tracks are here : ", track.kind == "audio");
        if (track.kind == "audio") {
          track.enabled = status;
          this.setState({
            audioAllow: status
          });
        }
      });
    } else {
      console.log("Video not found");
    }
  };

  // handle Microphone video to display and hide
  handleVideo = status => {
    if (stream) {
      stream.getTracks().forEach(track => {
        console.log("Tracks are here : ", track.kind == "audio");
        if (track.kind == "video") {
          track.enabled = status;
          this.setState({
            videoAllow: status
          });
        }
      });
    } else {
      console.log("Video not found");
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 p-5">
          <ICButton title="Call" clickEvent={this.handleCallEvent} />
          <ICButton title="Call Again" clickEvent={this.startVideoAgain} />
          <ICButton
            title="Hang Up"
            className="ml-3 red"
            clickEvent={this.hangUpCall}
          />
          <ICButton
            title="Login"
            className="ml-3 red"
            clickEvent={this.loginBtn}
          />
        </div>
        <div className="col-sm-12 p-5">
          <ICIconBtn
            icon="fas fa-microphone"
            className={!this.state.audioAllow ? "ml-2 small" : "d-none"}
            title=""
            clickEvent={() => this.handleMicrophone(true)}
          />
          <ICIconBtn
            icon="fas fa-microphone-slash"
            className={this.state.audioAllow ? "ml-2 small red" : "d-none"}
            title=""
            clickEvent={() => this.handleMicrophone(false)}
          />
          <ICIconBtn
            icon="fas fa-video"
            className={!this.state.videoAllow ? "ml-2 small" : "d-none"}
            title=""
            clickEvent={() => this.handleVideo(true)}
          />
          <ICIconBtn
            icon="fas fa-video-slash"
            className={this.state.videoAllow ? "ml-2 small red" : "d-none"}
            title=""
            clickEvent={() => this.handleVideo(false)}
          />
          {/* <i class="fas fa-microphone"></i> */}
          {/* <i class="fas fa-microphone-slash"></i> */}
          {/* <i class="fas fa-video"></i> */}
          {/* <i class="fas fa-video-slash"></i> */}
        </div>
        <div className="col-sm-3 p-3">
          {/* <video id="localVideo" autoPlay={true} src={this.state.localVideo} className="w-100"></video> */}
          <video
            autoPlay={true}
            id="localVideo"
            controls
            className="w-100"
          ></video>
        </div>
        <div className="col-sm-6 offset-sm-1 p-3">
          <video
            autoPlay={true}
            id="remoteVideo"
            controls
            className="w-100"
          ></video>
        </div>
      </div>
    );
  }
}

// export default CallRequestReceive;

function send(message) {
  //attach the other peer username to our messages
  if (connectedUser) {
    message.name = connectedUser;
  }

  console.log("GOt the connection here: ", conn);

  conn.send(JSON.stringify(message));
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CallRequestReceive);
