import React, {useRef, useCallback, useState, useEffect} from "react";
import { CircularProgress } from "@material-ui/core";
import SmallLogo from "../../assets/images/logo-small-white.png";
import Label from "../../components/label";
import moment from "moment";
import appRoutes from "../../routes/appRoutes";
import {notificationApiRoute} from "../../apiRoutes";

export default function StickyHeadTable(props) {
  const {history} = props;
  const observer = useRef();
  const [pageNo, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [limit] = useState(10);
  const [skipPrevData, setSkipPrevData] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Data to be appended
  const [rows, setRows] = useState([]);
  const [appendRows, setAppendRows] = useState([]);

  const lastElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(data => {
      if (data){
        if (data[0].isIntersecting && nextPage && loading) {
          setProgressBar(true);
          setLoading(false);
          if(loading){
            setPageNumber(pageNo => pageNo + 1);
            let skipDataCalculation = limit * pageNo;
            setSkipPrevData(skipDataCalculation);
            getNotifications(limit, skipDataCalculation);
            setRows(rows.concat(appendRows));
          }
          setTimeout(()=> {
            setLoading(true);
          }, 2000);
          setTimeout(()=> {
            setProgressBar(false);
          }, 1000)
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  const getNotifications = (limitPage, skipPage) => {
    let apiCallParam = {
      reqType: "GET",
      reqURL: notificationApiRoute,
      dataObj: {},
      params: {
        limit: limitPage,
        skip: skipPage
      },
      headers: {},
      successCallback: res => {
        if(res.data.data.totalCount > 0)
          props.setClearButton(true);
        else
          props.setClearButton(false);
        if (skipPage === 0) {
          setRows(res.data.data.notifications);
        } else {
          setAppendRows(res.data.data.notifications);
        }
        if (!res.data.data.notifications.length) {
          setDataLoaded(true);
          setNextPage(false);
        }
        let currentRowCount = res.data.data.totalCount - pageNo * limit;
        currentRowCount > 0 ? setNextPage(true) : setNextPage(false);
        props.updateNotificationNumber(res.data.data.unReadCount || 0);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    props.makeApiCall(apiCallParam);
  };

  useEffect(()=> {
    getNotifications(limit, skipPrevData);
  },[props.clear]);

  const onClickNotification = (notificationMsgString) => {
    console.log(notificationMsgString)
    if(notificationMsgString.includes('Inbox')){
      history.push(appRoutes.inbox)
    }
  };

  return (
      <ul className="list-group cursor-pointer list-group-flush border">
      {rows.map((data, index) => {
          var img="";
          if ((data.flag !== "18" && data.flag !== "24" && data.doctorId) || (data.flag == "CONSULT_NOTE" && data.doctorId))
          {
            //title = "Dr "+data.doctorId.firstName + " " + data.doctorId.lastName;
            img = data.doctorId.profilePic.original;
          }
          else
          {
            //title = "From Instant Consult";
            img = SmallLogo;
          }
          if (rows.length === index + 1) {
            return (
                <li className="list-group-item" onClick={() => onClickNotification(data.notificationMsg)} ref={lastElementRef} key={index}>
                    <div className="notification-icon-div mt-1 float-left">
                      <img
                          src={img}
                          alt="Small-Logo-Notificaiton"
                          className="notification-icon rounded-circle"
                      />
                    </div>
                    <div className="info-div">
                      <Label
                          className="font-15 secondary-font cursor-pointer gray w-100 p-0 m-0"
                          title={data.notificationMsg}
                      />
                      <Label className="font-15 gray cursor-pointer p-0 m-0" title={moment(data.notificationDate).format("D MMM YYYY")} />
                    </div>
                </li>
            );
          } else {
            return (
                <li className="list-group-item" onClick={() => onClickNotification(data.notificationMsg)} key={index}>
                    <div className="notification-icon-div mt-1 float-left">
                      <img
                          src={img}
                          alt="Small-Logo-Notificaiton"
                          className="notification-icon rounded-circle"
                      />
                    </div>
                    <div className="info-div">
                      <Label
                          className="font-15 cursor-pointer secondary-font gray w-100 p-0 m-0"
                          title={data.notificationMsg}
                      />
                      <Label className="font-15 cursor-pointer gray p-0 m-0" title={moment(data.notificationDate).format("D MMM YYYY")} />
                    </div>
                </li>
            );
          }
        })}
        <li className={progressBar ? "list-group-item text-center p-4 list-border-none" : 'd-none'}>
          <CircularProgress disableShrink className="primary-loader" />
        </li>
        <li className={dataLoaded ? "list-group-item text-center list-border-none p-4" : 'd-none'}>
          <Label title="No data found" className="w-100 text-center" />
        </li>
      </ul>
  );
}
