import React from "react";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

const ICPhoneInput = props => (
  <InputMask mask="9999 999 999" {...props} maskChar={null}>
    {inputProps => (
      <TextField {...inputProps} type="tel" className="ic-input" />
    )}
  </InputMask>
);

export default ICPhoneInput;
