import React from "react";
import InstaLabel from "../../components/label";
import PropTypes from "prop-types";

const Footer = props => (
  <div className="container-fluid footer">
    <div className="row">
      <div className="col-sm-12 pt-2 pb-2 bg-white border-top">
        <InstaLabel title="Instant Consult" className="ex-small gray" />
        <InstaLabel title="&trade;" className="gray ml-1 ex-small" />
        <InstaLabel title="2018-2023" className="ex-small gray ml-1" />
        <a
          href={props.privacyPolicyLink}
          target="_blank"
          rel="noopener noreferrer"
          className="pl-2 ex-small text-decoration-none font-12 insta-link-orange"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
);

export default Footer;

Footer.defaultProps = {
  privacyPolicyLink: "#"
};

Footer.prototype = {
  privacyPolicyLink: PropTypes.string
};
