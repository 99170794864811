import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import appRoutes from "../../routes/appRoutes";

import Button from "../../components/button";
import ICInput from "../../components/form/input";

import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import InputMask from "react-input-mask";

const EmergencyContactsForm = props => {
    const propsConst=props.userDetails;
    const formik = useFormik({
        initialValues: {
            en1: propsConst.kin1 ? propsConst.kin1.name:"",
            ecn1: propsConst.kin1 ? propsConst.kin1.contact:"",
            en2: propsConst.kin2  ? propsConst.kin2.name:"",
            ecn2: propsConst.kin2 ? propsConst.kin2.contact:""
        },
        validationSchema: Yup.object({
            ecn1: Yup.string()
              .matches(/([0-9]{4}\s[0-9]{3}\s[0-9]{3})/, 'Please enter correct Mobile Number'),
            ecn2: Yup.string()
              .matches(/([0-9]{4}\s[0-9]{3}\s[0-9]{3})/, 'Please enter correct Mobile Number')
        }),
        onSubmit: values => {
            props.onFormSubmit(values);
        }
    });
    
    return (
        <form
            onSubmit={formik.handleSubmit}
            className="w-100"
        >
            <div className="col-sm-12 mt-3 float-left">
                <Label title="Emergency Contact 1" className="font-15 gray" />
            </div>
            <div className="col-sm-6 m-0 float-left">
                <Label title="Name" className="small secondary-font gray" />
                <ICInput
                    id="en1"
                    name="en1"
                    type="text"
                    placeholder="Name"
                    label=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.en1}
                />
            </div>
            <div className="col-sm-6 m-0 float-left">
                <Label
                    title="Contact Number"
                    className="small secondary-font gray"
                />
                <InputMask
                    mask="0499 999 999"
                    value={formik.values.ecn1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.ecn1 && formik.errors.ecn1
                            ? true
                            : false
                    }
                    helperText={
                        formik.touched.ecn1 && formik.errors.ecn1
                            ? formik.errors.ecn1
                            : ""
                    }
                >
                    {inputProps => (
                        <ICInput
                            id="ecn1"
                            name="ecn1"
                            label=""
                            placeholder="Contact Number"
                            {...inputProps}
                        />
                    )}
                </InputMask>
            </div>
            <div className="clearfix" />
            <div className="col-sm-12 mt-3 float-left">
                <Label title="Emergency Contact 2" className="font-15 gray" />
            </div>
            <div className="col-sm-6 m-0 float-left">
                <Label title="Name" className="small secondary-font gray" />
                <ICInput
                    id="en2"
                    name="en2"
                    type="text"
                    label=""
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.en2}
                />
            </div>
            <div className="col-sm-6 m-0 float-left">
                <Label
                    title="Contact Number"
                    className="small secondary-font gray"
                />
                <InputMask
                    mask="0499 999 999"
                    value={formik.values.ecn2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.ecn2 && formik.errors.ecn2
                            ? true
                            : false
                    }
                    helperText={
                        formik.touched.ecn2 && formik.errors.ecn2
                            ? formik.errors.ecn2
                            : ""
                    }
                >
                    {inputProps => (
                        <ICInput
                            id="ecn2"
                            name="ecn2"
                            label=""
                            placeholder="Contact Number"
                            {...inputProps}
                        />
                    )}
                </InputMask>
            </div>
            <div className="clearfix" />
            <div className="col-sm-12 mt-5">
                <div className="text-center">
                    <Button
                        title="Save"
                        type="submit"
                        className="mr-2 insta-button-primary"
                    />
                    <NavLink
                        to={appRoutes.myProfile}
                        className="text-decoration-none insta-link-secondary"
                        href="#"
                    >
                        <Button
                            title="Cancel"
                            type="button"
                            className="ml-2 gray-outline"
                        />
                    </NavLink>
                </div>
            </div>
        </form>
    );
};

export default EmergencyContactsForm;
