import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ICSelect = props => {
  const [val, setVal] = React.useState(
      props.placeholder ? props.placeholder : ""
  );

  const handleChange = event => {
    setVal(event.target.value);
    props.handleChange(event.target.value);
  };

  const generateOptions = () => {
    let menuItems = [];
    props.items.map((val, key) => {
      return menuItems.push(
          <MenuItem key={key} value={val[props.useId]}>
            {val[props.useValue]}
          </MenuItem>
      );
    });
    return menuItems;
  };

  return (
      <FormControl
          className={"ic-select w-100 " + (props.className ? props.className : "")}
          id={props.id}
          name={props.name}
      >
        <InputLabel id={props.id}>{props.label}</InputLabel>
        <Select
            labelId={props.id}
            id={props.id}
            name={props.name}
            value={props.value}
            onChange={handleChange}
            onBlur={props.onBlur}
        >
          {props.placeholder ? (
              <MenuItem
                  className="select-default-value"
                  value={props.placeholder}
                  disabled
              >
                {props.placeholder}
              </MenuItem>
          ) : null}

          {props.items && props.items.length ? generateOptions() : ""}
        </Select>
      </FormControl>
  );
};

export default ICSelect;
