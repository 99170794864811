import APP_CONSTANTS from "../constants";

const apiRoute = APP_CONSTANTS.apiUrl + "/api/v4";

//Constants
export const constApiRoute = `${apiRoute}/constants`;

//Home Data
// export const homeApiRoute = `${apiRoute}/getHomePageData`; //! Not being used
export const homeApiRoute1 = `${apiRoute}/patient/getHomePageData`;
export const homePageDataApiRoute = `${apiRoute}/patient/getHomePageData`; //! Not being used

//Auth routes
export const loginApiRoute = `${apiRoute}/patient/login`;
export const login = `${apiRoute}/patient/login`;
export const forgotPasswordApiRoute = `${apiRoute}/patient/forgotPassword`;

//Retrieve State List
// export const stateListApiRoute = `${apiRoute}/getStateList`;

//Register
export const registerApiRoute = `${apiRoute}/patient/register`;

//Request a consult
export const checkBookingApiRoute = `${apiRoute}/patient/checkBooking`;
// export const makeABookingApiRoute = `${apiRoute}/patient/bookConsult`
export const getBookingApiRoute = `${apiRoute}/patient/bookings`;
export const reqAConsultApiRoute = `${apiRoute}/patient/requestConsult`;
export const getConsultsApiRoute = `${apiRoute}/patient/getConsults`;
export const cancelConsultRequestApiRoute = `${apiRoute}/patient/cancelConsultRequest`;
export const getBookingDetailsApiRoute = `${apiRoute}/patient/getBookingDetails`;
export const acceptCallApiRoute = `${apiRoute}/patient/call/callAccept`;
export const rejectCallApiRoute = `${apiRoute}/patient/call/callReject`;
export const rateDoctorApiRoute = `${apiRoute}/patient/booking/rateDoctor`;
export const cancelConsultRequestTimeOutApiRoute = `${apiRoute}/patient/cancelConsultRequestTimeOut`; // ! Not being used
export const connectCallApiRoute = `${apiRoute}/patient/call/connectCall`;

//Validate medicare number
export const validateMedicareApiRoute = `${apiRoute}/patient/verifyPatientMedicareForIC`;

//Notifications
export const notificationApiRoute = `${apiRoute}/patient/getNotifications`;
export const updateNotificationApiRoute = `${apiRoute}/patient/updateNotifications`;

//Inbox
export const inboxApiRoute = `${apiRoute}/patient/getMessagesPMS`;
export const inboxFavApiRoute = `${apiRoute}/patient/favouritePMS`;
export const inboxDelApiRoute = `${apiRoute}/patient/deletePMS`;

//Consult History
export const consultHistoryApiRoute = `${apiRoute}/patient/booking/getConsultHistoryWeb`;
export const consultHistoryDetailsApiRoute = `${apiRoute}/patient/getBookingDetails`;

//Consult Costs
export const consultCostsApiRoute = `${apiRoute}/patient/getConsultCost`;
export const getMedicinalCannabisConsultPriceAPI = `${apiRoute}/admin/getInstantSwitchConsultDetailsMedicinalCannabis`;
export const getInstantSwitchPriceAPI = `${apiRoute}/admin/getInstantSwitchConsultDetails`;

//Profile
export const getUserProfileApiRoute = `${apiRoute}/patient/accessTokenLogin`;
export const editProfileApiRoute = `${apiRoute}/patient/editProfile`;
export const editEmergencyContact = `${apiRoute}/patient/updateKins`;

//Change Password
export const changePasswordApiRoute = `${apiRoute}/patient/changePassword`;

//Update Password
export const updatePasswordApiRoute = `${apiRoute}/patient/updatePassword`;

//Block Doctors
export const blockedDoctorsListApiRoute = `${apiRoute}/patient/getBlockedDoctors`; // ! Not Using // TODO - Remain to develop
export const blockDoctorApiRoute = `${apiRoute}/patient/unBlockDoctor`; // ! Not using // TODO - Remain to develop

//Saved Cards
export const savedCardsApiRoute = `${apiRoute}/patient/card/getCreditCards`;
export const deleteCardApiRoute = `${apiRoute}/patient/card/deleteCreditCards`;
export const changeDefaultCard = `${apiRoute}/patient/card/changeDefaultCards`;

//Add Card
export const addNewCardApiRoute = `${apiRoute}/patient/card/addCreditCard`;

//Internet Connection Testing
export const internetConnectionFileUpload = `${apiRoute}/patient/newUploadImageForTestConnection`;

// End Call by Patient
export const endCallByPatient = `${apiRoute}/patient/endCallByPatient`; // not developed yet

export const checkIfMedicareCardDuplicateExistsApiRoute = `${apiRoute}/patient/checkIfMedicareCardDuplicateExists`;

// Coupons Route

export const getPatientCouponListApiRoute = `${apiRoute}/patient/getPatientCouponList`;
export const addPatientCouponApiRoute = `${apiRoute}/patient/addPatientCoupon`;

export const getCovidHotSpotsApiRoute = `${apiRoute}/patient/getCovidHotSpots`;

// HI API
export const publicRequestHIServiceSearchIHIApiRoute = `${apiRoute}/patient/ihi/check/withoutid`; // ! Not using it on front-end patient portal
export const requestHIServiceSearchIHIApiRoute = `${apiRoute}/patient/requestHIServiceSearchIHI`; // ! Not using it on front-end patient portal
export const checkDuplicatePatientDemographicInfoApiRoute = `${apiRoute}/patient/checkDuplicatePatientDemographicInfo`; // ! Not using it on front-end patient portal

// Register Dependent Account
export const registerDependentAccount = `${apiRoute}/patient/dependent/register`;
export const dependentProfileListAPI = `${apiRoute}/patient/dependent/getProfile`;
export const editDependentProfileAPI = `${apiRoute}/patient/dependent/editProfile`;
export const addExistingPatientAsDependentAPI = `${apiRoute}/patient/dependent/existing`;
export const confirmPersonalInfoUpToDatAPI = `${apiRoute}/patient/profile/uptodate`;

// Check patient had a consult using patient id
export const getPatientTotalConsultsAPI = `${apiRoute}/patient/checkPatientHadAConsultByPatientID`;

// select-pharmacy
export const searchPharmacyDetailApiRoute = `${apiRoute}/pms/searchPharmacyDetail`;
export const selectPharmacyAPI = `${apiRoute}/patient/selectPharmacy`;
export const getDrugDataFromPrescriptionAPI = `${apiRoute}/patient/getDrugDetailForPrescription`;
export const requestSupportToSelectPharmacyAPI = `${apiRoute}/patient/requestSupportToSelectPharmacy`;

// marketing
export const unsubscribeEmailAPI = `${apiRoute}/patient/marketing/unsubscribe`;
