export const phoneRegex = /^([+][0-9]{1,3}([ .-])?)?([(][0-9]{1,6}[)])?([0-9 .-]{1,32})(([A-Za-z :]{1,11})?[0-9]{1,4}?)$/;

export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])(?=.*[a-z])[A-Za-z\d$@$!%*#?&]{8,25}$/;

export const pinRegex = /[0-9]{4,4}$/;

// max 2 mb
export const MAX_FILE_SIZE = 1024 * 1024 * 2;

export const IMAGES_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg",
  "image/gif"
];

export const DOCS_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg",
  "application/pdf"
];

export const EXCEL_SHEET_FORMATS = ["csv", "xlsx", "xls"];

export const HIServiceType = {
  SEARCH_IHI: "searchIHI",
  SEARCH_FOR_PROVIDER_INDIVIDUAL: "searchForProviderIndividual"
};

export const STATE_LIST = [{"_id":"592682341c8804c0f68805e9","stateId":"ACT","stateName":"Australian Capital Territory"},{"_id":"58b675ca088ded0a4193954a","stateId":"NSW","stateName":"New South Wales"},{"_id":"58b675ca088ded0a4193954e","stateId":"NT","stateName":"Northern Territory"},{"_id":"58b675ca088ded0a41939549","stateId":"QLD","stateName":"Queensland"},{"_id":"58b675ca088ded0a4193954d","stateId":"SA","stateName":"South Australia"},{"_id":"58b675ca088ded0a4193954f","stateId":"TAS","stateName":"Tasmania"},{"_id":"58b675ca088ded0a4193954b","stateId":"VIC","stateName":"Victoria"},{"_id":"58b675ca088ded0a4193954c","stateId":"WA","stateName":"Western Australia"}];
