import {
  NOTIFICATION_NUMBER,
  ONESIGNAL_SUBSCRIPTION,
  APPOINTMENT_ID,
  BOOKING_STATUS,
  APPOINTMENT_DATA,
  VIDEO_CALL_STATUS,
  VIDEO_CALL_TIMER_VALUE,
  CALL_WAIT_TIME,
  WAIT_IF_CALL_END,
  IS_REQUEST_ACCEPTED,
  IS_CALL_MISSED,
  IS_DOCTOR_CALLING,
  CALL_SESSION_DATA,
  IS_DIRECT_CALL,
  TOTAL_PATIENT_CONSULTS,
  PATIENT_QUEUE_WAIT_TIME,
  SCHEDULED_BOOKING
} from "../constants/common";

export const notificationNumber = data => ({
  type: NOTIFICATION_NUMBER,
  payload: data
});

export const setTotalPatientConsultCount = data => ({
  type: TOTAL_PATIENT_CONSULTS,
  payload: data
});

export const bookingStatus = data => ({
  type: BOOKING_STATUS,
  payload: data
});

export const appointmentID = data => ({
  type: APPOINTMENT_ID,
  payload: data
});

export const oneSignalSubscription = data => ({
  type: ONESIGNAL_SUBSCRIPTION,
  payload: data
});

export const saveAppointmentData = data => ({
  type: APPOINTMENT_DATA,
  payload: data
});

export const saveVideoCallStatus = data => ({
  type: VIDEO_CALL_STATUS,
  payload: data
});

export const saveVideoCallTimerValue = data => ({
  type: VIDEO_CALL_TIMER_VALUE,
  payload: data
});

export const saveCallWaitTime = data => ({
  type: CALL_WAIT_TIME,
  payload: data
});

export const saveWaitIfCallEnd = data => ({
  type: WAIT_IF_CALL_END,
  payload: data
});

export const saveRequestAcceptedStatus = data => ({
  type: IS_REQUEST_ACCEPTED,
  payload: data
});

export const saveMissedCallStatus = data => ({
  type: IS_CALL_MISSED,
  payload: data
});

export const saveIsDoctorCalling = data => ({
  type: IS_DOCTOR_CALLING,
  payload: data
});

export const saveCallSessionData = data => ({
  type: CALL_SESSION_DATA,
  payload: data
});

export const saveIsDirectCallPMS = data => ({
  type: IS_DIRECT_CALL,
  payload: data
});

export const saveHomePageDataForQueueInfo = data => ({
  type: PATIENT_QUEUE_WAIT_TIME,
  payload: data
});

export const setScheduledBooking = data => ({
  type: SCHEDULED_BOOKING,
  payload: data
});