// import React, {useState, useEffect} from "react";

// import ICCheckbox from "../../components/form/checkbox";
// import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
// import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
// import InstaButton from "../../components/button";
// import Label from "../../components/label";

// import APP_CONSTANTS from "../../constants/index";
// import appRoutes from "../../routes/appRoutes";

// import Modal from "../../components/modal";

// const NewCardForm = props => {

//     const stripe = window.Stripe(APP_CONSTANTS.stripeKey);
//     const elements = stripe.elements({
//         locale: 'en'
//       }
//     );

//     let defCard = true;

//     const card = elements.create('cardNumber', {
//       style: {}
//     });
//     const cardExpiry = elements.create('cardExpiry', {
//       style: {}
//     });
//     const cardCvc = elements.create('cardCvc', {
//       style: {}
//     });

//     let valiCardNumber = false;
//     let valiCardExpiry= false;
//     let valiCardCvc = false;

//     const [invalidCardPopUp, setInvalidCardPopUp] = useState(false);

//     const onFormSubmit = async (event) => {
//         event.preventDefault();
//         if(valiCardNumber && valiCardExpiry && valiCardCvc)
//           stripe.createToken(card).then(props.onFormSubmit);
//         else
//           setInvalidCardPopUp(true);
//     };

//     const cancelButton = () => {
//         props.history.push(appRoutes.savedCards)
//       };

//     const handleDefCard = () => {
//         defCard = !defCard;
//       };

//     const closeInvalidCardPopUp = () => {
//       setInvalidCardPopUp(false);
//     };

//     useEffect(()=>{
//         card.mount('#card-number');
//         cardExpiry.mount('#card-expiry');
//         cardCvc.mount("#card-cvc");
//     },[invalidCardPopUp])

//     const cardBrandToPfClass = {
//         'visa': 'pf-visa',
//         'mastercard': 'pf-mastercard',
//         'amex': 'pf-american-express',
//         'discover': 'pf-discover',
//         'diners': 'pf-diners',
//         'jcb': 'pf-jcb',
//         'unknown': 'fas fa-credit-card',
//     }

//     const setBrandIcon = (brand) => {
//         var brandIconElement = document.getElementById('brand-icon');
//         var pfClass = 'pf-credit-card';
//         if (brand in cardBrandToPfClass) {
//             pfClass = cardBrandToPfClass[brand];
//         }
//         for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
//             brandIconElement.classList.remove(brandIconElement.classList[i]);
//         }
//         brandIconElement.classList.add('pf');
//         brandIconElement.classList.add(pfClass);
//     }

//     card.on('change', function (event) {
//         // Switch brand logo
//         if (event.brand) {
//             if(event.brand == "unknown" || "" | null)
//             {
//                 var brandIconElement = document.getElementById('brand-icon');
//                 for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
//                     brandIconElement.classList.remove(brandIconElement.classList[i]);
//                 }
//                 brandIconElement.classList.add("pf-credit-card")
//                 brandIconElement.classList.add("fa-credit-card")
//                 brandIconElement.classList.add("fas");
//             }
//             else
//                 setBrandIcon(event.brand);
//         }

//         // Validate card number
//         if(event.complete)
//           valiCardNumber = true;
//         else
//           valiCardNumber = false;
//     });

//     cardExpiry.on('change', function (event) {
//       // Validate card's expiry number
//       if(event.complete)
//         valiCardExpiry = true;
//       else
//         valiCardExpiry = false;
//     });

//     cardCvc.on('change', function (event) {
//       // Validate card's cvc number
//       if(event.complete)
//         valiCardCvc = true;
//       else
//         valiCardCvc = false
//     });

//     return (
//             <form
//             onSubmit={onFormSubmit}
//             className="w-100"
//             >
//             <div className="col-sm-12">
//               <div className="row">
//                 <div className="col-sm-6 ml-3 mt-4 position-relative" id="card-number">
//                 </div>
//                 <div className="col-sm-1 card-icon">
//                 <i className="fas pf-credit-card fa-credit-card" id="brand-icon"/>
//                 </div>
//               </div>
//               <div className="border-bottom col-sm-6 ml-3"/>
//           </div>
//           <div className="col-sm-12">
//             <div className="row">
//               <div className="col-sm-3 mt-4 ml-3">
//                 <div id="card-expiry"/>
//                 <div className="w-100 border-bottom"/>
//               </div>
//               <div className="col-sm-3 mt-4">
//                 <div id="card-cvc"/>
//                 <div className="w-100 border-bottom"/>
//               </div>
//             </div>
//           </div>
//           <div className="col-sm-12 ml-3 mt-3">
//             <Label
//               title="Security Encryption 128 Bit SSL Encryption"
//               className="font-12 gray"
//             />
//           </div>
//           <div className="col-sm-12 ml-3 mt-3">
//             <ICCheckbox
//               className="primary-color p-0 ml-2"
//               name="defaultCard"
//               id="defaultCard"
//               onChange={handleDefCard}
//               icon={<CircleUnchecked />}
//               checkedIcon={<CircleCheckedFilled />}
//               value={defCard}
//               inputProps={{ "aria-label": "primary checkbox" }}
//             />
//             <div className="cb-label">
//               <Label className="gray font-14" title="Default Card" />
//             </div>
//           </div>
//           <div className="col-sm-12 mt-4 ml-3">
//             <InstaButton title="Add Card" type="submit" />
//             <InstaButton
//               title="Cancel"
//               clickEvent={cancelButton}
//               className="gray-outline ml-3"
//             />
//           </div>
//           <Modal
//           modalBodyContent="Invalid Card Details"
//           cancelText="Okay"
//           title="Instant Consult"
//           useOneButton={true}
//           cancelEvent={closeInvalidCardPopUp}
//           openModal={invalidCardPopUp}
//           />
//         </form>
//     );
// };

// export default NewCardForm;

import React, { Component } from "react";

import ICCheckbox from "../../components/form/checkbox";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import InstaButton from "../../components/button";
import Label from "../../components/label";

import APP_CONSTANTS from "../../constants/index";
import appRoutes from "../../routes/appRoutes";

import Modal from "../../components/modal";

class NewCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defStatus: true,
      cardNumberValid: false,
      cardExpiryValid: false,
      cardCvcValid: false,
      invalidCardPopUp: false,
      stripe: "",
      card: "",
      cardExpiry: "",
      cardCvc: "",
      invalidPopUpText: ""
    };
  }

  componentDidMount() {
    let stripe = window.Stripe(APP_CONSTANTS.stripeKey);
    let elements = stripe.elements({
      locale: "en"
    });
    let card = elements.create("cardNumber", {
      style: {}
    });
    let cardExpiry = elements.create("cardExpiry", {
      style: {}
    });
    let cardCvc = elements.create("cardCvc", {
      style: {}
    });

    card.mount("#card-number");
    cardExpiry.mount("#card-expiry");
    cardCvc.mount("#card-cvc");

    this.setState({
      stripe: stripe,
      card: card,
      cardExpiry: cardExpiry,
      cardCvc: cardCvc
    });

    const cardBrandToPfClass = {
      visa: "pf-visa",
      mastercard: "pf-mastercard",
      amex: "pf-american-express",
      discover: "pf-discover",
      diners: "pf-diners",
      jcb: "pf-jcb",
      unknown: "fas fa-credit-card"
    };

    const setBrandIcon = brand => {
      var brandIconElement = document.getElementById("brand-icon");
      var pfClass = "pf-credit-card";
      if (brand in cardBrandToPfClass) {
        pfClass = cardBrandToPfClass[brand];
      }
      for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
        brandIconElement.classList.remove(brandIconElement.classList[i]);
      }
      brandIconElement.classList.add("pf");
      brandIconElement.classList.add(pfClass);
    };

    card.on("change", function(event) {
      // Switch brand logo
      if (event.brand) {
        if (event.brand == "unknown" || "" | null) {
          var brandIconElement = document.getElementById("brand-icon");
          for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
            brandIconElement.classList.remove(brandIconElement.classList[i]);
          }
          brandIconElement.classList.add("pf-credit-card");
          brandIconElement.classList.add("fa-credit-card");
          brandIconElement.classList.add("fas");
        } else setBrandIcon(event.brand);
      }

      //  Validating card number
      // if(event.complete)
      //   this.setState({cardNumberValid: true})
      // else
      //   this.setState({cardNumberValid: false})
    });

    // cardExpiry.on('change', function(event) {
    //   // Validating card number
    //   if(event.complete)
    //     this.setState({cardExpiryValid: true})
    //   else
    //     this.setState({cardExpiryValid: false})
    // });

    // cardCvc.on('change', function(event) {
    //   // Validating card number
    //   if(event.complete)
    //     this.setState({cardCvcValid: true})
    //   else
    //     this.setState({cardCvcValid: false})
    // });
  }

  onForm = async event => {
    event.preventDefault();
    if (this.props.defCardStatus) {
      this.props.defCardStatus(this.state.defStatus);
    }
    //console.log(this.state.cardNumberValid, this.state.cardCvcValid, this.state.cardExpiryValid)
    //if(this.state.cardNumberValid && this.state.cardCvcValid && this.state.cardExpiryValid) {
    await this.state.stripe.createToken(this.state.card).then(res => {
      if (res.token) {
        if (res.token.card.brand == "American Express")
          this.setState({
            invalidCardPopUp: true,
            invalidPopUpText: "We accept Visa and Mastercard only"
          });
        else this.props.onFormSubmit(res);
      } else
        this.setState({
          invalidCardPopUp: true,
          invalidPopUpText: "Invalid Card Details"
        });
    });
    // }
    // else
    //   this.setState({invalidCardPopUp: true})
  };

  cancelButton = () => {
    if (!this.props.isPopup) {
      this.props.history.push(appRoutes.savedCards);
    }
  };

  handleDefCard = () => {
    this.setState({
      defStatus: !this.state.defStatus
    });
  };

  closeInvalidCardPopUp = () => {
    this.setState({ invalidCardPopUp: false });
  };

  renderPopup = () => {
    return (
      <form onSubmit={this.onForm} className="w-100">
        <div className="pl-5 pt-2">
        <div className="col-sm-12">
          <div className="row ">
            <div
              className="col-sm-9 ml-3 position-relative"
              id="card-number"
            ></div>
            <div className="col-sm-1 card-icon">
              <i
                className="fas pf-credit-card fa-credit-card"
                id="brand-icon"
              />
            </div>
          </div>
          <div className="border-bottom col-sm-10 ml-3" />
        </div>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-5 mt-4 ml-3">
              <div id="card-expiry" />
              <div className="w-100 border-bottom" />
            </div>
            <div className="col-sm-5 mt-4">
              <div id="card-cvc" />
              <div className="w-100 border-bottom" />
            </div>
          </div>
        </div>
        </div>
        <div className="col-sm-12 ml-3 mt-3">
          <Label
            title="Security Encryption 128 Bit SSL Encryption"
            className="font-12 gray"
          />
        </div>
        <div className="col-sm-12 mt-4 ml-3">
          <InstaButton title="Add Card" type="submit" />
          <InstaButton
            title="Cancel"
            clickEvent={this.props.cancel}
            className="gray-outline ml-3"
          />
        </div>
        <Modal
          modalBodyContent={this.state.invalidPopUpText}
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeInvalidCardPopUp}
          openModal={this.state.invalidCardPopUp}
        />
      </form>
    );
  };

  render() {
    return this.props.isPopup ? (
      this.renderPopup()
    ) : (
      <form onSubmit={this.onForm} className="w-100">
        <div className="col-sm-12">
          <div className="row">
            <div
              className="col-sm-6 ml-3 mt-4 position-relative"
              id="card-number"
            ></div>
            <div className="col-sm-1 card-icon">
              <i
                className="fas pf-credit-card fa-credit-card"
                id="brand-icon"
              />
            </div>
          </div>
          <div className="border-bottom col-sm-6 ml-3" />
        </div>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-3 mt-4 ml-3">
              <div id="card-expiry" />
              <div className="w-100 border-bottom" />
            </div>
            <div className="col-sm-3 mt-4">
              <div id="card-cvc" />
              <div className="w-100 border-bottom" />
            </div>
          </div>
        </div>
        <div className="col-sm-12 ml-3 mt-3">
          <Label
            title="Security Encryption 128 Bit SSL Encryption"
            className="font-12 gray"
          />
        </div>
        <div className="col-sm-12 ml-3 mt-3">
          <ICCheckbox
            className="primary-color p-0 ml-2"
            name="defaultCard"
            id="defaultCard"
            onChange={this.handleDefCard}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            value={this.state.defStatus}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <div className="cb-label">
            <Label className="gray font-14" title="Default Card" />
          </div>
        </div>
        <div className="col-sm-12 mt-4 ml-3">
          <InstaButton title="Add Card" type="submit" />
          <InstaButton
            title="Cancel"
            clickEvent={this.cancelButton}
            className="gray-outline ml-3"
          />
        </div>
        <Modal
          modalBodyContent={this.state.invalidPopUpText}
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeInvalidCardPopUp}
          openModal={this.state.invalidCardPopUp}
        />
      </form>
    );
  }
}

export default NewCardForm;
