import React, { useEffect } from "react";
import Label from "../../components/label";
import Anchor from "../../components/anchor";
import {logFirebaseEvent} from "../../utils/common";

export default function LegalPage(props) {
  console.log({props});
  useEffect(() => {
    logFirebaseEvent("Legal Page");
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="container
        h-100 pb-5 mb-5"
    >
      <div className="col-sm-12 mt-4 pb-3">
        <Label title="Legal" className="big" />
      </div>
      <div className="border-bottom w-100" />
      {/* <div className="col-sm-12 mt-4 legal-text-size">
        <a
          title="Terms and Conditions"
          className="primary-color text-decoration-none w-100 thin-font"
          href={APP_CONSTANTS.tAcURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
      </div>

      <div className="col-sm-12 mt-2 legal-text-size">
        <a
          title="Privacy Policy"
          className="primary-color text-decoration-none w-100 thin-font"
          href={APP_CONSTANTS.privacyPolicyURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div> */}
        <div className="col-sm-12 mt-4">
          <Anchor
            title="Terms and Conditions"
            className="primary-color text-decoration-none w-100 thin-font"
            href={props.terms}
            target="_blank"
          />
        </div>
        <div className="col-sm-12 mt-2">
          <Anchor
            title="Privacy Policy"
            className="primary-color text-decoration-none w-100 thin-font"
            href={props.privacyPolicyURL}
            target="_blank"
          />
          </div>
    </div>
  );
}
