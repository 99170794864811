import React, { Component } from "react";
import { withRouter } from "react-router";

import PublicLayout from "../../containers/Layout/PublicLayout";

import LogoImg from "../../assets/images/group-3@3x.png";

import { logFirebaseEvent } from "../../utils/common";

import UnsubscribeEmailForm from "../../containers/UnsubscribeEmailsForm";

import MakeAPICall from "../../apiCalls/common";

import CustomModal from "../../components/customModal";
import Loader from "../../components/loader";
import Label from "../../components/label";
import { unsubscribeEmailAPI } from "../../apiRoutes";

class UnsubscribeEmailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiParam: null,
            emailId: '',
            openConfirmModel: false,
            isLoading: false,
            unsubscribeSuccessPopup: false
        };
    }
    onFormSubmit = async formValues => {
        console.log("Unsubscribe from emails: ", formValues);
        this.setState({
            emailId: formValues.email,
            openConfirmModel: true
        });
    };

    componentDidMount() {
        logFirebaseEvent("Unsubscribe Marketing Emails Page");
        window.scrollTo(0, 0);
    }

    handleConfirmUnsubscribe = () => {
        this.setState({
            openConfirmModel: false,
            isLoading: true
        });
        const dataToSend = {
            email: this.state.emailId
        }
        let apiCallObj = {
            reqType: "POST",
            reqURL: unsubscribeEmailAPI,
            dataObj: dataToSend,
            successCallback: async res => {
                console.log("Unsubscribe success: ", res);
                this.setState({
                    isLoading: false,
                    unsubscribeSuccessPopup: true
                });
            },
            failureCallback: err => {
                console.log("Error during unsubscribe: ", err);
                this.setState({
                    isLoading: false
                })
            },
            showPopUp: false
        };
        this.setState({ apiParam: apiCallObj });
    }

    render() {
        return (
            <PublicLayout>
                <Loader loading={this.state.isLoading} />
                <div className="login-page-container container d-flex align-items-center justify-content-center">
                    <div className="lg-page-inner-container frgt-pass-form-width">
                        <div className="lp-header row">
                            <div className="col-md-12">Unsubscribe From Our Email List</div>
                        </div>
                        <div className="row lp-content-container d-flex justify-content-center align-items-center">
                            <div className="col-md-12 logo-img-container d-flex justify-content-center align-items-center">
                                <img src={LogoImg} alt="logo-img" />
                            </div>
                            <div className="col-md-12 d-flex justify-content-center align-items-center lg-form-container mt-4 pt-1">
                                <UnsubscribeEmailForm
                                    onFormSubmit={this.onFormSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <CustomModal
                    okayEvent={() => this.handleConfirmUnsubscribe()}
                    okayText="Confirm"
                    cancelText="Cancel"
                    title="Unsubscribe"
                    cancelEvent={() => {
                        this.setState({
                            openConfirmModel: false
                        });
                    }}
                    openModal={this.state.openConfirmModel}
                    modalBodyContent={
                        <div className="row text-left">
                            <div className="col-sm-12 text-center">
                                <Label title={"Are you sure you wish to unsubscribe from our emails?"} className="w-100" />
                            </div>
                        </div>
                    }
                    backdrop="static"
                />

                <CustomModal
                    useOneButton={true}
                    cancelText="Okay"
                    title="Instant Consult"
                    cancelEvent={() => {
                        this.setState({
                            unsubscribeSuccessPopup: false
                        });
                        window.open("https://www.instantconsult.com.au/", "_self");
                    }}
                    openModal={this.state.unsubscribeSuccessPopup}
                    modalBodyContent={
                        <div className="row text-left">
                            <div className="col-sm-12 text-center">
                                <Label title={"You have successfully unsubscribed"} className="w-100" />
                            </div>
                        </div>
                    }
                    backdrop="static"
                />
                <MakeAPICall apiParam={this.state.apiParam} />
            </PublicLayout>
        );
    }
}

export default withRouter(UnsubscribeEmailPage);
