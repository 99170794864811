import axios from "axios";

import { loginApiRoute, forgotPasswordApiRoute } from "../apiRoutes";

export async function loginApi(payload) {
  return axios
    .post(loginApiRoute, payload)
    .then(res => res)
    .catch(err => err.response);
}

export async function forgotPasswordApi(payload) {
  let res = {
    successMsg: "",
    errorMsg: ""
  };

  const response = await axios
    .put(forgotPasswordApiRoute, payload)
    .then(res => res)
    .catch(err => err.response);

  if (response.data.statusCode === 200) {
    res.successMsg = response.data.message;
  } else if (response.data.statusCode === 401) {
    res.errorMsg = "Email address not found";
  } else {
    res.errorMsg = response.data.message;
  }

  return res;
}
