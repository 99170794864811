import React, { Component } from "react";
import { Navbar, NavbarBrand, NavbarText } from "reactstrap";
import FullLogo from "../../assets/images/web-logo.png";
import SmallLogo from "./../../assets/images/logo-small.png";
import Label from "./../../components/label";
import ATag from "./../../components/anchor";

class TopNav extends Component {
  render() {
    return (
      <Navbar
        fixed="top"
        color="light"
        light
        expand="md"
        className="top-nav row"
      >
        <NavbarBrand href="/" className="col-sm-2 offset-sm-1 pl-3">
          <img
            src={FullLogo}
            alt="Instant Consult Logo"
            className="d-none logo-icon d-sm-block w-100"
          />
          <img
            src={FullLogo}
            alt="Instant Consult Logo"
            className="d-block d-sm-none sm-logo"
          />
        </NavbarBrand>
        <NavbarText className="col-sm-5 offset-sm-3">
          <div className="support-mobile-number-topnav">
            <Label title="Support" className="mr-2 gray" />
          <i className="fas fa-phone-alt"></i>
          <ATag
            title="1300 003 310"
            href="tel:1300003310"
            className="insta-link-orange mr-3 thin-font"
          />
            <div className="w-100 d-block d-sm-none"></div>
          </div>
          <i className="fas fa-envelope mr-1"></i>
          <ATag
            href="mailTo:support@instantconsult.com.au"
            className="insta-link-orange thin-font"
            title="support@instantconsult.com.au"
          />
        </NavbarText>
      </Navbar>
    );
  }
}

export default TopNav;
