import React, { Component } from "react";
import Label from "../../components/label";
import IconButton from "../../components/iconButton";
import appRoutes from "../../routes/appRoutes";
import { connect } from "react-redux";
import InstaButton from "../../components/button";
import { getFormattedAddress, logFirebaseEvent } from "../../utils/common";

class Profile extends Component {
  constructor(props) {
    super(props);
    let propsConst = this.props.auth.user[0];

    let name = "",
      medicareNumber = "",
      IHINumber = "",
      refMedicareNumber = "",
      en1 = "",
      en2 = "",
      ecn1 = "",
      ecn2 = "";

    if (propsConst.middleName !== "") {
      name =
        propsConst.firstName +
        " " +
        propsConst.middleName +
        " " +
        propsConst.lastName;
    } else {
      name = propsConst.firstName + " " + propsConst.lastName;
    }

    const address = getFormattedAddress(propsConst.address);

    if (propsConst.medicareNumber === "") medicareNumber = "-";
    else medicareNumber = propsConst.medicareNumber;

    if (!propsConst.IHIInfo?.IHINumber) IHINumber = "-";
    else IHINumber = propsConst.IHIInfo?.IHINumber;

    if (propsConst.refNumerOnMedicareCard === "") refMedicareNumber = "-";
    else refMedicareNumber = propsConst.refNumerOnMedicareCard;

    if (propsConst && propsConst.kin1) {
      if ("kin1" in propsConst) {
        if (propsConst.kin1.name === "") en1 = "-";
        else en1 = propsConst.kin1.name;

        if (propsConst.kin1.contact === "") ecn1 = "-";
        else ecn1 = propsConst.kin1.contact;
      } else {
        en1 = "-";
        ecn1 = "-";
      }
    }

    if (propsConst && propsConst.kin2) {
      if ("kin2" in propsConst) {
        if (propsConst.kin2.name === "") en2 = "-";
        else en2 = propsConst.kin2.name;

        if (propsConst.kin2.contact === "") ecn2 = "-";
        else ecn2 = propsConst.kin2.contact;
      } else {
        en2 = "-";
        ecn2 = "-";
      }
    }

    let phoneNumber = "";
    propsConst.phoneNumber = propsConst.phoneNumber.replace(/\s/g, "");
    if (propsConst.phoneNumber.indexOf(" ") == 2) {
      phoneNumber = propsConst.phoneNumber;
    } else {
      phoneNumber = propsConst.phoneNumber.replace(/\s/g, "");
      phoneNumber = phoneNumber.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    }

    if (medicareNumber.indexOf(" ") != 2) {
      medicareNumber = medicareNumber.replace(/\s/g, "");
      medicareNumber = medicareNumber.replace(
        /(\d{4})(\d{5})(\d{1})/,
        "$1 $2 $3"
      );
    }

    if (IHINumber.indexOf(" ") != 2) {
      IHINumber = IHINumber.replace(/\s/g, "");
      IHINumber = IHINumber.replace(
        /(\d{4})(\d{4})(\d{4})(\d{4})/,
        "$1 $2 $3 $4"
      );
    }

    if (ecn1.indexOf(" ") != 2) {
      ecn1 = ecn1.replace(/\s/g, "");
      ecn1 = ecn1.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    }

    if (ecn2.indexOf(" ") != 2) {
      ecn2 = ecn2.replace(/\s/g, "");
      ecn2 = ecn2.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    }

    this.state = {
      name: name,
      dateOfBirth: propsConst.dateOfBirth,
      gender: propsConst.gender,
      email: propsConst.email,
      mobileNumber: phoneNumber,
      address: address,
      medicareNumber: medicareNumber,
      IHINumber: IHINumber,
      refMedicareNumber: refMedicareNumber,
      eName1: en1,
      eName2: en2,
      eContactNumber1: ecn1,
      eContactNumber2: ecn2
      // DVANumber: propsConst.DVANumber
    };
  }
  editProfile = () => {
    const { history } = this.props;
    history.push(appRoutes.editProfile);
  };

  editEmergencyContacts = () => {
    const { history } = this.props;
    history.push(appRoutes.editEmergencyContacts);
  };

  gotoFamilyProfile = () => {
    const { history } = this.props;
    history.push(appRoutes.familyProfile);
  };

  componentDidMount() {
    logFirebaseEvent("Profile Page");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className="container
        h-100 bg-white add-attachment-model font-12 pb-5 mb-5"
      >
        <div className="row">
          {/* BASIC INFORMATION */}

          <div className="col-sm mt-4 ml-3">
            <Label title="My Profile" className="big" />
          </div>
          <div className="col-sm text-right mt-4 pb-3 mr-3">
            <IconButton
              className="mr-3"
              title="Family Profile"
              icon="fas fa-users"
              clickEvent={this.gotoFamilyProfile}
            />
            <IconButton
              title="Edit"
              icon="fas fa-pencil-alt"
              clickEvent={this.editProfile}
            />
          </div>

          <div className="w-100 border-bottom" />

          <div className="col-sm-12 mt-4 ml-3">
            <Label title="Basic Information" className="gray" />
          </div>
          <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Your Name" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.name} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Date of Birth" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.dateOfBirth} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Gender" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.gender} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />

          {/* CONTACT INFORMATION */}

          <div className="col-sm-12 mt-4 ml-3">
            <Label title="Contact Information" className="gray" />
          </div>
          <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Email" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.email} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Mobile Number" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.mobileNumber} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Address" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.address} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />

          {/* MEDICAL INFORMATION */}

          <div className="col-sm-12 mt-4 ml-3">
            <Label title="Medical Information" className="gray" />
          </div>
          <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Medicare Number" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label
              title={this.state.medicareNumber}
              className="secondary-font"
            />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-4 offset-1 mt-2">
            <Label
              title="Ref. Number on Medicare Card"
              className="font-14 gray"
            />
          </div>
          <div className="col-sm-6 offset-sm-1 mt-2">
            <Label
              title={this.state.refMedicareNumber}
              className="secondary-font"
            />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-4 offset-1 mt-2">
            <Label title="IHI Number" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-1 mt-2">
            <Label title={this.state.IHINumber} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          {/*
          <div className="col-sm-4 offset-1 mt-2">
            <Label title="DVA Number" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-1 mt-2">
            <Label title={this.state.DVANumber} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
 */}

          {/* EMERGENCY CONTACT 1 */}

          <div className="col-sm-12 mt-4 ml-3">
            <Label title="Emergency Contact 1" className="gray" />
          </div>
          <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Name" className="gray font-14" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.eName1} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Contact Number" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label
              title={this.state.eContactNumber1}
              className="secondary-font"
            />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />

          {/* EMERGENCY CONTACT 2 */}

          <div className="col-sm-12 mt-4 ml-3">
            <Label title="Emergency Contact 2" className="gray" />
          </div>
          <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Name" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label title={this.state.eName2} className="secondary-font" />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <div className="col-sm-3 offset-1 mt-2">
            <Label title="Contact Number" className="font-14 gray" />
          </div>
          <div className="col-sm-6 offset-sm-2 mt-2">
            <Label
              title={this.state.eContactNumber2}
              className="secondary-font"
            />
          </div>
          <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
          <InstaButton
            title="Edit Emergency Contact"
            clickEvent={this.editEmergencyContacts}
            className="mt-4 ml-4"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ...state.modal
});

export default connect(mapStateToProps)(Profile);
