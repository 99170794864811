import React, { Component } from "react";
import { Navbar, NavbarBrand, Nav, NavbarText } from "reactstrap";
import { connect } from "react-redux";
import FullLogo from "../../assets/images/web-logo.png";
import SmallLogoWhite from "./../../assets/images/logo-small-white.png";
import defaultPic from "./../../assets/images/default-user-img.png";
import Label from "./../../components/label";
import { store } from "../../reduxStore/store";
import { NavLink } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import defaultUserPic from "../../assets/images/default-user-img.png";

import { logOutUser } from "../../reduxStore/actions/auth";
import appRoutes from "../../routes/appRoutes";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Anchor from "../../components/anchor";
import Modal from "../../components/modal";
import moment from "moment";
import MakeAPICall from "../../apiCalls/common";
import {
  notificationApiRoute,
  updateNotificationApiRoute
} from "../../apiRoutes";
import { notificationNumber } from "../../reduxStore/actions/common";
import {logFirebaseEvent} from "../../utils/common";

class TopNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      logOutPopUp: false,
      notificationList: [],
      notificationModalOpen: false,
      unreadCount: 0,
      totalCount: store.getState().commonReducers.totalNotification || 0,
      telNumber: ""
    };
  }

  componentDidMount() {
    this.setState({
      telNumber: this.props.auth.user
        ? this.props.auth.user[1].phoneNumber.replace(
            /(\d{4})(\d{3})(\d{3})/,
            "$1 $2 $3"
          )
        : ""
      //telNumber: this.props.auth.user[1].phoneNumber
    });
    store.subscribe(() => {
      this.setState({
        totalCount: store.getState().commonReducers.totalNotification
          ? store.getState().commonReducers.totalNotification
          : 0
      });
    });
    this.retrieveAllNotifications();
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.totalCount !== this.state.totalCount)
      this.retrieveAllNotifications();
  }

  notificationNumber = async data => {
    await this.props.notificationNumber({
      totalNotification: data
    });
  };

  retrieveAllNotifications = () => {
    let apiCallParam = {
      reqType: "GET",
      reqURL: notificationApiRoute,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          notificationList: res.data.data.notifications,
          unreadCount: res.data.data.unReadCount,
          totalCount: res.data.data.totalCount
        });
        this.notificationNumber(res.data.data.totalCount);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallParam
    });
  };

  markAllReadNotification = () => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: updateNotificationApiRoute,
      dataObj: { status: 1 },
      params: {},
      headers: {},
      successCallback: res => {
        this.retrieveAllNotifications();
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallParam
    });
  };

  readNotification = value => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: updateNotificationApiRoute,
      dataObj: {
        status: 1,
        id: value._id
      },
      params: {},
      headers: {},
      successCallback: res => {
        this.retrieveAllNotifications();
        if (value.notificationMsg.includes("Inbox"))
          this.props.history.push(appRoutes.inbox);
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallParam
    });
  };

  logOut = async () => {
    logFirebaseEvent("User Logged Out");
    await this.props.logOutUser();
  };

  toggleNotificationList = () => {
    this.setState({ notificationModalOpen: !this.state.notificationModalOpen });
  };

  logOutPopUp = () => {
    const cValue = this.state.logOutPopUp;
    this.setState({
      logOutPopUp: !cValue
    });
  };

  closeLogOutPopUp = () => {
    this.setState({
      logOutPopUp: false
    });
  };

  render() {
    if (this.props.auth.user) {
      return (
        <Navbar
          fixed="top"
          color="light"
          light
          expand="md"
          className="top-nav-container top-nav top-nav-loggedIn"
        >
          <NavLink to={appRoutes.requestAConsult}>
            <img
              src={FullLogo}
              alt="Instant Consult Logo"
              className="d-none d-sm-block ic-logo"
            />
            <img
              src={SmallLogoWhite}
              alt="Instant Consult Logo"
              className="d-block d-sm-none mx-auto"
            />
          </NavLink>
          <Nav className="mx-auto input-width"></Nav>

          <NavbarText className="ml-2">
            <div>
              <div className="float-left">
                <Label title="Support" className="mr-2" />
                <i className="fas fa-phone-alt"></i>
                <Anchor
                  title={this.state.telNumber}
                  href={"tel:" + this.props.auth.user[1].phoneNumber}
                  className="insta-link-orange mr-3 thin-font"
                />
              </div>
              <div className="w-100 d-block d-sm-none"></div>
              <div className="float-left">
                <i className="fas fa-envelope mr-1"></i>
                <Anchor
                  href="mailTo:support@instantconsult.com.au"
                  className="insta-link-orange thin-font"
                  title={this.props.auth.user[1].email}
                />
              </div>
            </div>
          </NavbarText>
          <div className="top-nav-right">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="rounded-circle user-pic"
                  src={
                    this.props.auth.user[0] &&
                    this.props.auth.user[0].profilePic &&
                    this.props.auth.user[0].profilePic.original !== null &&
                    this.props.auth.user[0].profilePic.original !== undefined &&
                    this.props.auth.user[0].profilePic.original !== "null"
                      ? this.props.auth.user[0].profilePic.original
                      : defaultUserPic
                  }
                  alt="user-pic"
                />{" "}
                {this.props.auth.user[0].firstName +
                  " " +
                  this.props.auth.user[0].lastName}
              </DropdownToggle>
              <DropdownMenu right>
                <NavLink to={appRoutes.myProfile}>
                  <DropdownItem className="top-nav-list-item">
                    <i className="fas fa-user"></i>&nbsp;&nbsp;&nbsp;My Profile
                  </DropdownItem>
                </NavLink>
                <NavLink to={appRoutes.changePassword}>
                  <DropdownItem className="top-nav-list-item">
                    <i className="fas fa-lock"></i>&nbsp;&nbsp;&nbsp;Change
                    Password
                  </DropdownItem>
                </NavLink>
                <DropdownItem
                  className="top-nav-list-item"
                  onClick={this.logOutPopUp}
                >
                  <i className="fas fa-sign-out-alt"></i>
                  &nbsp;&nbsp;&nbsp;Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <div
              className="notification-div cursor-pointer"
              onClick={this.toggleNotificationList}
            >
              <i className="fas fa-bell mt-3 mr-3 ml-2 cursor-pointer bell-icon"></i>
              <span className={this.state.unreadCount > 0 ? "count" : "d-none"}>
                {this.state.unreadCount}
              </span>
              <div className="notification-list">
                <div
                  className={
                    this.state.notificationModalOpen ? "after-block" : "d-none"
                  }
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        <div onClick={this.markAllReadNotification}>
                          <Anchor
                            title="Mark All Read"
                            className="cursor-pointer insta-link-orange font-14"
                          />
                        </div>
                      </ListSubheader>
                    }
                  >
                    {this.state.notificationList.map((value, index) => {
                      //console.log(value)
                      let img = "",
                        title = "";
                      if (value.flag !== "18" && value.flag !== "24" && value.doctorId) {
                        //title = "Dr "+data.doctorId.firstName + " " + data.doctorId.lastName;
                        img = value.doctorId.profilePic.original;
                        title = value.notificationMsg;
                      } else {
                        //title = "From Instant Consult";
                        img = SmallLogoWhite;
                        title = value.notificationMsg;
                      }
                      let formattedDate = moment(value.notificationDate).format(
                        "D MMM YYYY"
                      );
                      return (
                        <ListItem
                          button
                          key={value._id}
                          className={value.isRead ? "" : "unread-noti"}
                          onClick={() => this.readNotification(value)}
                        >
                          <img
                            src={img}
                            alt="ic-logo"
                            className="notification-logo cursor-pointer"
                          />
                          <div className="notification-text float-right">
                            <Label
                              title={title}
                              className="secondary-font w-100 mb-0 cursor-pointer"
                            />
                            <Label
                              title={formattedDate}
                              className="gray w-100 mb-0 cursor-pointer"
                            />
                          </div>
                        </ListItem>
                      );
                    })}
                    <ListItem
                      className={this.state.unreadCount !== 0 ? "d-none" : ""}
                      button
                    >
                      <div className="notification-text w-100">
                        <Label
                          title="No data found"
                          className="secondary-font w-100 mb-0 cursor-pointer text-center"
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </div>
            </div>
          </div>
          <Modal
            modalBodyContent="Are you sure you want to logout?"
            okayText="Yes"
            cancelText="No"
            title="Instant Consult"
            useOneButton={false}
            okayEvent={this.logOut}
            cancelEvent={this.closeLogOutPopUp}
            openModal={this.state.logOutPopUp}
          />
          <MakeAPICall apiParam={this.state.apiCall} />
        </Navbar>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ...state.modal
});

const mapDispatchToProps = dispatch => ({
  logOutUser: () => dispatch(logOutUser()),
  notificationNumber: data => dispatch(notificationNumber(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
