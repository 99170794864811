import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const CustomModal = props => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  return (
    <Modal
      isOpen={props.openModal}
      toggle={props.cancelEvent}
      className={"custom-modal-container " + props.className}
    >
      <ModalHeader toggle={props.cancelEvent}>{props.title}</ModalHeader>
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: props.modalBodyContent }}></div>
      </ModalBody>
      <ModalFooter>
        <React.Fragment>
          <button className="insta-button-primary" onClick={props.firstButton}>
            {props.firstButtonText}
          </button>
        </React.Fragment>
        {props.isSecondBtnAsDropDown === true ? (
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret className="insta-button-primary">
              {props.dropDownTitle}
            </DropdownToggle>
            <DropdownMenu>{props.dropDownItems}</DropdownMenu>
          </ButtonDropdown>
        ) : (
          <button className="insta-button-primary" onClick={props.secondButton}>
          {props.secondButtonText}
        </button>
        )}
        <button className="insta-button-primary" onClick={props.thirdButton}>
            {props.thirdButtonText}
          </button>
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;

CustomModal.defaultProps = {
  okayText: "OKAY",
  cancelText: "CANCEL",
  modalBodyContent: "",
  openModal: false,
  className: "",
  useOneButton: false,
  isSecondBtnAsDropDown: false
};

CustomModal.propTypes = {
  okayText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.string,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  className: PropTypes.string,
  useOneButton: PropTypes.bool,
  isSecondBtnAsDropDown: PropTypes.bool
};
