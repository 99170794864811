import React, { useEffect } from "react";
import Label from "../../components/label";
import { logFirebaseEvent } from "../../utils/common";
import PublicLayout from "../../containers/Layout/PublicLayout";

export default function DeleteAccountInstructions(props) {
  useEffect(() => {
    logFirebaseEvent("Account Deletion Page");
    window.scrollTo(0, 0);
  }, []);
  return (
    <PublicLayout>
      <div className="col-sm-12 mt-5 pb-3">
        <Label title="Account Deletion" className="big mt-5"/>
      </div>
      <div className="border-bottom w-100"/>
      <div className="col-sm-12 mt-4">
        <p>
          To delete your account, please open you app, go to the profile page and at the bottom of the page you will find the option to delete your account.
        </p>
      </div>
    </PublicLayout>
  );
}
