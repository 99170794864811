import React, { Fragment, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
// import TextField from "@material-ui/core/TextField";
import ICInput from "../../components/form/input";

import MakeAPICall from "../../apiCalls/common";

import appRoutes from "../../routes/appRoutes";

import {
  consultHistoryDetailsApiRoute,
  rateDoctorApiRoute
} from "../../apiRoutes";
import { saveAppointmentData } from "../../reduxStore/actions/common";

import { getFormattedMonthDateV3 } from "../../utils/dateTimeHelper";
import { getConsultDuraionInRedableFormat } from "../../utils/common";

import Loader from "../../components/loader";
import CustomModal from "../../components/customModal";
import Label from "../../components/label";
import ICButton from "../../components/button";

import defaultImg from "../../assets/images/default-user-img.png";

import { logFirebaseEvent } from "../../utils/common";

const ConsultSummaryPage = props => {
  let { appointmentId } = useParams();
  let history = useHistory();

  const Ratinglabels = {
    1: "Poor",
    2: "Below Average",
    3: "Average",
    4: "Above Average",
    5: "Excellent"
  };

  const [data, setData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiCallParams, setApiCallParams] = useState(null);
  const [showReviewSecton, setShowReviewSection] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [errModalText, setErrModalText] = useState("");
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState("");

  const [ratingPopup, setRatingPopup] = useState(false);

  useEffect(() => {
    setIsProcessing(true);

    setApiCallParams({
      reqType: "GET",
      reqURL: consultHistoryDetailsApiRoute,
      params: {
        booking_id: appointmentId
      },
      successCallback: res => {
        setIsProcessing(false);
        setData(res.data.data);
      },
      failureCallback: err => {
        setIsProcessing(false);
        // setErrModalText(
        //   "Please try again. Please contact Instant Consult Support on 1300 003 310 if this issue persists."
        // );
        // setShowErrModal(true);
      }
    });
  }, []);

  const submitReview = () => {
    setIsProcessing(true);

    var formData = new FormData();
    formData.append("appointmentId", data._id);
    formData.append("userType", "Doctor");
    formData.append("doctorId", data.doctorId._id);
    formData.append("rating", rating);
    formData.append("comment", comments);
    formData.append("isBlocked", false);

    setApiCallParams({
      reqType: "PUT",
      reqURL: rateDoctorApiRoute,
      dataObj: formData,
      successCallback: res => {
        setIsProcessing(false);
        if (rating > 3) {
          setRatingPopup(true);
        } else {
          setShowThankYouModal(true);
        }
      },
      failureCallback: err => {
        setIsProcessing(false);
        // setErrModalText(
        //   "Please try again. Please contact Instant Consult Support on 1300 003 310 if this issue persists."
        // );
        // setShowErrModal(true);
      }
    });
  };

  const getConsultDetails = () => {
    logFirebaseEvent("Consult Rate Doctor Page");
    setIsProcessing(true);
    setApiCallParams({
      reqType: "GET",
      reqURL: consultHistoryDetailsApiRoute,
      params: {
        booking_id: data._id
      },
      successCallback: async res => {
        setIsProcessing(false);
        setShowErrModal(false);
        setErrModalText("");
        await props.saveAppointmentData(null);
        history.push(appRoutes.consultDetails, {
          consultDetails: res.data.data,
          ratings: rating
        });
      },
      failureCallback: err => {
        setIsProcessing(false);
        // setErrModalText(
        //   "Please try again. Please contact Instant Consult Support on 1300 003 310 if this issue persists."
        // );
        // setShowErrModal(true);
      }
    });
  };

  const handleErrorOkayEvent = async () => {
    setShowErrModal(false);
    setErrModalText("");
    await props.saveAppointmentData(null);
    history.push(appRoutes.requestAConsult);
  };

  const switchToReviewSection = () => {
    setShowReviewSection(true);
  };

  const okayRatingPopup = () => {
    window.open(
      "https://au.trustpilot.com/evaluate/instantconsult.com.au",
      "_blank"
    );
    setRatingPopup(false);
    getConsultDetails();
  };

  const closeRatingPopup = () => {
    setRatingPopup(false);
    getConsultDetails();
  };

  const getCouponPrice = data => {
    let calculatedDiscountAmount;
    if (data.patientCouponId.isPercent) {
      calculatedDiscountAmount = (
        (data.patientCouponId.amount * data.appointmentPrice) /
        100
      ).toFixed(2);
    } else {
      calculatedDiscountAmount = data.patientCouponId.amount;
    }
    let paidAmount = data.appointmentPrice - calculatedDiscountAmount;
    if (paidAmount <= 0) {
      paidAmount = 0;
    }
    return "$" + paidAmount.toFixed(2);
  };

  return (
    <div className="w-75 p-0 container consult-summary-page-container">
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        openModal={showThankYouModal}
        cancelEvent={() => {
          // go to the consult details page after submitting feedback
          getConsultDetails();
        }}
        modalBodyContent={
          <p className="text-center mb-0">
            Thank you! Your feedback is appreciated
          </p>
        }
        backdrop="static"
      />
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        openModal={showErrModal}
        cancelEvent={() => {
          handleErrorOkayEvent();
        }}
        modalBodyContent={<p className="text-center mb-0">{errModalText}</p>}
        backdrop="static"
      />
      {isProcessing && <Loader />}
      <div
        className={
          isProcessing
            ? "disableContainer row cs-inner-cont"
            : "row cs-inner-cont"
        }
      >
        {data !== null && (
          <Fragment>
            <div className="col-md-12 text-center doc-info-container">
              <Label title="You just spoke to"></Label>
              <br />
              <img
                className="rounded-circle mt-3 user-pic"
                alt="doc-img"
                src={
                  data.doctorId.profilePic
                    ? data.doctorId.profilePic.original
                    : defaultImg
                }
              />
              <br />
              <Label
                className="font-weight-bold"
                title={
                  "Dr. " +
                  data.doctorId.firstName +
                  " " +
                  data.doctorId.lastName
                }
              ></Label>
            </div>
            <div className="col-md-12 h-100 text-center consult-details-container1">
              {showReviewSecton === false && (
                <Fragment>
                  <Label
                    className="mb-0"
                    title={getFormattedMonthDateV3(data.updatedAt)}
                  ></Label>
                  <br />
                  <Label title={"Consult ID: " + data.appointmentId}></Label>
                  <br />
                  <br />
                  {data.isBulkBilled === undefined ||
                  data.isBulkBilled === false ? (
                    <Fragment>
                      <Label title="Consult Duration" className="mb-0"></Label>
                      <br />
                      <Label
                        title={getConsultDuraionInRedableFormat(
                          data.consultDuration
                        )}
                      ></Label>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Label title="Item No" className="mb-0"></Label>
                      <br />
                      <Label
                        title={
                          data.consultDetailMedicareId
                            ? data.consultDetailMedicareId.mbsItemNo
                            : ""
                        }
                      ></Label>
                    </Fragment>
                  )}
                  <br />
                  <br />
                  <Label title="Total Cost" className="mb-0"></Label>
                  <br />
                  {(data.isBulkBilled === undefined ||
                    data.isBulkBilled === false) &&
                    data.patientCouponId === undefined && (
                      <Label
                        title={`$${
                          parseFloat(data.totalPaidPrice).toFixed(2) <= 0
                            ? "0.00"
                            : parseFloat(data.totalPaidPrice).toFixed(2)
                        }`}
                      ></Label>
                    )}

                  {data.patientCouponId !== undefined && (
                    <Label title={getCouponPrice(data)}></Label>
                  )}

                  {data.isBulkBilled !== undefined &&
                    data.isBulkBilled === true && (
                      <Label title="Bulk-Billed"></Label>
                    )}

                  <br />
                  {data.clinicId && data.clinicId !== "" && (
                    <Fragment>
                      <Label title="Billed to Clinic"></Label>
                      <br />
                    </Fragment>
                  )}
                  {/* <br />
                  <Label
                    title="You will receive a Receipt in your
                Inbox folder shortly"
                  ></Label> */}
                  <br />
                  <br />
                  <ICButton
                    title="Okay"
                    clickEvent={() => {
                      switchToReviewSection();
                    }}
                  />
                  <br />
                </Fragment>
              )}
              {showReviewSecton === true && (
                <Fragment>
                  <Label className="mb-0" title="Overall Rating"></Label>
                  <br />
                  <Box
                    className="ml-2"
                    component="fieldset"
                    borderColor="transparent"
                  >
                    <Rating
                      name="rating"
                      size="large"
                      value={rating}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                    />
                  </Box>
                  <Label
                    className="mb-0"
                    title={rating !== null && Ratinglabels[rating]}
                  ></Label>
                  <br />
                  <br />
                  {/* <TextField
                    name="comments"
                    className="w-100 text-area"
                    variant="outlined"
                    placeholder="Please enter any comments about the consult…"
                    rows="8"
                    multiline
                    value={comments}
                    onChange={e => {
                      setComments(e.target.value);
                    }}
                  /> */}
                  <ICInput
                    name="comments"
                    id="comments"
                    type="text"
                    label=""
                    className="text-area mt-3"
                    placeholder="Please type any additional information for your consult..."
                    value={comments}
                    multiline
                    rows={8}
                    onChange={e => {
                      setComments(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  <ICButton
                    title="Okay"
                    clickEvent={() => {
                      submitReview();
                    }}
                  />
                  <br />
                </Fragment>
              )}
            </div>
            <br />
            <br />
          </Fragment>
        )}
      </div>
      <CustomModal
        modalBodyContent="We would love for you to rate us on Trustpilot"
        okayText="Okay"
        cancelText="Not Now"
        title="Instant Consult"
        useOneButton={false}
        okayEvent={okayRatingPopup}
        cancelEvent={closeRatingPopup}
        openModal={ratingPopup}
      />
      {/* API Call */}
      <MakeAPICall apiParam={apiCallParams} />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  saveAppointmentData: data => dispatch(saveAppointmentData(data))
});

export default connect(null, mapDispatchToProps)(ConsultSummaryPage);
