import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";

const InstaLabel = props => (
  <Label
    className={"insta-label " + props.className}
    style={props.style}
    title={props.toolTip}
    data-toggle="tooltip"
    data-placement="bottom"
  >
    {props.title}{" "}
    <sup className={props.required === true ? "asterisk" : "d-none"}>*</sup>
  </Label>
);

export default InstaLabel;

InstaLabel.defaultProps = {
  title: "",
  className: ""
};

InstaLabel.propTypes = {
  // title: PropTypes.string,
  className: PropTypes.string
};
