import React, {useState, useEffect, useRef} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import appRoutes from "../../routes/appRoutes";

import OutsideClickHandler from 'react-outside-click-handler';

import Error from "../../components/error";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
// import LoginInput from "../../components/form/loginInput";
import ICCheckbox from "../../components/form/checkbox";

import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import Divider from '@material-ui/core/Divider';
import ListItem from "@material-ui/core/ListItem";

var osType;

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

const detectBrowserAndServe = () => {
  let os = getMobileOperatingSystem();
  if (os === "Android") {
    osType = 1;
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.instantconsult&hl=en_AU";
    //window.location.href = "https://instantconsult-new.s3-ap-southeast-2.amazonaws.com/App/InstantConsultAndroid.apk"
  } else if (os === "iOS") {
    osType = 2;
    window.location.href =
      "https://apps.apple.com/au/app/instant-consult/id1388965848";
  } else if (os === "Windows Phone") {
    osType = 3;
    window.location.href = "http://www.WindowsPhoneexample.com";
  } else {
  }
};

const getDesktopBrowser = () => {
  var isBrave = false;
  try {
    isBrave = navigator.brave.isBrave()
    if(isBrave) return false;
  }
  catch(err) {
  }
  if (/Edge/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent)) {
    return false
}
if (/edge/.test(navigator.userAgent) || /edg/.test(navigator.userAgent)) {
  return false
}
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) || (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor));
  //var isFirefox = typeof InstallTrigger !== "undefined";
  if (isChrome) return true;
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') != -1) {
    return true
  }
  else return false;
};

const LoginForm = props => {
  // const loginDisabled = props.userSubscribed && props.userSubscribed.oneSignalSubscribed && props.userSubscribed.oneSignalSubscribed.isSubscribed ? false : true;
  const [savedEmails, setSavedEmails] = useState(false);  
  const [storedEmails, setStoredEmails] = useState(JSON.parse(localStorage.getItem("emails")));
  
  const [isMobile, setIsMobile] = useState(false);

  let compatibleDesktopBrowser = getDesktopBrowser();

  if(window.location.pathname == "/recover") {
    compatibleDesktopBrowser = true
    osType = 0;
  }
  else {
    detectBrowserAndServe();
  }
  
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      rememberMeCheckbox: false,
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .min(7, "Email must be of minimum length 7")
        .email("Please provide a valid email address")
        .required("Please enter the email address"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required("Please enter the password")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });
  const openSavedEmails = () => {
    setSavedEmails(true);
  };
  const selectSavedEmail = (value, index) => {
    formik.values.userName = value;
    setSavedEmails(false);
  };
  const deleteSavedEmail = (value, index) => {
    let arr = [...storedEmails]
    arr.splice(index, 1);
    setStoredEmails(arr);

    localStorage.setItem("emails", JSON.stringify(arr));
    console.log(localStorage.getItem("emails"));
  };

  useEffect(() => {
    setStoredEmails(JSON.parse(localStorage.getItem("emails")));

    if (osType === 1 || osType === 2 || osType === 3) {
      setIsMobile(true);
    } else if (!compatibleDesktopBrowser) {
      setIsMobile(true);
    }

  }, [localStorage.getItem("emails")])

  return (
    <form
      className="d-flex flex-column align-items-center"
      onSubmit={formik.handleSubmit}
      id="LoginForm"
    >
       <OutsideClickHandler
      onOutsideClick={() => {
        setSavedEmails(false);
      }}
    >
      <ICInput
        id="userName"
        name="userName"
        type="text"
        label="Email Address *"
        onChange={formik.handleChange}
        onFocus={openSavedEmails}
        onBlur={formik.handleBlur}
        value={formik.values.userName}
        error={formik.touched.userName && formik.errors.userName ? true : false}
        helperText={
          formik.touched.userName && formik.errors.userName
            ? formik.errors.userName
            : ""
        }
      />
            {/* Patient Search record show */}
            <div className={storedEmails && savedEmails ? "email-list" : "d-none"}>
              {storedEmails!=null ? 
              storedEmails.map((values, index) => {
                return (<List component="nav" className="p-0" key={index} aria-label="secondary mailbox folders">
                  <ListItem
                    className="p-2"
                    button
                  >
                    <div className="row p-0 m-0 cursor-pointer">
                      <div className="col-sm-12 p-0 cursor-pointer">
                        <span onClick={selectSavedEmail.bind(this, values,index)} className="email-text">{values}</span>
                        <i
                        className="fas fa-trash font-12"
                        onClick={deleteSavedEmail.bind(this, values, index)}
                      />
                      </div>
                    </div>
                    {/* <div className="offset-sm-7">
                      <i
                        className="fas fa-trash font-12"
                        onClick={deleteSavedEmail.bind(this, values, index)}
                      />
                      </div> */}
                  </ListItem>
                  <Divider />
                </List>)
              })
              :
              ""
              }
            </div>
    </OutsideClickHandler>

      {/* <LoginInput
        id="userName"
        name="userName"
        type="text"
        label="Email Address *"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.userName}
        error={formik.touched.userName && formik.errors.userName ? true : false}
        helperText={
          formik.touched.userName && formik.errors.userName
            ? formik.errors.userName
            : ""
        }
      /> */}
      <br />
      <ICInput
        id="password"
        name="password"
        type="password"
        label="Password *"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && formik.errors.password ? true : false}
        helperText={
          formik.touched.password && formik.errors.password
            ? formik.errors.password
            : ""
        }
      />
      <NavLink
        to={appRoutes.forgotPassword}
        className="lg-forgot-button insta-link-orange text-decoration-none"
        href="#"
      >
        Forgot?
      </NavLink>
      <div className="rm-forget-container d-flex flex-row justify-content-between align-items-center flex-wrap">
        <p>
          <ICCheckbox
            style={{ padding: 0, paddingRight: "5px" }}
            name="rememberMeCheckbox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.rememberMeCheckbox}
            inputProps={{ "aria-label": "primary checkbox" }}
            label="Remember Me"
          />
        </p>
      </div>
      <br />
      {/* <Button title="Login" disabled={props.mobile || loginDisabled} type="submit" /> */}
      <Button title="Login" type="submit" disabled={isMobile}/>
      <br />
      <Error>{props.serverErrorMsg}</Error>
    </form>
  );
};

const mapStateToProps = props => ({
  userSubscribed: props.commonReducers
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

LoginForm.defaultProps = {
  serverErrorMsg: ""
};

LoginForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};

