import React, { Component } from "react";
import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import EmergencyContactsForm from "../../containers/EmergencyContactsForm";
import { connect } from "react-redux";
import MakeAPICall from "../../apiCalls/common";
import { editEmergencyContact } from "../../apiRoutes";
import Modal from "../../components/modal";
import { updateUserData } from "../../reduxStore/actions/auth";
import {logFirebaseEvent} from "../../utils/common";

class EmergencyContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      editProfileSuccess: false,
      editSuccessPopUp: false
    };
  }

  componentDidMount() {
    logFirebaseEvent("Emergency Contact Page");
    window.scrollTo(0, 0);
  }

  onFormSubmit = values => {
    let formData = new FormData();

    let kin1 = { name: values.en1, contact: values.ecn1.toString() };
    let kin2 = { name: values.en2, contact: values.ecn2.toString() };

    formData.append("kin1", JSON.stringify(kin1));
    formData.append("kin2", JSON.stringify(kin2));

    let tmpStore = this.props.auth;
    delete tmpStore.auth;

    let apiCallObj = {
      reqType: "PUT",
      reqURL: editEmergencyContact,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: res => {
        tmpStore.user[0].kin1 = kin1;
        tmpStore.user[0].kin2 = kin2;
        //delete tmpStore.auth;
        this.props.updateUserData(tmpStore);
        this.setState({
          editSuccessPopUp: true
        });
      },
      failureCallback: err => {},
      showPopUp: false
    };

    this.setState({
      apiCall: apiCallObj
    });
  };

  okayEditProfileSuccess = () => {
    const { history } = this.props;
    history.push(appRoutes.myProfile);
  };

  render() {
    return (
      <div
        className="container
        h-100 bg-white pb-5 mb-5"
      >
        <div className="row">
          <div className="col-sm-12 mt-4">
            <NavLink to={appRoutes.myProfile}>
              <i title="" className="fa fa-arrow-left primary-color" />
              <Label
                title="Back to My Profile"
                className="cursor-pointer font-15 on-hover-orange pl-2"
              />
            </NavLink>
          </div>
          <div className="col-sm-12 mt-4 pb-3">
            <Label title="Emergency Contacts" className="big" />
          </div>
          <div className="border-bottom w-100" />
          <EmergencyContactsForm
            onFormSubmit={this.onFormSubmit}
            userDetails={this.props.auth.user[0]}
          />
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Emergency Contacts has been updated successfully"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.okayEditProfileSuccess}
          openModal={this.state.editSuccessPopUp}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ...state.modal
});

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(updateUserData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContacts);
