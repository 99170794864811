/*
 * This file is created by Vatsaly Patel
 * This function will be used for any API calls, you have to pass necessary arguments to use this function.
 * You can use call back function for the responce of APIs
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Model from "../components/modal";
import { store } from "../reduxStore/store";
import axios from "axios";
import APP_CONSTANTS from "../constants";
import { logOutUser } from "../reduxStore/actions/auth";
import Loader from "../components/loader";
// import appRoutes from "../routes/appRoutes"; // not used for now

const MakeAPICall = props => {
  let history = useHistory(); // used for redirection
  const [errorModelOpen, seterrorModelOpen] = useState(false); // error model open - close
  const [sessionOut, setSessionOut] = useState(false); // session timeout error
  const [errorModelBody, seterrorModelBody] = useState(""); // set error model body contents
  const [errorModelTitle, seterrorModelTitle] = useState(""); // set error model title
  const apiRoute = APP_CONSTANTS.apiUrl + "/api/"; // api URL
  const [redirectURL, setRedirect] = useState(null); // pass parameter to redirect to another page on model okay click
  const [apiLoading, setApiLoading] = useState(false); //loader for all the API calls

  // Close error / success model
  const closeErrorModel = () => {
    seterrorModelBody();
    seterrorModelTitle();
    seterrorModelOpen(false);
    setSessionOut(false);
    if (redirectURL) history.push(redirectURL);
  };

  // open error / success model
  const openErrorModel = (title, body) => {
    seterrorModelTitle(title);
    seterrorModelBody(body);
    seterrorModelOpen(true);
  };

  // Parameters : Request type, Request URL, data obj, parameter obj, headers, success callback, failure callback, showPopUp, redirect URL
  const makeRequest = (
    reqType,
    reqURL,
    dataObj,
    params,
    headers,
    successCallback,
    failureCallback,
    showPopUp,
    redirect,
    showErrorRes
  ) => {
    // Check headers is passed or not [If passed then use that otherwise append authorization header default]
    setApiLoading(false);
    let appendedHeader = {};
    if (
      !headers ||
      (headers &&
        Object.entries(headers).length === 0 &&
        headers.constructor === Object)
    ) {
      appendedHeader = {
        Authorization:
          "Bearer " +
          (store.getState().auth.user &&
          store.getState().auth.user[0] &&
          store.getState().auth.user[0].accessToken
            ? store.getState().auth.user[0].accessToken
            : "")
      };
    } else {
      appendedHeader = headers;
    }
    axios({
      method: reqType,
      url: reqURL,
      baseURL: apiRoute,
      headers: appendedHeader,
      data: dataObj,
      params: params,
      timeout: 60000,
      onUploadProgress: function(progressEvent) {
        // Do whatever you want with the native progress event
        // console.log("API call on process");
      },
      onDownloadProgress: function(progressEvent) {
        // Do whatever you want with the native progress event
        // console.log("API call on Download process");
      }
    })
      .then(res => {
        setApiLoading(false);
        if (showPopUp) {
          seterrorModelTitle("Instant Consult");
          seterrorModelBody(res.data.message || "Success");
          seterrorModelOpen(true);
          if (redirect) setRedirect(redirect);
        }
        successCallback(res);
      })
      .catch(err => {
        setApiLoading(false);
        if (err.response) {
          if (
            err.response.data.statusCode === 401 &&
            err.response.data.error === "Unauthorized"
          ) {
            seterrorModelTitle("Instant Consult");
            seterrorModelBody("Unauthorized Request");
            seterrorModelOpen(true);
          } else if (
            (err.response.data.statusCode === 403 &&
              err.response.data.type === "SESSION_EXPIRED") ||
            (err.response.data.statusCode === 401 &&
              err.response.data.error === "INVALID_CREDENTIALS")
          ) {
            seterrorModelTitle("Instant Consult");
            seterrorModelBody("Session has expired");
            //seterrorModelOpen(true);
            setSessionOut(true);
            setTimeout(() => {
              logOut();
            }, 1500);
          } else {
            const messageToDisplay = err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again. <br/> Please contact Instant Consult Support <br/> on 1300 003 310 if this issue persists.";
            if (showErrorRes === true) {
              seterrorModelTitle("Instant Consult");
              seterrorModelBody(messageToDisplay);
              seterrorModelOpen(true);
            }
          }
        } else if (showErrorRes === true) {
          seterrorModelTitle("Instant Consult");
          seterrorModelBody(
            "Please try again. <br/> Please contact Instant Consult Support <br/> on 1300 003 310 if this issue persists."
          );
          seterrorModelOpen(true);
        }
        console.log(err);
        failureCallback(
          err.response && err.response.data ? err.response.data : err.response
        );
      });
  };

  // Handle API Param on Change
  useEffect(() => {
    if (props.apiParam)
      makeRequest(
        props.apiParam.reqType,
        props.apiParam.reqURL,
        props.apiParam.dataObj,
        props.apiParam.params,
        props.apiParam.headers,
        props.apiParam.successCallback,
        props.apiParam.failureCallback,
        props.apiParam.showPopUp,
        props.apiParam.redirect ? props.apiParam.redirect : null,
        props.apiParam.showErrorRes === false ? false : true
      );
  }, [props.apiParam]);

  // Logout if session out
  const logOut = async () => {
    await props.logOutUser();
  };

  return (
    <div>
      <Model
        openModal={errorModelOpen}
        modalBodyContent={errorModelBody}
        okayText="Okay"
        cancelText="Okay"
        title={errorModelTitle}
        useOneButton={true}
        okayEvent={closeErrorModel}
        cancelEvent={closeErrorModel}
      />
      <Model
        openModal={sessionOut}
        modalBodyContent={"You are signed out. Please login again."}
        okayText="Okay"
        cancelText="Okay"
        title={"Signed Out"}
        useOneButton={true}
        okayEvent={closeErrorModel}
        cancelEvent={closeErrorModel}
      />
      <Loader loading={apiLoading} />
    </div>
  );
};

// get the store
const mapStateToProps = props => ({
  auth: props.auth
});

const mapDispatchToProps = dispatch => ({
  logOutUser: () => dispatch(logOutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(MakeAPICall);
