import React, { Component } from "react";
import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import NewCardForm from "../../containers/NewCardForm";
import MakeApiCall from "../../apiCalls/common";
import { addNewCardApiRoute } from "../../apiRoutes/index";
import Modal from "../../components/modal";
import { loadStripe } from "@stripe/stripe-js";
import APP_CONSTANTS from "../../constants/index";
import { Elements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import axios from "axios";

import { logOutUser } from "../../reduxStore/actions/auth";
import {logFirebaseEvent} from "../../utils/common";

import Loader from "../../components/loader";

class AddNewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: "",
      cardSavedPopUp: false,
      defCardStatus: "",
      stripePromise: loadStripe(APP_CONSTANTS.stripeKey),
      modalText: "",
      cardPopUpRoute: "",
      isProcessing: false
    };
  }

  componentDidMount() {
    logFirebaseEvent("Add New Card Page");
    window.scrollTo(0, 0);
  }

  onFormSubmit = formValues => {
    // let apiCallObj = {
    //   reqType: "POST",
    //   reqURL: addNewCardApiRoute,
    //   dataObj: {
    //     stripeToken: formValues.token.id,
    //     defaultStatus: this.state.defCardStatus ? 1 : 0
    //   },
    //   params: {},
    //   headers: {},
    //   successCallback: res => {
    // this.setState({
    //   cardSavedPopUp: true
    //     })
    //   },
    //   failureCallback: err => {
    //     console.log(err);
    //   },
    //   showPopUp: false
    // };
    // this.setState({
    //   apiCall: apiCallObj,
    // });
    this.setState({
      isProcessing: true
    });
    let headers = {
      Authorization: "Bearer " + this.props.auth.user[0].accessToken
    };
    let data = {
      stripeToken: formValues.token.id,
      defaultStatus: this.state.defCardStatus ? 1 : 0
    };
    axios({
      method: "POST",
      url: addNewCardApiRoute,
      headers: headers,
      data: data
    })
      .then(res => {
        this.setState({
          modalText: "Card Saved Successfully",
          cardPopUpRoute: appRoutes.savedCards,
          cardSavedPopUp: true,
          isProcessing: false
        });
      })
      .catch(err => {
        console.log("Got the error here: ", err.response);
        this.setState({
          isProcessing: false
        });
        if (err.response) {
          if (
            err.response.data.statusCode === 401 &&
            err.response.data.error === "Unauthorized"
          ) {
            this.setState({
              modalText: "Unauthorized Request",
              cardPopUpRoute: "",
              cardSavedPopUp: true
            });
          } else if (
            err.response.data.statusCode === 403 &&
            err.response.data.type === "SESSION_EXPIRED"
          ) {
            this.setState({
              modalText: "Session has expired",
              cardPopUpRoute: "",
              cardSavedPopUp: true
            });

            setTimeout(() => {
              this.props.logOut();
            }, 1500);
          } else {
            console.log("Got the message here: ", err.response);
            let popUpErrMsg = err.response.data.message;
            console.log("Error message: ", popUpErrMsg);
            // popUpErrMsg = popUpErrMsg.substr(0, popUpErrMsg.indexOf("."));
            // if (popUpErrMsg.includes("Insufficient")) popUpErrMsg += "s";
            // else if (popUpErrMsg.includes("Security Code"))
            //   popUpErrMsg = popUpErrMsg.replace("Security Code", "CVC");
            this.setState({
              modalText: popUpErrMsg,
              cardPopUpRoute: "",
              cardSavedPopUp: true
            });
          }
        }
      });
  };

  setDefCardStatus = status => {
    this.setState({
      defCardStatus: status
    });
  };

  closecardSavedPopUp = () => {
    this.setState({
      cardSavedPopUp: false
    });
    if (this.state.cardPopUpRoute != "")
      this.props.history.push(this.state.cardPopUpRoute);
  };

  render() {
    return (
      <div
        className="container
        h-100 bg-white pb-5 mb-5 addNewCard"
      >
        <Loader loading={this.state.isProcessing} />
        <div className="row">
          <div className="col-sm-12 mt-4">
            <NavLink to={appRoutes.savedCards}>
              <i title="" className="fa fa-arrow-left primary-color" />
              <Label
                title="Back to Saved Cards"
                className="cursor-pointer font-15 on-hover-orange pl-2"
              />
            </NavLink>
          </div>
          <div className="col-sm-12 mt-4 ml-3 pb-3">
            <Label title="Add New Card" className="big" />
          </div>
          <div className="border-bottom w-100" />
          <Elements stripe={this.state.stripePromise}>
            <NewCardForm
              onFormSubmit={this.onFormSubmit}
              history={this.props.history}
              defCardStatus={this.setDefCardStatus}
            />
          </Elements>
        </div>
        <MakeApiCall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent={this.state.modalText}
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closecardSavedPopUp}
          openModal={this.state.cardSavedPopUp}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ...state.modal
});

const mapDispatchToProps = dispatch => ({
  logOutUser: () => dispatch(logOutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCard);
