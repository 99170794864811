import { put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";
import { LOGIN } from "../constants/auth";
import { loginUserSuccess, loginUserError } from "../actions/auth";
import {constApiRoute, login} from "../../apiRoutes";
import setAuthToken from "../../utils/setAuthToken";
import {logFirebaseEvent} from "../../utils/common";

function* loginUser(action) {
  try {
    const response = yield axios
      .post(login, action.payload)
      .then(res => res)
      .catch(err => err.response);
    const constResponse = yield axios
        .get(constApiRoute)
        .then(res => res)
        .catch(err => err.response);
    const authConst = [response.data.data, constResponse.data.data];
    console.log(response.data);
    const message = response.data && response.data.message ? response.data.message : "Please contact Instant Consult Support on 1300 003 310";
    if (response.status === 200) {
      logFirebaseEvent("User Logged In");
      setAuthToken(response.data.data.accessToken);
      yield put(loginUserSuccess(authConst));
    } else if (response.status === 400 || response.status === 401) {
      yield put(loginUserError(response.data.message));
    } else if(response.status === 403) {
      yield put(loginUserError(message))
    } else if (response.status === 500) {
      yield put(loginUserError(message));
    }
  } catch (error) {
    yield put(loginUserError("Some error occurred. Please try again later."));
    if (process.env.NODE_ENV !== "production") {
      console.log(error);
    }
  }
}

export default function* authSaga() {
  yield all([takeLatest(LOGIN, loginUser)]);
}
