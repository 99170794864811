import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import Button from "../../components/button";
import Error from "../../components/error";

const CovidCriteriaForm = props => {
  const [showSelectInput, setShowSelectInput] = useState(false);

  document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);

  const generateOptions = () => {
    let menuItems = [];
    props.covidHotSpots.map((val, index) => {
      return menuItems.push(
        <MenuItem style={{ fontSize: "14px" }} key={index} value={val.name}>
          {val.name}
        </MenuItem>
      );
    });
    return menuItems;
  };

  const formik = useFormik({
    initialValues: {
      selectedRadioOption: "",
      selectedCovidLocation: ""
    },
    validationSchema: Yup.object({
      selectedRadioOption: Yup.string().required("Please select one option"),
      selectedCovidLocation: Yup.string().when("selectedRadioOption", {
        is: selectedRadioOption =>
          selectedRadioOption ===
          "Patient declared they are living in a COVID-19 impacted area:",
        then: Yup.string().required("Please select COVID-19 hot spot")
      })
    }),
    onSubmit: values => {
      if (
        values.selectedRadioOption ===
        "Patient declared they are living in a COVID-19 impacted area:"
      ) {
        var hotspotIndex = props.covidHotSpots.findIndex(x => x.name ===values.selectedCovidLocation);
        var areaType = props.covidHotSpots[hotspotIndex].type
        var isAreaHotspot = false
        if(areaType == "medicare") {
          isAreaHotspot = true
        }
        props.onFormSubmit(
          values.selectedRadioOption + " " + values.selectedCovidLocation,
          true,
          isAreaHotspot
        );
      }
      else if (
        [
          "Patient declared they have been tested for COVID-19 and are awaiting results",
          "Patient declared they have been diagnosed with COVID-19 and are isolating at home",
          "Patient declared they have entered a state/territory with border restrictions requiring them to self-isolate upon arrival",
          "Patient declared they are experiencing homelessness"
        ].includes(values.selectedRadioOption)
      ) {
        props.onFormSubmit(values.selectedRadioOption, true);
      } else {
        props.onFormSubmit(values.selectedRadioOption, false);
      }
      //  else if (
      //   [
      //     "Patient declared as under 12 months of age",
      //     "Patient declared they are homeless"
      //   ].includes(values.selectedRadioOption)
      // ) {
      //   props.onFormSubmit(values.selectedRadioOption, true);
      // } else {
      //   props.onFormSubmit(values.selectedRadioOption, false);
      // }
    }
  });
  return (
    <form
      className="d-flex flex-column text-left"
      onSubmit={formik.handleSubmit}
    >
      <FormControl component="fieldset" className="ICRadioBtn">
        <p className="label-text">{props.covidHotSpotsVisibility ? "Select which applies:" : "Tick which applies to check your eligibility for a bulk-billed consult:"}</p>
        <Error>
          {formik.touched.selectedRadioOption &&
          formik.errors.selectedRadioOption
            ? formik.errors.selectedRadioOption
            : ""}
        </Error>
        <RadioGroup
          name="selectedRadioOption"
          value={formik.values.selectedRadioOption}
          onChange={e => {
            formik.handleChange(e);
            if (
              e.target.value ===
              "Patient declared they are living in a COVID-19 impacted area\:"
            ) {
              setShowSelectInput(true);
            } else {
              setShowSelectInput(false);
            }
          }}
        >
          {props.covidHotSpotsVisibility === true && (
          <FormControlLabel
            value="Patient declared they are living in a COVID-19 impacted area:"
            control={<Radio />}
            label="I declare I am living in a COVID-19 impacted area"
          />
          )}
           {props.covidHotSpotsVisibility === true && ( 
            <FormControl
              className="ic-select w-100"
              name="selectedCovidLocation"
            >
              <InputLabel
                style={{ position: "inherit", fontSize: "14px" }}
                id="covid-hot-spot-label"
              >
                Select COVID-19 impacted area
              </InputLabel>
              <Select
                labelId="covid-hot-spot-label"
                className="mb-2 mt-0"
                value={formik.values.selectedCovidLocation}
                displayEmpty
                onChange={event => {
                  formik.setFieldValue(
                    "selectedCovidLocation",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.selectedCovidLocation &&
                  formik.errors.selectedCovidLocation
                    ? true
                    : false
                }
                helperText={
                  formik.touched.selectedCovidLocation &&
                  formik.errors.selectedCovidLocation
                    ? formik.errors.selectedCovidLocation
                    : ""
                }
              >
                {props.covidHotSpots && props.covidHotSpots.length
                  ? generateOptions()
                  : ""}
              </Select>
            </FormControl>
           )} 
           <FormControlLabel
            value="Patient declared they have been tested for COVID-19 and are awaiting results"
            control={<Radio />}
            label="I declare I have been tested for COVID-19 and am awaiting my results"
            className="mr-0"
          />
          <FormControlLabel
            value="Patient declared they have been diagnosed with COVID-19 and are isolating at home"
            control={<Radio />}
            label="I declare I have been diagnosed with COVID-19 and am isolating at home"
            className="mr-0"
          />
          <FormControlLabel
            value="Patient declared they have entered a state/territory with border restrictions requiring them to self-isolate upon arrival"
            control={<Radio />}
            label="I declare I have entered a state/territory with border restrictions requiring me to self-isolate upon arrival"
            className="mr-0"
          />
          <FormControlLabel
            value="Patient declared they are experiencing homelessness"
            control={<Radio />}
            label="I declare I am experiencing homelessness"
            className="mr-0"
          /> 
          {/* <FormControlLabel
            value="Patient declared as under 12 months of age"
            control={<Radio />}
            label="I declare the patient is under 12 months of age"
          />
          <FormControlLabel
            value="Patient declared they are homeless"
            control={<Radio />}
            label="I declare I am homeless"
          /> */}
          <FormControlLabel
            value="None of these apply to me"
            control={<Radio />}
            label={props.covidHotSpotsVisibility ? "None of these apply to me":"None of these apply to me (private fee will be applicable)"}
            className="mr-0"
          />
        </RadioGroup>
      </FormControl>
      <br />
      <div className="d-flex flex-row align-items-center justify-content-center">
        <Button className="mt-2" title="Submit" type="submit" />
      </div>
    </form>
  );
};

export default CovidCriteriaForm;

CovidCriteriaForm.defaultProps = {
  onFormSubmit: () => {},
  covidHotSpots: []
};

CovidCriteriaForm.propTypes = {
  onFormSubmit: PropTypes.func,
  covidHotSpots: PropTypes.array
};
