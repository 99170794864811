import React, { Component } from "react";

import PublicLayout from "../../containers/Layout/PublicLayout";
import RecoverPasswordForm from "../../containers/RecoverPassword";

import { updatePasswordApiRoute } from "../../apiRoutes";

import LogoImg from "../../assets/images/group-3@3x.png";

import MakeAPICall from "../../apiCalls/common";

import CustomModal from "../../components/customModal";
import appRoutes from "../../routes/appRoutes";
import {logFirebaseEvent} from "../../utils/common";

class RecoverPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMsg: "",
      errorMsg: "",
      onApiSuccess: false,
      urlToken: ""
    };
  }
  onFormSubmit = async formValues => {

    let formData = new FormData();
    formData.append("password", formValues.password);

    //Need to get the token from the URL here
    formData.append("token", this.state.urlToken);

    let apiCallObj = {
      reqType: "PUT",
      reqURL: updatePasswordApiRoute,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: async res => {this.setState({onApiSuccess: true})},
      failureCallback: async err => {},
      showPopUp: true
    };
    this.setState({ apiCall: apiCallObj });
  };

  componentDidMount() {
    logFirebaseEvent("Recover Password Page");
    window.scrollTo(0, 0);
    let urlGot = document.URL;
      let access_token = urlGot.split("token=")[1];
      if (access_token) {
        this.setState({
          urlToken: access_token.split("&")[0]
        })
      }
  }

  routeOnSuccess = () => {
    this.props.history.push(appRoutes.login)
  }

  render() {
    return (
      <PublicLayout>
        <div className="login-page-container container d-flex align-items-center justify-content-center">
          <div className="lg-page-inner-container frgt-pass-form-width">
            <div className="lp-header row">
              <div className="col-md-12">Recover Password</div>
            </div>
            <div className="row lp-content-container d-flex justify-content-center align-items-center">
              <div className="col-md-12 logo-img-container d-flex justify-content-center align-items-center">
                <img src={LogoImg} alt="logo-img" />
              </div>
              <div className="col-md-12 d-flex justify-content-center align-items-center lg-form-container mt-4 pt-1">
                <RecoverPasswordForm
                  onFormSubmit={this.onFormSubmit}
                  serverErrorMsg={this.state.errorMsg}
                  successMsg={this.state.successMsg}
                />
              </div>
            </div>
          </div>
        </div>
        <MakeAPICall apiParam={this.state.apiCall} />
        <CustomModal
            openModal={this.state.onApiSuccess}
            modalBodyContent="New Password Updated Successfully"
            cancelText="Okay"
            title="Instant Consult"
            useOneButton={true}
            cancelEvent={this.routeOnSuccess}
            backdrop="static"
          />
      </PublicLayout>
    );
  }
}

export default RecoverPasswordPage;
