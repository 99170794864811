import React, { Component } from "react";
import Label from "../../components/label";
import appRoutes from "../../routes/appRoutes";
import { NavLink } from "react-router-dom";
import Modal from "../../components/modal";
import { blockDoctorApiRoute } from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import moment from "moment";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { logFirebaseEvent } from "../../utils/common";

class ConsultHistoryDetails extends Component {
  constructor(props) {
    super(props);
    let propsConst = this.props.location.state.consultDetails;
    let minutes = Math.floor(propsConst.consultDuration / 60000);
    let seconds = ((propsConst.consultDuration % 60000) / 1000).toFixed(0);
    let duration = "";
    if (minutes === 0 || minutes === 1)
      duration = minutes + "min " + seconds + "secs";
    else duration = minutes + "mins " + seconds + "secs";
    this.state = {
      apiCall: "",
      blockDoctorPopUp: false,
      consultDate: moment(propsConst.createdAt).format("Do MMMM YYYY hh:mm A"),
      consultID: propsConst.appointmentId,
      consultCost: "$" + propsConst.totalPaidPrice.toFixed(2),
      couponUsed: propsConst.patientCouponId !== undefined ? true : false,
      receiptUrl: propsConst.receiptUrl,
      docID: propsConst.doctorId._id,
      docImg: propsConst.doctorId.profilePic.original,
      docName:
        "Dr " +
        propsConst.doctorId.firstName +
        " " +
        propsConst.doctorId.lastName,
      docAddress:
        propsConst.doctorId.address.suburb +
        " " +
        propsConst.doctorId.address.state +
        " " +
        propsConst.doctorId.address.postCode,
      docRatings: propsConst.rating ? propsConst.rating : 0,
      natureOfConsult: propsConst.consultId.consultName,
      consultPic: propsConst.consultId.consultPic.original,
      durationOfConsult: duration,
      images: propsConst.consultImgs,
      documents: propsConst.consultDocs,
      additionalInfo: propsConst.consultInfo,
      patientCouponId: propsConst.patientCouponId,
      appointmentPrice: propsConst.appointmentPrice,
      isBlocked: propsConst.isBlocked,
      bulkType:
        propsConst.isBulkBilled !== undefined &&
          propsConst.isBulkBilled === true
          ? true
          : false,
      itemNo:
        propsConst.consultDetailMedicareId !== undefined
          ? propsConst.consultDetailMedicareId.mbsItemNo
          : "",
      formUrl: propsConst.bbDB4FormLink ? propsConst.bbDB4FormLink : null,
      patientName: propsConst.patientId ? (propsConst.patientId.firstName + ' ' + propsConst.patientId.lastName) : null,
    };
  }

  blockDoctorPopUp = () => {
    const cValue = this.state.blockDoctorPopUp;
    this.setState({
      blockDoctorPopUp: !cValue
    });
  };

  closeBlockDoctorPopUp = () => {
    this.setState({
      blockDoctorPopUp: false
    });
  };

  handleBlockDoctor = () => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: blockDoctorApiRoute,
      dataObj: {},
      params: { doctorId: this.state.docID, isBlocked: !this.state.isBlocked },
      headers: {},
      successCallback: res => {
        this.setState({
          isBlocked: !this.state.isBlocked
        });
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallParam,
      blockDoctorPopUp: false
    });
  };

  componentDidMount() {
    logFirebaseEvent("Consult History Details Page");
    console.log("C H: ", this.props);
    window.scrollTo(0, 0);
  }

   getCouponPrice(data) {
    let calculatedDiscountAmount;
    if (data.patientCouponId.isPercent) {
      calculatedDiscountAmount = data.patientCouponId.amount * data.appointmentPrice / 100;
    } else {
      calculatedDiscountAmount = data.patientCouponId.amount;
    }


     let paidAmount = data.appointmentPrice - calculatedDiscountAmount;
     if (paidAmount <= 0) {
       paidAmount = 0;
     }
     return "$" + (paidAmount).toFixed(2);
  }

  render() {
    return (
      <div className="row bg-white rounded p-3 notification-page">
        <div className="col-sm-12 mt-4">
          <NavLink to={appRoutes.consultHistory}>
            <i title="" className="fa fa-arrow-left primary-color" />
            <Label
              title="Back to Consult History"
              className="cursor-pointer font-15 on-hover-orange pl-2"
            />
          </NavLink>
        </div>
        <div className="col-sm-4 p-3">
          <Label title="Consult Details" className="big" />
        </div>
        <div className="border-bottom w-100" />
        <div className="col-sm-12 mt-4">
          <Label
            title={this.state.patientName || ''}
            className="secondary-font font-17 gray w-100 primary-color"
          />
          <Label className="gray font-15" title={this.state.consultDate} />
          <Label
            className="gray secondary-font font-17 pt-2 float-right"
            title={
              this.state.bulkType
                ? "Bulk-Billed"
                : this.state.couponUsed === true
                  ? this.getCouponPrice(this.state)
                  : this.state.consultCost
            }
          />
        </div>
        <div className="col-sm-12 mt-3">
          <Label
            className="secondary-font font-17"
            title={"Consult ID: " + this.state.consultID}
          />
          {this.state.bulkType ? (
            ""
          ) : (
            <span
              onClick={
                this.state.receiptUrl
                  ? () => window.open(this.state.receiptUrl)
                  : ""
              }
            >
              <Label
                className={
                  this.state.receiptUrl
                    ? "gray font-15 float-right cursor-pointer on-hover-orange"
                    : "primary-color font-15 float-right"
                }
                title={this.state.receiptUrl ? "View Receipt" : "Not Available"}
              />
            </span>
          )}
        </div>
        <div className="border-bottom w-100 mt-2 mb-2" />
        <div className="col-sm-12 mt-2">
          <Label className="secondary-font font-15" title="You Consulted" />
        </div>
        <div className="col-sm-2 pr-0">
          <img
            src={this.state.docImg}
            alt="no-img-instant-consult"
            className="img-thumbnail cnslt-hist-det-img rounded-circle border-0 p-0 "
          />
        </div>
        <div className="col-sm-4 dr-text-style mt-3">
          <Label
            title={this.state.docName}
            className="secondary-font font-17 gray w-100"
          />
          <Label
            title={this.state.docAddress}
            className="secondary-font font-17 gray w-100"
          />
          <div className="w-100">
            <Box component="fieldset" borderColor="transparent">
              <Rating
                name="doctor-rating"
                precision={0.5}
                value={parseFloat(this.state.docRatings).toFixed(2)}
                readOnly
              />
            </Box>
          </div>
          {/*<IconButton*/}
          {/*  title={this.state.isBlocked ? "Blocked":"Block" }*/}
          {/*  clickEvent={this.blockDoctorPopUp}*/}
          {/*  className={this.state.isBlocked ?"mt-2 custom-block-btn font-12":"mt-2 custom-block-btn font-12 gray-outline"}*/}
          {/*  icon="fas fa-ban"*/}
          {/*/>*/}
          {/* {this.state.isBlocked ? (
            <div className="blocked-doc-btn mt-2">
              <button type="button" onClick={this.blockDoctorPopUp}>
                <i className="fas fa-ban" />
                BLOCKED
              </button>
            </div>
          ) : (
            <div className="unblocked-doc-btn mt-2">
              <button type="button" onClick={this.blockDoctorPopUp}>
                <i className="fas fa-ban" />
                BLOCK
              </button>
            </div>
          )} */}
        </div>
        <div className="border-bottom w-100 mt-3 mb-3" />
        <div className="col-sm-5">
          <Label
            title="Nature of Consult"
            className="font-17 secondary-font gray"
          />
        </div>
        <div className="col-sm-5 offset-sm-2">
          <Label
            title={this.state.bulkType ? "Item No" : "Duration of Consult"}
            className="font-17 secondary-font gray"
          />
        </div>
        <div className="col-sm-5">
          <img
            src={this.state.consultPic}
            alt="medical-certificate-instant-consult-attachment"
            className="nature-cnslt-img"
          />
          <Label
            title={this.state.natureOfConsult}
            className="pl-1 primary-color"
          />
        </div>
        <div className="col-sm-5 offset-sm-2">
          <Label
            title={
              this.state.bulkType
                ? this.state.itemNo
                : this.state.durationOfConsult
            }
            className="primary-color"
          />
        </div>
        <div className="border-bottom w-100 m-3" />
        <div className="col-sm-12">
          <Label title="Images/Documents" className="font-17 secondary-font" />
        </div>
        <div className="col-sm-12">
          {this.state.images.length === 0 &&
            this.state.documents.length === 0 && (
              <Label title="No Images/Documents" className="gray" />
            )}
          {this.state.images.length > 0 &&
            this.state.images.map((element, index) => {
              let pic = element.original.split(".");
              return (
                <div className="float-left m-3 position-relative" key={index}>
                  {pic[pic.length - 1] !== "pdf" && (
                    <img
                      src={element.original}
                      alt="doc-img"
                      className="img-thumbnail att-cnslt-fixed-size"
                    />
                  )}
                  {pic[pic.length - 1] === "pdf" && (
                    <iframe
                      title="pdfFrame"
                      src={element.original}
                      alt="no-img-instant-consult"
                      className="img-thumbnail att-cnslt-fixed-size"
                    />
                  )}
                </div>
              );
            })}
          {this.state.documents.length > 0 &&
            this.state.documents.map((element, index) => {
              let pic = element.original.split(".");
              return (
                <div className="float-left m-3 position-relative" key={index}>
                  {pic[pic.length - 1] !== "pdf" && (
                    <img
                      src={element.original}
                      alt="doc-img"
                      className="img-thumbnail att-cnslt-fixed-size"
                    />
                  )}
                  {pic[pic.length - 1] === "pdf" && (
                    <iframe
                      title="pdfFrame"
                      src={element.original}
                      alt="no-img-instant-consult"
                      className="img-thumbnail att-cnslt-fixed-size"
                    />
                  )}
                </div>
              );
            })}
        </div>
        <div className="border-bottom w-100 m-3" />
        <div className="col-sm-12">
          <Label
            title="Additional Information"
            className="font-17 secondary-font"
          />
        </div>
        <div className="col-sm-12">
          <Label
            className="gray"
            title={
              this.state.additionalInfo !== ""
                ? this.state.additionalInfo
                : "No Additional Information"
            }
          />
        </div>
        <div className="border-bottom w-100 m-3" />
        <div className="col-sm-12">
          {this.state.bulkType ? (
            <Label
              title="Bulk-Bill Assignment of Benefit Form DB4"
              className="font-17 secondary-font"
            />
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-12">
          {this.state.bulkType ? (
            <span
              onClick={
                this.state.formUrl ? () => window.open(this.state.formUrl) : ""
              }
            >
              <Label
                className={
                  this.state.formUrl
                    ? "gray cursor-pointer on-hover-orange"
                    : "gray"
                }
                title={this.state.formUrl ? "View Form" : "Not Available"}
              />
            </span>
          ) : (
            ""
          )}
        </div>
        {this.state.bulkType ? <div className="border-bottom w-100 m-3" /> : ""}
        <Modal
          modalBodyContent={
            this.state.isBlocked
              ? "Do you want to unblock this Practitioner?"
              : "Do you want to block this Practitioner?"
          }
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={this.handleBlockDoctor}
          cancelEvent={this.closeBlockDoctorPopUp}
          openModal={this.state.blockDoctorPopUp}
        />
      </div>
    );
  }
}

export default ConsultHistoryDetails;
