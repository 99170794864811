//Login
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

//Logout
export const LOGOUT = "LOGOUT";

//Dismiss
export const DISMISS_MESSAGE = "DISMISS_MESSAGE";

//IHIInfo
export const SET_IHI_INFO = "SET_IHI_INFO";
