import { getFormattedDate } from "./dateTimeHelper";

export const searchIHICriteriaType = {
  IHINumber: "IHI_NUMBER",
  Medicare: "MEDICARE",
  address: "ADDRESS"
};

const checkValidInputOfSearchIHI = values => {
  const isValidInput = {
    basicInfo: false,
    IHINumber: false,
    Medicare: false,
    address: false
  };

  const { fname, lname, dob, gender } = values;
  const { IHINumber, medNo, refNo, un, streetName } = values;

  const emptyElementArray = checkEmptyElementInObj({
    fname,
    lname,
    dob,
    gender
  });

  if (emptyElementArray.length === 0) {
    isValidInput.basicInfo = true;
  }

  if (IHINumber && IHINumber.length === 16) {
    isValidInput.IHINumber = true;
  }

  let medicareNumber = medNo;
  if (medicareNumber.length > 0) {
    // remove space in medicareNumber
    medicareNumber = medicareNumber.replace(/\s+/g, "");
  }
  if (medicareNumber && refNo && medicareNumber.length === 10) {
    isValidInput.Medicare = true;
  }

  let streetNumber;
  if (un.includes("/")) {
    let spl = un.split("/");
    streetNumber = spl[1].trim();
  } else {
    streetNumber = un.trim();
  }
  if (streetName && streetNumber) {
    isValidInput.address = true;
  }

  return isValidInput;
};

const getSearchIHIPayload = (type, values = {}) => {
  let DOB = values.dob;
  if (!DOB) {
    DOB = values.dateOfBirth ? getFormattedDate(values.dateOfBirth) : "";
  }

  const payload = {
    firstName:
      values.firstNameForHIService || values.fname || values.firstName || "",
    lastName:
      values.lastNameForHIService || values.lname || values.lastName || "",
    DOB,
    gender: values.gender || ""
  };

  switch (type) {
    case searchIHICriteriaType.IHINumber:
      payload.IHINumber = values.IHINumber;
      break;

    case searchIHICriteriaType.Medicare:
      let medicareNumber = values.medNo || values.medicareNumber || "";
      if (medicareNumber.length > 0) {
        // remove space in medicareNumber
        medicareNumber = medicareNumber.replace(/\s+/g, "");
      }
      payload.medicareNumber = medicareNumber;
      payload.medicareReferenceNumber =
        values.refNo || values.refNumerOnMedicareCard || "";
      break;

    case searchIHICriteriaType.address:
      let streetNumber;
      if (values.un && values.un.includes("/")) {
        let spl = values.un.split("/");
        streetNumber = spl[1].trim();
      } else {
        streetNumber = values.un ? values.un.trim() : "";
      }
      payload.state = values.state || "";
      payload.postcode = values.postCode || "";
      payload.suburb = values.suburb || "";
      payload.streetNumber = streetNumber || "";
      payload.streetName = values.streetName || "";
      break;

    default:
      break;
  }

  return payload;
};

const checkIsValidCheckDigit = HINum => {
  if (typeof HINum !== "string" && typeof HINum !== "number") {
    return false;
  } else if (typeof HINum === "number") {
    HINum = HINum.toString();
  }

  if (HINum.length !== 16) return false;

  const HINumDigitArr = HINum.slice(0, 15).split("");
  let sum = 0;
  HINumDigitArr.forEach((item, index) => {
    if (index % 2 === 0) {
      sum += item * 2 > 9 ? item * 2 - 9 : item * 2;
    } else {
      sum += item * 1;
    }
  });

  let checkDigit = 10 - (sum % 10);
  if (checkDigit === 10) checkDigit = 0;

  return parseInt(HINum[15]) === checkDigit;
};

const checkEmptyElementInObj = obj => {
  const emptyKeyArray = [];
  if (!obj || typeof obj !== "object" || Object.keys(obj).length === 0)
    return emptyKeyArray;

  const objKeyValueArray = Object.entries(obj);
  objKeyValueArray.forEach(item => {
    if (typeof item[1] === "number" || typeof item[1] === "boolean") return;
    if (!item[1] || (typeof item[1] === "object" && item[1].length === 0)) {
      return emptyKeyArray.push(item[0]);
    }
    if (typeof item[1] === "object" && Object.keys(item[1]).length === 0) {
      return emptyKeyArray.push(item[0]);
    }
    if (!item[1]) emptyKeyArray.push(item[0]);
  });

  return emptyKeyArray;
};

export default {
  checkValidInputOfSearchIHI,
  searchIHICriteriaType,
  getSearchIHIPayload,
  checkIsValidCheckDigit
};
