import React from "react";
import PropTypes from "prop-types";

const RefreshButton = props => (
  <button
    disabled={props.disabled}
    type="button"
    className={"btn btn-edit ic-refresh-btn " + props.className}
    onClick={props.onClick}
  >
    <i className="fas fa-sync-alt" />
  </button>
);

export default RefreshButton;

RefreshButton.defaultProps = {
  className: "",
  onClick: () => {}
};

RefreshButton.prototype = {
  className: PropTypes.string,
  onClick: PropTypes.func
};
