import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import appRoutes from "../../routes/appRoutes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import ICCheckbox from "../../components/form/checkbox";
import Anchor from "../../components/anchor";
import Label from "../../components/label";
import ICSelect from "../../components/form/selectInput";
import ICDateInput from "../../components/form/dateInput";
import DefaultImage from "../../assets/images/default-user-img.png";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Error from "../../components/error";
import moment from "moment";
import InputMask from "react-input-mask";
import APP_CONSTANTS from "../../constants";
import CustomModal from "../../components/customModal";
import { checkIsMedicareCheckDigitValid } from "../../utils/MedicareCardUtil";
import { IHIStates } from "../../utils/common";
import HIServiceUtil, {
  searchIHICriteriaType
} from "../../utils/HIServiceUtil";
import { HIServiceType } from "../../constants/constants";
import { publicRequestHIServiceSearchIHIApiRoute } from "../../apiRoutes";
import RefreshButton from "../../components/refreshButton";
import IHIInfoText from "../../components/ihiInfoText";
import MakeAPICall from "../../apiCalls/common";
import Loader from "../../components/loader";

// Yup.addMethod(Yup.string, "checkAge", function (errorMessage) {
//   console.log("Method Calling this");
//   return this.test(`test-person-age`, errorMessage, function (value) {
//     console.log("Value here: ", value);
//     return moment(value, "DD-MM-YYYY") < moment();
//   });
// });

const SignUpForm = props => {
  const [apiCall, setApiCall] = useState();
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [streetNumber, setStreetNumber] = React.useState("");
  const [streetName, setStreetName] = React.useState("");
  const [suburb, setSuburb] = React.useState("");
  const [state, setState] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [showErrModal, setShowErrModal] = React.useState(false);
  const [errModalText, setErrModalText] = React.useState("");
  const [
    isMedicareCheckDigitValid,
    setIsMedicareCheckDigitValid
  ] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const [apiSearchResult, setAPISearchResult] = React.useState([]);
  const loading = open && options.length === 0 && searchTerm.trim().length > 4;
  const [userSelectedOption, setUserSelectedOption] = React.useState(null);
  const autoCompleteClearBtn = useRef(null);
  const [ihiState, setIhiState] = useState("");
  const [profilePicLargeErr, setProfilePicLargeErr] = React.useState(null);
  const [stateNames, setStateNames] = React.useState({
    "new south wales": "NSW",
    queensland: "QLD",
    "northern territory": "NT",
    "western australia": "WA",
    "south australia": "SA",
    victoria: "VIC",
    "australian capital territory": "ACT",
    tasmania: "TAS"
  });

  const inputFile = useRef(null);
  const onChangeHandler = event => {
    let maxAllowedSize = 800000; //800KB
    if (event.target.files && event.target.files[0]) {
      console.log("Inside change handler");
      let fileSizebig = false;
      console.log(event.target.files[0].size);
      if (event.target.files[0].size > maxAllowedSize) {
        fileSizebig = true;
        setProfilePicLargeErr("Maximum 800K file size is allowed");
      } else {
        fileSizebig = false;
        setProfilePicLargeErr(null);
      }
      console.log("File size is: ", fileSizebig);
      if (fileSizebig === false) {
        setSelectedFile(URL.createObjectURL(event.target.files[0]));
        formik.values.profilePic = event.target.files[0];
        formik.values.isProfilePic = true;
      }
    }
  };

  function getIHIText() {
    if (ihiState === "error") return IHIStates.FAILED;
    if (ihiState === "success") return IHIStates.SUCCESS;
    return IHIStates.NOT_LOADED;
  }

  function getIHIClassname() {
    if (ihiState === "error") return "error col-11 my-auto";
    if (ihiState === "success") return "green col-11 my-auto";
    return "col-11 my-auto";
  }

  const handleFileInput = () => {
    inputFile.current.click();
  };

  const cancelImgBtn = () => {
    setSelectedFile(DefaultImage);
    formik.values.isProfilePic = false;
    formik.values.profilePic = null;
  };

  const addressCbHandler = () => {
    formik.values.addressCheckBox = formik.values.addressCheckBox === false;
    if (formik.values.addressCheckBox === false) {
      formik.setFieldTouched("streetNumber", false);
      formik.setFieldTouched("streetName", false);
      formik.setFieldTouched("suburb", false);
      formik.setFieldTouched("state", false);
      formik.setFieldTouched("postCode", false);
    }
    if (formik.values.addressCheckBox === true) {
      formik.values.streetName = "";
      formik.values.streetNumber = "";
      formik.values.suburb = "";
      formik.values.state = "";
      formik.values.postCode = "";

      setStreetNumber("");
      setStreetName("");
      setSuburb("");
      setState("");
      setPostCode("");

      setSearchTerm("");
      setSearchResult([]);
      setAPISearchResult([]);
      setOpen(false);
      setUserSelectedOption(null);
      document.querySelector(".MuiAutocomplete-clearIndicator").click();
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      gender: "",
      phoneNumber: "",
      streetNumber: "",
      streetName: "",
      suburb: "",
      state: "",
      postCode: "",
      IHINumber: "",
      medicareNumber: "",
      refNumerOnMedicareCard: "",
      medicalInfo: "",
      marketing: "",
      profilePic: null,
      password: "",
      ihiInfo: undefined,
      addressCheckBox: false,
      cEmailAddress: "",
      confirmPassword: "",
      tcCheckBox: false,

      deviceType: "2",
      deviceToken: "e873e0ba-6f46-44f3-b970-ea262fed1c6b",
      appVersion: "100",
      isProfilePic: false,
      timeZone: moment.tz.guess(),
      // DVANumber: "",
      newsletterCheckbox: false
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter the First Name"),
      lastName: Yup.string().required("Please enter the Last Name"),
      dateOfBirth: Yup.string().required("Please select the Birth Date"),
      phoneNumber: Yup.string()
        .matches(
          /([0-9]{4}\s[0-9]{3}\s[0-9]{3})/,
          "Please enter correct Mobile Number"
        )
        .required("Please enter the Mobile Number"),
      gender: Yup.string()
        .notOneOf(["Select Gender"])
        .required("Please select Gender"),
      state: Yup.string()
        .notOneOf(["State"])
        .required("Please select State"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter the Email Address"),
      cEmailAddress: Yup.string()
        .email("Invalid email")
        .oneOf([Yup.ref("email")], "Both Email IDs should match")
        .required("Please confirm Email Address"),
      password: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 15 characters long and should contain one number, one uppercase and one lowercase"
        )
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/,
          "Password should be at least 8 and maximum 15 characters long and should contain one number, one uppercase and one lowercase"
        )
        .required("Please enter the password"),
      confirmPassword: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 15 characters long and should contain one number, one special character, one uppercase and one lowercase"
        )
        .required("Please enter the confirm password")
        .oneOf([Yup.ref("password")], "Both Passwords should match"),
      streetNumber: Yup.string().required("Please enter Unit/Street Number"),
      streetName: Yup.string().required("Please enter Street Name"),
      suburb: Yup.string().required("Please enter Suburb"),
      postCode: Yup.string()
        .matches(/([0-9]{4})/, "Please enter correct Postcode")
        .required("Please enter Postcode"),
      medicareNumber: Yup.string().matches(
        /(^$)|[2-6]{1}[0-9]{3}\s?[0-9]{5}\s?[0-9]{1}/,
        "Please enter 10 digits, with 1st digit ranging from 2 to 6"
      ),
      IHINumber: Yup.string().matches(
        /[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}/,
        "Please enter 16 digits"
      ),
      marketing: Yup.string().required(
        "Please share where did you hear about us."
      ),
      tcCheckBox: Yup.boolean().oneOf([true])
    }),
    onSubmit: values => {
      if (isMedicareCheckDigitValid || !formik.values.medicareNumber) {
        props.onFormSubmit(values);
      } else {
        setShowErrModal(true);
        setErrModalText(
          "The check digit of Medicare Card Number is invalid. Please correct it or remove it and contact our staff for help."
        );
      }
    }
  });

  // validate MedicareCard Number check digit
  useEffect(() => {
    if (formik.values.medicareNumber) {
      const medicareNumber = formik.values.medicareNumber;
      if (typeof medicareNumber === "string") {
        const cardNum = medicareNumber.replace(/\s+/g, "");
        if (
          cardNum.length === 10 &&
          parseInt(cardNum).toString().length === 10
        ) {
          const isCheckDigitValid = checkIsMedicareCheckDigitValid(cardNum);
          setIsMedicareCheckDigitValid(isCheckDigitValid);
          if (!isCheckDigitValid) {
            setShowErrModal(true);
            setErrModalText(
              "The check digit of Medicare Card Number is invalid"
            );
          }
        }
      }
    }
  }, [formik.values.medicareNumber]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const userFindingAddress = event => {
    setSearchTerm(event.target.value);
  };

  const fillSelectedAddressData = id => {
    let apiUniNo = apiSearchResult[id].address.unit
        ? apiSearchResult[id].address.unit
        : "",
      apiStreetNo = apiSearchResult[id].address.houseNumber
        ? (apiUniNo.length ? apiUniNo + "/" : "") +
          apiSearchResult[id].address.houseNumber
        : "",
      apiStreetName = apiSearchResult[id].address.street
        ? apiSearchResult[id].address.street
        : "",
      apiSuburb = apiSearchResult[id].address.district
        ? apiSearchResult[id].address.district
        : "",
      apiState = apiSearchResult[id].address.state
        ? apiSearchResult[id].address.state
        : "",
      apiPostCode = apiSearchResult[id].address.postalCode
        ? apiSearchResult[id].address.postalCode
        : "";
    let updatedStateNameIfRequried = null;
    if (stateNames.hasOwnProperty(apiState.toLowerCase())) {
      updatedStateNameIfRequried = stateNames[apiState.toLowerCase()];
    } else {
      updatedStateNameIfRequried = apiState;
    }
    setStreetNumber(apiStreetNo);
    setStreetName(apiStreetName);
    setSuburb(apiSuburb);
    setState(updatedStateNameIfRequried);
    setPostCode(apiPostCode);
    formik.setFieldValue("streetNumber", apiStreetNo);
    formik.setFieldValue("streetName", apiStreetName);
    formik.setFieldValue("suburb", apiSuburb);
    formik.setFieldValue("state", updatedStateNameIfRequried);
    formik.setFieldValue("postCode", apiPostCode);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.trim().length > 4) {
        const params =
          "?" +
          "q=" +
          encodeURIComponent(searchTerm) + // The search text which is the basis of the query
          "&maxresults=5" + // The upper limit the for number of suggestions to be included
          "&in=countryCode:AUS" +
          "&language=en" +
          "&apikey=" +
          APP_CONSTANTS.hereMAPApiKEY;
        axios({
          method: "GET",
          url: APP_CONSTANTS.hereMAPApi + params,
          timeout: 20000
        })
          .then(async res => {
            let optionsFilter = [],
              suggestionsArray = res.data.items || [];
            for (let i = 0; i < suggestionsArray.length; i++) {
              let suggestion = "";
              suggestion += suggestionsArray[i].address.unit
                ? suggestionsArray[i].address.unit + "/"
                : "";
              suggestion += suggestionsArray[i].address.houseNumber
                ? suggestionsArray[i].address.houseNumber + " "
                : "";
              suggestion += suggestionsArray[i].address.street
                ? suggestionsArray[i].address.street + " "
                : "";
              suggestion += suggestionsArray[i].address.district
                ? suggestionsArray[i].address.district + " "
                : "";
              suggestion += suggestionsArray[i].address.state
                ? suggestionsArray[i].address.state + " "
                : "";
              suggestion += suggestionsArray[i].address.postalCode
                ? suggestionsArray[i].address.postalCode + " "
                : "";

              // suggestion += suggestionsArray[i].address.country
              //   ? suggestionsArray[i].address.country
              //   : "";
              optionsFilter.push({
                id: i,
                name: suggestion
              });
            }
            await setSearchResult([...optionsFilter]);
            await setAPISearchResult(res.data.items);
            if (optionsFilter.length <= 0) {
              setOpen(false);
              setUserSelectedOption(null);
            }
            // setOptions(Object.keys(countries).map(key => countries[key].item[0]));
          })
          .catch(err => {
            setOptions([]);
            setUserSelectedOption(null);
          });
      } else {
        setOpen(false);
        setOptions([]);
        setSearchResult([]);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (searchResult.length) {
      setOptions([...searchResult]);
    } else {
      setOptions([]);
    }
  }, [searchResult]);

  function refreshIHINumber() {

    const medicareNo = formik.values.medicareNumber.trim();
    const medicareRefNo = formik.values.refNumerOnMedicareCard.trim();


    let searchType = searchIHICriteriaType.IHINumber;
    if (medicareNo && medicareRefNo) {
      searchType = searchIHICriteriaType.Medicare;
    }


    const payload = HIServiceUtil.getSearchIHIPayload(
      searchType,
      { ...formik.values, dob: formik.values.dateOfBirth }
    );
    payload.serviceType = HIServiceType.SEARCH_IHI;

    // const formData = new FormData();
    // formData.append("HIInfo", JSON.stringify(payload));
    setApiLoading(true);
    setApiCall({
      reqType: "POST",
      reqURL: publicRequestHIServiceSearchIHIApiRoute,
      dataObj: payload,
      params: {},
      headers: {},
      successCallback: async res => {
        setApiLoading(false);
        const IHIResp = res.data?.data;

        if (!IHIResp) {
          setIhiState("error");
        } else {
          // there is searchIHIResult in response, and there is no serviceMessages in searchIHIResult
          const { isAlert, alertMessage } = IHIResp;
          if (isAlert) {
            setIhiState("error");
            console.error({ IHIError: alertMessage });
          } else {
            formik.setFieldValue("IHINumber", IHIResp.IHINumber);
            formik.setFieldValue("ihiInfo", IHIResp);
            setIhiState("success");
          }
        }
      },
      failureCallback: err => {
        console.log({ err });
        setIhiState("error");
        setApiLoading(false);
      },
      showPopUp: false,
      showErrorRes: false
    });
  }

  return (
    <Fragment>
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        openModal={showErrModal}
        cancelEvent={() => {
          setShowErrModal(false);
          setErrModalText("");
        }}
        modalBodyContent={<p className="text-center mb-0">{errModalText}</p>}
        backdrop="static"
      />
      <form onSubmit={formik.handleSubmit} className="w-100">
        <div className="col-sm-12 mt-3 float-left">
          <Label title="Basic Information" className="big gray" />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="First Name" className="font-15 gray" required={true} />
          <ICInput
            id="firstName"
            name="firstName"
            type="text"
            label=""
            placeholder="First"
            autocomplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={
              formik.touched.firstName && formik.errors.firstName ? true : false
            }
            helperText={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Middle Name" className="font-15 gray" />
          <ICInput
            id="middleName"
            name="middleName"
            autocomplete="new-password"
            type="text"
            label=""
            placeholder="Middle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.middleName}
            error={
              formik.touched.middleName && formik.errors.middleName
                ? true
                : false
            }
            helperText={
              formik.touched.middleName && formik.errors.middleName
                ? formik.errors.middleName
                : ""
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Last Name" className="font-15 gray" required={true} />
          <ICInput
            id="lastName"
            name="lastName"
            autocomplete="new-password"
            type="text"
            label=""
            placeholder="Last"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={
              formik.touched.lastName && formik.errors.lastName ? true : false
            }
            helperText={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""
            }
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Date of Birth"
            className="font-15 gray"
            required={true}
          />
          <ICDateInput
            id="dateOfBirth"
            name="dateOfBirth"
            autocomplete="new-password"
            className="cal-primary-bg pt-6"
            label=""
            maxDate={new Date()}
            minDate={moment("1900", "YYYY").toDate()}
            defaultDate={false}
            handleChange={val => {
              formik.values.dateOfBirth = val;
              formik.setFieldValue(
                "dateOfBirth",
                moment(val).format("DD-MM-YYYY")
              );
              formik.handleChange("dateOfBirth");
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
            error={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? true
                : false
            }
            helperText={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? formik.errors.dateOfBirth
                : ""
            }
          />
          <Label
            title="Ensure this your correct Date of Birth,
          otherwise you will be unable to use our service"
            className="error font-14"
          />
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="Gender" className="font-15 gray" required={true} />
          <ICSelect
            label=""
            placeholder="Select Gender"
            id="gender"
            name="gender"
            useId="_id"
            useValue="val"
            items={[
              { _id: "Male", val: "Male" },
              { _id: "Female", val: "Female" }
            ]}
            value={formik.values.gender}
            onBlur={formik.handleBlur}
            handleChange={val => {
              formik.values.gender = val;
              formik.setFieldValue("gender", val);
              formik.handleChange("gender");
            }}
            error={formik.touched.gender && formik.errors.gender ? true : false}
            helperText={
              formik.touched.gender && formik.errors.gender
                ? formik.errors.gender
                : ""
            }
          />
          {formik.errors.gender && formik.touched.gender ? (
            <Label title="Please select Gender" className="small error" />
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-12 mt-5 float-left">
          <Label title="Contact Information" className="big gray" />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label
            title="Mobile Number"
            className="font-15 gray"
            required={true}
          />
          <InputMask
            mask="0499 999 999"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? true
                : false
            }
            helperText={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="phoneNumber"
                name="phoneNumber"
                label=""
                placeholder="Mobile Number"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="clearfix" />
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Email Address"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="email"
            name="email"
            type="email"
            autocomplete="new-password"
            label=""
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Confirm Email Address"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="cEmailAddress"
            name="cEmailAddress"
            type="email"
            autocomplete="new-password"
            label=""
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cEmailAddress}
            error={
              formik.touched.cEmailAddress && formik.errors.cEmailAddress
                ? true
                : false
            }
            helperText={
              formik.touched.cEmailAddress && formik.errors.cEmailAddress
                ? formik.errors.cEmailAddress
                : ""
            }
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Create Password"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="password"
            name="password"
            type="password"
            autocomplete="new-password"
            label=""
            placeholder="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
            helperText={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Confirm Password"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            autocomplete="new-password"
            label=""
            placeholder="Confirm Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? true
                : false
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : ""
            }
          />
        </div>
        <div className="col-sm-12 mt-5 float-left">
          <Label title="Address Information" className="big gray" />
        </div>
        <div className="col-sm-12 m-0 float-left">
          <Autocomplete
            id="asynchronous-api-call"
            ref={autoCompleteClearBtn}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
              setSearchResult([]);
              setSearchTerm("");
            }}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            getOptionLabel={option => option.name}
            options={options}
            filterOptions={options => options}
            loading={loading}
            noOptionsText="Please type your address"
            onChange={(event, value) => {
              if (value) fillSelectedAddressData(value.id);
            }}
            disabled={formik.values.addressCheckBox ? true : false}
            renderInput={params => (
              <ICInput
                {...params}
                onKeyDown={userFindingAddress}
                placeholder="Search Address..."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
          {/*<ICInput*/}
          {/*  id="searchAddress"*/}
          {/*  name="searchAddress"*/}
          {/*  type="text"*/}
          {/*  label=""*/}
          {/*  placeholder="Search Address..."*/}
          {/*  onChange={formik.handleChange}*/}
          {/*  onBlur={formik.handleBlur}*/}
          {/*  value={formik.values.searchAddress}*/}
          {/*  error={*/}
          {/*    formik.touched.searchAddress && formik.errors.searchAddress*/}
          {/*      ? true*/}
          {/*      : false*/}
          {/*  }*/}
          {/*  helperText={*/}
          {/*    formik.touched.searchAddress && formik.errors.searchAddress*/}
          {/*      ? formik.errors.searchAddress*/}
          {/*      : ""*/}
          {/*  }*/}
          {/*  disabled={formik.values.addressCheckBox ? true : false}*/}
          {/*/>*/}
          {/* <GooglePlaceAutoComplete
          selectedVal={googleValues}
          cbState={formik.values.addressCheckBox}
        /> */}
        </div>
        <div className="col-sm-1 mt-sm-3 float-left" onClick={addressCbHandler}>
          <ICCheckbox
            id="addressCheckBox"
            name="addressCheckBox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.addressCheckBox}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        <div className="float-left mt-4 custom-cb-label-margin">
          <Label title="Manually Enter Address" className="font-14" />
          <span> </span>
          <Label
            title="(I cannot find my address)"
            className="font-italic font-14"
          />
        </div>
        <div className="clearfix" />
        <div className="col-sm-4 m-0 float-left">
          <Label
            title="Unit/Street Number"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="streetNumber"
            name="streetNumber"
            type="text"
            autocomplete="new-password"
            label=""
            placeholder="Unit/Street Number"
            disabled={formik.values.addressCheckBox ? false : true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.addressCheckBox
                ? formik.values.streetNumber
                : streetNumber
            }
            error={
              formik.touched.streetNumber && formik.errors.streetNumber
                ? true
                : false
            }
            helperText={
              formik.touched.streetNumber && formik.errors.streetNumber
                ? formik.errors.streetNumber
                : ""
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Street Name" className="font-15 gray" required={true} />
          <ICInput
            id="streetName"
            name="streetName"
            type="text"
            label=""
            placeholder="Street Name"
            autocomplete="new-password"
            disabled={formik.values.addressCheckBox ? false : true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.addressCheckBox
                ? formik.values.streetName
                : streetName
            }
            error={
              formik.touched.streetName && formik.errors.streetName
                ? true
                : false
            }
            helperText={
              formik.touched.streetName && formik.errors.streetName
                ? formik.errors.streetName
                : ""
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Suburb" className="font-15 gray" required={true} />
          <ICInput
            id="suburb"
            name="suburb"
            type="text"
            autocomplete="new-password"
            label=""
            disabled={formik.values.addressCheckBox ? false : true}
            placeholder="Suburb"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.addressCheckBox ? formik.values.suburb : suburb
            }
            error={formik.touched.suburb && formik.errors.suburb ? true : false}
            helperText={
              formik.touched.suburb && formik.errors.suburb
                ? formik.errors.suburb
                : ""
            }
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="State" className="font-15 gray" required={true} />
          {formik.values.addressCheckBox ? (
            <ICSelect
              label=""
              placeholder="State"
              id="state"
              name="state"
              disabled={formik.values.addressCheckBox ? false : true}
              items={props.states}
              useId="stateId"
              useValue="stateId"
              value={
                formik.values.addressCheckBox ? formik.values.state : state
              }
              onBlur={formik.handleBlur}
              handleChange={val => {
                formik.values.state = val;
                formik.setFieldValue("state", val);
                formik.handleChange("state");
              }}
              error={formik.touched.state && formik.errors.state ? true : false}
              helperText={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : ""
              }
            />
          ) : (
            <ICInput
              id="repStSel"
              name="repStSel"
              type="text"
              value={state}
              label=""
              disabled={formik.values.addressCheckBox ? false : true}
              placeholder="State"
            />
          )}
          {formik.errors.state && formik.touched.state ? (
            <Label title="Please select State" className="small error" />
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="Postcode" className="font-15 gray" required={true} />
          {/*<ICInput*/}
          {/*  id="postCode"*/}
          {/*  name="postCode"*/}
          {/*  type="number"*/}
          {/*  disabled={formik.values.addressCheckBox ? false : true}*/}
          {/*  label=""*/}
          {/*  placeholder="Postcode"*/}
          {/*  onChange={formik.handleChange}*/}
          {/*  onBlur={formik.handleBlur}*/}
          {/*  value={formik.values.addressCheckBox ? formik.values.postCode:postCode}*/}
          {/*  error={*/}
          {/*    formik.touched.postCode && formik.errors.postCode ? true : false*/}
          {/*  }*/}
          {/*  helperText={*/}
          {/*    formik.touched.postCode && formik.errors.postCode*/}
          {/*      ? formik.errors.postCode*/}
          {/*      : ""*/}
          {/*  }*/}
          {/*/>*/}
          <InputMask
            mask="9999"
            value={
              formik.values.addressCheckBox ? formik.values.postCode : postCode
            }
            onChange={formik.handleChange}
            disabled={formik.values.addressCheckBox ? false : true}
            onBlur={formik.handleBlur}
            error={
              formik.touched.postCode && formik.errors.postCode ? true : false
            }
            helperText={
              formik.touched.postCode && formik.errors.postCode
                ? formik.errors.postCode
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="postCode"
                disabled={formik.values.addressCheckBox ? false : true}
                name="postCode"
                label=""
                placeholder="Postcode"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-12 mt-5 float-left">
          <Label title="Medical Information" className="big gray" />
        </div>
        <div className="col-sm-6 m-0 float-left">
          {/*<Label title="Medicare Number" className="font-15 gray" />*/}
          {/*<ICInput*/}
          {/*  id="medicareNumber"*/}
          {/*  name="medicareNumber"*/}
          {/*  type="number"*/}
          {/*  label=""*/}
          {/*  placeholder="Medicare Number"*/}
          {/*  onChange={formik.handleChange}*/}
          {/*  onBlur={formik.handleBlur}*/}
          {/*  value={formik.values.medicareNumber}*/}
          {/*  error={*/}
          {/*    formik.touched.medicareNumber && formik.errors.medicareNumber*/}
          {/*      ? true*/}
          {/*      : false*/}
          {/*  }*/}
          {/*  helperText={*/}
          {/*    formik.touched.medicareNumber && formik.errors.medicareNumber*/}
          {/*      ? formik.errors.medicareNumber*/}
          {/*      : ""*/}
          {/*  }*/}
          {/*/>*/}
          <Label title="Medicare Number" className="font-15 gray" />
          <InputMask
            mask="9999 99999 9"
            value={formik.values.medicareNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.medicareNumber && formik.errors.medicareNumber
                ? true
                : false
            }
            helperText={
              formik.touched.medicareNumber && formik.errors.medicareNumber
                ? formik.errors.medicareNumber
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="medicareNumber"
                name="medicareNumber"
                autocomplete="new-password"
                label=""
                placeholder="Medicare Number"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-6 m-0 float-left">
          <Label
            title="Ref. Number on Medicare Card"
            className="font-15 gray"
          />
          <InputMask
            mask="9"
            value={formik.values.refNumerOnMedicareCard}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.refNumerOnMedicareCard &&
              formik.errors.refNumerOnMedicareCard
                ? true
                : false
            }
            helperText={
              formik.touched.refNumerOnMedicareCard &&
              formik.errors.refNumerOnMedicareCard
                ? formik.errors.refNumerOnMedicareCard
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="refNumerOnMedicareCard"
                name="refNumerOnMedicareCard"
                autocomplete="new-password"
                label=""
                placeholder="Ref. Number on Medicare Card"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>

        <div className="clearfix" />
        {/* <div className="col-sm-12 m-0 float-left">
            <Label
              title="Note: all consults through Instant Consult are not bulk-billed - refer to our FAQs for more detail"
              className="green font-14"
            />
          </div>
          <div className="clearfix" />
        */}

        <div className="row ml-1">
          <div className="col-sm-6 m-0 mt-4 float-left">
            <Label title="IHI Number" className="font-15 gray" />
            <InputMask
              mask="9999 9999 9999 9999"
              value={formik.values.IHINumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.IHINumber && formik.errors.IHINumber
                  ? true
                  : false
              }
              helperText={
                formik.touched.IHINumber && formik.errors.IHINumber
                  ? formik.errors.IHINumber
                  : ""
              }
            >
              {inputProps => (
                <ICInput
                  id="IHINumber"
                  name="IHINumber"
                  label=""
                  placeholder="Individual Healthcare Identifier Number"
                  {...inputProps}
                />
              )}
            </InputMask>
          </div>

          <div className="col-sm-6 m-0 mt-4 float-right">
            <div className="form-group row  pr-1">
              <RefreshButton className="col-1" onClick={refreshIHINumber} />
              <p className={getIHIClassname()}>{getIHIText()}</p>
            </div>
          </div>
        </div>
        <div className="d-block col-6 pt-2 pl-3 pr-1">
          {ihiState === "error" && (
            <>
              <IHIInfoText
                title="Please call 1300 003 310 if your Medicare Details are correct, however your IHI Number could not be generated"
                color="#f5ab35"
              />
              <IHIInfoText
                className="d-block col-12"
                title="Please follow steps below if you do not have Medicare Details"
                color="#00A3E9"
              />
            </>
          )}
        </div>

        <div className="col-sm-12 m-0 mt-4 float-left">
          <Label
            title="If you have a valid IHI Number,
                ensure you input the correct number,
                otherwise you will be unable to obtain:"
            className="government-text-color font-14"
          />
        </div>
        <div className="m-0 float-left ml-4">
          <Label
            title="• Electronic Prescriptions (Prescriptions / eTokens)"
            className="government-text-color font-14"
          />
        </div>

        <div className="col-sm-12 m-0 float-left">
          <Label
            title="Not sure what your IHI Number is?"
            className="government-text-color font-14"
          />
        </div>
        <div className="m-0 float-left ml-3">
          <a
            href="https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier"
            className="government-text-color font-15 w-100"
            target="_blank"
          >
            <b>Visit</b>{" "}
            https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier
          </a>
        </div>
        <div className="clearfix" />

        <div className="col-sm-12 mt-3 float-left">
          <Label title="Medical Info" className="small gray" />
          <ICInput
            id="medicalInfo"
            name="medicalInfo"
            autocomplete="new-password"
            type="text"
            label=""
            placeholder="Any allergies or medical history your doctor should be aware of..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.medicalInfo}
            error={
              formik.touched.medicalInfo && formik.errors.medicalInfo
                ? true
                : false
            }
            helperText={
              formik.touched.medicalInfo && formik.errors.medicalInfo
                ? formik.errors.medicalInfo
                : ""
            }
          />
        </div>

        <div className="col-sm-12 mt-5 float-left">
          <Label title="Marketing" className="big gray" />
        </div>
        <div className="col-sm-12 m-0 float-left">
          <Label
            title="Where did you hear about us?"
            required={true}
            className="font-15 gray"
          />
          <ICInput
            id="marketing"
            name="marketing"
            type="text"
            autocomplete="new-password"
            label=""
            placeholder="Let us know how you found out about us..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.marketing}
            error={
              formik.touched.marketing && formik.errors.marketing ? true : false
            }
            helperText={
              formik.touched.marketing && formik.errors.marketing
                ? formik.errors.marketing
                : ""
            }
          />
        </div>

        <div className="col-sm-12 mt-5 float-left">
          <Label title="Upload" className="big gray" />
        </div>
        <div className="col-sm-12 m-0 float-left">
          <Label title="Profile Pic" className="font-15 gray" />
        </div>
        <div className="row p-2">
          <div className="col-sm-2 mt-2">
            <img
              src={selectedFile ? selectedFile : DefaultImage}
              alt="no-img-instant-consult"
              className="custom-user-img-size img-thumbnail rounded-circle border-0"
            />
          </div>
          <div className="col-sm-7">
            <i
              className="fas fa-file-upload ic-file-upload-icon float-left"
              onClick={handleFileInput}
            />
            <br />
            <input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              id="file"
              name="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onChangeHandler}
            />
            <Label title="Choose File" className="gray mb-0 ml-2" />
            <br />
            <Label
              title="JPG, GIF or PNG. max size of 800K"
              className="gray ml-2"
            />
            <div className="w-100 clearfix-both mt-0" />
            {profilePicLargeErr ? (
              <Label title={profilePicLargeErr} className="error mt-0 pt-0" />
            ) : (
              ""
            )}
            <div className="w-100 clearfix-both" />

            <Button
              title="Cancel"
              clickEvent={cancelImgBtn}
              type="button"
              className="gray-outline text-center font-14"
            />
          </div>
        </div>

        <div className="col-sm-1 mt-sm-3 float-left">
          <ICCheckbox
            name="tcCheckBox"
            id="tcCheckBox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tcCheckBox}
            inputProps={{ "aria-label": "primary checkbox" }}
            label=""
          />
        </div>
        <div className="float-left mt-4 custom-cb-label-margin">
          <Label title="I agree to the" className="font-14" />
          <Anchor
            title="Terms & Conditions"
            className="insta-link-orange font-14"
            href={props.constants ? props.constants.terms : ""}
            target="_blank"
          />
          <br />
          {formik.errors.tcCheckBox && formik.touched.tcCheckBox ? (
            <Label
              title="Please accept our Terms and Conditions"
              className="small error"
            />
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 row">
          <div className="col-sm-1 float-left">
            <ICCheckbox
              name="newsletterCheckbox"
              id="newsletterCheckbox"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newsletterCheckbox}
              inputProps={{ "aria-label": "primary checkbox" }}
              label=""
            />
          </div>
          <div className="float-left mt-2 custom-cb-label-margin">
            <Label
              style={{ marginLeft: "2px" }}
              title="I would like to receive Instant Consult's weekly newsletter"
              className="font-14 mb-0"
            />
            <br />
            <Label
              style={{ marginLeft: "2px" }}
              title="(you can unsubscribe any time)"
              className="small mt-0"
            />
          </div>
        </div>

        {/* 
        <div className="col-sm-6 mt-3 float-left">
          <Label title="DVA Number" className="font-15 gray" />
          <ICInput
            id="DVANumber"
            name="DVANumber"
            type="text"
            label=""
            placeholder="DVA Number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.DVANumber}
            error={
              formik.touched.DVANumber && formik.errors.DVANumber ? true : false
            }
            helperText={
              formik.touched.DVANumber && formik.errors.DVANumber
                ? formik.errors.DVANumber
                : ""
            }
          />
          <br />
        </div>
        */}

        <div className="clearfix" />
        <div className="text-center mt-4">
          <Button type="submit" className="insta-button-primary" />
        </div>
        <div>
          <div className="text-center w-100 mt-4">
            <Label title="Already have an account?" className="font-14 gray" />
            <NavLink
              to={appRoutes.login}
              className="insta-link-orange ml-1 font-15 text-decoration-none"
              href="#"
            >
              Login
            </NavLink>
          </div>
          <Error>{props.serverErrorMsg}</Error>
        </div>
      </form>
      <MakeAPICall apiParam={apiCall} />
      <Loader loading={apiLoading} />
    </Fragment>
  );
};

export default SignUpForm;

SignUpForm.defaultProps = {
  serverErrorMsg: ""
};

SignUpForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
