import React, { Component, Fragment } from "react";
import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import Model from "../../components/modal";
import { internetConnectionFileUpload } from "../../apiRoutes";
import ImageToUpload from "../../assets/images/background-1.jpg";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { homeApiRoute1 } from "../../apiRoutes/index";
import MakeAPICall from "../../apiCalls/common";
import {logFirebaseEvent} from "../../utils/common";
import { setTotalPatientConsultCount, saveHomePageDataForQueueInfo } from "../../reduxStore/actions/common";

class SideNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internetConnectionModelOpen: false,
      poorInternetConnection:
        "Your internet connection is not suitable for a video call. <br/>  Please check your internet connection and try again.",
      internetConnectionModelBody: null,
      testingConnectionLoader: false,
      waitTime: props.commonReducers && props.commonReducers.patientQueueWaitTime ? props.commonReducers.patientQueueWaitTime : 'N/A',
      doctorsOnline: props.commonReducers ? props.commonReducers.onlineDocCount : 0,
      apiCall: ""
    };
  }
  checkInternetConnection = () => {
    this.setState({
      testingConnectionLoader: true
    });

    let startTime = new Date().getTime(),
      file = ImageToUpload + "?" + new Date().getTime();

    axios
      .get(file, {
        responseType: "blob",
        timeout: 20000
      })
      .then(res => {
        let endTime = new Date().getTime(),
          duration = (endTime - startTime) / 1000,
          fileSize = 2900000,
          bitsLoaded = fileSize * 8,
          bps = (bitsLoaded / duration).toFixed(2),
          kbps = (bps / 1024).toFixed(2),
          mbps = (kbps / 1024).toFixed(2);

        let downloadSpeed = mbps;

        this.calculateUploadingSpeed(res, file, downloadSpeed);
      })
      .catch(error => {
        this.setState({
          internetConnectionModelBody: null,
          internetConnectionModelOpen: true,
          testingConnectionLoader: false
        });
      });
  };

  calculateUploadingSpeed = (data, file, downloadSpeed) => {
    logFirebaseEvent("Speed Test")
    let formData = new FormData(),
      startTime1 = new Date().getTime(),
      fileURL = new File([data.data], file, { type: "image/jpeg" });

    formData.append("picture", fileURL, fileURL.name);

    axios
      .post(internetConnectionFileUpload, formData, {
        headers: {
          authorization: "bearer " + this.props.auth.user[0].accessToken,
          "content-type": undefined
        },
        timeout: 20000
      })
      .then(data => {
        let endTime1 = new Date().getTime(),
          duration1 = (endTime1 - startTime1) / 1000,
          fileSize1 = 2900000,
          bitsLoaded1 = fileSize1 * 8,
          bps1 = (bitsLoaded1 / duration1).toFixed(2),
          kbps1 = (bps1 / 1024).toFixed(2),
          mbps1 = (kbps1 / 1024).toFixed(2);

        let uploadSpeed = mbps1,
          minUploadSpeed = 1.0,
          // minDownloadSpeed = 5.0,
          minDownloadSpeed = 4.0,
          dicon = "",
          uicon = "",
          internetStatus = false;

        if (downloadSpeed && uploadSpeed) {
          // Check internet speed here
          if (
            downloadSpeed >= minDownloadSpeed &&
            uploadSpeed >= minUploadSpeed
          ) {
            internetStatus = true;
          } else {
            internetStatus = false;
          }

          // now for icon color
          downloadSpeed < minDownloadSpeed ? (dicon = "bad") : (dicon = "good");
          uploadSpeed < minUploadSpeed ? (uicon = "bad") : (uicon = "good");

          this.setState({
            testingConnectionLoader: false
          });

          // if (internetStatus) {
          //   this.setState({
          //     internetConnectionModelBody: `<i class="fas fa-wifi fa-3x mt-2 good"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes"> <span class="${dicon}"> ${downloadSpeed} mbps DL </span> |  <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p>`,
          //     internetConnectionModelOpen: true
          //   });
          // } else {
          //   this.setState({
          //     internetConnectionModelBody: `<i class="fas fa-wifi fa-3x mt-2 bad"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes">  <span class="${dicon}">  ${downloadSpeed} mbps DL </span> | <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p>`,
          //     internetConnectionModelOpen: true
          //   });
          // }
          if (internetStatus) {
            this.setState({
              internetConnectionModelBody: `<i class="fas fa-wifi fa-3x mt-2 good"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes"> <span class="${dicon}"> ${downloadSpeed} mbps DL </span> |  <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p> <p class="m-0 p-0 mt-4 speedTestReqTitle">Minimum Requirements</p> <p class="p-0 m-0 mt-1 speedTestReqText">4 mbps DL | 1 mbps UL </p> `,
              internetConnectionModelOpen: true
            });
          } else {
            this.setState({
              internetConnectionModelBody: `<i class="fas fa-wifi fa-3x mt-2 bad"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes">  <span class="${dicon}">  ${downloadSpeed} mbps DL </span> | <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p> <p class="m-0 p-0 mt-4 speedTestReqTitle">Minimum Requirements</p> <p class="p-0 m-0 mt-1 speedTestReqText">4 mbps DL | 1 mbps UL </p> <p class="mt-4 font-15"> Your internet speed is very slow. <br/> Please try again when you have <br/> a stronger internet connection. </p> `,
              internetConnectionModelOpen: true
            });
          }
        }
      })
      .catch(err => {
        this.setState({
          internetConnectionModelBody: null,
          internetConnectionModelOpen: true,
          testingConnectionLoader: false
        });
      });
  };

  closeInternetConnectionModel = () => {
    this.setState({
      internetConnectionModelOpen: false
    });
  };

  componentDidMount() {
    let apiCallObj = {
      reqType: "GET",
      reqURL: homeApiRoute1,
      successCallback: res => {
        this.setState({
          waitTime: res.data.data.waitTime,
          doctorsOnline: res.data.data.totalDoctor
        });
        this.setTotalConsultCount(res.data.data.totalConsultsByPatient);
        this.props.saveHomePageDataForQueueInfo({
          patientQueueWaitTime: res.data.data.waitTime,
          onlineDocCount: res.data.data.totalDoctor
        })
      },
      failureCallback: err => {
        //console.log("Got the error : ", err);
      },
      showPopUp: false
    };
    this.setState({ apiCall: apiCallObj });
    // this.callHomePageDataApi();
  }

  setTotalConsultCount = async data => {
    await this.props.setTotalPatientConsultCount(data);
  };

  // callHomePageDataApi = () => {
  //   setInterval(() => {
  //     let apiCallObj = {
  //     reqType: "GET",
  //     reqURL: homeApiRoute1,
  //     successCallback: res => {
  //       this.setState({
  //         waitTime: res.data.data.waitTime,
  //         doctorsOnline: res.data.data.totalDoctor
  //       });
  //       this.setTotalConsultCount(res.data.data.totalConsultsByPatient);
  //     },
  //     failureCallback: err => {
  //       //console.log("Got the error : ", err);
  //     },
  //     showPopUp: false
  //   };
  //   this.setState({ apiCall: apiCallObj });
  //   }, 10000);
  // };

  render() {
    return (
      <Fragment>
        <div className="custom-li list-group">
          <NavLink
            to={appRoutes.requestAConsult}
            className="list-group-item list-group-item-action req-a-consult-btn"
            href="#"
          >
            <i className="fas fa-video font-15 mr-3"></i>
            <Label
              title="Request a Consult"
              className="text-white secondary-font custom-re p-0 m-0 font-15"
            />
          </NavLink>
        </div>
        <div className="custom-li list-group mt-4">
          <a
            href="/"
            className="list-group-item list-group-item-action disabled"
          >
            <i className="fas fa-user-md font-15 mr-3 primary-color"></i>
            {
              this.props.commonReducers && this.props.commonReducers.onlineDocCount !== 0 ? (
                <Label
                  title="DOCTORS ONLINE NOW"
                  className="p-0 m-0 secondary-font font-14 blinking-text"
                />
              ) : (
                <Label
                  title="Doctors Offline"
                  className="p-0 m-0 secondary-font font-14"
                />
              )
            }
            {/* {this.state.doctorsOnline !== 0 && (
              <Label
                title="DOCTORS ONLINE NOW"
                className="p-0 m-0 secondary-font font-14 blinking-text"
              />
            )}
            {this.state.doctorsOnline === 0 && (
              <Label
                title="Doctors Offline"
                className="p-0 m-0 secondary-font font-14"
              />
            )}  */}
          </a>
          <a
            href="/"
            className="list-group-item list-group-item-action disabled"
          >
            <i className="far fa-clock font-15 mr-3 primary-color"></i>
            <Label
              title="Wait Time"
              className="p-0 secondary-font m-0 font-15"
            />
            <Label
              title={
                (this.props.commonReducers && (this.props.commonReducers.patientQueueWaitTime !== null && this.props.commonReducers.patientQueueWaitTime !== undefined && this.props.commonReducers.patientQueueWaitTime !== Infinity && (!Number.isNaN(this.props.commonReducers.patientQueueWaitTime)) && this.props.commonReducers.patientQueueWaitTime != "N/A") ? Math.min(45, this.props.commonReducers.patientQueueWaitTime) + " Mins" : 'N/A')
                // this.state.doctorsOnline !== 0
                //   ? this.state.waitTime + " Mins"
                //   : "N/A"
              }
              className="gray p-0 m-0 secondary-font primary-color font-15 pt-1 float-right"
            />
          </a>
          {/* <a
            href="/"
            className="list-group-item list-group-item-action disabled"
          >
            <i className="fas fa-users font-14 mr-3 primary-color"></i>
            <Label
              title="Position in Queue"
              className="p-0 secondary-font m-0 font-15"
            />
            <Label
              title="1"
              className="gray p-0 m-0 secondary-font primary-color font-15 pt-1 float-right"
            />
          </a> */}
        </div>

        <div className="custom-li list-group mt-4">
          <a
            className="list-group-item custom-sidebar-i list-group-item-action"
            href="#"
            onClick={this.checkInternetConnection}
          >
            <i className="fas fa-wifi font-14 mr-3"></i>
            <Label
              title="Test Connection"
              className="p-0 secondary-font m-0 font-15"
            />
            <CircularProgress
              disableShrink
              className={
                this.state.testingConnectionLoader
                  ? "primary-loader test-connection-loader"
                  : "d-none"
              }
            />
          </a>
        </div>

        <div className="custom-li list-group mt-4">
          <NavLink
            to={appRoutes.notifications}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-bell font-15 mr-3"></i>
            <Label
              title="Notifications"
              className="p-0 secondary-font m-0 font-15"
            />
          </NavLink>
          <NavLink
            to={appRoutes.inbox}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-paperclip font-15 mr-3"></i>
            <Label title="Inbox" className="p-0 secondary-font m-0 font-15" />
          </NavLink>
          <NavLink
            to={appRoutes.consultHistory}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-history font-15 mr-3"></i>
            <Label
              title="Consult History"
              className="p-0 secondary-font m-0 font-15"
            />
          </NavLink>
        </div>

        <div className="custom-li list-group mt-4">
          <NavLink
            to={appRoutes.myProfile}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-user font-15 mr-3"></i>
            <Label title="Profile" className="p-0 secondary-font m-0 font-15" />
          </NavLink>
          <NavLink
            to={appRoutes.savedCards}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-credit-card font-12 mr-3"></i>
            <Label
              title="Saved Cards"
              className="p-0 secondary-font m-0 font-15"
            />
          </NavLink>
          <NavLink
            to={appRoutes.consultCosts}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-dollar-sign font-15 mr-3"></i>
            <Label
              title="Consult Costs"
              className="p-0 secondary-font m-0 font-15"
            />
          </NavLink>
        </div>

        <div className="custom-li list-group mt-4">
          <NavLink
            to={appRoutes.helpPage}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-question-circle font-15 mr-3"></i>
            <Label title="Help" className="p-0 m-0 secondary-font font-15" />
          </NavLink>
          <NavLink
            to={appRoutes.legalPage}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-file font-15 mr-3"></i>
            <Label title="Legal" className="p-0 secondary-font m-0 font-15" />
          </NavLink>
          {/* <NavLink
            to={appRoutes.blockedDoctors}
            className="list-group-item list-group-item-action"
            href="#"
          >
            <i className="fas fa-ban font-14 mr-3"></i>
            <Label
              title="Blocked Doctors"
              className="p-0 secondary-font m-0 font-15"
            />
          </NavLink> */}
        </div>
        <Model
          openModal={this.state.internetConnectionModelOpen}
          className="test-connection-model-body"
          modalBodyContent={
            this.state.internetConnectionModelBody
              ? this.state.internetConnectionModelBody
              : this.state.poorInternetConnection
          }
          okayText="Okay"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          okayEvent={this.closeInternetConnectionModel}
          cancelEvent={this.closeInternetConnectionModel}
        />
        <MakeAPICall apiParam={this.state.apiCall} />
      </Fragment>
    );
  }
}

const mapStateToProps = props => ({
  auth: props.auth,
  commonReducers: props.commonReducers,
  ...props.modal
});

const mapDispatchToProps = dispatch => ({
  setTotalPatientConsultCount: data => dispatch(setTotalPatientConsultCount(data)),
  saveHomePageDataForQueueInfo: data => dispatch(saveHomePageDataForQueueInfo(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
