import React from "react";
import PropTypes from "prop-types";

const IHIInfoText = props => (
  <div
    style={{
      color: props.color,
      fontSize: "14px",
      border: "1px solid " + props.color,
      borderRadius: "5px",
      padding: "10px",
      height: "auto",
      margin: "6px 4px",
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      verticalAlign: "middle"
    }}
  >
    <p className="m-0">{props.title}</p>
  </div>
);

export default IHIInfoText;

IHIInfoText.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string
};
