import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import { DropdownItem } from "reactstrap";
import APP_CONSTANTS from "../../constants";
import momentTz from "moment-timezone";
import { loadStripe } from "@stripe/stripe-js";
import NewCardForm from "../../containers/NewCardForm";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";

import {
  addNewCardApiRoute,
  checkBookingApiRoute,
  checkIfMedicareCardDuplicateExistsApiRoute,
  confirmPersonalInfoUpToDatAPI,
  consultCostsApiRoute,
  dependentProfileListAPI,
  getConsultsApiRoute,
  getCovidHotSpotsApiRoute,
  getInstantSwitchPriceAPI,
  getMedicinalCannabisConsultPriceAPI,
  internetConnectionFileUpload,
  reqAConsultApiRoute,
  validateMedicareApiRoute
} from "../../apiRoutes";

import MakeAPICall from "../../apiCalls/common";
import appRoutes from "../../routes/appRoutes";

import { DOCS_FORMATS, MAX_FILE_SIZE } from "../../constants/constants";

import { saveAppointmentData } from "../../reduxStore/actions/common";
import { setIHIInfo } from "../../reduxStore/actions/auth";
import TextField from "@material-ui/core/TextField";
import {
  getBrowserDetails,
  getFormattedAddress,
  getFormattedMoney,
  linkify,
  logFirebaseEvent,
  replaceNewLineWithBrTag
} from "../../utils/common";
import Label from "../../components/label";
import AddAttachment from "../../containers/AddAttachment/AddAttachment";
import ICInput from "../../components/form/input";
import InstaButton from "../../components/button";
import Modal from "../../components/modal";
import ModalThreeButtons from "../../components/modalThreeButtons";
import Loader from "../../components/loader";
import ConsentPage from "../ConsentPage";
import CovidCriteriaForm from "../../containers/CovidCriteriaForm";
import CovidHotspotForm from "../../containers/CovidHotspotForm";
import CustomModal from "../../components/customModal";

import InternetTestLoaderGIF from "../../assets/images/loader.gif";
import ImageToUpload from "../../assets/images/background-1.jpg";

import audioVideoPermisions from "../../utils/checkAudioVideoPermissions";
import moment from "moment";

class RequestAConsult extends Component {
  EARLIEST_TIME = 6;

  constructor(props) {
    super(props);
    this.state = {
      userData: undefined,
      showConfirmUserData: false,
      consultCostList: [],
      requestStep: 1,
      isProcessing: false,
      apiCall: null,
      covidHotSpots: [],
      covidHotSpotsVisibility: false,
      covidHotSpotApiCallParams: null,
      dependentAPICallParam: null,
      openCovidCriteriaModal: false,
      openCovidHotspotModal: false,
      covidSituationSelected: "",
      selectedFile: [],
      activeTab: 0,
      fileType: [],
      imgs: [],
      additionalInfo: "",
      consentPageValues: null,
      modal: false,
      modalBodyContent: "",
      modalTitle: "",
      modalSingleButton: false,
      okayModal: () => {},
      cancelModal: () => {},
      modalOkayButtonTitle: "",
      modalCloseButtonTitle: "",
      modalClassName: "",
      testConnectionLoader: false,
      loaderText: "",
      poorInternetConnection:
        "Your internet connection is not suitable for a video call. <br/>  Please check your internet connection and try again.",
      callReqAConsultAPI: false,
      getConsultsApiCall: false,
      consultTypes: [],
      cardPopUpRoute: "",
      isReqActivated: false,
      fileError: "",
      showErrorModal: false,
      medicareValidation: false,
      modalBodyContentThreeBtns: "three buttons content check",
      modalThreeBtns: false,
      isSecondBtnAsDropDown: false,
      firstButtonText: "",
      secondButtonText: "",
      thirdButtonText: "",
      firstButton: "",
      secondButton: "",
      thirdButton: "",
      validateMedicare: false,
      cameraStatusModelOpen: false,
      showPrivateFeeAcceptanceModal: false,
      validateIHIApiCallParams: null,
      dependents: [],
      selectedUserForConsult: null,
      getBookingApiCall: null,
      showBookingOrRequestModal: false,
      showBookingModal: false,
      showBookingCancellationModal: false,
      hourList: [],
      selectedTime: "",
      avgWaitTime: "",
      checkBookingsApiCallParams: null,
      refreshHourListInterval: null,
      stripePromise: loadStripe(APP_CONSTANTS.stripeKey),
      addCardPopup: false,
      cardSavedPopUp: false,
      cardSaveSuccessful: false,
      isBooking: false,
      followUpConsultCost: "$69.00",
      initialConsultCost: "$99.00",
      smokingCessationCost: "$59.99"
    };
    this.scrollDiv = createRef();
    this.handleScrollToElement = this.handleScrollToElement.bind(this);
  }

  componentDidMount() {
    logFirebaseEvent("Request A Consult Page");
    this.scrollTop();

    this.callGetConsultsAPI();
    this.callCheckBookingApi();
    this.callGetCovidApi();
    this.getDependentList();
    this.getConsultCosts();
    // this.getBooking();
  }

  componentWillUnmount() {
    this.scrollTop();
    this.cancelRefreshHourListInterval();
  }

  cancelRefreshHourListInterval() {
    try {
      clearInterval(this.state.refreshHourListInterval);
      this.setState({ refreshHourListInterval: null });
    } catch (e) {}
  }

  setAvgTimeAndRefreshHourList = () => {
    this.setAvgWaitTime();
    this.getIncrementedHoursList();
  };

  startRefreshHourListInterval() {
    this.cancelRefreshHourListInterval();
    const refreshHourListInterval = setInterval(() => {
      if (!this.state.showBookingModal) {
        this.cancelRefreshHourListInterval();
        return;
      }

      this.setAvgTimeAndRefreshHourList();
    }, 20_000);

    this.setState({ refreshHourListInterval });
  }

  handleAdditionalInfoInputChange = val => {
    this.setState({
      additionalInfo: val
    });
  };

  callGetCovidApi = () => {
    let apiCallObj = {
      reqType: "GET",
      reqURL: getCovidHotSpotsApiRoute,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          covidHotSpots: res.data.data
        });
      },
      failureCallback: err => {
        console.log("Something wrong with Get Covid API: ", err);
      },
      showPopUp: false
    };
    this.setState({
      covidHotSpotApiCallParams: apiCallObj
    });
  };

  getEarliestTime = () => {
    const time = moment();

    // let num = this.state.avgWaitTime;
    // if (this.state.avgWaitTime === "N/A") {
    //   num = 5;
    // }

    // let minute = time.add(num, "minute").format("mm");
    let minute = time.format("mm");
    let hour = time.format("HH");
    if (hour >= this.EARLIEST_TIME) {
      hour++;
    }

    minute = Math.ceil(minute / 10) * 10; // round to the nearest 10

    if (minute > 59) {
      minute %= 60;
      hour++;
    }

    if (hour > 23) {
      console.log("too late, next day?"); // todo next day?
    }

    if (hour < this.EARLIEST_TIME) {
      hour = this.EARLIEST_TIME;
      minute = 0;
    }

    time.set({ hour, minute });

    return moment(time.toISOString());
  };

  getIncrementedHoursList = () => {
    let time = this.getEarliestTime();

    let newHoursList = ["Select Time"];

    const latestTime = momentTz(moment().set({ hour: 23, minute: 30 })).tz(
      "Australia/Brisbane"
    );
    while (time <= latestTime) {
      newHoursList.push(time.format("hh:mm a").toLocaleUpperCase());
      time = time.add(10, "minute");
    }

    this.setState({ hourList: newHoursList });
    return newHoursList;
  };

  // callHomePageDataApi = async () => {
  //   this.setState({
  //     apiCall: {
  //       reqType: "GET",
  //       reqURL: homeApiRoute1,
  //       dataObj: {},
  //       params: {},
  //       headers: {},
  //       successCallback: res => {
  //         let avgTime = res.data.data.waitTime;
  //         if (Number.isNaN(avgTime)) {
  //           avgTime = "N/A";
  //         }

  //         this.setState({
  //           avgWaitTime: avgTime
  //         });
  //       },
  //       failureCallback: err => {
  //         this.setState({
  //           avgWaitTime: "N/A"
  //         });
  //       },
  //       showPopUp: false
  //     }
  //   });
  // };

  checkForDuplicateMedicareCard = () => {
    console.log(
      "Selected checkForDuplicateMedicareCard: ",
      this.state.selectedUserForConsult
    );
    // We will check the medicare response once the API is ready
    const medicareNumber = this.state.selectedUserForConsult.medicareNumber?.replace(
      /\s+/g,
      ""
    );
    const IHINumber = this.state.selectedUserForConsult.IHIInfo?.IHINumber?.replace(
      /\s+/g,
      ""
    );

    console.log( {selectedUserForConsult: this.state.selectedUserForConsult});

    // automatically validate patient's IHI by function validateIHI()
    // this.validateIHI(this.state.selectedUserForConsult);

    if (
      (medicareNumber !== "" &&
        this.state.selectedUserForConsult.refNumerOnMedicareCard) ||
      IHINumber
    ) {
      let formData = new FormData();
      formData.append("patientId", this.state.selectedUserForConsult._id);

      if (medicareNumber) {
        formData.append("medicareNumber", medicareNumber);
        formData.append(
          "refNumerOnMedicareCard",
          this.state.selectedUserForConsult.refNumerOnMedicareCard
        );
      }

      if (IHINumber) {
        formData.append("IHINumber", IHINumber);
      }

      this.setState({
        isProcessing: true
      });

      let apiCallObj = {
        reqType: "POST",
        reqURL: checkIfMedicareCardDuplicateExistsApiRoute,
        dataObj: formData,
        params: {},
        headers: {},
        successCallback: res => {
          this.setState({
            isProcessing: false
          });
          if (res.data.data === "No duplicate found") {
            this.validateMedicare();
          } else {
            this.setState({
              modal: true,
              modalTitle: "Instant Consult",
              modalBodyContent: res.data.message,
              modalSingleButton: true,
              cancelModal: this.closeModal,
              modalCloseButtonTitle: "Okay"
            });
          }
        },
        failureCallback: err => {
          this.setState({
            isProcessing: false
          });
        },
        showPopUp: false
      };
      this.setState({
        getConsultsApiCall: apiCallObj
      });
    } else {
      this.validateMedicare();
    }
  };

  // checkForDuplicateMedicareCard = () => {

  //   // We will check the medicare response once the API is ready
  //   let medicareNumber = this.props.auth.user[0].medicareNumber;
  //   // remove space in medicareNumber
  //   medicareNumber = medicareNumber.replace(/\s+/g, "");

  //   // automatically validate patient's IHI by function validateIHI()
  //   // this.validateIHI(this.props.auth.user[0]);

  //   if (
  //     medicareNumber !== "" &&
  //     this.props.auth.user[0].refNumerOnMedicareCard
  //   ) {
  //     let formData = new FormData();
  //     formData.append("patientId", this.props.auth.user[0]._id);
  //     formData.append("medicareNumber", medicareNumber);
  //     formData.append(
  //       "refNumerOnMedicareCard",
  //       this.props.auth.user[0].refNumerOnMedicareCard
  //     );

  //     this.setState({
  //       isProcessing: true
  //     });

  //     let apiCallObj = {
  //       reqType: "POST",
  //       reqURL: checkIfMedicareCardDuplicateExistsApiRoute,
  //       dataObj: formData,
  //       params: {},
  //       headers: {},
  //       successCallback: res => {
  //         this.setState({
  //           isProcessing: false
  //         });
  //         if (res.data.data === "No duplicate found") {
  //           this.validateMedicare();
  //         } else {
  //           this.setState({
  //             modal: true,
  //             modalTitle: "Instant Consult",
  //             modalBodyContent:
  //               "Your Medicare details are already registered<br />with a different account.<br /><br />Please contact Instant Consult Support on 1300 003 310 to assist.",
  //             modalSingleButton: true,
  //             cancelModal: this.closeModal,
  //             modalCloseButtonTitle: "Okay"
  //           });
  //         }
  //       },
  //       failureCallback: err => {
  //         this.setState({
  //           isProcessing: false
  //         });
  //       },
  //       showPopUp: false
  //     };
  //     this.setState({
  //       getConsultsApiCall: apiCallObj
  //     });
  //   } else {
  //     this.validateMedicare();
  //   }
  // };

  getDependentList = () => {
    this.setState({
      dependentAPICallParam: {
        reqType: "GET",
        reqURL: dependentProfileListAPI,
        dataObj: {},
        params: {},
        headers: {},
        successCallback: res => {
          this.setState({
            dependents: res.data.data.dependents || []
          });
          if (
            !res.data.data?.personalInfoUpdatedAt ||
            moment(res.data.data?.personalInfoUpdatedAt)
              .add(90, "d")
              .isBefore(moment())
          ) {
            this.setState({
              showConfirmUserData: true,
              userData: res.data.data
            });
          }
        },
        failureCallback: err => {
          this.getDependentList();
        },
        showPopUp: false
      }
    });
  };

  confirmPersonalInfo = () => {
    this.setState({
      dependentAPICallParam: {
        reqType: "POST",
        reqURL: confirmPersonalInfoUpToDatAPI,
        dataObj: {},
        params: {},
        headers: {},
        successCallback: _ => {},
        failureCallback: _ => {},
        showPopUp: false
      }
    });
  };

  getConsultCosts() {
    this.setState({
      apiCall: {
        reqType: "GET",
        reqURL: consultCostsApiRoute,
        dataObj: {},
        params: {},
        headers: {},
        successCallback: res => {
          this.setState({
            consultCostList: res.data.data
          });
          this.getInstantSwitchConsultPrice();
        },
        failureCallback: err => {
          console.log(err);
        },
        showPopUp: false
      }
    });
  }

  getInstantSwitchConsultPrice() {
    this.setState({
      apiCall: {
        reqType: "GET",
        reqURL: getInstantSwitchPriceAPI,
        dataObj: {},
        params: {},
        headers: {},
        successCallback: res => {
          this.setConsultCosts(res.data?.data?.consultDetailData);
          this.getMedicinalCannabisConsultPrice();
        },
        failureCallback: err => {
          console.log(err);
        },
        showPopUp: false
      }
    });
  }

  getMedicinalCannabisConsultPrice() {
    this.setState({
      apiCall: {
        reqType: "GET",
        reqURL: getMedicinalCannabisConsultPriceAPI,
        dataObj: {},
        params: {},
        headers: {},
        successCallback: res => {
          this.setConsultCosts(res.data?.data?.consultDetailData);
        },
        failureCallback: err => {
          console.log(err);
        },
        showPopUp: false
      }
    });
  }

  setConsultCosts(data) {
    for (const datum of data) {
      if (datum.description.toLowerCase().includes("smoking cessation")) {
        this.setState({
          smokingCessationCost: getFormattedMoney(datum.consultPrice)
        });
      } else if (datum.description.toLowerCase().startsWith("initial")) {
        this.setState({
          initialConsultCost: getFormattedMoney(datum.consultPrice)
        });
      } else if (datum.description.toLowerCase().startsWith("subsequent")) {
        this.setState({
          followUpConsultCost: getFormattedMoney(datum.consultPrice)
        });
      }
    }
  }

  openEditMedicareDetailPopUp = () => {
    let name =
      (this.state.selectedUserForConsult.firstNameForHIService ||
        this.state.selectedUserForConsult.firstName) +
      " " +
      (this.state.selectedUserForConsult.lastNameForHIService ||
        this.state.selectedUserForConsult.lastName);
    let gender = this.state.selectedUserForConsult.gender;
    let dob = this.state.selectedUserForConsult.dateOfBirth;
    let md =
      this.state.selectedUserForConsult.medicareNumber === ""
        ? "N/A"
        : this.state.selectedUserForConsult.medicareNumber;
    let ihi = !this.state.selectedUserForConsult.IHINumber
      ? "N/A"
      : this.state.selectedUserForConsult.IHINumber;

    if (
      md !== "N/A" &&
      this.state.selectedUserForConsult.refNumerOnMedicareCard !== ""
    ) {
      md =
        md + " - " + this.state.selectedUserForConsult.refNumerOnMedicareCard;
    }
    this.setState({
      modalThreeBtns: true,
      isSecondBtnAsDropDown: false,
      firstButtonText: "Edit Patient Details",
      firstButton: this.routeToEditPatientDets,
      secondButtonText: "I do not have a Medicare Card or IHI Number",
      secondButton: this.togglePrivateFeeAcceptanceModal,
      thirdButtonText: "Call 1300 003 310",
      thirdButton: this.callOnSupport,
      modalClassName: "modal-size-med-error modal-lg",
      modalBodyContentThreeBtns: `<p class="p-bold">Patient Details</p>
                                        <div class="div-align-left"><span>Name:</span> ${name}</div>
                                        <div class="div-align-left"><span>Gender:</span> ${gender}</div>
                                        <div class="div-align-left"><span>DOB:</span> ${dob}</p></div>
                                        <div class="div-align-left"><span>Medicare Number:</span> ${md}</div>
                                        <div class="div-align-left"><span>IHI Number:</span> ${ihi}</div></br>
                                        <p>The above details could not be verified with the Department of Human Services.</p></br>
                                        <p>Ensure your name, gender and date of birth is correct. If your name is incorrect, you will</br> need to call Instant Consult Support on 1300 003 310 to amend this for you.</p></br>
                                        <div class="green div-align-left">If you have a valid Medicare Card, ensure you input the correct number <span>and</span> reference</br> number, otherwise you will be unable to obtain:</br>
                                        <p class="green">
                                          <ul>
                                            <li>PBS Prescriptions</li>
                                            <li>Bulk-Billed Referrals & Requests</li>
                                          </ul>
                                        </p>

                                         <div class="government-text-color div-align-left">If you have a valid IHI Number, ensure you input the correct number, otherwise you will be unable to obtain:</br>
                                        <p class="government-text-color">
                                          <ul>
                                            <li>Electronic Prescriptions (Prescriptions / eTokens)</li>
                                          </ul>
                                        </p>
                                        <a href="https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier"
                                            class="government-text-color font-15 w-100" target="_blank"
                                        ><b>Visit</b> https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier</a>`
    });
  };

  validateMedicare = () => {
    this.closeModal();

    this.setState({
      isProcessing: true
    });

    console.log(
      "Selected User For Consult: ",
      this.state.selectedUserForConsult
    );

    // We will check the medicare response once the API is ready
    let medicareNumber = this.state.selectedUserForConsult.medicareNumber;
    // remove space in medicareNumber
    medicareNumber = medicareNumber.replace(/\s+/g, "");

    const formData = new FormData();
    formData.append("_id", this.state.selectedUserForConsult._id);
    formData.append(
      "firstName",
      this.state.selectedUserForConsult.firstNameForHIService ||
        this.state.selectedUserForConsult.firstName
    );
    formData.append(
      "lastName",
      this.state.selectedUserForConsult.lastNameForHIService ||
        this.state.selectedUserForConsult.lastName
    );
    formData.append(
      "dateOfBirth",
      this.state.selectedUserForConsult.dateOfBirth
    );
    formData.append("gender", this.state.selectedUserForConsult.gender);
    formData.append("medicareNumber", medicareNumber);
    formData.append(
      "refNumerOnMedicareCard",
      this.state.selectedUserForConsult.refNumerOnMedicareCard
    );

    let apiCallObj = {
      reqType: "POST",
      reqURL: validateMedicareApiRoute,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: async res => {
        this.setState({
          isProcessing: false
        });

        if (res.data && res.data.data && res.data.data.isMedicareVerified) {
          logFirebaseEvent("Medicare Eligibility");
          this.scrollTop();

          console.log(
            "Medicare Okay: ",
            moment(this.state.selectedUserForConsult.dateOfBirth)
          );
          let dobToCheck = this.state.selectedUserForConsult.dateOfBirth;
          let validDOB = moment(this.state.selectedUserForConsult.dateOfBirth);
          if (moment(dobToCheck).format("DD-MM-YYYY") === "Invalid date") {
            validDOB = moment(dobToCheck, "DD-MM-YYYY");
          }
          let ageToCheck = moment(new Date().toISOString()).diff(
            validDOB,
            "months",
            true
          );
          console.log("Aget to check: ", ageToCheck);
          if (ageToCheck && ageToCheck < 12.0) {
            this.handleCovidFormSubmit(
              "Patient declared as under 12 months of age",
              true
            );
          } else {
            this.setState({
              openCovidHotspotModal: true
            });
          }
        } else if (res.data?.data?.isPatientHasValidIHI) {
          this.togglePrivateFeeAcceptanceModal();
        } else {
          logFirebaseEvent("Medicare Unverified");
          this.openEditMedicareDetailPopUp();
        }
      },
      failureCallback: err => {
        this.setState({
          isProcessing: false
        });
        console.log("Hey got the error here: ", err);
        if (err && err.type === "INVALID_MEDICARE_DETAILS") {
          logFirebaseEvent("Medicare Unverified");
          this.openEditMedicareDetailPopUp();
        } else {
          this.setState({
            modal: true,
            modalTitle: "Instant Consult",
            modalBodyContent:
              "<p className='text-center'>Department of Human Services (Medicare) is not responding.<br/>Please try again later, or proceed without Medicare verification.<br/><br/>If you proceed without Medicare verification, your consult will result in a private fee with no Medicare rebates available.</p>",
            modalSingleButton: false,
            okayModal: this.closeModal,
            cancelModal: this.showBookingOrRequestModal,
            modalOkayButtonTitle: "Try Again Later",
            modalCloseButtonTitle: "Proceed Without Medicare"
          });
        }
      },
      showErrorRes: false
    };
    this.setState({
      getConsultsApiCall: apiCallObj
    });
  };

  // validateMedicare = () => {
  //   this.closeModal();

  //   this.setState({
  //     isProcessing: true
  //   });

  //   // We will check the medicare response once the API is ready
  //   let medicareNumber = this.props.auth.user[0].medicareNumber;
  //   // remove space in medicareNumber
  //   medicareNumber = medicareNumber.replace(/\s+/g, "");

  //   const formData = new FormData();
  //   formData.append("_id", this.props.auth.user[0]._id);
  //   formData.append(
  //     "firstName",
  //     this.props.auth.user[0].firstNameForHIService ||
  //       this.props.auth.user[0].firstName
  //   );
  //   formData.append(
  //     "lastName",
  //     this.props.auth.user[0].lastNameForHIService ||
  //       this.props.auth.user[0].lastName
  //   );
  //   formData.append("dateOfBirth", this.props.auth.user[0].dateOfBirth);
  //   formData.append("gender", this.props.auth.user[0].gender);
  //   formData.append("medicareNumber", medicareNumber);
  //   formData.append(
  //     "refNumerOnMedicareCard",
  //     this.props.auth.user[0].refNumerOnMedicareCard
  //   );

  //   let apiCallObj = {
  //     reqType: "POST",
  //     reqURL: validateMedicareApiRoute,
  //     dataObj: formData,
  //     params: {},
  //     headers: {},
  //     successCallback: async res => {
  //       this.setState({
  //         isProcessing: false
  //       });
  //       if (
  //         res.data.data.medicareStatus &&
  //         res.data.data.medicareStatus.status.code === 0
  //       ) {
  //         logFirebaseEvent("Medicare Eligibility");
  //         this.scrollTop();

  //         console.log(
  //           "Medicare Okay: ",
  //           moment(this.props.auth.user[0].dateOfBirth)
  //         );
  //         let dobToCheck = this.props.auth.user[0].dateOfBirth;
  //         let validDOB = moment(this.props.auth.user[0].dateOfBirth);
  //         if (moment(dobToCheck).format("DD-MM-YYYY") === "Invalid date") {
  //           validDOB = moment(dobToCheck, "DD-MM-YYYY");
  //         }
  //         let ageToCheck = moment(new Date().toISOString()).diff(
  //           validDOB,
  //           "months",
  //           true
  //         );
  //         console.log("Aget to check: ", ageToCheck);
  //         if (ageToCheck && ageToCheck < 12.0) {
  //           this.handleCovidFormSubmit(
  //             "Patient declared as under 12 months of age",
  //             true
  //           );
  //         } else {
  //           this.setState({
  //             openCovidHotspotModal: true
  //           });
  //         }
  //       } else {
  //         logFirebaseEvent("Medicare Unverified");
  //         let name =
  //           (this.props.auth.user[0].firstNameForHIService ||
  //             this.props.auth.user[0].firstName) +
  //           " " +
  //           (this.props.auth.user[0].lastNameForHIService ||
  //             this.props.auth.user[0].lastName);
  //         let gender = this.props.auth.user[0].gender;
  //         let dob = this.props.auth.user[0].dateOfBirth;
  //         let md =
  //           this.props.auth.user[0].medicareNumber === ""
  //             ? "N/A"
  //             : this.props.auth.user[0].medicareNumber;

  //         if (
  //           md !== "N/A" &&
  //           this.props.auth.user[0].refNumerOnMedicareCard !== ""
  //         ) {
  //           md = md + " - " + this.props.auth.user[0].refNumerOnMedicareCard;
  //         }
  //         this.setState({
  //           modalThreeBtns: true,
  //           isSecondBtnAsDropDown: false,
  //           firstButtonText: "Edit Patient Details",
  //           firstButton: this.routeToEditPatientDets,
  //           secondButtonText: "I do not have a Medicare Card",
  //           secondButton: this.togglePrivateFeeAcceptanceModal,
  //           thirdButtonText: "Call 1300 003 310",
  //           thirdButton: this.callOnSupport,
  //           modalClassName: "modal-size-med-error modal-lg",
  //           modalBodyContentThreeBtns: `<p class="p-bold">Patient Details</p>
  //                                       <div class="div-align-left"><span>Name:</span> ${name}</div>
  //                                       <div class="div-align-left"><span>Gender:</span> ${gender}
  //                                       <div class="div-align-left"><span>DOB:</span> ${dob}</p>
  //                                       <div class="div-align-left"><span>Medicare Number:</span> ${md}</br></br>
  //                                       <p>The above details could not be verified with the Department of Human Services.</p></br>
  //                                       <p>Ensure your name, gender and date of birth is correct. If your name is incorrect, you will</br> need to call Instant Consult Support on 1300 003 310 to amend this for you.</p></br>
  //                                       <div class=""div-align-left>If you have a valid Medicare Card, ensure you input the correct number <span>and</span> reference</br> number, otherwise you will be unable to obtain:</br>
  //                                       <p>
  //                                         <ul style="list-style-type:bullet;">
  //                                           <li><p>PBS Prescriptions</p></li>
  //                                           <li><p>Bulk-Billed Referrals & Requests</p></li>
  //                                         </ul>
  //                                       </p>
  //                                       <p>Note: if your details cannot be validated, you will incur a private consult fee and not be</br> eligible for any Medicare rebates - refer to our <a target='_blank' class='insta-link-orange text-decoration-none' href='https://instantconsult.com.au/FAQ'>FAQs</a> for more detail</p>`
  //         });
  //       }
  //     },
  //     failureCallback: err => {
  //       this.setState({
  //         isProcessing: false
  //       });
  //       //console.log("medicare validation api failed", err);
  //       this.setState({
  //         modal: true,
  //         modalTitle: "Instant Consult",
  //         modalBodyContent:
  //           "<p className='text-center'>Department of Human Services (Medicare) is not responding.<br/>Please try again later, or proceed without Medicare verification.<br/><br/>If you proceed without Medicare verification, your consult will result in a private fee with no Medicare rebates available.</p>",
  //         modalSingleButton: false,
  //         okayModal: this.closeModal,
  //         cancelModal: this.callReqAConsultAPI,
  //         modalOkayButtonTitle: "Try Again Later",
  //         modalCloseButtonTitle: "Proceed Without Medicare"
  //       });
  //     },
  //     showErrorRes: false
  //   };
  //   this.setState({
  //     getConsultsApiCall: apiCallObj
  //   });
  // };

  // set the scroll to top of the document body
  scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
  };

  // validate input file
  validateFile = file => {
    let error = "";
    if (file) {
      if (file.size >= MAX_FILE_SIZE) {
        error = "Maximum 2MB file is allowed";
      } else if (!DOCS_FORMATS.includes(file.type)) {
        error = "File must be one of these formats only jpg, png, svg or pdf";
      }
    }

    if (this.state.imgs.length === 5) {
      error = "Maximum 5 documents/images are allowed!";
    }

    this.setState({
      fileError: error
    });

    return error === "";
  };

  cancelISModal = () => {
    this.setState({
      modal: false,
      activeTab: 0
    });
    this.scrollTop();
    this.closeModal();
  };

  // check if user had a booking
  callCheckBookingApi = () => {
    let apiCallObj = {
      reqType: "GET",
      reqURL: checkBookingApiRoute,
      successCallback: async res => {
        // if (res.data.data.appointmentData && res.data.data.appointmentData.length){
        //   let appointment =  res.data.data.appointmentData[0];

        //   if (appointment.status === 0) {
        //     await this.props.saveAppointmentData({
        //       _id: appointment._id
        //     });
        //     this.props.history.push(appRoutes.booking);
        //   } else {
        //     await this.props.saveAppointmentData({
        //       _id: appointment._id
        //     });
        //     this.props.history.push(appRoutes.consultationDetails);
        //   }
        // }
        const isBookedConsult =
          res.data.data &&
          res.data.data.appointmentData &&
          res.data.data.appointmentData[0] &&
          res.data.data.appointmentData[0].status === 0
            ? true
            : false;
        const isAppointmentInProgress =
          res.data.data &&
          res.data.data.appointmentData &&
          res.data.data.appointmentData[0]
            ? true
            : false;
        if (isBookedConsult) {
          await this.props.saveAppointmentData({
            _id: res.data.data.appointmentData[0]._id
          });
          this.props.history.push(appRoutes.booking);
        } else if (isAppointmentInProgress) {
          await this.props.saveAppointmentData({
            _id: res.data.data.appointmentData[0]._id
          });
          this.props.history.push(appRoutes.consultationDetails);
        }
      },
      failureCallback: err => {
        // this.setState({
        //   showErrorModal: true
        // });
      },
      showPopUp: false
    };
    this.setState({ checkBookingsApiCallParams: apiCallObj });
  };

  onClickDelete = index => {
    const copyImgArray = Object.assign([], this.state.selectedFile);
    const copyFileTypeArray = Object.assign([], this.state.fileType);
    copyImgArray.splice(index, 1);
    copyFileTypeArray.splice(index, 1);
    this.setState({
      selectedFile: copyImgArray,
      fileType: copyFileTypeArray
    });
  };

  medicalCertModalText =
    "A doctor can issue you with a Medical Certificate when clinically appropriate. </br>These Medical Certificates are predominately used for workplace or university personal leave.</br></br>Doctors do not backdate Medical Certificates and cannot issue a Medical Certificate covering more than 3 days.</br>The details issued on the Medical Certificate is up to the doctor and what they deem suitable during consultation.</br></br>If you require a Mask Exemption, ‘Fit for Work’ Certificate, Centrelink Certificate, University Form completed, </br> Work Cover or Gym Cancellation Certificate, a certificate for missing police station attendances,  parole or <br/> court appearances, or any external forms completed  – you will need to present to a GP in-person to undergo </br>a physical examination and will not be able to use the Instant Consult service for these types of medical documents.</br></br>The consults are charged according to the duration of the consult – not what medical documents are issued to you.</br>Just like any consultation if you were to visit a local medical clinic, we cannot guarantee what medical documents</br>the doctor will issue you. Please visit <a target='_blank' class='insta-link-orange text-decoration-none' href='https://instantconsult.com.au/FAQ'>instantconsult.com.au/FAQ</a> for more information.";
  prescriptionModalText =
    "A doctor can issue you with Prescriptions when clinically appropriate.<br/><br/>Please note that some medications cannot be prescribed through Instant Consult, including<br/>Panadeine Forte, Mersyndol Forte, Benzodiazepines (Valium), Phentermine (Duromine), Semaglutide (Ozempic), Liraglutide (Saxenda), Modafinil, Armodafinil, Gabapentin, Quetiapine, Pregabalin (Lyrica), Tramadol (Tramal), Zolpidem (Stilnox), Zopiclone (Imovane), Ivermectin (Stromectol), controlled substances, narcotics or opioids, <br/>any Schedule 8 (S8) controlled drugs and medications requiring close monitoring by a doctor.<br/><br/>Medications used to prevent and treat COVID-19 cannot be prescribed through Instant Consult.<br/><br/>The consults are charged according to the duration of the consult – not what prescriptions are issued to you. <br/>Just like any consultation if you were to visit a local medical clinic, we cannot guarantee what medication<br/>the doctor will prescribe you. Please visit <a class='insta-link-orange text-decoration-none' href='https://www.instantconsult.com.au/FAQ'>instantconsult.com.au/FAQ</a> for more information.";
  handleScrollToElement = (tabNumber, consultName) => {
    this.setState({
      activeTab: tabNumber
    });
    const hasDescription = this.state.consultTypes[tabNumber - 1][
      "description"
    ];
    const isQuitSmokingTab =
      this.state.consultTypes[tabNumber - 1]["consultName"] === "Quit Smoking";
    console.log("Has Description: ", hasDescription, isQuitSmokingTab);

    const hasToRedirect = this.state.consultTypes[tabNumber - 1]["isRedirect"]
      ? this.state.consultTypes[tabNumber - 1]["isRedirect"]
      : false;
    const hasRedirectLink = this.state.consultTypes[tabNumber - 1]["isRedirect"]
      ? this.state.consultTypes[tabNumber - 1]["redirectURL"]
      : "";

    if (hasDescription && !hasToRedirect) {
      const updatedDescription = linkify(
        hasDescription.replace(/(?:\r\n|\r|\n)/g, "<br>")
      );
      this.setState({
        modal: true,
        modalTitle: "Important Information",
        modalBodyContent: updatedDescription,
        modalSingleButton: false,
        okayModal: this.okayModal,
        cancelModal: this.closeModal,
        modalOkayButtonTitle: "Accept",
        modalCloseButtonTitle: "Go Back",
        modalClassName: "modal-size modal-lg"
      });
    } else if (hasDescription && hasToRedirect && hasRedirectLink) {
      this.setState({
        modal: true,
        modalTitle: "Important Information",
        modalBodyContent: `
            <div class="div-align-left">
              ${hasDescription.replace(/(?:\r\n|\r|\n)/g, "<br>")}
          </div>`,
        modalSingleButton: false,
        okayModal: () => this.openLinkAndCloseModal(hasRedirectLink),
        cancelModal: this.cancelISModal,
        modalOkayButtonTitle: "Okay",
        modalCloseButtonTitle: "Cancel",
        modalClassName: "modal-size modal-lg"
      });
    }
    // if (consultName === "Medical Certificate") {
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: this.medicalCertModalText,
    //     modalSingleButton: false,
    //     okayModal: this.okayModal,
    //     cancelModal: this.closeModal,
    //     modalOkayButtonTitle: "Accept",
    //     modalCloseButtonTitle: "Go Back",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Prescription") {
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: this.prescriptionModalText,
    //     modalSingleButton: false,
    //     okayModal: this.okayModal,
    //     cancelModal: this.closeModal,
    //     modalOkayButtonTitle: "Accept",
    //     modalCloseButtonTitle: "Go Back",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Referral") {
    //   let referralModalText =
    //     "A doctor can issue you with a Specialist Referral when clinically appropriate. <br/><br/> If you require a GP Mental Health Treatment Plan – you will need to present to your regular GP and will not be able to use the Instant Consult service for this type of medical document. <br/> Doctors are unable to generate a GP Mental Health Treatment Plan via the Instant Consult platform. <br/><br/> The consults are charged according to the duration of the consult – not what medical documents are issued to you. Just like any consultation if you were to visit a local medical clinic, we cannot guarantee what medical documents the doctor will issue you. Please visit <a class='insta-link-orange text-decoration-none' href='https://www.instantconsult.com.au/FAQ'>instantconsult.com.au/FAQ</a> for more information.";
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: referralModalText,
    //     modalSingleButton: false,
    //     okayModal: this.okayModal,
    //     cancelModal: this.closeModal,
    //     modalOkayButtonTitle: "Accept",
    //     modalCloseButtonTitle: "Go Back",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Pathology Request") {
    //   let pathologyRequestModalText =
    //     "A doctor can issue you with a Pathology Request when clinically appropriate. <br/><br/> The Pathology Request will be available in your Instant Consult Inbox immediately following your consultation. <br/> You can print the form and take it to your preferred Pathology Centre, or you can email direct to their administrator. <br/><br/> Results for pathology are sent directly back to our clinic, where we will notify you when to request a consult to discuss the results with a doctor. If you have not been notified that your results have been received within 3 business days from your test date, please contact our support team. <br/><br/> Alternatively, during your initial consult you can request for your results to be sent to your usual family practice where you can discuss your results with your regular GP. <br/><br/> The consults are charged according to the duration of the consult – not what medical documents are issued to you. Just like any consultation if you were to visit a local medical clinic, we cannot guarantee what medical documents the doctor will issue you. Please visit <a class='insta-link-orange text-decoration-none' href='https://www.instantconsult.com.au/FAQ'>instantconsult.com.au/FAQ</a> for more information.";
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: pathologyRequestModalText,
    //     modalSingleButton: false,
    //     okayModal: this.okayModal,
    //     cancelModal: this.closeModal,
    //     modalOkayButtonTitle: "Accept",
    //     modalCloseButtonTitle: "Go Back",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Radiology Request") {
    //   let radiologyRequestModalText =
    //     "A doctor can issue you with a Radiology Request when clinically appropriate. <br/><br/> The Radiology Request will be available in your Instant Consult Inbox immediately following your consultation. <br/> You can print the form and take it to your preferred Imaging Centre, or you can email direct to their administrator. <br/><br/> Reports for imaging are sent directly back to our clinic, where we will notify you when to request a consult to discuss the report with a doctor. If you have not been notified that your results have been received within 3 business days from your test date, please contact our support team. <br/><br/> Alternatively, during your initial consult you can request for your report to be sent to your usual family practice where you can discuss your report with your regular GP. <br/><br/> The consults are charged according to the duration of the consult – not what medical documents are issued to you. Just like any consultation if you were to visit a local medical clinic, we cannot guarantee what medical documents the doctor will issue you. Please visit <a class='insta-link-orange text-decoration-none' href='https://www.instantconsult.com.au/FAQ'>instantconsult.com.au/FAQ</a> for more information.";
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: radiologyRequestModalText,
    //     modalSingleButton: false,
    //     okayModal: this.okayModal,
    //     cancelModal: this.closeModal,
    //     modalOkayButtonTitle: "Accept",
    //     modalCloseButtonTitle: "Go Back",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Results for Pathology or Radiology") {
    //   let radiologyRequestModalText =
    //     "Have you received a notification <b>from Instant Consult</b> (<b>not</b> the pathology or imaging centre) that your Pathology Results or Radiology Report have been received by our practice? <br/><br/> If not, do <b>not</b> proceed. Please contact our support team and let us know which collection or imaging centre you had your pathology or radiology request conducted and we will follow-up with them promptly. You will be notified when to request a consult to discuss your results.<br/><br/> If you have been notified by Instant Consult that your Pathology Results or Radiology Report have been received by our practice, please proceed to request a consult. <br/><br/> The consults are charged according to the duration of the consult – not what medical documents are issued to you. Just like any consultation if you were to visit a local medical clinic, we cannot guarantee what medical documents the doctor will issue you. Please visit <a class='insta-link-orange text-decoration-none' target='_blank' href='https://www.instantconsult.com.au/FAQ'>instantconsult.com.au/FAQ</a> for more information.";
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: radiologyRequestModalText,
    //     modalSingleButton: false,
    //     okayModal: this.okayModal,
    //     cancelModal: this.closeModal,
    //     modalOkayButtonTitle: "Accept",
    //     modalCloseButtonTitle: "Go Back",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Medicinal Plant Therapy") {
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: `<div class="div-align-left">Instant Consult™ cannot prescribe Medicinal Plant Prescriptions as these are a Schedule 8 controlled drug<br/><br/>We will forward you to Instant Switch® for access to TGA-Approved Authorised Prescribers<br/><br/>Online Assessments are completed FREE of charge and followed with a comprehensive consult<br/><br/>The following consult fees are privately paid and are <b>not</b> rebatable or subsidised by Medicare<br/><br/><b>${this.state.initialConsultCost}</b> for your first consult <br/><b>${this.state.followUpConsultCost}</b> for subsequent consults</div>`,
    //     modalSingleButton: false,
    //     okayModal: () =>
    //       this.openLinkAndCloseModal(APP_CONSTANTS.instantSwitchPatientPortal),
    //     cancelModal: this.cancelISModal,
    //     modalOkayButtonTitle: "Okay",
    //     modalCloseButtonTitle: "Cancel",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // } else if (consultName === "Quit Smoking") {
    //   this.setState({
    //     modal: true,
    //     modalTitle: "Important Information",
    //     modalBodyContent: `
    //       <div class="div-align-left">
    //         Instant Consult™ cannot create Smoking Cessation Plans
    //         <br/>
    //         <br/>
    //         We will forward you to Instant Switch® for access to TGA-Approved Authorised Prescribers
    //         <br/>
    //         <br/>
    //         Online Assessments are completed FREE of charge and followed with a comprehensive consult
    //         <br/>
    //         <br/>
    //         The following consult fees are privately paid and are <b>not</b> rebatable or subsidised by Medicare
    //         <br/><br/>
    //         <b>${this.state.smokingCessationCost}</b>
    //         per consult
    //     </div>`,
    //     modalSingleButton: false,
    //     okayModal: () =>
    //       this.openLinkAndCloseModal(APP_CONSTANTS.instantSwitchPatientPortal),
    //     cancelModal: this.cancelISModal,
    //     modalOkayButtonTitle: "Okay",
    //     modalCloseButtonTitle: "Cancel",
    //     modalClassName: "modal-size modal-lg"
    //   });
    // }
    this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };

  openLinkAndCloseModal(redirectLink) {
    this.setState({
      modal: false,
      activeTab: 0
    });
    this.scrollTop();
    const url =
      redirectLink +
      `/#/auto/${this.props.auth.user[0].accessToken}/${this.props.auth.user[0]._id}`;
    window.open(url);
  }

  okayModal = () => {
    this.setState({
      modal: false
    });
    if (this.state.activeTab !== 0)
      this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };

  closeModal = () => {
    this.setState({
      modal: false
    });
  };

  requestModalText =
    "Is this consult for " +
    this.props.auth.user[0].firstName +
    " " +
    this.props.auth.user[0].lastName +
    " or someone else?";

  requestForSomeoneElse = () => {
    this.closeModal();
    this.setState({
      modal: true,
      modalTitle: "Instant Consult",
      modalBodyContent:
        "Please request a consult using the patient's account.</br> Read our <a target='_blank' class='insta-link-orange text-decoration-none' href='https://instantconsult.com.au/FAQ'>FAQs</a> or contact Instant Consult Support</br>on 1300 003 310 for more information.",
      modalSingleButton: true,
      cancelModal: this.closeModal,
      modalCloseButtonTitle: "Okay",
      modalClassName: ""
    });
  };

  setSelectedTime = time => {
    this.setState({ selectedTime: time.target.value });
  };

  showBookingOrRequestModal = () => {
    this.setState({
      modal: false,
      showBookingOrRequestModal: true,
      showPrivateFeeAcceptanceModal: false
    });
  };

  hideBookingOrRequestModal = () => {
    this.setState({ showBookingOrRequestModal: false });
  };

  showBookingModal = () => {
    this.getIncrementedHoursList();
    this.setState({ showBookingModal: true, showBookingOrRequestModal: false });
  };

  hideBookingModal = () => {
    this.setState({ showBookingModal: false });
  };

  // getBooking = () => {
  //   let headers = {
  //     Authorization: "Bearer " + this.props.auth.user[0].accessToken
  //   };

  //   let apiCallObj = {
  //     reqType: "GET",
  //     reqURL: g,
  //     params: {},
  //     headers: headers,
  //     successCallback: async res => {
  //       if (res.data.data) this.props.history.push(appRoutes.booking);
  //     },
  //     failureCallback: err => {
  //       console.log("get booking fail", err);
  //     },
  //     showErrorRes: false
  //   };
  //   this.setState({
  //     getBookingApiCall: apiCallObj
  //   });
  // };

  testInternetConnection = () => {
    this.setState({
      modalThreeBtns: false,
      isSecondBtnAsDropDown: false,
      modal: false,
      testConnectionLoader: true,
      loaderText: "Testing Connection..."
    });

    let startTime = new Date().getTime(),
      file = ImageToUpload + "?" + new Date().getTime();

    axios
      .get(file, {
        responseType: "blob",
        timeout: 20000
      })
      .then(res => {
        let endTime = new Date().getTime(),
          duration = (endTime - startTime) / 1000,
          fileSize = 2900000,
          bitsLoaded = fileSize * 8,
          bps = (bitsLoaded / duration).toFixed(2),
          kbps = (bps / 1024).toFixed(2),
          mbps = (kbps / 1024).toFixed(2);

        let downloadSpeed = mbps;

        this.calculateUploadingSpeed(res, file, downloadSpeed);
      })
      .catch(error => {
        this.setState({
          modalBodyContent: this.state.poorInternetConnection,
          modal: true,
          modalClassName: "test-connection-model-body",
          testConnectionLoader: false,
          cancelModal: this.closeTestConnectionModal,
          modalCloseButtonTitle: "Okay",
          modalTitle: "Instant Consult",
          modalSingleButton: true
        });
      });
  };

  calculateUploadingSpeed = (data, file, downloadSpeed) => {
    let formData = new FormData(),
      startTime1 = new Date().getTime(),
      fileURL = new File([data.data], file, { type: "image/jpeg" });

    formData.append("picture", fileURL, fileURL.name);

    axios
      .post(internetConnectionFileUpload, formData, {
        headers: {
          authorization: "bearer " + this.props.auth.user[0].accessToken,
          "content-type": undefined
        },
        timeout: 20000
      })
      .then(data => {
        let endTime1 = new Date().getTime(),
          duration1 = (endTime1 - startTime1) / 1000,
          fileSize1 = 2900000,
          bitsLoaded1 = fileSize1 * 8,
          bps1 = (bitsLoaded1 / duration1).toFixed(2),
          kbps1 = (bps1 / 1024).toFixed(2),
          mbps1 = (kbps1 / 1024).toFixed(2);

        let uploadSpeed = mbps1,
          // minUploadSpeed = 1.0,
          minUploadSpeed = 1.0,
          // minDownloadSpeed = 5.0,
          minDownloadSpeed = 4.0,
          dicon = "",
          uicon = "",
          internetStatus = false;

        if (downloadSpeed && uploadSpeed) {
          // Check internet speed here
          if (
            downloadSpeed >= minDownloadSpeed &&
            uploadSpeed >= minUploadSpeed
          ) {
            internetStatus = true;
          } else {
            internetStatus = false;
          }

          // now for icon color
          downloadSpeed < minDownloadSpeed ? (dicon = "bad") : (dicon = "good");
          uploadSpeed < minUploadSpeed ? (uicon = "bad") : (uicon = "good");

          this.setState({
            testConnectionLoader: false
          });

          if (internetStatus) {
            this.setState({
              //modalBodyContent: `<i class="fas fa-wifi fa-3x mt-2 good"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes"> <span class="${dicon}"> ${downloadSpeed} mbps DL </span> |  <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p>`,
              modalBodyContent: `<i class="fas fa-wifi fa-3x mt-2 good"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes"> <span class="${dicon}"> ${downloadSpeed} mbps DL </span> |  <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p> <p class="m-0 p-0 mt-4 speedTestReqTitle">Minimum Requirements</p> <p class="p-0 m-0 mt-1 speedTestReqText">4 mbps DL | 1 mbps UL </p> `,
              modal: true,
              cancelModal: this.checkForDuplicateMedicareCard,
              modalCloseButtonTitle: "Okay",
              modalClassName: "test-connection-model-body",
              modalTitle: "Instant Consult",
              modalSingleButton: true
            });
          } else {
            this.setState({
              //modalBodyContent: `<i class="fas fa-wifi fa-3x mt-2 bad"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes">  <span class="${dicon}">  ${downloadSpeed} mbps DL </span> | <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p>`,
              modalBodyContent: `<i class="fas fa-wifi fa-3x mt-2 bad"></i> <p class="m-0 p-0 mt-4 speedTestTitle">Speed Test Results</p> <p class="p-0 m-0 mt-1 speedTestRes">  <span class="${dicon}">  ${downloadSpeed} mbps DL </span> | <span class="${uicon}"> ${uploadSpeed} mbps UL </span> </p> <p class="m-0 p-0 mt-4 speedTestReqTitle">Minimum Requirements</p> <p class="p-0 m-0 mt-1 speedTestReqText">4 mbps DL | 1 mbps UL </p> <p class="mt-4 font-15"> Your internet speed is very slow. <br/> Please try again when you have <br/> a stronger internet connection. </p> `,
              modal: true,
              modalClassName: "test-connection-model-body",
              cancelModal: this.closeTestConnectionModal,
              modalCloseButtonTitle: "Okay",
              modalTitle: "Instant Consult",
              modalSingleButton: true
            });
          }
        }
      })
      .catch(err => {
        this.setState({
          modalBodyContent: this.state.poorInternetConnection,
          modal: true,
          modalClassName: "test-connection-model-body",
          testConnectionLoader: false,
          cancelModal: this.closeTestConnectionModal,
          modalCloseButtonTitle: "Okay",
          modalTitle: "Instant Consult",
          modalSingleButton: true
        });
      });
  };

  openAddCardModal = () => {
    this.setState({ modal: false, addCardPopup: true });

    // if (this.state.cardPopUpRoute === "SAVED_CARD") {
    //   this.props.history.push(appRoutes.savedCards);
    // } else {
    //   this.props.history.push(appRoutes.addNewCard);
    // }
  };

  routeToConsultationDet = () => {
    this.setState({ modal: false });
    this.props.history.push(appRoutes.consultationDetails);
  };

  routeToBookingPage = () => {
    this.setState({ modal: false });
    this.props.history.push(appRoutes.booking);
  };

  routeToEditPatientDets = () => {
    this.setState({ modalThreeBtns: false, isSecondBtnAsDropDown: false });
    this.props.history.push(
      this.state.selectedUserForConsult.isDependent
        ? appRoutes.familyProfile
        : appRoutes.editProfile
    );
  };

  callOnSupport = () => {
    this.setState({
      modalThreeBtns: false,
      modal: false,
      isSecondBtnAsDropDown: false
    });
    document.location.href = "tel:1300 003 310";
  };

  setAvgWaitTime = () => {
    let avgWaitTime =
      this.props.commonReducers &&
      this.props.commonReducers.patientQueueWaitTime !== null &&
      this.props.commonReducers.patientQueueWaitTime !== undefined &&
      this.props.commonReducers.patientQueueWaitTime !== Infinity &&
      !Number.isNaN(this.props.commonReducers.patientQueueWaitTime) &&
      this.props.commonReducers.patientQueueWaitTime != "N/A"
        ? this.props.commonReducers.patientQueueWaitTime
        : "N/A";
    this.setState({
      avgWaitTime
    });
  };

  confirmConsultRequest = () => {
    if (!this.state.activeTab) {
      this.scrollTop();
      return;
    }
    logFirebaseEvent("Consult Request Confirm Button");
    // this.callHomePageDataApi();
    this.setAvgWaitTime();
    this.startRefreshHourListInterval();
    this.setState({
      isProcessing: true
    });
    audioVideoPermisions(data => {
      console.log("Audio Video Test done: ", data);
      this.setState({
        modalThreeBtns: true,
        modalTitle: "Instant Consult",
        firstButtonText:
          this.props.auth.user[0].firstName +
          " " +
          this.props.auth.user[0].lastName,
        firstButton: () => {
          this.setState({
            modalThreeBtns: false,
            selectedUserForConsult: this.props.auth.user[0]
          });
          // this.checkForDuplicateMedicareCard();
          // state update takes time to update variable details
          setTimeout(() => {
            this.checkForDuplicateMedicareCard();
          }, 100);
        },
        secondButtonText: "Add Dependent",
        secondButton: () => {
          this.setState({ modalThreeBtns: false });
          this.props.history.push(appRoutes.familyProfile);
        },
        thirdButtonText: "Someone Else",
        thirdButton: () => {
          this.setState({ modalThreeBtns: false });
          this.requestForSomeoneElse();
        },
        modalBodyContentThreeBtns: this.requestModalText,
        isProcessing: false,
        modalClassName: "md",
        isSecondBtnAsDropDown: this.state.dependents.length ? true : false
      });
      // TODO - please remove above code and un-comment below code
      // if (data[0] === false) {
      //   this.setState({
      //     cameraStatusModelOpen: true,
      //     isProcessing: false
      //   });
      // } else if (data[1] === false) {
      //   this.setState({
      //     cameraStatusModelOpen: true,
      //     isProcessing: false
      //   });
      // } else {
      //   if (this.state.activeTab !== 0) {
      //     this.setState({
      //       modalThreeBtns: true,
      //       modalTitle: "Instant Consult",
      //       firstButtonText:
      //         this.props.auth.user[0].firstName +
      //         " " +
      //         this.props.auth.user[0].lastName,
      //       firstButton: () => {
      //         this.setState({
      //           modalThreeBtns: false,
      //           selectedUserForConsult: this.props.auth.user[0]
      //         });
      //         // this.checkForDuplicateMedicareCard();
      //         // state update takes time to update variable details
      //         setTimeout(() => {
      //           this.checkForDuplicateMedicareCard();
      //         }, 100);
      //       },
      //       secondButtonText: "Add Dependent",
      //       secondButton: () => {
      //         this.setState({ modalThreeBtns: false });
      //         this.props.history.push(appRoutes.familyProfile);
      //       },
      //       thirdButtonText: "Someone Else",
      //       thirdButton: () => {
      //         this.setState({ modalThreeBtns: false });
      //         this.requestForSomeoneElse();
      //       },
      //       modalBodyContentThreeBtns: this.requestModalText,
      //       isProcessing: false,
      //       modalClassName: "md",
      //       isSecondBtnAsDropDown: this.state.dependents.length ? true : false
      //     });
      //   } else {
      //     this.setState({
      //       modal: true,
      //       modalTitle: "Instant Consult",
      //       modalBodyContent: "Please select Nature of Consult",
      //       modalSingleButton: true,
      //       cancelModal: this.closeModal,
      //       modalCloseButtonTitle: "Okay",
      //       modalClassName: "",
      //       isProcessing: false
      //     });
      //   }
      // }
    });
  };

  // get the nature of consult list
  callGetConsultsAPI = () => {
    this.setState({
      isProcessing: true
    });

    let apiCallObj = {
      reqType: "GET",
      reqURL: getConsultsApiRoute,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: res => {
        this.setState({
          consultTypes: res.data.data,
          isProcessing: false
        });
      },
      failureCallback: err => {
        this.setState({
          // showErrorModal: true,
          isProcessing: false
        });
      },
      showPopUp: false
    };
    this.setState({
      getConsultsApiCall: apiCallObj
    });
  };

  consentPageFormSubmit = values => {
    this.setState({
      consentPageValues: values
    });

    this.showBookingOrRequestModal();
  };

  callReqAConsultAPI = () => {
    if (this.state.showPrivateFeeAcceptanceModal === true) {
      logFirebaseEvent("Private Fee Consent Accepted");
    }
    var formData = new FormData();
    if (this.state.isBooking) {
      const time = momentTz(this.state.selectedTime, "hh:mm a");
      const earliestTime = momentTz(
        this.getEarliestTime().format("hh:mm a"),
        "hh:mm a"
      );

      if (moment(time).valueOf() < earliestTime.valueOf()) {
        this.setAvgTimeAndRefreshHourList();
        this.setState({
          testConnectionLoader: false,
          modal: true,
          modalTitle: "Instant Consult",
          modalBodyContent: "Please select another time",
          modalSingleButton: true,
          cancelModal: () => {
            this.setState({ modal: false });
          },
          modalCloseButtonTitle: "Okay"
        });
        return;
      }

      formData.append("bookingTime", time.toISOString());
    }

    this.setState({
      modal: false,
      showPrivateFeeAcceptanceModal: false,
      showBookingModal: false,
      showBookingOrRequestModal: false
    });

    this.setState({
      modal: false,
      showBookingOrRequestModal: false,
      showPrivateFeeAcceptanceModal: false,
      modalThreeBtns: false,
      isSecondBtnAsDropDown: false,
      testConnectionLoader: true,
      loaderText: "Requesting a Consult..."
    });

    // get the browser details
    let browserDetails = getBrowserDetails();

    formData.append("isBooking", this.state.isBooking);

    // send medicare fields to the request consult api
    if (this.state.consentPageValues !== null) {
      formData.append("isBulkBilled", true);
      formData.append(
        "medicareGuardianChecbox",
        this.state.consentPageValues.cbOptional
      );
      formData.append(
        "medicareConcessionCardCheckBox",
        this.state.consentPageValues.cbConcession
      );
      formData.append("assignRightsCheckbox", this.state.consentPageValues.cb);
      // formData.append(
      //   "patientSignature",
      //   this.state.consentPageValues.signatureImg
      // );
      formData.append(
        "patientSignature",
        this.state.consentPageValues.signatureImg.replace(
          "data:image/png;base64,",
          ""
        )
      );
      formData.append(
        "selectedCovidSituation",
        this.state.covidSituationSelected
      );
    } else {
      formData.append("isBulkBilled", false);
    }

    if (this.state.imgs.length) {
      for (var i = 0; i < this.state.imgs.length; i++) {
        formData.append("docs[]", this.state.imgs[i]);
      }
      formData.append("isFile", true);
    } else formData.append("isFile", false);

    formData.append(
      "consultId",
      this.state.consultTypes[this.state.activeTab - 1]._id
    );

    formData.append("consultInfo", this.state.additionalInfo);
    formData.append("device_model", browserDetails.device_model);
    formData.append("os_version", browserDetails.os_version);
    formData.append("app_version", browserDetails.app_version);
    formData.append("browser_name", browserDetails.browser_name);
    formData.append("patientId", this.state.selectedUserForConsult._id);
    formData.append(
      "isDependent",
      this.state.selectedUserForConsult.isDependent ? true : false
    );

    let headers = {
      Authorization: "Bearer " + this.props.auth.user[0].accessToken
    };

    let apiCallObj = {
      reqType: "POST",
      reqURL: reqAConsultApiRoute,
      dataObj: formData,
      params: {},
      headers: headers,
      successCallback: async res => {
        if (res.data.statusCode !== 200 && res.data.statusCode !== 201) {
          if (
            res.data.type === "ADD_CARD_FIRST" ||
            res.data.type === "INSUFFICIENT-FUNDS" ||
            res.data.type === "INVALID_CARD" ||
            res.data.type === "EXPIRED_CARD" ||
            res.data.type === "INVALID_CVC" ||
            res.data.type === "INVALID_PIN" ||
            res.data.type === "INVALID_ACCOUNT"
          ) {
            let cardNav = "SAVED_CARD";

            let popUpErrMsg = res.data.message;

            if (res.data.type === "ADD_CARD_FIRST") {
              popUpErrMsg = "Please add a Payment Method to Request a Consult";
              cardNav = "NEW_CARD";
            }

            this.setState({
              testConnectionLoader: false,
              cardPopUpRoute: cardNav,
              modal: true,
              modalTitle: "Instant Consult",
              modalBodyContent: popUpErrMsg,
              modalSingleButton: true,
              cancelModal: this.openAddCardModal,
              modalCloseButtonTitle: "Okay",
              modalClassName: ""
            });
          } else {
            this.setState({
              showErrorModal: true
            });
          }
        } else {
          console.log({ makeRequestRes: res.data.data });
          await this.props.saveAppointmentData(res.data.data);
          setTimeout(() => {
            this.setState({
              testConnectionLoader: false
            });

            if (this.state.isBooking) {
              this.routeToBookingPage();
            } else {
              this.routeToConsultationDet();
            }
          }, 1000);
        }
      },
      failureCallback: err => {
        console.log("Card error: ", err);
        this.setState({
          testConnectionLoader: false
        });

        if (
          err &&
          (err.type === "ADD_CARD_FIRST" ||
            err.type === "INSUFFICIENT-FUNDS" ||
            err.type === "INVALID_CARD" ||
            err.type === "EXPIRED_CARD" ||
            err.type === "INVALID_CVC" ||
            err.type === "INVALID_PIN" ||
            err.type === "INVALID_ACCOUNT"
            || err.type === "CARD_EXPIRED"
          )
        ) {
          let cardNav = "SAVED_CARD";
          let popUpErrMsg = err.message;

          if (err.type === "ADD_CARD_FIRST") {
            popUpErrMsg = "Please add a Payment Method to Request a Consult";
            cardNav = "NEW_CARD";
          }

          this.setState({
            cardPopUpRoute: cardNav,
            modal: true,
            modalTitle: "Instant Consult",
            modalBodyContent: popUpErrMsg,
            modalSingleButton: true,
            cancelModal: this.openAddCardModal,
            modalCloseButtonTitle: "Okay",
            modalClassName: ""
          });
        } else if (err.message) {
          this.setState({
            cardPopUpRoute: "",
            modal: true,
            modalTitle: "Instant Consult",
            modalBodyContent: replaceNewLineWithBrTag(err.message),
            modalSingleButton: true,
            cancelModal: () => {
              window.location.reload(true);
            },
            modalCloseButtonTitle: "Okay",
            modalClassName: ""
          });
        } else {
          this.setState({
            showErrorModal: true
          });
        }
      },
      showErrorRes: false
    };
    this.setState({
      getConsultsApiCall: apiCallObj
    });
  };

  // callReqAConsultAPI = () => {
  //   if (this.state.showPrivateFeeAcceptanceModal === true) {
  //     logFirebaseEvent("Private Fee Consent Accepted");
  //   }
  //   this.setState({
  //     modal: false,
  //     showPrivateFeeAcceptanceModal: false,
  //     modalThreeBtns: false,
  //     isSecondBtnAsDropDown: false,
  //     testConnectionLoader: true,
  //     loaderText: "Requesting a Consult..."
  //   });

  //   // get the browser details
  //   let browserDetails = getBrowserDetails();

  //   var formData = new FormData();

  //   // send medicare fields to the request consult api
  //   if (this.state.consentPageValues !== null) {
  //     formData.append("isBulkBilled", true);
  //     formData.append(
  //       "medicareGuardianChecbox",
  //       this.state.consentPageValues.cbOptional
  //     );
  //     formData.append(
  //       "medicareConcessionCardCheckBox",
  //       this.state.consentPageValues.cbConcession
  //     );
  //     formData.append("assignRightsCheckbox", this.state.consentPageValues.cb);
  //     formData.append(
  //       "patientSignature",
  //       this.state.consentPageValues.signatureImg
  //     );
  //     formData.append(
  //       "selectedCovidSituation",
  //       this.state.covidSituationSelected
  //     );
  //   } else {
  //     formData.append("isBulkBilled", false);
  //   }

  //   if (this.state.imgs.length) {
  //     for (var i = 0; i < this.state.imgs.length; i++) {
  //       formData.append("docs[]", this.state.imgs[i]);
  //     }
  //     formData.append("isFile", true);
  //   } else formData.append("isFile", false);

  //   formData.append(
  //     "consultId",
  //     this.state.consultTypes[this.state.activeTab - 1]._id
  //   );

  //   formData.append("consultInfo", this.state.additionalInfo);
  //   formData.append("device_model", browserDetails.device_model);
  //   formData.append("os_version", browserDetails.os_version);
  //   formData.append("app_version", browserDetails.app_version);
  //   formData.append("browser_name", browserDetails.browser_name);

  //   let headers = {
  //     Authorization: "Bearer " + this.props.auth.user[0].accessToken
  //   };

  //   let apiCallObj = {
  //     reqType: "POST",
  //     reqURL: reqAConsultApiRoute,
  //     dataObj: formData,
  //     params: {},
  //     headers: headers,
  //     successCallback: async res => {
  //       if (res.data.statusCode !== 200 && res.data.statusCode !== 201) {
  //         if (
  //           res.data.type === "ADD_CARD_FIRST" ||
  //           res.data.type === "INSUFFICIENT-FUNDS" ||
  //           res.data.type === "INVALID_CARD" ||
  //           res.data.type === "EXPIRED_CARD" ||
  //           res.data.type === "INVALID_CVC" ||
  //           res.data.type === "INVALID_PIN" ||
  //           res.data.type === "INVALID_ACCOUNT"
  //         ) {
  //           let cardNav = "SAVED_CARD";

  //           let popUpErrMsg = res.data.message;

  //           if (res.data.type === "ADD_CARD_FIRST") {
  //             popUpErrMsg = "Please add a Payment Method to Request a Consult";
  //             cardNav = "NEW_CARD";
  //           }

  //           this.setState({
  //             testConnectionLoader: false,
  //             cardPopUpRoute: cardNav,
  //             modal: true,
  //             modalTitle: "Instant Consult",
  //             modalBodyContent: popUpErrMsg,
  //             modalSingleButton: true,
  //             cancelModal: this.routeForCardErrors,
  //             modalCloseButtonTitle: "Okay",
  //             modalClassName: ""
  //           });
  //         } else {
  //           this.setState({
  //             showErrorModal: true
  //           });
  //         }
  //       } else {
  //         await this.props.saveAppointmentData(res.data.data);
  //         setTimeout(() => {
  //           this.setState({
  //             testConnectionLoader: false
  //           });

  //           this.routeToConsultationDet();
  //         }, 1000);
  //       }
  //     },
  //     failureCallback: err => {
  //       this.setState({
  //         testConnectionLoader: false
  //       });

  //       if (
  //         err.type === "ADD_CARD_FIRST" ||
  //         err.type === "INSUFFICIENT-FUNDS" ||
  //         err.type === "INVALID_CARD" ||
  //         err.type === "EXPIRED_CARD" ||
  //         err.type === "INVALID_CVC" ||
  //         err.type === "INVALID_PIN" ||
  //         err.type === "INVALID_ACCOUNT"
  //       ) {
  //         let cardNav = "SAVED_CARD";
  //         let popUpErrMsg = err.message;

  //         if (err.type === "ADD_CARD_FIRST") {
  //           popUpErrMsg = "Please add a Payment Method to Request a Consult";
  //           cardNav = "NEW_CARD";
  //         }

  //         this.setState({
  //           cardPopUpRoute: cardNav,
  //           modal: true,
  //           modalTitle: "Instant Consult",
  //           modalBodyContent: popUpErrMsg,
  //           modalSingleButton: true,
  //           cancelModal: this.routeForCardErrors,
  //           modalCloseButtonTitle: "Okay",
  //           modalClassName: ""
  //         });
  //       } else {
  //         this.setState({
  //           showErrorModal: true
  //         });
  //       }
  //     }
  //   };
  //   this.setState({
  //     getConsultsApiCall: apiCallObj
  //   });
  // };

  closeAddCardPopUp = () => {
    this.setState({
      addCardPopup: false
    });
  };

  closecardSavedPopUp = () => {
    this.setState({
      cardSavedPopUp: false
    });

    if (this.state.cardSaveSuccessful) {
      this.callReqAConsultAPI();
    }
  };

  submitCardForm = formValues => {
    this.setState({
      isProcessing: true
    });
    let headers = {
      Authorization: "Bearer " + this.props.auth.user[0].accessToken
    };
    let data = {
      stripeToken: formValues.token.id,
      defaultStatus: 1
    };
    axios({
      method: "POST",
      url: addNewCardApiRoute,
      headers: headers,
      data: data
    })
      .then(res => {
        this.setState({
          modalText: "Card Saved Successfully",
          cardSaveSuccessful: true,
          cardSavedPopUp: true,
          addCardPopup: false,
          isProcessing: false
        });
      })
      .catch(err => {
        console.log("Got the error here: ", err.response);
        this.setState({
          isProcessing: false
        });
        if (err.response) {
          if (
            err.response.data.statusCode === 401 &&
            err.response.data.error === "Unauthorized"
          ) {
            this.setState({
              modalText: "Unauthorized Request",
              cardSaveSuccessful: false,
              cardSavedPopUp: true
            });
          } else if (
            err.response.data.statusCode === 403 &&
            err.response.data.type === "SESSION_EXPIRED"
          ) {
            this.setState({
              modalText: "Session has expired",
              cardSaveSuccessful: false,
              cardSavedPopUp: true
            });

            setTimeout(() => {
              this.props.logOut();
            }, 1500);
          } else {
            console.log("Got the message here: ", err.response);
            let popUpErrMsg = err.response.data.message;
            console.log("Error message: ", popUpErrMsg);
            // popUpErrMsg = popUpErrMsg.substr(0, popUpErrMsg.indexOf("."));
            // if (popUpErrMsg.includes("Insufficient")) popUpErrMsg += "s";
            // else if (popUpErrMsg.includes("Security Code"))
            //   popUpErrMsg = popUpErrMsg.replace("Security Code", "CVC");
            this.setState({
              modalText: popUpErrMsg,
              cardPopUpRoute: "",
              cardSaveSuccessful: false,
              cardSavedPopUp: true
            });
          }
        }
      });
  };

  showQueuePopup = () => {
    let queuingText =
      "You have joined a short queue as all doctors are attending to consults now or there are no doctors online. Please wait in the queue until you are connected.</br></br>We thank you for your patience.";

    this.setState({
      modal: true,
      modalTitle: "QUEUING",
      modalBodyContent: queuingText,
      modalSingleButton: true,
      cancelModal: this.routeToConsultationDet,
      modalCloseButtonTitle: "Okay"
    });
  };

  closeTestConnectionModal = () => {
    this.setState({ modal: false });
  };

  closeCameraStatusModel = () => {
    this.setState({
      cameraStatusModelOpen: false
    });
  };

  handleCovidFormSubmit = (
    selectedOption,
    isBulkBilledConsult,
    isAreaHotspot
  ) => {
    if (
      isBulkBilledConsult === true &&
      (isAreaHotspot === true || !selectedOption.includes("impacted area"))
    ) {
      this.scrollTop();
      // show concent page
      this.setState({
        openCovidHotspotModal: false,
        openCovidCriteriaModal: false,
        requestStep: 2,
        covidSituationSelected: selectedOption
      });
    } else {
      this.scrollTop();
      logFirebaseEvent("Private Fee Consent");
      // do private consult
      this.setState({
        openCovidHotspotModal: false,
        openCovidCriteriaModal: false,
        covidSituationSelected: selectedOption,
        showPrivateFeeAcceptanceModal: true
      });
    }
  };

  // handleCovidFormSubmit_backup = (
  //   selectedOption,
  //   isBulkBilledConsult,
  //   isAreaHotspot
  // ) => {
  //   if (isBulkBilledConsult === true) {
  //     this.scrollTop();
  //     if (isAreaHotspot === true || !selectedOption.includes("impacted area")) {
  //       // show concent page
  //       this.setState({
  //         openCovidHotspotModal: false,
  //         openCovidCriteriaModal: false,
  //         requestStep: 2,
  //         covidSituationSelected: selectedOption
  //       });
  //     } else {
  //       this.setState({
  //         openCovidHotspotModal: false,
  //         openCovidCriteriaModal: false,
  //         openCovidCriteriaModal: true,
  //         covidHotSpotsVisibility: false
  //       });
  //     }
  //   } else {
  //     this.scrollTop();
  //     logFirebaseEvent("Private Fee Consent");
  //     // do private consult
  //     this.setState({
  //       openCovidHotspotModal: false,
  //       openCovidCriteriaModal: false,
  //       covidSituationSelected: selectedOption,
  //       showPrivateFeeAcceptanceModal: true
  //     });
  //   }
  // };

  togglePrivateFeeAcceptanceModal = () => {
    logFirebaseEvent("Private Fee Consent");
    this.setState({
      modalThreeBtns: false,
      isSecondBtnAsDropDown: false,
      showPrivateFeeAcceptanceModal: !this.state.showPrivateFeeAcceptanceModal
    });
  };

  handleDependentDropDown = (dependentId, dependent) => {
    console.log("Hey DD changed dependentId: ", dependentId, dependent);
    this.setState({
      modalThreeBtns: false,
      selectedUserForConsult: dependent
    });
    // state update takes time to update variable details
    setTimeout(() => {
      this.checkForDuplicateMedicareCard();
    }, 100);
  };

  gotoFamilyProfile = () => {
    this.props.history.push(appRoutes.familyProfile);
  };

  /*
  validateIHI = patientInfo => {
    //TODO: this.state.activeTab===2, prescription

    const {
      _id,
      address,
      dateOfBirth: dob,
      firstName: fname,
      lastName: lname,
      gender,
      medicareNumber: medNo,
      refNumerOnMedicareCard: refNo,
      IHIInfo = {}
    } = patientInfo;

    const { updatedAt, IHIRecordStatus, IHINumberStatus, IHINumber } = IHIInfo;

    const updatedAtTimestamp = new Date(updatedAt).getTime();
    const validationInterval = 1000 * 3600;
    const currentTimestamp = new Date().getTime();

    if (currentTimestamp - updatedAtTimestamp < validationInterval) return;

    const { streetNumber: un, streetName, state, postCode, suburb } = address;

    const values = {
      _id,
      dob,
      fname,
      lname,
      gender,
      medNo,
      refNo,
      IHINumber,
      un,
      streetName,
      state,
      postCode,
      suburb
    };
    const isValidInput = HIServiceUtil.checkValidInputOfSearchIHI(values);

    if (!isValidInput.basicInfo) return;

    if (
      !isValidInput.IHINumber &&
      !isValidInput.Medicare &&
      !isValidInput.address
    )
      return;

    let criteriaType = ""; // use criteriaType to determine the IHI search method
    if (isValidInput.IHINumber) {
      // validate IHI by IHINumber
      criteriaType = HIServiceUtil.searchIHICriteriaType.IHINumber;
    } else if (isValidInput.Medicare) {
      // search IHI by Medicare
      criteriaType = HIServiceUtil.searchIHICriteriaType.Medicare;
    } else if (isValidInput.address) {
      // search IHI by address
      criteriaType = HIServiceUtil.searchIHICriteriaType.address;
    }

    // before validating IHI, check whether there are any other patients with the same demographic info
    const checkDuplicatePatientDemographicInfo = values => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("patientId", values._id);
        formData.append("firstName", values.fname);
        formData.append("lastName", values.lname);
        formData.append("DOB", values.dob);
        formData.append("gender", values.gender);
        formData.append("postcode", values.postCode);
        formData.append("streetName", values.streetName);

        const apiCallObj = {
          reqType: "POST",
          reqURL: checkDuplicatePatientDemographicInfoApiRoute,
          dataObj: formData,
          params: {},
          headers: {},
          successCallback: res => {
            if (res.data && res.data.data) {
              const result = res.data.data;
              const duplicatePatientCount = Object.keys(result).length;
              if (duplicatePatientCount > 0) {
                reject("Duplicate Patient in Database");
              } else {
                resolve();
              }
            } else {
              reject("no valid response");
            }
          },
          failureCallback: err => {
            reject(err ? err.message : "");
          },
          showPopUp: false
        };

        this.setState({
          validateIHIApiCallParams: apiCallObj
        });
      });
    };

    checkDuplicatePatientDemographicInfo(values).then(() => {
      const payload = HIServiceUtil.getSearchIHIPayload(criteriaType, values);
      payload.patientID = values._id;
      payload.serviceType = HIServiceType.SEARCH_IHI;
      const formData = new FormData();
      formData.append("HIInfo", JSON.stringify(payload));

      const apiCallObj = {
        reqType: "POST",
        reqURL: requestHIServiceSearchIHIApiRoute,
        dataObj: formData,
        params: {},
        headers: {},
        successCallback: res => {
          if (!res.data || !res.data.data || !res.data.data.searchIHIResult)
            return;

          // if (
          //   res.data.data.searchIHIResult.serviceMessages &&
          //   res.data.data.searchIHIResult.serviceMessages.serviceMessage
          // )
          //   return;

          const {
            IHIUpdatedAt: updatedAt,
            isAlert,
            alertMessage
          } = res.data.data;
          const {
            ihiNumber: IHINumber,
            ihiRecordStatus: IHIRecordStatus,
            ihiStatus: IHINumberStatus
          } = res.data.data.searchIHIResult;

          this.props.setIHIInfo({
            updatedAt,
            isAlert,
            alertMessage,
            IHINumber,
            IHIRecordStatus,
            IHINumberStatus
          });
        },
        failureCallback: err => {
          console.error("requestHIServiceSearchIHIApiRoute err", err);
        },
        showPopUp: false
      };

      this.setState({
        validateIHIApiCallParams: apiCallObj
      });
    });
  };
 */

  render() {
    return (
      <div className="row bg-white rounded p-3 add-attachment-model">
        {this.state.isProcessing === true && <Loader />}
        <div
          className={this.state.isProcessing === true ? "disableContainer" : ""}
        >
          {this.state.requestStep === 1 && (
            <Fragment>
              <Label
                title="Request a Consult"
                className="mt-4 w-100 ml-3 pb-3 big"
              />
              <div className="border-bottom w-100" />
              <div className="col-md-12">
                <Label title="1. Nature of Consult" className="mt-4 big" />
                {this.state.consultTypes.length > 0 &&
                  this.state.consultTypes.map((consult, index) => (
                    <div
                      key={index}
                      className="col-sm-12 text-left pl-0 pr-0 mt-4 cursor-pointer"
                      onClick={() =>
                        this.handleScrollToElement(
                          index + 1,
                          consult.consultName
                        )
                      }
                    >
                      <div
                        className={
                          this.state.activeTab === index + 1
                            ? "text-decoration-none active-border-gray links p-2 mt-2 border-gray"
                            : "text-decoration-none links p-2 mt-2 border-gray"
                        }
                      >
                        <img
                          src={consult.consultPic.original}
                          alt="consult-img"
                          // className={consult.consultName === "Results for Pathology or Radiology" ? "resize-req-consult-img mr-2" : ""}
                        />
                        <span className="ml-2 font-15">
                          {consult.consultName}
                        </span>
                      </div>
                    </div>
                  ))}
                <Label title="2. Images/Documents" className="mt-4 w-100 big" />

                <div className="col-sm-12">
                  <div className="row">
                    {this.state.selectedFile.map((element, index) => {
                      return (
                        <div
                          className="float-left position-relative"
                          key={index}
                        >
                          {this.state.fileType[index].includes("image") ? (
                            <img
                              src={element}
                              alt="no-img-instant-consult"
                              className="m-3 img-thumbnail att-fixed-size"
                            />
                          ) : (
                            <iframe
                              title="pdfFrame"
                              src={element}
                              alt="no-img-instant-consult"
                              className="m-3 img-thumbnail att-fixed-size"
                            />
                          )}
                          <i
                            className="fas fa-trash-alt att-delete-btn"
                            onClick={this.onClickDelete.bind(this, index)}
                          />
                          <div className="clearfix" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="row" ref={this.scrollDiv}>
                    <div className="col-sm text-center">
                      <AddAttachment
                        getFiles={data => {
                          if (this.validateFile(data)) {
                            let photoDoc = this.state.selectedFile;
                            let pd = this.state.imgs;
                            pd = pd.concat(data);
                            photoDoc = photoDoc.concat(
                              URL.createObjectURL(data)
                            );
                            let type = this.state.fileType;
                            type = type.concat(data.type);
                            this.setState({
                              selectedFile: photoDoc,
                              fileType: type,
                              imgs: pd
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="error mt-3 mb-0">{this.state.fileError}</p>

                <Label
                  title="3. Additional Information"
                  className="mt-4 w-100 big"
                />
                <ICInput
                  id="additionalInfo"
                  name="additionalInfo"
                  type="text"
                  label=""
                  placeholder="Please type any additional information for your consult..."
                  multiline
                  rows={5}
                  className="text-area mt-3"
                  onChange={e => {
                    this.handleAdditionalInfoInputChange(e.target.value);
                  }}
                />
                <Label
                  title="If you require emergency medical attention, do not proceed, dial 000 now"
                  className="mt-4 w-100 text-center red font-italic secondary-font"
                />
                <div className="text-center mt-5">
                  <InstaButton
                    title="Confirm"
                    type="button"
                    //disabled={this.state.activeTab === 0 ? true : false}
                    clickEvent={this.confirmConsultRequest}
                  />
                </div>
              </div>
              {this.state.testConnectionLoader && (
                <div className="loaded">
                  <div className="loads ">
                    <div className="load-div">
                      <div className="loader1">
                        <img
                          src={InternetTestLoaderGIF}
                          height="90px"
                          width="90px"
                          id="requestLoader"
                          alt="test-conn"
                        ></img>
                        <p>{this.state.loaderText}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}

          {this.state.requestStep === 2 && (
            <ConsentPage
              onFormSubmit={this.consentPageFormSubmit}
              selectedUserForConsult={this.state.selectedUserForConsult}
            />
          )}
        </div>
        <CustomModal
          modalBodyContent={
            <div className="requested-patient-name  w-100 mt-3 mb-0">
              {this.state.avgWaitTime === "N/A" ? (
                <p>Doctors currently offline</p>
              ) : (
                <p className="text-center">
                  The average wait time for a consult now is{" "}
                  {this.state.avgWaitTime} mins
                </p>
              )}
              {/* <span>
                Refer to our{" "}
                <a
                  href="https://www.instantconsult.com.au/FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="insta-link-orange text-decoration-none"
                >
                  FAQs
                </a>{" "}
                for more detail
              </span> */}
              {/* <br /> */}
              <br />
              <div className="p-2">
                <button
                  className="insta-button-primary"
                  onClick={async () => {
                    await this.setState({ isBooking: false });
                    logFirebaseEvent("Request a Consult Now Button Clicked");
                    this.callReqAConsultAPI();
                  }}
                >
                  Request a Consult Now
                </button>
                &nbsp;&nbsp;
                <button
                  className="insta-button-primary gray"
                  onClick={() => {
                    logFirebaseEvent("Booking for later button clicked");
                    this.setState({ isBooking: true });
                    this.showBookingModal();
                  }}
                >
                  Make a Booking for Later
                </button>
              </div>
            </div>
          }
          title="Request Consult"
          noFooterButtons={true}
          openModal={this.state.showBookingOrRequestModal}
          backdrop="static"
        />

        <CustomModal
          modalBodyContent={
            <div className="requested-patient-name  w-100 mt-3 mb-0">
              {this.state.hourList.length > 1 ? (
                <>
                  <p className="text-center">
                    What time today would you like to book your consult?
                  </p>
                  <TextField
                    className="col-sm-4"
                    placeholder="Select Time"
                    select
                    SelectProps={{
                      native: true
                    }}
                    onChange={this.setSelectedTime}
                    value={this.state.selectedTime}
                  >
                    {this.state.hourList.map((item, index) => {
                      return (
                        <option key={item + index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </TextField>
                </>
              ) : (
                <p className="text-center">
                  No further bookings available today
                </p>
              )}
            </div>
          }
          cancelText={this.state.hourList.length < 2 ? "Dismiss" : "Cancel"}
          okayText="Confirm"
          useOneButton={this.state.hourList.length < 2}
          title="Book Consult"
          cancelEvent={this.hideBookingModal}
          okayEvent={() => {
            this.callReqAConsultAPI(true);
          }}
          openModal={this.state.showBookingModal}
          backdrop="static"
        />

        <Modal
          modalBodyContent={this.state.modalBodyContent}
          cancelText={this.state.modalCloseButtonTitle}
          okayText={this.state.modalOkayButtonTitle}
          title={this.state.modalTitle}
          useOneButton={this.state.modalSingleButton}
          cancelEvent={this.state.cancelModal}
          okayEvent={this.state.okayModal}
          openModal={this.state.modal}
          className={this.state.modalClassName}
          backdrop="static"
        />
        <Modal
          modalBodyContent="Some error occured. Please refresh the page."
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={() => {
            window.location.reload(true);
          }}
          openModal={this.state.showErrorModal}
          backdrop="static"
        />
        <ModalThreeButtons
          modalBodyContent={this.state.modalBodyContentThreeBtns}
          firstButtonText={this.state.firstButtonText}
          secondButtonText={this.state.secondButtonText}
          thirdButtonText={this.state.thirdButtonText}
          title="Instant Consult"
          firstButton={this.state.firstButton}
          secondButton={this.state.secondButton}
          thirdButton={this.state.thirdButton}
          openModal={this.state.modalThreeBtns}
          className={this.state.modalClassName}
          isSecondBtnAsDropDown={this.state.isSecondBtnAsDropDown}
          dropDownTitle="My Child/Dependent"
          dropDownItems={
            <Fragment>
              {this.state.dependents.map((value, index) => {
                return (
                  <DropdownItem
                    key={index}
                    onClick={() =>
                      this.handleDependentDropDown(value._id, value)
                    }
                  >
                    {value.firstName + " " + value.lastName}
                  </DropdownItem>
                );
              })}
              <DropdownItem divider />
              <DropdownItem onClick={this.gotoFamilyProfile}>
                Add New
              </DropdownItem>
            </Fragment>
          }
        />

        <Modal
          openModal={this.state.cameraStatusModelOpen}
          // modalBodyContent="You will be unable to connect with patients <br> <br> You must login to another device <br>(with camera/microphone installed)<br> if you are accepting consult requests</p>"
          modalBodyContent="Please use a device with a camera and microphone installed or alternatively, download the Instant Consult app onto your smartphone. Contact Instant Consult Support on 1300 003 310 if you require any assistance."
          okayText="Okay"
          cancelText="Okay"
          title="Camera/Microphone Not Detected"
          useOneButton={true}
          okayEvent={this.closeCameraStatusModel}
          cancelEvent={this.closeCameraStatusModel}
        />

        <CustomModal
          title="Medicare Eligibility"
          openModal={this.state.openCovidHotspotModal}
          modalBodyContent={
            <CovidHotspotForm
              covidHotSpots={this.state.covidHotSpots}
              covidHotSpotsVisibility={this.state.covidHotSpotsVisibility}
              onFormSubmit={this.handleCovidFormSubmit}
            />
          }
          noFooterButtons={true}
          backdrop="static"
        />

        <CustomModal
          title="Medicare Eligibility"
          openModal={this.state.openCovidCriteriaModal}
          modalBodyContent={
            <CovidCriteriaForm
              covidHotSpots={this.state.covidHotSpots}
              covidHotSpotsVisibility={this.state.covidHotSpotsVisibility}
              onFormSubmit={this.handleCovidFormSubmit}
            />
          }
          noFooterButtons={true}
          backdrop="static"
          className="medicare-eligibility-modal"
        />

        <CustomModal
          modalBodyContent={
            <div className="requested-patient-name  w-100 mt-3 mb-0">
              <p className="text-center">
                I accept a private fee is applicable as I am not eligible for
                any Medicare rebates
              </p>
              {this.state.consultCostList.map((data, index) => {
                return (
                  <div className="w-100" key={index}>
                    <div className="col-sm-11 ml-3 mt-3 text-left">
                      <Label
                        title={
                          data.consultMode +
                          " " +
                          data.consultDuration +
                          " mins"
                        }
                        className="secondary-font custom-cost-width font-17 primary-color"
                      />
                      <Label
                        title={"$" + data.consultPrice.toFixed(2)}
                        className="secondary-font font-17"
                      />
                    </div>
                    <div className="col-sm-11 ml-3 text-left">
                      <Label
                        title={data.description}
                        className="gray font-14"
                      />
                      {/* {data.consultDuration > 10 && data.consultMode == "<" ? (
                      <Label
                        title={
                          "Consult Duration " +
                          "> " +
                          data.consultDuration / 2 +
                          " mins" +
                          "\xa0\xa0" +
                          data.consultMode +
                          " " +
                          data.consultDuration +
                          " mins"
                        }
                        className="gray font-14"
                      />
                    ) : (
                      <Label
                        title={
                          "Consult Duration" +
                          " " +
                          data.consultMode +
                          " " +
                          data.consultDuration +
                          " mins"
                        }
                        className="gray font-14"
                      />
                    )} */}
                    </div>
                    <div className="w-100 pt-2 pb-2 border-bottom" />
                  </div>
                );
              })}
              <br />
              <span>
                Refer to our{" "}
                <a
                  href="https://www.instantconsult.com.au/FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="insta-link-orange text-decoration-none"
                >
                  FAQs
                </a>{" "}
                for more detail
              </span>
            </div>
          }
          cancelText="Cancel"
          okayText="Accept"
          title="Private Fee Consent"
          cancelEvent={this.togglePrivateFeeAcceptanceModal}
          okayEvent={this.showBookingOrRequestModal}
          openModal={this.state.showPrivateFeeAcceptanceModal}
          backdrop="static"
        />

        <CustomModal
          modalBodyContent={
            <Elements stripe={this.state.stripePromise}>
              <NewCardForm
                cancel={this.closeAddCardPopUp}
                isPopup={true}
                onFormSubmit={this.submitCardForm}
                history={this.props.history}
                defCardStatus={this.setDefCardStatus}
              />
            </Elements>
          }
          cancelText="Okay"
          title="Add New Card"
          noFooterButtons={true}
          cancelEvent={this.closeAddCardPopUp}
          openModal={this.state.addCardPopup}
        />

        <Modal
          modalBodyContent={this.state.modalText}
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closecardSavedPopUp}
          openModal={this.state.cardSavedPopUp}
        />
        <CustomModal
          openModal={this.state.showConfirmUserData && this.state.userData}
          title="Instant Consult"
          okayEvent={() => {
            this.confirmPersonalInfo();
            this.setState({
              showConfirmUserData: false,
              userData: undefined
            });
          }}
          cancelEvent={() => {
            this.setState({
              showConfirmUserData: false,
              userData: undefined
            });

            this.props.history.push(appRoutes.editProfile);
          }}
          okayText="Confirm"
          cancelText="My Details Have Changed"
          modalBodyContent={
            <div className="w-100 mt-3 mb-0">
              <p className="font-15 py-0 text-left w-100 semi-bold-font">
                Are your contact details current?
              </p>
              <p className="font-15 text-left w-100">{`Name: ${this.state.userData?.firstName} ${this.state.userData?.lastName}`}</p>
              <p className="font-15 text-left w-100">{`Mobile: ${this.state.userData?.phoneNumber.replace(/\s/g, "").replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3")}`}</p>
              <p className="font-15 text-left w-100">{`Email Address: ${this.state.userData?.email}`}</p>
              <p className="font-15 text-left w-100">{`Home Address: ${getFormattedAddress(
                this.state.userData?.address
              )}`}</p>
            </div>
          }
        />

        <MakeAPICall apiParam={this.state.checkBookingsApiCallParams} />
        <MakeAPICall apiParam={this.state.getConsultsApiCall} />
        <MakeAPICall apiParam={this.state.apiCall} />
        {/* <MakeAPICall apiParam={this.state.getBookingApiCall} /> */}
        <MakeAPICall apiParam={this.state.covidHotSpotApiCallParams} />
        <MakeAPICall apiParam={this.state.validateIHIApiCallParams} />
        <MakeAPICall apiParam={this.state.dependentAPICallParam} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({
  saveAppointmentData: data => dispatch(saveAppointmentData(data)),
  // setScheduledBooking: data => dispatch(setScheduledBooking(data)),
  setIHIInfo: data => dispatch(setIHIInfo(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestAConsult);
