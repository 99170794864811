import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  DISMISS_MESSAGE,
  UPDATE_USER_INFO,
  SET_IHI_INFO
} from "../constants/auth";

export const loginUser = data => ({
  type: LOGIN,
  payload: data
});

export const loginUserSuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: data
});

export const loginUserError = data => ({
  type: LOGIN_ERROR,
  payload: data
});

export const logOutUser = () => ({
  type: LOGOUT
});

export const dismissMessage = () => ({
  type: DISMISS_MESSAGE
});

export const updateUserData = data => ({
  type: UPDATE_USER_INFO,
  payload: data
});

export const setIHIInfo = data => ({
  type: SET_IHI_INFO,
  payload: data
});
