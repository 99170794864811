const appRoutes = {
  //pages before login
  login: "/login",
  forgotPassword: "/forgot-password",
  recover: "/recover",
  signUp: "/signup",
  deleteAccountInstructions: "/deleteAccountInstructions",

  //pages after login
  requestAConsult: "/Dashboard/RequestAConsult",
  notifications: "/Dashboard/Notifications",
  inbox: "/Dashboard/Inbox",
  consultHistory: "/Dashboard/ConsultHistory",
  consultDetails: "/Dashboard/ConsultDetails",
  booking: "/Dashboard/booking",
  myProfile: "/Dashboard/Profile",
  savedCards: "/Dashboard/SavedCards",
  consultCosts: "/Dashboard/ConsultCosts",
  addNewCard: "/Dashboard/AddNewCard",
  editProfile: "/Dashboard/EditProfile",
  familyProfile: "/Dashboard/FamilyProfile",
  addFamilyProfile: "/Dashboard/Add/FamilyProfile",
  editEmergencyContacts: "/Dashboard/EditEmergencyContacts",
  editFamilyProfile: "/Dashboard/Edit/FamilyProfile",
  helpPage: "/Dashboard/Help",
  legalPage: "/Dashboard/Legal",
  blockedDoctors: "/Dashboard/BlockedDoctors",
  changePassword: "/Dashboard/ChangePassword",
  consultationDetails: "/Dashboard/ConsultationDetails",
  patientCallReceived: "/doctor/call/received",
  consultSummary: "/Dashboard/ConsultSummary",
  selectPharmacy: "/select-pharmacy",
  unsubscribe: '/marketing/unsubscribe'
};

export default appRoutes;
