import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

import appRoutes from "../../routes/appRoutes";

import Error from "../../components/error";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import SuccessMsg from "../../components/successMsg";

import Label from "../../components/label";
import { NavLink } from "react-router-dom";
import { Fragment } from "react";

const UnsubscribeEmailForm = props => {
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .min(7, "Email must be of minimum length 7")
                .email("Please provide a valid email address")
                .required("Please enter the email address")
        }),
        onSubmit: values => {
            props.onFormSubmit(values);
        }
    });
    return (
        <form
            className="d-flex flex-column align-items-center"
            onSubmit={formik.handleSubmit}
        >
            <Label
                title="We are sorry to see you go!"
                className="gray"
            />
            <Label
                title="Email Address"
                className="gray text-left w-75 mt-2 ex-small"
                required={true}
            />
            <ICInput
                id="email"
                name="email"
                type="text"
                label=""
                className="w-75"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email ? true : false}
                helperText={
                    formik.touched.email && formik.errors.email ? formik.errors.email : ""
                }
            />
            <br />
            <Button className="mt-2" title="Unsubscribe" type="submit" />
        </form>
    );
};

export default UnsubscribeEmailForm;

UnsubscribeEmailForm.defaultProps = {
    serverErrorMsg: "",
    successMsg: ""
};

UnsubscribeEmailForm.propTypes = {
    onFormSubmit: PropTypes.func,
    serverErrorMsg: PropTypes.string,
    successMsg: PropTypes.string
};
