import React, { useEffect, useState } from "react";
import TopNavBarLoggedIn from "../../TopNavBarLoggedIn";
import SideNavBar from "../../SideNavbar";
import Footer from "../../Footer";
import { connect } from "react-redux";
import { oneSignalSubscription } from "../../../reduxStore/actions/common";
import { useHistory } from "react-router-dom";
import Modal from "../../../components/modal";
import VideoInstruction1 from "../../../assets/images/Video_Allow_Instruction_1.jpg";
import VideoInstruction2 from "../../../assets/images/Video_Allow_Instruction_2.jpg";
import audioVideoPermisions from "../../../utils/checkAudioVideoPermissions";
import SocketHandler from "../../../containers/SocketHandler";
import APP_CONSTANTS from "../../../constants";
import socket from "../../../utils/socket";


const DashboardLayout = props => {
  //const [subscribeModelStatus, setSubscribedModelStatus] = useState(!props.userSubscribed);  // default user subscribed is false
  const [cameraStatusModelOpen, setCameraStatusModelOpen] = useState(false); // camera supported or not model
  const [audioVideoStatusModelOpen, setAudioVideoStatusModelOpen] = useState(
    false
  ); // check audio and video (if test fails then open this model)

  const [appointmentInProgress, setAppointmentInProgress] = useState(false); // if doctor accepted appointment then we have to set side bar to display none because appointment is not completed by doctor

  /*
   * Close subscribed model
   */

  // const closeSubscribeModel = () => {
  //   setSubscribedModelStatus(false);
  // };

  /*
   * Close camera status model
   */

  const closeCameraStatusModel = () => {
    setCameraStatusModelOpen(false);
  };

  /*
   * Close audio & video test model
   */

  const closeAudioVideoStatusModelOpen = () => {
    setAudioVideoStatusModelOpen(false);
  };

  useEffect(() => {
    // checking audio video test
    audioVideoPermisions(data => {
      console.log(data);
      if (data[0] === false) setCameraStatusModelOpen(true);
      else if (data[1] === false) setAudioVideoStatusModelOpen(true);
      else console.log("Audio Video Working Good");
    });
    askForLeavePage();
  }, []);

  // useEffect(() => {
  //   window.firebase.analytics().logEvent(window.location.pathname);
  // }, [window.location.pathname]);

  useEffect(() => {
    console.log("Common Reducers in Dashboard : ", props.commonReducers);
    if (props.commonReducers.appointmentData && props.commonReducers.appointmentData.status !== 0) setAppointmentInProgress(true);
    else setAppointmentInProgress(false);
  }, [props.commonReducers.appointmentData]);

  useEffect(() => {
    console.log("Auth Changes: ", props.auth);
    const isUserLoggedIn = props.auth && props.auth.user && props.auth.user[0] && props.auth.user[0]['accessToken'] ? true : false;
    console.log("Is User Logged In? ", isUserLoggedIn);


    if (isUserLoggedIn) {
      socket.connect();
      socket.on("connect", function () {
        console.log("patient connect via main socket");

        console.log("Emit event from patient done");
        socket.emit('join', "PATIENTS");
      });
    } else {
      console.log("Socket Disconnected");
      socket.disconnect();
    }
  }, [props.auth]);

  const askForLeavePage = () => {
    console.log("Ask For Leave on close");

    window.onbeforeunload = e => {
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = "Sure?";
      }

      // For Safari
      return "Sure?";
    };
  };

  return (
    <div className="d-flex flex-column main-app-container">
      <TopNavBarLoggedIn history={useHistory()} />
      <div className="main-content flex-grow-1 h-100">
        <div className="container-fluid">
          <div className="row p-4 mb-5">
            <div
              className={
                appointmentInProgress
                  ? "d-none"
                  : "col-sm-3 pl-4 pr-4 left-column"
              }
            >
              <SideNavBar />
            </div>
            <SocketHandler />
            <div
              className={
                appointmentInProgress
                  ? "col-sm-9 offset-sm-2 bg-white right-column-appointment-progress pb-3 card"
                  : "col-sm-9 bg-white right-column"
              }
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
      <Footer
        privacyPolicyLink={
          props.auth.user && props.auth.user.length > 1
            ? props.auth.user[1].privacyPolicy
            : APP_CONSTANTS.privacyPolicyURL
          // props.serverConstants ? props.serverConstants.legal : "#"
        }
      />
      <Modal
        openModal={cameraStatusModelOpen}
        // modalBodyContent="You will be unable to connect with patients <br> <br> You must login to another device <br>(with camera/microphone installed)<br> if you are accepting consult requests</p>"
        modalBodyContent="Please use a device with a camera and microphone installed or alternatively, download the Instant Consult app onto your smartphone. Contact Instant Consult Support on 1300 003 310 if you require any assistance."
        okayText="Okay"
        cancelText="Okay"
        title="Camera/Microphone Not Detected"
        useOneButton={true}
        okayEvent={closeCameraStatusModel}
        cancelEvent={closeCameraStatusModel}
      />
      <Modal
        openModal={audioVideoStatusModelOpen}
        modalBodyContent={`
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br />
            <p class="You-have-a-new-Consu">Please follow these instructions to allow your <br/> Camera & Microphone to proceed</p>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <img src="${VideoInstruction1}" style="width: 100%" alt="video-allow-instruction-1" />                    
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br />
            <p class="You-have-a-new-Consu">Then please click on Reload</p>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <img src="${VideoInstruction2}" style="width: 100%" alt="video-allow-instruction-1" />                    
        </div>
      `}
        okayText="Okay"
        cancelText="Okay"
        title="Camera/Microphone Not Detected"
        useOneButton={true}
      />
    </div>
  );
};

const mapStateToProps = props => ({
  userSubscribed:
    props.commonReducers &&
    props.commonReducers.oneSignalSubscribed &&
    props.commonReducers.oneSignalSubscribed.isSubscribed
      ? props.commonReducers.oneSignalSubscribed.isSubscribed
      : false,
  commonReducers: props.commonReducers,
  auth: props.auth
});

const mapDispatchToProps = dispatch => ({
  oneSignalSubscription: data => dispatch(oneSignalSubscription(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
