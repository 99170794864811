import React, { Fragment, useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Label from "../../components/label";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import SearchInput from "../../components/searchInput";
import Loader from "../../components/loader";

import PharmacyListDialog from "./pharmacyListDialog";

import {
    searchPharmacyDetailApiRoute,
} from "../../apiRoutes/index";
import MakeAPICall from "../../apiCalls/common";
import ChemistWarehouseInfo from "./ChemistWarehouseInfo";

const PharmacySelectForm = props => {
    const pharmacyListRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [apiCallParams, setApiCallParams] = useState(null);
    const [pharmacyList, setPharmacyList] = useState([]);
    const [selectedPharmacy, setSelectedPharmacy] = useState({});

    const formik = useFormik({
        initialValues: {
            postcode: ""
        },
        validationSchema: Yup.object({
            postcode: Yup.string()
                .required("this field is required")
                .matches(
                    Object.keys(selectedPharmacy).length > 0 ? /.*/ : /\d{4,}/,
                    "Please input a valid postcode"
                )
        }),
        onSubmit: () => {
            props.handlePharmacySubmission(selectedPharmacy);
        }
    });

    // deal with Postcode input API
    useEffect(() => {
        if (formik.values.postcode.length === 4 && typeof +formik.values.postcode === 'number') {
            // set API, setPharmacyList
            setLoading(true);

            setApiCallParams({
                reqType: "GET",
                reqURL: searchPharmacyDetailApiRoute,
                params: {
                    searchParam: formik.values.postcode
                },
                successCallback: res => {
                    setLoading(false);
                    if (!res.data || !res.data.data) return;
                    let pharmacyArray = [];
                    pharmacyArray = pharmacyArray.concat(res.data.data);

                    // chemist warehouse item in pharmacy list
                    pharmacyArray.push({
                        chemistWarehouse: "Chemist Warehouse Online"
                    });

                    setPharmacyList(pharmacyArray);
                },
                failureCallback: err => {
                    setLoading(false);
                    setPharmacyList([]);
                }
            });
        } else if (formik.values.postcode.length < 4) {
            setPharmacyList([]);
            return setSelectedPharmacy({});
        }
    }, [formik.values.postcode]);

    // // cancel pharmacy list popup when click outside of the popup
    // useEffect(() => {
    //     const handleClickOutside = event => {
    //         if (
    //             pharmacyListRef.current &&
    //             !pharmacyListRef.current.contains(event.target)
    //         ) {
    //             setPharmacyList([]);
    //             setIsShowPharmacyList(false);
    //         }
    //     };
    //     // Bind the event listener
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [pharmacyListRef]);

    // handle select a pharmacy
    const selectPharmacy = data => {
        console.log("Handeling data here: ", data);
        let selectedPharmacy = {};

        if (data.PharmacyName) {
            formik.values.postcode = data.PharmacyName;
            selectedPharmacy.pharmacyName = data.PharmacyName;
            selectedPharmacy.pharmacyAddress = data.PharmacyAddress1;
            selectedPharmacy.pharmacyAddress1 = data.PharmacyAddress2;
            selectedPharmacy.pharmacyAddress2 =
                data.Suburb + " " + data.State + " " + data.Postcode;
            selectedPharmacy.pharmacyEmail = data.PharmacyEmailAddress;
            selectedPharmacy.pharmacyNo = data.PharmacyPhoneNumber;
            selectedPharmacy.isPatient = false;
            selectedPharmacy.isChemistWarehouse = false;
        } else if (data.patientName) {
            selectedPharmacy.isPatient = true;
            selectedPharmacy.isChemistWarehouse = false;
            formik.values.postcode = data.patientName;
        } else if (data.chemistWarehouse) {
            selectedPharmacy.isChemistWarehouse = true;
            selectedPharmacy.isPatient = false;
            formik.values.postcode = data.chemistWarehouse;
        }

        setSelectedPharmacy(selectedPharmacy);
        props.handlePharmacySubmission(selectedPharmacy);

        setTimeout(() => {
            setLoading(false);
            setPharmacyList([]);
            formik.resetForm();
        }, 600);
    };

    return (
        <Fragment>
            {loading && <Loader />}
            <form className="w-100">
                <SearchInput
                    id="postcode"
                    name="postcode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.postcode}
                    className="w-100 font-14"
                    placeholder="Input Postcode"
                    autoComplete="new-password"
                />
                {formik.touched.postcode && formik.errors.postcode && (
                    <Label
                        className="small ml-1 error prescription-error"
                        title={formik.errors.postcode}
                    />
                )}
                <div className="pharmacy-list">
                    <ul className="list-group pharmacy-list" ref={pharmacyListRef}>

                        {
                            pharmacyList.map((item, index) => {
                                return (
                                    <li className="list-group-item" key={index} onClick={() => selectPharmacy(item)}>
                                        {item.PharmacyName && (
                                            <div className="item-container">
                                                <p className="m-0">
                                                    <Label title={item.PharmacyName}></Label>
                                                </p>

                                                <p className="m-0">
                                                    {item.PharmacyAddress1 && (
                                                        <Fragment><Label title={item.PharmacyAddress1}></Label>&nbsp;</Fragment>
                                                    )}
                                                    {item.PharmacyAddress2 && (
                                                        <Fragment><Label title={item.PharmacyAddress2}></Label>&nbsp;</Fragment>
                                                    )}
                                                </p>

                                                <p
                                                    className="m-0"
                                                    style={{ textTransform: "uppercase" }}
                                                >
                                                    <Label className="font-12" title={item.Suburb}></Label>&nbsp;<Label className="font-12" title={item.State}></Label>&nbsp;<Label className="font-12" title={item.Postcode}></Label>&nbsp;
                                                </p>
                                            </div>
                                        )}
                                        {item.chemistWarehouse && (
                                            <div className="item-container font-12">
                                                <span className="chemist-warehouse-online">
                                                    <b>{item.chemistWarehouse}</b>
                                                </span>
                                            </div>
                                        )}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                {/* <div ref={pharmacyListRef} style={{ position: "relative" }}>
                    {!loading && isShowPharmacyList && pharmacyList && (
                        <PharmacyListDialog
                            pharmacyList={pharmacyList}
                            selectPharmacy={selectPharmacy}
                            isNotShowPatient={props.isSelectPharmacyForSending}
                        />
                    )}
                </div> */}
                {/* <div className="text-center mt-5 ">
                    <Button
                        type="submit"
                        title="Submit"
                        // clickEvent={() => alert(formik.values.postcode)}
                        disabled={false}
                    ></Button>
                    <Button
                        type="button"
                        title="Cancel"
                        className="ml-3 gray-outline"
                        clickEvent={() => props.handleCancel(false)}
                    />
                </div> */}
            </form>
            <MakeAPICall apiParam={apiCallParams} />
        </Fragment>
    );
};

export default PharmacySelectForm;
