import React from "react";
import Label from "../../../components/label";
import Anchor from "../../../components/anchor";
import {logFirebaseEvent} from "../../../utils/common";

export default function ContactUs(props) {
  logFirebaseEvent("Contact Us Page");
  return (
    <div className="row contact-us mt-5">
      <div className="col-sm-6 text-center p-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              {" "}
              <i className="fas fa-phone-alt w-100 icon"></i>
            </h5>
            <Label className="gray large w-100 title" title="Call Support" />
            <Anchor
              title={(props.supportNumber).replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')}
              className="anchor text-decoration-none primary-color"
              href={"tel:"+props.supportNumber}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-6 text-center p-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">
              {" "}
              <i className="fas fa-envelope w-100 icon"></i>
            </h5>
            <Label className="gray large w-100 title" title="Email Support" />
            <Anchor
              title={props.email}
              className="anchor text-decoration-none primary-color"
              href={"mailto:"+props.email}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
