import React, { useState, useEffect } from "react";
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";

function ICDateInput(props) {
    const [selectedDate, handleDateChange] = useState(
        props.defaultDate ? new Date(props.defaultDate) : null
    );

    const handleChange = date => {
        handleDateChange(date);
        props.handleChange(date ? date.toISOString() : "");
    };

    useEffect(()=> {
        if(props.filterOn === false)
        handleChange("");
    },[props.filterOn]);

    return (
        <div
            className={
                "w-100 ic-date-input " + (props.className ? props.className : "")
            }
        >
            <MuiPickersUtilsProvider
                utils={MomentUtils}
                className={props.className ? props.className : ""}
            >
                <KeyboardDatePicker
                    autoOk
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    variant="inline"
                    id={props.id}
                    name={props.name}
                    label={props.label}
                    format="DD/MM/YYYY"
                    value={selectedDate ? selectedDate : null}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={date => {
                        handleChange(date);
                    }}
                    error={props.error}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default ICDateInput;
