// Don't modify anything except the last testing one's
const APP_CONSTANTS = {};

APP_CONSTANTS.turnServerInfo = {
  iceServers: [
    // separate aws stun server
    {
      urls: "stun:13.236.67.5:3478"
    },
    {
      // separate aws turn server
      urls: "turn:52.62.164.177",
      username: "prouser",
      credential: "3TptDG7cAfz5TaXsda"
    },
    {
      // turn server on rebuild staging
      urls: "turn:54.252.245.148",
      username: "prouser",
      credential: "3TptDG7cAfz5TaXsda"
    },
    // stun server on dev
    {
      urls: "stun:3.104.223.138:3478"
    }
  ]
};

APP_CONSTANTS.tAcURL =
  "https://instantconsult.s3.amazonaws.com/undefined/Document_0iwkoBdocJNZB37.pdf";
APP_CONSTANTS.privacyPolicyURL =
  "https://instantconsult.s3.amazonaws.com/undefined/Document_a85q6sdocP67OB3.pdf";
APP_CONSTANTS.privacyNoticeURL =
  "https://instantconsult.s3.amazonaws.com/undefined/Privacy+Notice.pdf";

// APP_CONSTANTS.hereMAPApi = "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json";
APP_CONSTANTS.hereMAPApi = "https://autocomplete.search.hereapi.com/v1/autocomplete";
// APP_CONSTANTS.hereMAPApiKEY = 'VIuu4eENMfVE61xtAggBEy0QDDPDOPamwiYa9VT00oU';
APP_CONSTANTS.hereMAPApiKEY = 'ju_lfXyDzJT7bK9QErr68UgtZoTUljz4a271qWG2P3Q';
// APP_CONSTANTS.hereMAPApiKEY = 'CPJp46Ru7eWu_2W74JGJBGNE5lqE3Ne7ZzTSiZT287Q'; // Used IOS Key for now - URGENT FIX

// For new staging
if (window.location.origin.indexOf("staging") > -1) {
  APP_CONSTANTS.apiUrl = "https://v4-staging-api.instantconsult.com.au";
  APP_CONSTANTS.pmsURL = "https://staging-pr.instantconsult.com.au";
  APP_CONSTANTS.patientURL = "https://staging-patient.instantconsult.com.au";
  APP_CONSTANTS.stripeKey = "pk_test_QZZD3xvNi8fL0FOfkbPhoafE";
  APP_CONSTANTS.instantSwitchPatientPortal =
    "https://staging-patient.instantswitch.com.au";
} else if (window.location.origin.indexOf("patient") > -1) {
  // for live
  APP_CONSTANTS.apiUrl = "https://v4-live-api.instantconsult.com.au";
  APP_CONSTANTS.pmsURL = "https://pr.instantconsult.com.au";
  APP_CONSTANTS.patientURL = "https://patient.instantconsult.com.au";
  APP_CONSTANTS.stripeKey = "pk_live_tNEvqj89jEDNsW5SmjhFWF4w";
  APP_CONSTANTS.instantSwitchPatientPortal =
    "https://patient.instantswitch.com.au";
} else {
  // For testing
  APP_CONSTANTS.apiUrl = "http://localhost:4000";
  APP_CONSTANTS.pmsURL = "http://localhost:3003";
  APP_CONSTANTS.patientURL = "http://localhost:3004";
  APP_CONSTANTS.stripeKey = "pk_test_QZZD3xvNi8fL0FOfkbPhoafE";
  APP_CONSTANTS.stripeKey = "pk_test_QZZD3xvNi8fL0FOfkbPhoafE";
  APP_CONSTANTS.instantSwitchPatientPortal = "http://localhost:4200";
}

export default APP_CONSTANTS;
