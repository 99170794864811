import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import appRoutes from "../../routes/appRoutes";

import Button from "../../components/button";
import ICInput from "../../components/form/input";
import ICCheckbox from "../../components/form/checkbox";
import Label from "../../components/label";
import ICSelect from "../../components/form/selectInput";
import ICDateInput from "../../components/form/dateInput";
import DefaultImage from "../../assets/images/default-user-img.png";
import MedicareLogo from "../../assets/images/medicare-logo.png";
import GovLogo from "../../assets/images/auGovLogo.png";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import moment from "moment";
import InputMask from "react-input-mask";
import CustomModal from "../../components/customModal";
import APP_CONSTANTS from "../../constants";
import ICPhoneInput from "../../components/form/phoneInput";
import { checkIsMedicareCheckDigitValid } from "../../utils/MedicareCardUtil";
import { requestHIServiceSearchIHIApiRoute } from "../../apiRoutes";
import RefreshButton from "../../components/refreshButton";
import MakeAPICall from "../../apiCalls/common";
import Loader from "../../components/loader";
import HIServiceUtil, { searchIHICriteriaType } from "../../utils/HIServiceUtil";
import { HIServiceType } from "../../constants/constants";
import IHIInfoText from "../../components/ihiInfoText";
import { IHIStates } from "../../utils/common";

const EditProfileForm = props => {
  const propsConst = props.userDetails;
  const [apiCall, setApiCall] = useState();
  const [apiLoading, setApiLoading] = useState(false);

  const streetNumberSetter = () => {
    let streetNumber;
    if (propsConst.address.unitNumber !== "") {
      streetNumber =
        propsConst.address.unitNumber + "/" + propsConst.address.streetNumber;
    } else streetNumber = propsConst.address.streetNumber;
    return streetNumber;
  };

  const [selectedFile, setSelectedFile] = useState(
    propsConst.profilePic.original
  );
  const [streetNumber, setStreetNumber] = useState(streetNumberSetter);
  const [unitNumber, setUnitNumber] = useState(propsConst.address.unitNumber);
  const [streetName, setStreetName] = useState(propsConst.address.streetName);
  const [suburb, setSuburb] = useState(propsConst.address.suburb);
  const [state, setState] = useState(propsConst.address.state);
  const [postCode, setPostCode] = useState(propsConst.address.postCode);
  const [showErrModal, setShowErrModal] = useState(false);
  const [errModalText, setErrModalText] = useState("");
  const [isMedicareCheckDigitValid, setIsMedicareCheckDigitValid] = useState(
    true
  );
  const inputFile = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [apiSearchResult, setAPISearchResult] = useState([]);
  const loading = open && options.length === 0 && searchTerm.trim().length > 4;
  const [userSelectedOption, setUserSelectedOption] = useState(null);
  const autoCompleteClearBtn = useRef(null);
  const [profilePicLargeErr, setProfilePicLargeErr] = useState(null);
  const [ihiState, setIhiState] = useState("");
  const [stateNames, setStateNames] = useState({
    "new south wales": "NSW",
    queensland: "QLD",
    "northern territory": "NT",
    "western australia": "WA",
    "south australia": "SA",
    victoria: "VIC",
    "australian capital territory": "ACT",
    tasmania: "TAS"
  });

  function getIHIText() {
    if (ihiState === "error") return IHIStates.FAILED;
    if (ihiState === "success") return IHIStates.SUCCESS;
    return IHIStates.NOT_LOADED;
  }

  function getIHIClassname() {
    if (ihiState === "error") return "error col-11 my-auto";
    if (ihiState === "success") return "green col-11 my-auto";
    return "col-11 my-auto";
  }

  const onChangeHandler = event => {
    let maxAllowedSize = 800000; //800KB
    if (event.target.files && event.target.files[0]) {
      let fileSizebig = false;
      if (event.target.files[0].size > maxAllowedSize) {
        fileSizebig = true;
        setProfilePicLargeErr("Maximum 800K file size is allowed");
      } else {
        fileSizebig = false;
        setProfilePicLargeErr(null);
      }
      if (fileSizebig === false) {
        setSelectedFile(URL.createObjectURL(event.target.files[0]));
        formik.values.profilePic = event.target.files[0];
        formik.values.isProfilePic = true;
      }
    }
  };

  const handleFileInput = () => {
    inputFile.current.click();
  };

  const cancelImgBtn = () => {
    setSelectedFile(DefaultImage);
    formik.values.isProfilePic = false;
    formik.values.profilePic = null;
  };

  const addressCbHandler = () => {
    formik.values.addressCheckBox = formik.values.addressCheckBox === false;
    if (formik.values.addressCheckBox === false) {
      formik.setFieldTouched("streetNumber", false);
      formik.setFieldTouched("streetName", false);
      formik.setFieldTouched("suburb", false);
      formik.setFieldTouched("state", false);
      formik.setFieldTouched("postCode", false);
    }
    if (formik.values.addressCheckBox === true) {
      formik.values.streetName = "";
      formik.values.streetNumber = "";
      formik.values.suburb = "";
      formik.values.state = "";
      formik.values.postCode = "";

      setStreetNumber("");
      setStreetName("");
      setSuburb("");
      setState("");
      setPostCode("");

      setSearchTerm("");
      setSearchResult([]);
      setAPISearchResult([]);
      setOpen(false);
      setUserSelectedOption(null);
      document.querySelector(".MuiAutocomplete-clearIndicator").click();
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: propsConst.firstName,
      middleName: propsConst.middleName,
      lastName: propsConst.lastName,
      email: propsConst.email,
      dateOfBirth: moment(propsConst.dateOfBirth, "DD-MM-YYYY").format(
        "YYYY-MM-DD[T]HH:mm:ss"
      ),
      gender: propsConst.gender,
      phoneNumber: propsConst.phoneNumber,
      unitNumber: unitNumber,
      streetNumber: streetNumber,
      streetName: streetName,
      suburb: suburb,
      state: state,
      postCode: postCode,
      medicareNumber: propsConst.medicareNumber,
      IHINumber: propsConst.IHIInfo?.IHINumber || '',
      refNumerOnMedicareCard: propsConst.refNumerOnMedicareCard,
      profilePic:
        propsConst.profilePic.original != "null"
          ? propsConst.profilePic.original
          : DefaultImage,

      addressCheckBox: false,

      deviceType: "2",
      deviceToken: "e873e0ba-6f46-44f3-b970-ea262fed1c6b",
      appVersion: "100",
      isProfilePic: false,
      timeZone: moment.tz.guess()
      // DVANumber: propsConst.DVANumber
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter the First Name"),
      lastName: Yup.string().required("Please enter the Last Name"),
      dateOfBirth: Yup.string().required("Please select your Birth Date"),
      phoneNumber: Yup.string().required("Please enter the Mobile Number"),
      gender: Yup.string()
        .notOneOf(["Select Gender"])
        .required("Please select Gender"),
      state: Yup.string()
        .notOneOf(["State"])
        .required("Please select State"),
      streetNumber: Yup.string().required("Please enter Unit/Street Number"),
      streetName: Yup.string().required("Please enter Street Name"),
      suburb: Yup.string().required("Please enter Suburb"),
      postCode: Yup.string().required("Please enter Postcode"),
      medicareNumber: Yup.string().matches(
        /[2-6]{1}[0-9]{3}\s?[0-9]{5}\s?[0-9]{1}/,
        "Please enter 10 digits, with 1st digit ranging from 2 to 6"
      ),
      IHINumber: Yup.string().matches(
        /[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}/,
        "Please enter 16 digits"
      )
      // DVANumber: Yup.string()
    }),
    onSubmit: values => {
      if (isMedicareCheckDigitValid || !formik.values.medicareNumber) {
        props.onFormSubmit(values);
      } else {
        setShowErrModal(true);
        setErrModalText(
          "The check digit of Medicare Card Number is invalid. Please correct it or remove it and contact our staff for help."
        );
      }
    }
  });

  // validate MedicareCard Number check digit
  useEffect(() => {
    if (formik.values.medicareNumber) {
      const medicareNumber = formik.values.medicareNumber;
      if (typeof medicareNumber === "string") {
        const cardNum = medicareNumber.replace(/\s+/g, "");
        if (
          cardNum.length === 10 &&
          parseInt(cardNum).toString().length === 10
        ) {
          const isCheckDigitValid = checkIsMedicareCheckDigitValid(cardNum);
          setIsMedicareCheckDigitValid(isCheckDigitValid);
          if (!isCheckDigitValid) {
            setShowErrModal(true);
            setErrModalText(
              "The check digit of Medicare Card Number is invalid"
            );
          }
        }
      }
    }
  }, [formik.values.medicareNumber]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const userFindingAddress = event => {
    setSearchTerm(event.target.value);
  };

  const fillSelectedAddressData = id => {
    let apiUniNo = apiSearchResult[id].address.unit
        ? apiSearchResult[id].address.unit
        : "",
      apiStreetNo = apiSearchResult[id].address.houseNumber
        ? (apiUniNo.length ? apiUniNo + "/" : "") +
        apiSearchResult[id].address.houseNumber
        : "",
      apiStreetName = apiSearchResult[id].address.street
        ? apiSearchResult[id].address.street
        : "",
      apiSuburb = apiSearchResult[id].address.district
        ? apiSearchResult[id].address.district
        : "",
      apiState = apiSearchResult[id].address.state
        ? apiSearchResult[id].address.state
        : "",
      apiPostCode = apiSearchResult[id].address.postalCode
        ? apiSearchResult[id].address.postalCode
        : "";
    let updatedStateNameIfRequried = null;
    if (stateNames.hasOwnProperty(apiState.toLowerCase())) {
      updatedStateNameIfRequried = stateNames[apiState.toLowerCase()];
    } else {
      updatedStateNameIfRequried = apiState;
    }
    setStreetNumber(apiStreetNo);
    setStreetName(apiStreetName);
    setSuburb(apiSuburb);
    setState(updatedStateNameIfRequried);
    setPostCode(apiPostCode);
    formik.setFieldValue("streetNumber", apiStreetNo);
    formik.setFieldValue("streetName", apiStreetName);
    formik.setFieldValue("suburb", apiSuburb);
    formik.setFieldValue("state", updatedStateNameIfRequried);
    formik.setFieldValue("postCode", apiPostCode);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.trim().length > 4) {
        const params =
          "?" +
          "q=" +
          encodeURIComponent(searchTerm) + // The search text which is the basis of the query
          "&maxresults=5" + // The upper limit the for number of suggestions to be included
          "&in=countryCode:AUS" +
          "&language=en" +
          "&apikey=" +
          APP_CONSTANTS.hereMAPApiKEY;
        axios({
          method: "GET",
          url: APP_CONSTANTS.hereMAPApi + params,
          timeout: 20000
        })
          .then(async res => {
            let optionsFilter = [],
              suggestionsArray = res.data.items || [];;
            for (let i = 0; i < suggestionsArray.length; i++) {
              let suggestion = "";
              suggestion += suggestionsArray[i].address.unit
                ? suggestionsArray[i].address.unit + "/"
                : "";
              suggestion += suggestionsArray[i].address.houseNumber
                ? suggestionsArray[i].address.houseNumber + " "
                : "";
              suggestion += suggestionsArray[i].address.street
                ? suggestionsArray[i].address.street + " "
                : "";
              suggestion += suggestionsArray[i].address.district
                ? suggestionsArray[i].address.district + " "
                : "";
              suggestion += suggestionsArray[i].address.state
                ? suggestionsArray[i].address.state + " "
                : "";
              suggestion += suggestionsArray[i].address.postalCode
                ? suggestionsArray[i].address.postalCode + " "
                : "";
              // suggestion += suggestionsArray[i].address.country
              //   ? suggestionsArray[i].address.country
              //   : "";
              optionsFilter.push({
                id: i,
                name: suggestion
              });
            }
            await setSearchResult([...optionsFilter]);
            await setAPISearchResult(res.data.items || []);
            if (optionsFilter.length <= 0) {
              setOpen(false);
              setUserSelectedOption(null);
            }
            // setOptions(Object.keys(countries).map(key => countries[key].item[0]));
          })
          .catch(err => {
            setOptions([]);
            setUserSelectedOption(null);
          });
      } else {
        setOpen(false);
        setOptions([]);
        setSearchResult([]);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (searchResult.length) {
      setOptions([...searchResult]);
    } else {
      setOptions([]);
    }
  }, [searchResult]);

  function removeSpecificWordFromHIResponse(str) {
    return str.replace(/\(INFORMATION\)\s*|\(ERROR\)\s*/g, "");
  }

  function refreshIHINumber() {


    const medicareNo = formik.values.medicareNumber.trim();
    const medicareRefNo = formik.values.refNumerOnMedicareCard.trim();

    let searchType = searchIHICriteriaType.IHINumber;
    if (medicareNo && medicareRefNo) {
      searchType = searchIHICriteriaType.Medicare;
    }


    const payload = HIServiceUtil.getSearchIHIPayload(
      searchType,
      Object.assign(propsConst, formik.values)
    );
    payload.patientID = propsConst._id;
    payload.serviceType = HIServiceType.SEARCH_IHI;

    // const formData = new FormData();
    // formData.append("HIInfo", JSON.stringify(payload));
    setApiLoading(true);
    setApiCall({
      reqType: "POST",
      reqURL: requestHIServiceSearchIHIApiRoute,
      dataObj: {
        HIInfo: payload
      },
      params: {},
      headers: {},
      successCallback: async res => {
        setApiLoading(false);
        if (
          !res.data?.data?.searchIHIResult ||
          res.data?.data?.searchIHIResult?.serviceMessages?.serviceMessage && (res.data?.data?.searchIHIResult?.ihiRecordStatus !== "Verified" && res.data?.data?.searchIHIResult?.ihiStatus !== "Active")
        ) {
          formik.values.IHINumber = "";
          formik.values.IHINumberStatus = "";
          formik.values.IHIRecordStatus = "";
          formik.values.IHIUpdateAt = "";
          setIhiState("error");
        } else {
          // there is searchIHIResult in response, and there is no serviceMessages in searchIHIResult
          const { IHIUpdatedAt = "", isAlert, alertMessage } = res.data.data;
          const {
            ihiNumber = "",
            ihiRecordStatus = "",
            ihiStatus = ""
          } = res.data.data.searchIHIResult;

          formik.values.IHINumber = ihiNumber;
          formik.values.IHINumberStatus = ihiRecordStatus;
          formik.values.IHIRecordStatus = ihiStatus;
          formik.values.IHIUpdateAt = IHIUpdatedAt;

          console.error({ IHIError: alertMessage });
          setIhiState(isAlert ? "error" : "success");
        }
      },
      failureCallback: err => {
        console.log({ err });
        setIhiState("error");
        setApiLoading(false);
      },
      showPopUp: false,
      showErrorRes: false
    });
  }

  return (
    <Fragment>
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        openModal={showErrModal}
        cancelEvent={() => {
          setShowErrModal(false);
          setErrModalText("");
        }}
        modalBodyContent={<p className="text-center mb-0">{errModalText}</p>}
        backdrop="static"
      />
      <form onSubmit={formik.handleSubmit} className="w-100 editProfile">
        <div className="row">
          <div className="col-sm-2 mt-2 offset-sm-3">
            <img
              src={selectedFile || DefaultImage}
              alt="no-img-instant-consult"
              className="profile-pic-size img-edit-profile img-thumbnail rounded-circle border-0"
            />
          </div>
          <div className="col-sm-7 mt-3">
            <i
              className="fas fa-file-upload ic-file-upload-icon float-left"
              onClick={handleFileInput}
            />
            <input
              type="file"
              id="file"
              accept="image/x-png,image/gif,image/jpeg"
              name="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onChangeHandler}
            />
            <Label
              title="Choose File"
              className="gray font-weight-bolder text-shadow mb-0 ml-2"
            />
            <br/>
            <Label
              title="JPG, GIF or PNG. max size of 800K"
              className="gray font-weight-bolder text-shadow ml-2"
            />
            {/* <div className="w-100" /> */}
            <div className="w-100 clearfix-both mt-0"/>
            {profilePicLargeErr ? (
              <Label title={profilePicLargeErr} className="error mt-0 pt-0"/>
            ) : (
              ""
            )}
            <div className="w-100 clearfix-both"/>
            <Button
              title="Cancel"
              clickEvent={cancelImgBtn}
              type="button"
              className="gray-outline text-center"
            />
          </div>
        </div>
        <div className="col-sm-12 mt-3 float-left">
          <Label title="Basic Information" className="big gray"/>
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="First Name" className="font-15 gray"/>
          <ICInput
            id="firstName"
            name="firstName"
            type="text"
            label=""
            placeholder="First"
            value={formik.values.firstName}
            disabled={props.isEditNamesDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Middle Name" className="font-15 gray"/>
          <ICInput
            id="middleName"
            name="middleName"
            type="text"
            label=""
            placeholder="Middle"
            value={formik.values.middleName}
            disabled={props.isEditNamesDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Last Name" className="font-15 gray"/>
          <ICInput
            id="lastName"
            name="lastName"
            type="text"
            label=""
            placeholder="Last"
            disabled={props.isEditNamesDisabled}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Date of Birth"
            className="font-15 gray"
            required={true}
          />
          <ICDateInput
            id="dateOfBirth"
            name="dateOfBirth"
            maxDate={new Date()}
            className="cal-primary-bg pt-6"
            label=""
            defaultDate={formik.values.dateOfBirth}
            handleChange={val => {
              formik.values.dateOfBirth = val;
              formik.setFieldValue(
                "dateOfBirth",
                moment(val).format("DD-MM-YYYY")
              );
              formik.handleChange("dateOfBirth");
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
            error={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? true
                : false
            }
            helperText={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? formik.errors.dateOfBirth
                : ""
            }
          />
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="Gender" className="font-15 gray" required={true}/>
          <ICSelect
            label=""
            placeholder="Select Gender"
            id="gender"
            name="gender"
            useId="_id"
            useValue="val"
            items={[
              { _id: "Male", val: "Male" },
              { _id: "Female", val: "Female" }
            ]}
            value={formik.values.gender}
            onBlur={formik.handleBlur}
            handleChange={val => {
              formik.values.gender = val;
              formik.setFieldValue("gender", val);
              formik.handleChange("gender");
            }}
            error={formik.touched.gender && formik.errors.gender ? true : false}
            helperText={
              formik.touched.gender && formik.errors.gender
                ? formik.errors.gender
                : ""
            }
          />
          {formik.errors.gender && formik.touched.gender ? (
            <Label title="Please select Gender" className="small error"/>
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-12 mt-5 float-left">
          <Label title="Contact Information" className="big gray"/>
        </div>
        <div className="col-sm-6 m-0 float-left">
          <Label
            title="Mobile Number"
            className="font-15 gray"
            required={true}
          />
          <ICPhoneInput
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            error={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? true
                : false
            }
            helperText={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : ""
            }
          />
          {/* <InputMask
          mask="0499 999 999"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? true
              : false
          }
          helperText={
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? formik.errors.phoneNumber
              : ""
          }
        >
          {inputProps => (
            <ICInput
              id="phoneNumber"
              name="phoneNumber"
              label=""
              placeholder="Mobile Number"
              {...inputProps}
            />
          )}
        </InputMask> */}
        </div>
        <div className="col-sm-6 float-left">
          <Label title="Email Address" className="font-15 gray"/>
          <ICInput
            id="email"
            name="email"
            type="email"
            label=""
            placeholder="Email"
            disabled={true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-12 mt-5 float-left">
          <Label title="Address Information" className="big gray"/>
        </div>
        <div className="col-sm-12 m-0 float-left">
          <Autocomplete
            id="asynchronous-api-call"
            ref={autoCompleteClearBtn}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
              setSearchResult([]);
              setSearchTerm("");
            }}
            getOptionSelected={(option, value) => {
              return option.name === value.name;
            }}
            getOptionLabel={option => option.name}
            options={options}
            filterOptions={options => options}
            loading={loading}
            noOptionsText="Please type your address"
            onChange={(event, value) => {
              if (value) fillSelectedAddressData(value.id);
            }}
            disabled={formik.values.addressCheckBox ? true : false}
            renderInput={params => (
              <ICInput
                {...params}
                onKeyDown={userFindingAddress}
                placeholder="Search Address..."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20}/>
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
          {/* <GooglePlaceAutoComplete
          selectedVal={googleValues}
          cbState={formik.values.addressCheckBox}
        /> */}
        </div>
        <div className="col-sm-1 mt-sm-3 float-left" onClick={addressCbHandler}>
          <ICCheckbox
            id="addressCheckBox"
            name="addressCheckBox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.addressCheckBox}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        <div className="float-left mt-4 custom-cb-label-margin">
          <Label title="Manually Enter Address" className="font-14"/>
          <span> </span>
          <Label
            title="(I cannot find my address)"
            className="font-italic font-14"
          />
        </div>
        <div className="clearfix"/>
        <div className="col-sm-4 m-0 float-left">
          <Label
            title="Unit/Street Number"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="streetNumber"
            name="streetNumber"
            type="text"
            label=""
            placeholder="Unit/Street Number"
            disabled={formik.values.addressCheckBox ? false : true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.addressCheckBox
                ? formik.values.streetNumber
                : streetNumber
            }
            error={
              formik.touched.streetNumber && formik.errors.streetNumber
                ? true
                : false
            }
            helperText={
              formik.touched.streetNumber && formik.errors.streetNumber
                ? formik.errors.streetNumber
                : ""
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Street Name" className="font-15 gray" required={true}/>
          <ICInput
            id="streetName"
            name="streetName"
            type="text"
            label=""
            placeholder="Street Name"
            disabled={formik.values.addressCheckBox ? false : true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.addressCheckBox
                ? formik.values.streetName
                : streetName
            }
            error={
              formik.touched.streetName && formik.errors.streetName
                ? true
                : false
            }
            helperText={
              formik.touched.streetName && formik.errors.streetName
                ? formik.errors.streetName
                : ""
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Suburb" className="font-15 gray" required={true}/>
          <ICInput
            id="suburb"
            name="suburb"
            type="text"
            label=""
            disabled={formik.values.addressCheckBox ? false : true}
            placeholder="Suburb"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.addressCheckBox ? formik.values.suburb : suburb
            }
            error={formik.touched.suburb && formik.errors.suburb ? true : false}
            helperText={
              formik.touched.suburb && formik.errors.suburb
                ? formik.errors.suburb
                : ""
            }
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="State" className="font-15 gray" required={true}/>
          {formik.values.addressCheckBox ? (
            <ICSelect
              label=""
              placeholder="State"
              id="state"
              name="state"
              disabled={formik.values.addressCheckBox ? false : true}
              items={props.stateList}
              useId="stateId"
              useValue="stateId"
              value={
                formik.values.addressCheckBox ? formik.values.state : state
              }
              onBlur={formik.handleBlur}
              handleChange={val => {
                formik.values.state = val;
                formik.setFieldValue("state", val);
                formik.handleChange("state");
              }}
              error={formik.touched.state && formik.errors.state ? true : false}
              helperText={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : ""
              }
            />
          ) : (
            <ICInput
              id="repStSel"
              name="repStSel"
              type="text"
              value={state}
              label=""
              disabled={formik.values.addressCheckBox ? false : true}
              placeholder="State"
            />
          )}
          {formik.errors.state && formik.touched.state ? (
            <Label title="Please select State" className="small error"/>
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="Postcode" className="font-15 gray" required={true}/>
          {/*<ICInput*/}
          {/*  id="postCode"*/}
          {/*  name="postCode"*/}
          {/*  type="number"*/}
          {/*  disabled={formik.values.addressCheckBox ? false : true}*/}
          {/*  label=""*/}
          {/*  placeholder="Postcode"*/}
          {/*  onChange={formik.handleChange}*/}
          {/*  onBlur={formik.handleBlur}*/}
          {/*  value={formik.values.addressCheckBox ? formik.values.postCode:postCode}*/}
          {/*  error={*/}
          {/*    formik.touched.postCode && formik.errors.postCode ? true : false*/}
          {/*  }*/}
          {/*  helperText={*/}
          {/*    formik.touched.postCode && formik.errors.postCode*/}
          {/*      ? formik.errors.postCode*/}
          {/*      : ""*/}
          {/*  }*/}
          {/*/>*/}
          <InputMask
            mask="9999"
            value={
              formik.values.addressCheckBox ? formik.values.postCode : postCode
            }
            onChange={formik.handleChange}
            disabled={formik.values.addressCheckBox ? false : true}
            onBlur={formik.handleBlur}
            error={
              formik.touched.postCode && formik.errors.postCode ? true : false
            }
            helperText={
              formik.touched.postCode && formik.errors.postCode
                ? formik.errors.postCode
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="postCode"
                disabled={formik.values.addressCheckBox ? false : true}
                name="postCode"
                label=""
                placeholder="Postcode"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-12 mt-5 float-left">
          <Label title="Medical Information" className="big gray"/>
        </div>

        <div className="col-sm-1 mt-4">
          <img
            src={MedicareLogo}
            alt="Medicare Logo"
            className="medicare-logo-size"
          />
        </div>

        <div className="col-sm-6 m-0 float-left">
          <Label title="Medicare Number" className="font-15 gray"/>
          <InputMask
            mask="9999 99999 9"
            value={formik.values.medicareNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.medicareNumber && formik.errors.medicareNumber
                ? true
                : false
            }
            helperText={
              formik.touched.medicareNumber && formik.errors.medicareNumber
                ? formik.errors.medicareNumber
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="medicareNumber"
                name="medicareNumber"
                label=""
                placeholder="Medicare Number"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-6 m-0 float-left">
          <Label
            title="Ref. Number on Medicare Card"
            className="font-15 gray"
          />
          <InputMask
            mask="9"
            value={formik.values.refNumerOnMedicareCard}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.refNumerOnMedicareCard &&
              formik.errors.refNumerOnMedicareCard
                ? true
                : false
            }
            helperText={
              formik.touched.refNumerOnMedicareCard &&
              formik.errors.refNumerOnMedicareCard
                ? formik.errors.refNumerOnMedicareCard
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="refNumerOnMedicareCard"
                name="refNumerOnMedicareCard"
                label=""
                placeholder="Ref. Number on Medicare Card"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-12 m-0 mt-4 float-left">
          <Label
            title="If you have a valid Medicare Card,
                ensure you input the correct number and reference number,
                otherwise you will be unable to obtain:"
            className="green font-14"
          />
        </div>
        <div className="m-0 float-left ml-4">
          <Label title="• PBS Prescriptions" className="green font-14"/>
        </div>
        <div className="clearfix"/>
        <div className="m-0 float-left ml-4">
          <Label
            title="• Bulk-Billed Referrals & Requests"
            className="green font-14"
          />
        </div>

        <div className="col-sm-12 m-0 float-left">
          <Label
            title="If you do not have a Medicare Card, please skip this field"
            className="green font-14"
          />
        </div>

        <div className="clearfix"/>

        <div className="col-sm-1 mt-4">
          <img
            src={GovLogo}
            alt="Australian Government Logo"
            className="gov-logo-size"
          />
        </div>
        <div className="row pl-4">
          <div className="col-sm-6 m-0 mt-4 float-left">
            <Label title="IHI Number" className="font-15 gray"/>
            <InputMask
              mask="9999 9999 9999 9999"
              value={formik.values.IHINumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.IHINumber && formik.errors.IHINumber
                  ? true
                  : false
              }
              helperText={
                formik.touched.IHINumber && formik.errors.IHINumber
                  ? formik.errors.IHINumber
                  : ""
              }
            >
              {inputProps => (
                <ICInput
                  id="IHINumber"
                  name="IHINumber"
                  label=""
                  placeholder="Individual Healthcare Identifier Number"
                  {...inputProps}
                />
              )}
            </InputMask>
          </div>

          <div className="col-sm-6 m-0 mt-4 float-right">
            <div className="form-group row">
              <RefreshButton className="col-1" onClick={refreshIHINumber}/>
              <p className={getIHIClassname()}>{getIHIText()}</p>
            </div>
          </div>
        </div>
        <div className="d-block col-6 pl-4 pr-1 pt-2">
          {ihiState === "error" && (
            <>
              <IHIInfoText
                title="Please call 1300 003 310 if your Medicare Details are correct, however your IHI Number could not be generated"
                color="#f5ab35"
              />
              <IHIInfoText
                className="d-block col-12"
                title="Please follow steps below if you do not have Medicare Details"
                color="#00A3E9"
              />
            </>
          )}
        </div>
        <div className="col-sm-12 m-0 mt-4 float-left">
          <Label
            title="If you have a valid IHI Number,
                ensure you input the correct number,
                otherwise you will be unable to obtain:"
            className="government-text-color font-14"
          />
        </div>
        <div className="m-0 float-left ml-4">
          <Label
            title="• Electronic Prescriptions (Prescriptions / eTokens)"
            className="government-text-color font-14"
          />
        </div>

        <div className="col-sm-12 m-0 float-left">
          <Label
            title="Not sure what your IHI Number is?"
            className="government-text-color font-14"
          />
        </div>
        <div className="m-0 float-left ml-3">
          <a
            href="https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier"
            className="government-text-color font-15 w-100"
            target="_blank"
          >
            Visit{" "}
            <b>
              https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier
            </b>
          </a>
        </div>
        <div className="clearfix"/>

        <div className="clearfix"/>
        <div className="text-center mt-4">
          <Button title="Save" type="submit" className="mr-2"/>
          <NavLink
            to={appRoutes.myProfile}
            className="text-decoration-none insta-link-secondary"
            href="#"
          >
            <Button
              title="Cancel"
              type="button"
              className="ml-2 gray-outline"
            />
          </NavLink>
        </div>
      </form>
      <MakeAPICall apiParam={apiCall}/>
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        openModal={showModal}
        title={modalTitle}
        className="text-left"
        cancelEvent={() => {
          setShowModal(false);
          setModalText("");
        }}
        modalBodyContent={
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: modalText
            }}
          ></p>
        }
      />
      <Loader loading={apiLoading}/>
    </Fragment>
  );
};

export default EditProfileForm;

EditProfileForm.defaultProps = {
  serverErrorMsg: ""
};

EditProfileForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}
