import {
  consultHistoryApiRoute,
  consultHistoryDetailsApiRoute
} from "../../apiRoutes";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import Label from "../../components/label";
import moment from "moment";
import appRoutes from "../../routes/appRoutes";

export default function StickyHeadTable(props) {
  const observer = useRef();
  const [pageNo, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [limit] = useState(5);
  const [skipPrevData, setSkipPrevData] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [watchRowUpdate, setWatchRowUpdate] = useState(false);
  // Data to be appended
  const [rows, setRows] = useState([]);
  // const [appendRows, setAppendRows] = useState([]);

  // const [rowData, setRowData] = useState(null);
  // const [rowIndex, setRowIndex] = useState(null);

  const lastElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(data => {
      if (data) {
        if (data[0].isIntersecting && nextPage && loading) {
          // setProgressBar(true);
          setLoading(false);
          if (loading) {
            setPageNumber(pageNo => pageNo + 1);
            let skipCalculate = limit * pageNo;
            setSkipPrevData(skipCalculate);
            getConsultHistory(limit, skipCalculate);
          }
          setTimeout(() => {
            setLoading(true);
          }, 2000);
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  const getCouponPrice = data => {
    let calculatedDiscountAmount;
    if (data.patientCouponId.isPercent) {
      calculatedDiscountAmount = data.patientCouponId.amount * data.appointmentPrice / 100;
    } else {
      calculatedDiscountAmount = data.patientCouponId.amount;
    }


    let paidAmount = data.appointmentPrice - calculatedDiscountAmount;
    if (paidAmount <= 0) {
      paidAmount = 0;
    }
    return "$" + (paidAmount).toFixed(2);
  }

  const getConsultHistory = (limitPage, skipPage, curPage) => {
    let params = null;
    if (props.filterByDate && props.searchKeyword !== "") {
      params = {
        limit: limitPage,
        skip: skipPage,
        startDate: props.startDate,
        endDate: props.endDate,
        searchParam: props.searchKeyword
      };
    } else if (props.filterByDate) {
      params = {
        limit: limitPage,
        skip: skipPage,
        startDate: props.startDate,
        endDate: props.endDate
      };
    } else if (props.searchKeyword !== "") {
      params = {
        limit: limitPage,
        skip: skipPage,
        searchParam: props.searchKeyword
      };
    } else {
      params = {
        limit: limitPage,
        skip: skipPage
      };
    }
    let apiCallParam = {
      reqType: "GET",
      reqURL: consultHistoryApiRoute,
      dataObj: {},
      params,
      headers: {},
      successCallback: res => {
        console.log("Got the response bere: ", res.data);
        if (skipPage === 0) {
          setRows(res.data.data.messages);
        } else {
          setRows(prev => prev.concat(res.data.data.messages));
        }
        let currentRowCount = res.data.data.count - pageNo * limitPage;
        if (currentRowCount > 0) {
          setNextPage(true);
        } else {
          setNextPage(false);
          setDataLoaded(true);
          setProgressBar(false);
        }
        // if (!res.data.data.messages.length) {
        //   setDataLoaded(true);
        //   setNextPage(false);
        //   setProgressBar(false);
        // }
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    props.makeApiCall(apiCallParam);
  };

  const resetPagination = () => {
    setPageNumber(1);
    setNextPage(false);
    setLoading(true);
    setProgressBar(true);
    setSkipPrevData(0);
    setDataLoaded(false);
  };

  useEffect(() => {
    //resetPagination();
    setRows(rows);
  }, [watchRowUpdate]);

  useEffect(() => {
    resetPagination();
    setRows([]);
    getConsultHistory(limit, 0, 1);
  }, [props.filterByDate, props.searchKeyword]);

  const onClickRow = (data, index) => {
    getConsultDetails(data._id);
  };

  const getConsultDetails = id => {
    let apiCallParam = {
      reqType: "GET",
      reqURL: consultHistoryDetailsApiRoute,
      dataObj: {},
      params: { booking_id: id },
      headers: {},
      successCallback: res => {
        const { history } = props;
        history.push(appRoutes.consultDetails, {
          consultDetails: res.data.data
        });
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    props.makeApiCall(apiCallParam);
  };

  return (
    <ul className="list-group list-group-flush border">
      {
        rows && (

          rows.map((data, index) => {
            let title, img;
            title = "Dr " + data.doctorId.firstName + " " + data.doctorId.lastName;
            img = data.doctorId.profilePic.original;

              let minutes = Math.floor(data.consultDuration / 60000);
              let seconds = ((data.consultDuration % 60000) / 1000).toFixed(0);
              let duration = "";
              if (minutes === 0 || minutes === 1)
                duration = minutes + "min " + seconds + "secs";
              else duration = minutes + "mins " + seconds + "secs";

              let isBulkBilled =
                data.isBulkBilled === undefined || data.isBulkBilled === false
                  ? false
                  : true;

              let mbsItemNo =
                data.consultDetailMedicareId !== undefined
                  ? data.consultDetailMedicareId.mbsItemNo
                  : "";

              if (rows.length === index + 1) {
                return (
                  <li
                    className="list-group-item cursor-pointer"
                    ref={lastElementRef}
                    key={index}
                    onClick={() => onClickRow(data, index)}
                  >
                    <div className="col-sm-12 ml-3 pl-1 mt-2 p-0">
                      <Label
                        title={moment(data.createdAt).format("D MMM YYYY")}
                        className="small secondary-font float-left"
                      />
                      <span className="float-left mt-2">
                        <Label
                          title={data.patientId && data.patientId.firstName ? (data.patientId.firstName + ' ' + data.patientId.lastName) : ''}
                          className="m-0 ml-4 font-15 cursor-pointer p-0 secondary-font primary-color"
                        />
                      </span>
                      <div className="clearfix"></div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-4">
                          <img
                            src={img}
                            alt="no-img-instant-consult"
                            className="img-thumbnail cnslt-hist-img rounded-circle border-0 p-0"
                          />
                          <Label
                            title={title}
                            className="secondary-font cursor-pointer pl-2 font-15"
                          />
                        </div>
                        <div className="col-sm-2 pl-4 p-0 m-0">
                          <Label
                            title="Consult ID"
                            className="w-100 m-0 cursor-pointer font-15 p-0"
                          />
                          <Label
                            title={data.appointmentId}
                            className="m-0 font-15 cursor-pointer p-0 secondary-font"
                          />
                        </div>
                        <div className="col-sm-3 pl-5 p-0 m-0">
                          <Label
                            title={isBulkBilled ? "Item No" : "Duration"}
                            className="w-100 font-15 cursor-pointer m-0 p-0"
                          />
                          <Label
                            title={isBulkBilled ? mbsItemNo : duration}
                            className="m-0 font-15 p-0 cursor-pointer secondary-font"
                          />
                        </div>
                        <div className="col-sm-3 p-0 pl-4 m-0">
                          <Label
                            title="Consult Cost"
                            className="w-100 font-15 cursor-pointer m-0 p-0"
                          />
                          <Label
                            title={
                              isBulkBilled
                                ? "Bulk-Billed"
                                : data.patientCouponId !== undefined
                                  ? getCouponPrice(data)
                                  : "$" + Number(data.totalPaidPrice).toFixed(2)
                            }
                            className="m-0 p-0 font-15 cursor-pointer secondary-font"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li
                    className="list-group-item cursor-pointer"
                    ref={lastElementRef}
                    key={index}
                    onClick={() => onClickRow(data, index)}
                  >
                    <div className="col-sm-12 ml-3 pl-1 mt-2 p-0">
                      <Label
                        title={moment(data.createdAt).format("D MMM YYYY")}
                        className="small secondary-font float-left"
                      />
                      <span className="float-left mt-2">
                        <Label
                          title={data.patientId && data.patientId.firstName ? (data.patientId.firstName + ' ' + data.patientId.lastName) : ''}
                          className="m-0 ml-4 font-15 cursor-pointer p-0 secondary-font primary-color"
                        />
                      </span>
                      <div className="clearfix"></div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-4">
                          <img
                            src={img}
                            alt="no-img-instant-consult"
                            className="img-thumbnail cnslt-hist-img rounded-circle border-0 p-0"
                          />
                          <Label
                            title={title}
                            className="secondary-font cursor-pointer pl-2 font-15"
                          />
                        </div>
                        <div className="col-sm-2 pl-4 p-0 m-0">
                          <Label
                            title="Consult ID"
                            className="w-100 m-0 cursor-pointer font-15 p-0"
                          />
                          <Label
                            title={data.appointmentId}
                            className="m-0 font-15 cursor-pointer p-0 secondary-font"
                          />
                        </div>
                        <div className="col-sm-3 pl-5 p-0 m-0">
                          <Label
                            title={isBulkBilled ? "Item No" : "Duration"}
                            className="w-100 font-15 cursor-pointer m-0 p-0"
                          />
                          <Label
                            title={isBulkBilled ? mbsItemNo : duration}
                            className="m-0 font-15 p-0 cursor-pointer secondary-font"
                          />
                        </div>
                        <div className="col-sm-3 p-0 pl-4 m-0">
                          <Label
                            title="Consult Cost"
                            className="w-100 font-15 cursor-pointer m-0 p-0"
                          />
                          <Label
                            title={
                              isBulkBilled
                                ? "Bulk-Billed"
                                : data.patientCouponId !== undefined
                                  ? getCouponPrice(data)
                                  : "$" + Number(data.totalPaidPrice).toFixed(2)
                            }
                            className="m-0 p-0 font-15 cursor-pointer secondary-font"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
            })
        )
      }

      <li
        className={
          progressBar
            ? "list-group-item text-center list-border-none p-4"
            : "d-none"
        }
      >
        <CircularProgress disableShrink className="primary-loader" />
      </li>
      <li
        className={
          dataLoaded
            ? "list-group-item text-center list-border-none p-4"
            : "d-none"
        }
      >
        <Label title="No data found" className="w-100 text-center" />
      </li>
    </ul>
  );
}
