import React, { useEffect, Fragment, useState } from "react";
import { UncontrolledCollapse, CardBody, Card } from 'reactstrap';
import { useHistory } from "react-router-dom";
import Label from "../../components/label";
import PropTypes from "prop-types";
import IconButton from "../../components/iconButton";
import appRoutes from "../../routes/appRoutes";
import { getPatientTotalConsultsAPI } from "../../apiRoutes";
import CustomModal from "../../components/customModal";

const FamilyProfileList = props => {

    const history = useHistory();

  const [apiErrorModelStatus, setAPIErrrorModelStatus] = useState(false);
  const [apiErrorMsg, setAPIErrorMsg] = useState("Something went wrong");

    const gotoEditDependant = () => {
      props.toggleLoading(true);
      // check dependent had a consult or not
      const dataToSend = {
        patientId: props.dependent._id
      };
      const apiCallObj = {
        reqType: "GET",
        reqURL: getPatientTotalConsultsAPI,
        dataObj: {},
        params: dataToSend,
        headers: {},
        successCallback: async res => {
          const isPatientConsultedBefore = res.data.data.count === 0 ? false : true;
          history.push(appRoutes.editFamilyProfile, {
            dependentDetails: props.dependent,
            isPatientConsultedBefore: isPatientConsultedBefore
          })
          props.toggleLoading(false);
        },
        failureCallback: err => {
          console.log("Got some error: ", err);
          // if (err && err.message) {
          //   setAPIErrorMsg(err.message);
          //   setAPIErrrorModelStatus(true);
          // }
          props.toggleLoading(false);
        },
        showPopUp: false
      };
      props.doAPICall(apiCallObj);
    }

  useEffect(() => {}, []);
  

  return (
    <Fragment>
     <div className="row ml-3 mr-3 mb-1 mt-2" id={`toggler${props.dependentIndex+1}`}>
         <div className="col-sm-6">
         <Label title={<i className="fas fa-chevron-down gray"></i>} />
        <Label title={`Dependent ${props.dependentIndex + 1}`} className="secondary-font p-3 mb-0" />
         </div>
         <div className="col-sm-6 text-right">
            <IconButton 
                 className="only-icon mt-2"
                 title=""
                 icon="fas fa-pencil-alt"
                 clickEvent={gotoEditDependant}
            />
         </div>
      </div>
      <UncontrolledCollapse className="row p-0 m-0" toggler={`toggler${props.dependentIndex+1}`}>
     {/* BASIC INFORMATION */}
     <div className="col-sm-12 mt-1 ml-3">
        <Label title="Basic Information" className="gray" />
      </div>
      <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
      <div className="col-sm-3 offset-1 mt-2">
        <Label title="Family Member Name" className="font-14 gray" />
      </div>
      <div className="col-sm-6 offset-sm-2 mt-2">
        <Label title={`${props.dependent.firstName} ${props.dependent.lastName}`} className="secondary-font" />
      </div>
      <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
      <div className="col-sm-3 offset-1 mt-2">
        <Label title="Date of Birth" className="font-14 gray" />
      </div>
      <div className="col-sm-6 offset-sm-2 mt-2">
        <Label title={props.dependent.dateOfBirth} className="secondary-font" />
      </div>
      <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
      <div className="col-sm-3 offset-1 mt-2">
        <Label title="Gender" className="font-14 gray" />
      </div>
      <div className="col-sm-6 offset-sm-2 mt-2">
        <Label title={props.dependent.gender} className="secondary-font" />
      </div>
      <span className="border-bottom w-100 ml-4 mt-1 mr-4" />

      {/* MEDICAL INFORMATION */}

      <div className="col-sm-12 mt-4 ml-3">
        <Label title="Medical Information" className="gray" />
      </div>
      <span className="border-bottom custom-profile-border w-100 ml-4 mr-4" />
      <div className="col-sm-3 offset-1 mt-2">
        <Label title="Medicare Number" className="font-14 gray" />
      </div>
      <div className="col-sm-6 offset-sm-2 mt-2">
        <Label title={props.dependent.medicareNumber ? (`${props.dependent.medicareNumber}`) : '-'} className="secondary-font" />
      </div>
      <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
      <div className="col-sm-4 offset-1 mt-2">
        <Label title="Ref. Number on Medicare Card" className="font-14 gray" />
      </div>
      <div className="col-sm-6 offset-sm-1 mt-2">
        <Label
          title={props.dependent.refNumerOnMedicareCard ? props.dependent.refNumerOnMedicareCard : '-'}
          className="secondary-font"
        />
      </div>
      <span className="border-bottom w-100 ml-4 mt-1 mr-4" />
      <div className="col-sm-4 offset-1 mt-2">
        <Label title="IHI Number" className="font-14 gray" />
      </div>
      <div className="col-sm-6 offset-sm-1 mt-2">
        <Label title={props.dependent.IHIInfo?.IHINumber ? (`${props.dependent.IHIInfo?.IHINumber}`) : '-'} className="secondary-font" />
      </div>
      </UncontrolledCollapse>

      <CustomModal
        openModal={apiErrorModelStatus}
        title="Instant Consult"
        useOneButton={true}
        cancelEvent={() => {
          setAPIErrrorModelStatus(false);
        }}
        cancelText="Okay"
        modalBodyContent={<Label title={apiErrorMsg} />}
      />

    </Fragment>
  );
};

export default FamilyProfileList;

FamilyProfileList.defaultProps = {
  serverErrorMsg: ""
};

FamilyProfileList.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
