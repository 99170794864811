import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  UPDATE_USER_INFO,
  DISMISS_MESSAGE,
  SET_IHI_INFO
} from "../constants/auth";

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  isLoggedIn: false,
  user: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        success: false,
        error: false,
        isLoggedIn: true,
        loading: false,
        message: "",
        user: action.payload
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        success: false,
        error: false,
        isLoggedIn: true,
        loading: false,
        message: "",
        auth: action.payload
      };
    case LOGIN_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        isLoggedIn: false,
        loading: false,
        message: action.payload
      };
    case DISMISS_MESSAGE:
      return {
        ...state,
        success: false,
        error: false,
        message: ""
      };
    case SET_IHI_INFO:
      return {
        ...state,
        user: [
          {
            ...state.user[0],
            IHIInfo: {
              ...state.user[0].IHIInfo,
              alertMessage: action.payload.alertMessage,
              isAlert: action.payload.isAlert,
              updatedAt: action.payload.updatedAt,
              IHINumber: action.payload.IHINumber,
              IHIRecordStatus: action.payload.IHIRecordStatus,
              IHINumberStatus: action.payload.IHINumberStatus
            }
          },
          state.user[1]
        ]
      };
    default:
      return state;
  }
}
