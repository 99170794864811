import React, { Component } from "react";
import Label from "../../components/label";
import SearchInput from "../../components/searchInput";
import ICDateInput from "../../components/form/dateInput";
import InboxList from "../../containers/InboxList";
import MakeAPICall from "../../apiCalls/common";
import {connect} from "react-redux";
import Modal from "../../components/modal";
import moment from "moment";
import orangeHeartIcon from "../../assets/images/hearts-16.png";
import whiteHeartIcon from "../../assets/images/hearts-white-16.png";
import {logFirebaseEvent} from "../../utils/common";

class Inbox extends Component {
  constructor(props) {
      super(props);
      this.state = {
          dateErrorPopUp: false,
          dateEmptyPopUp: false,
          apiCall: "",
          onlyFav: false,
          startDate: "",
          endDate: "",
          filterByDate: false,
          filtersVisibility: false,
          searchKeyword: "",
          searchEnabled: false
      };
  }
  componentDidMount() {
    logFirebaseEvent("Inbox Page");
    window.scrollTo(0, 0);
  }

    componentApiCall = data => {
        this.setState({
            apiCall: data
        })
    };

  changeStateOnFav =()=> {
    this.setState({
        onlyFav: !this.state.onlyFav
    });
  };

  filterByDate = () => {
      if(this.state.filterByDate === true)
      {
          this.setState({
              startDate: "",
              endDate: ""
          })
      }
    if(moment(this.state.startDate).isAfter(this.state.endDate))
    {
      this.setState({
        dateErrorPopUp: true
      })
    }
    else if(this.state.startDate === "" || this.state.endDate === "")
    {
      this.setState({
        dateEmptyPopUp: true
      })
    }
    else {
      this.setState({
        filterByDate: !this.state.filterByDate
      });
    }
  };

    setFiltersVisibility = (bool) => {
        this.setState({
            filtersVisibility: bool
        })
    };

    closeDateErrorPopUp = () => {
      this.setState({
        dateErrorPopUp: false,
        dateEmptyPopUp: false
      })
    };

  render() {
    return (
      <div className="row rounded p-3 notification-page">
        {/* Top Filters */}
        <div className="col-sm-3 p-3 pl-4">
          <Label title="Inbox" className="big" />
        </div>
        {this.state.onlyFav ?
          <div className="offset-sm-3 mt-3 col-sm-2 fav-activated-btn">
            <button type="button" onClick={this.changeStateOnFav}>
              <img src={whiteHeartIcon} alt=""/>
              only
            </button>
          </div>
          :
          <div className="offset-sm-3 mt-3 col-sm-2 fav-deactivated-btn">
            <button type="button" onClick={this.changeStateOnFav}>
              <img src={orangeHeartIcon} alt=""/>
              only
            </button>
          </div>
        }
        <div className="col-sm-4 mt-3">
          <SearchInput
              className=""
              placeholder="Search"
              value={this.state.searchKeyword}
              onChange={e =>
                  this.setState({ searchKeyword: e.target.value })
              }
          />
        </div>
        <div className="border-bottom mt-2 mb-2 w-100" />
        <div className="col-sm-3 offset-sm-5">
          <ICDateInput
            id="date"
            name="date"
            label="Start Date"
            defaultDate={this.state.startDate}
            handleChange={date => this.setState({ startDate: date })}
            filterOn={this.state.filterByDate ? "":this.state.filterByDate}
          />
        </div>
        <div className="col-sm-3">
          <ICDateInput
            id="date"
            name="date"
            label="End Date"
            defaultDate={this.state.endDate}
            handleChange={date => this.setState({ endDate: date })}
            filterOn={this.state.filterByDate ? "":this.state.filterByDate}
          />
        </div>
          {this.state.filterByDate ?
            <div className="mt-2 col-sm-1 date-activated-btn">
              <button type="button" onClick={this.filterByDate}>
                <i className="fas fa-times"/>
              </button>
            </div>
            :
            <div className="mt-2 col-sm-1 date-deactivated-btn">
              <button type="button" onClick={this.filterByDate}>
                <i className="fas fa-arrow-right fa-2x"/>
              </button>
            </div>
          }
        <div className="col-sm-12 custom-li mt-4 pl-0 pr-0">
            <InboxList accessToken={this.props.auth.user[0].accessToken} searchKeyword={this.state.searchKeyword} filterByDate={this.state.filterByDate} startDate={this.state.startDate} endDate={this.state.endDate} onlyFav={this.state.onlyFav} setFiltersVisibility={this.setFiltersVisibility}  makeApiCall={this.componentApiCall} history={this.props.history} clear={this.state.clear}/>
        </div>
          <MakeAPICall apiParam={this.state.apiCall} />
        <Modal
          modalBodyContent="Start Date cannot be greater than End Date"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeDateErrorPopUp}
          openModal={this.state.dateErrorPopUp}
        />
        <Modal
          modalBodyContent="Please select both Start Date and End Date before proceeding"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeDateErrorPopUp}
          openModal={this.state.dateEmptyPopUp}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
    auth: state.auth,
    ...state.modal
});

export default connect(mapStateToProps)(Inbox);
