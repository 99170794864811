import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Label from "../../components/label";
import InstaButton from "../../components/button";
import ICCheckbox from "../../components/form/checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Visa from "../../assets/images/Visa.png";
import MasterCard from "../../assets/images/MasterCard.png";
import appRoutes from "../../routes/appRoutes";
import Modal from "../../components/modal";
import CustomModal from "../../components/customModal";
import { logFirebaseEvent } from "../../utils/common";

import {
  savedCardsApiRoute,
  deleteCardApiRoute,
  changeDefaultCard,
  getPatientCouponListApiRoute,
  addPatientCouponApiRoute
} from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";

import { getFormattedDate } from "../../utils/dateTimeHelper";

import AddCouponForm from "../../containers/AddCouponForm";

class SavedCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteCardPopUp: false,
      defaultCardPopUp: false,
      deleteDefault: false,
      currentDefault: "",
      savedCardsList: [],
      apiCall: "",
      apiCall1: "",
      rowIndex: null,
      rowData: null,
      patientCoupons: [],
      showCouponModal: false,
      showCommonModal: false,
      commonModalText: ""
    };
  }

  componentDidMount() {
    logFirebaseEvent("Saved Cards Page");
    window.scrollTo(0, 0);
    this.getSavedCardsList();
    this.getCouponList();
  }

  openDeleteModal = (value, rowIndex) => {
    this.setState({
      deleteCardPopUp: true,
      rowData: value,
      rowIndex: rowIndex
    });
  };

  openDefaultCardModal = (value, rowIndex) => {
    this.setState({
      defaultCardPopUp: true,
      rowData: value,
      rowIndex: rowIndex
    });
  };

  closeDeleteCardPopUp = () => {
    this.setState({
      deleteCardPopUp: false
    });
  };

  closeDeleteDefaultCardPopUp = () => {
    this.setState({
      deleteDefault: false,
      deleteCardPopUp: false
    });
  };

  closeDefaultCardPopUp = () => {
    this.setState({
      defaultCardPopUp: false
    });
  };

  deleteSavedCard = () => {
    if (
      this.state.savedCardsList[this.state.rowIndex]["defaultStatus"] == true &&
      this.state.savedCardsList.length > 1
    ) {
      this.setState({ deleteDefault: true });
    } else {
      let apiCallParam = {
        reqType: "DELETE",
        reqURL: deleteCardApiRoute,
        dataObj: {},
        params: {
          cardId: this.state.rowData,
          lastCard: this.state.savedCardsList.length > 1 ? false : true
        },
        headers: {},
        successCallback: res => {
          let tmpList = [...this.state.savedCardsList];
          tmpList.splice(this.state.rowIndex, 1);
          this.setState({
            savedCardsList: tmpList,
            deleteCardPopUp: false
          });
        },
        failureCallback: err => {
          console.log("Something went wrong. Please try again ", err);
        },
        showPopUp: false
      };
      this.setState({
        apiCall: apiCallParam,
        deleteCardPopUp: false
      });
    }
  };

  changeDefaultCard = () => {
    let apiCallParam = {
      reqType: "PUT",
      reqURL: changeDefaultCard,
      dataObj: { cardId: this.state.rowData },
      params: {},
      headers: {},
      successCallback: res => {
        this.handleCurrentDefault();
        let tmpList = [...this.state.savedCardsList];
        tmpList[this.state.rowIndex]["defaultStatus"] = true;
        this.setState({
          savedCardsList: tmpList,
          defaultCardPopUp: false
        });
      },
      failureCallback: err => {
        console.log("Something went wrong. Please try again ", err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallParam,
      defaultCardPopUp: false
    });
  };

  handleCurrentDefault = () => {
    let tmpList = this.state.savedCardsList;

    tmpList.map((data, index) => {
      tmpList[index]["defaultStatus"] = false;
    });

    this.setState({
      savedCardsList: tmpList
    });
  };

  addNewCard = () => {
    const { history } = this.props;
    history.push(appRoutes.addNewCard);
  };

  getSavedCardsList() {
    let apiCallObj = {
      reqType: "GET",
      reqURL: savedCardsApiRoute,
      dataObj: {},
      params: { limit: 10, skip: 0 },
      headers: {},
      successCallback: res => {
        this.setState({
          savedCardsList: res.data.data
        });
      },
      failureCallback: err => {
        console.log(err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall: apiCallObj
    });
  }

  toggleCouponModal = () => {
    if (this.state.showCouponModal === true) {
      logFirebaseEvent("Add Coupon Popup");
    }
    this.setState({
      showCouponModal: !this.state.showCouponModal
    });
  };

  toggleCommonModal = () => {
    this.setState({
      showCommonModal: !this.state.showCommonModal
    });
  };

  addNewCoupon = data => {
    this.toggleCouponModal();

    let form = new FormData();
    form.append(
      "patientId",
      this.props.auth.user !== null ? this.props.auth.user[0]._id : ""
    );
    form.append("couponText", data.couponText.trim());

    let apiCallObj = {
      reqType: "POST",
      reqURL: addPatientCouponApiRoute,
      dataObj: form,
      params: {},
      headers: {},
      successCallback: res => {
        console.log(res);
        this.setState({
          commonModalText: "Coupon Added Successfully",
          showCommonModal: true
        });
        this.getCouponList();
      },
      failureCallback: err => {
        console.log(err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall1: apiCallObj
    });
  };

  getCouponList = () => {
    let apiCallObj = {
      reqType: "GET",
      reqURL: getPatientCouponListApiRoute,
      dataObj: {},
      params: {
        patientId:
          this.props.auth.user !== null ? this.props.auth.user[0]._id : ""
      },
      headers: {},
      successCallback: res => {
        console.log(res);
        this.setState({
          patientCoupons: res.data.data
        });
      },
      failureCallback: err => {
        console.log(err);
      },
      showPopUp: false
    };
    this.setState({
      apiCall1: apiCallObj
    });
  };

  render() {
    return (
      <div
        className="container
        h-100 bg-white add-attachment-model font-12 pb-5 mb-5"
      >
        <div className="row">
          <div className="col-sm-5 ml-3 mt-4">
            <Label title="Saved Cards" className="big" />
          </div>
          <div className="col-sm-6 text-right ml-3 pr-0 mb-3 mt-4">
            <InstaButton
              title="Add Coupon"
              clickEvent={this.toggleCouponModal}
            />
            &nbsp;&nbsp;&nbsp;
            <InstaButton title="Add New Card" clickEvent={this.addNewCard} />
          </div>
          <div className="w-100 border-bottom" />
          <div className="clearfix" />
          {this.state.savedCardsList.length < 1 &&
          this.state.patientCoupons.length === 0 ? (
            <div className="offset-sm-5 mt-5">
              <Label title="No Data Found" className="offset-sm-5" />
            </div>
          ) : (
            <Fragment>
              {this.state.savedCardsList.map((data, index) => {
                return (
                  <div
                    className={
                      index > 1
                        ? "col-sm-5 p-0 ml-2 saved-card-margin"
                        : "col-sm-5 mt-3 p-0 ml-2"
                    }
                    key={index}
                  >
                    <div className="card m-4">
                      <div className="card-body p-0 pt-2">
                        <div className="col-sm-12 circle-checkbox-margin">
                          <ICCheckbox
                            className="primary-color p-0 ml-2"
                            name="defaultCard"
                            id="defaultCard"
                            onChange={this.openDefaultCardModal.bind(
                              this,
                              data._id,
                              index
                            )}
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            value={data.defaultStatus}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <Label
                            className="secondary-font font-14 p-0 m-0 ml-2"
                            title={"XXXX XXXX XXXX " + data.lastFourDigit}
                          />
                        </div>
                        <div className="col-sm-11 offset-sm-1">
                          <img
                            src={data.cardType === "Visa" ? Visa : MasterCard}
                            alt="no-img-instant-consult"
                            className="border-0"
                          />
                          <InstaButton
                            title="Delete"
                            clickEvent={this.openDeleteModal.bind(
                              this,
                              data._id,
                              index
                            )}
                            className={
                              "link insta-link-default cursor-pointer primary-color float-right font-14 p-0 text-right"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <br />
              {this.state.patientCoupons.map((data, index) => {
                return (
                  <div
                    className={
                      index > 1
                        ? "col-sm-5 p-0 ml-2 saved-card-margin"
                        : "col-sm-5 mt-3 p-0 ml-2"
                    }
                    key={index}
                  >
                    <div style={{ minHeight: "71.19px" }} className="card m-4">
                      <div className="card-body p-0 pt-2 pb-2">
                        <div className="col-sm-12">
                          <Label
                            className="secondary-font primary-color font-17 p-0 m-0 ml-2"
                            title={`${data.code} (${data.isPercent ? "" : "$"}${
                              data.amount
                            }${data.isPercent ? "%" : ""} Off)`}
                          />
                          <br />
                          <Label
                            className="secondary-font font-12 p-0 m-0 ml-2 mr-3 mt-2"
                            title="This will be applied to your next consult"
                          />
                          <Label
                            className="secondary-font font-12 p-0 m-0 ml-2 mt-2"
                            title={"Exp " + getFormattedDate(data.expiryDate)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          )}
        </div>
        <CustomModal
          title="Instant Consult"
          useOneButton={true}
          cancelText="OKAY"
          openModal={this.state.showCommonModal}
          cancelEvent={this.toggleCommonModal}
          modalBodyContent={
            <p className="text-center mb-0">{this.state.commonModalText}</p>
          }
        />
        <CustomModal
          openModal={this.state.showCouponModal}
          title="Add Coupon"
          modalBodyContent={
            <AddCouponForm
              cancelEvent={this.toggleCouponModal}
              onAddSubmit={this.addNewCoupon}
            />
          }
          noFooterButtons={true}
        />
        <Modal
          modalBodyContent="Do you want to delete this card?"
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={this.deleteSavedCard}
          cancelEvent={this.closeDeleteCardPopUp}
          openModal={this.state.deleteCardPopUp}
        />
        <Modal
          modalBodyContent="Do you want to make this card default?"
          okayText="Yes"
          cancelText="No"
          title="Instant Consult"
          useOneButton={false}
          okayEvent={this.changeDefaultCard}
          cancelEvent={this.closeDefaultCardPopUp}
          openModal={this.state.defaultCardPopUp}
        />
        <Modal
          modalBodyContent="Card cannot be deleted as it is the default card"
          cancelText="Okay"
          title="Instant Consult"
          useOneButton={true}
          cancelEvent={this.closeDeleteDefaultCardPopUp}
          openModal={this.state.deleteDefault}
        />
        <MakeAPICall apiParam={this.state.apiCall} />
        <MakeAPICall apiParam={this.state.apiCall1} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(SavedCards);
