import React, { useRef, useEffect, Fragment } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import appRoutes from "../../routes/appRoutes";

import Button from "../../components/button";
import ICInput from "../../components/form/input";
import Label from "../../components/label";
import PropTypes from "prop-types";
const SearchDependentEmailForm = props => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter correct email address")
        .required("Please enter the email address"),
        password: Yup.string().required("Please enter the password")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });

  return (
    <Fragment>
      <form
        onSubmit={formik.handleSubmit}
        className="w-100 dependent-email-form"
      >
        <div className="col-sm-12 text-left">
          <Label title="Please input the email address and password of your dependent's account" className="font-15 gray" />
        </div>
        <div className="col-sm-12 m-0 text-left">
          <Label title="Email" className="font-15 gray" required={true} />
          <ICInput
            id="email"
            name="email"
            type="text"
            label=""
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
            error={formik.touched.email && formik.errors.email ? true : false}
          />
        </div>

        <div className="col-sm-12 text-left mt-3">
            <Label title="Password" className="font-15 gray" required={true} />
            <ICInput
        id="password"
        name="password"
        type="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && formik.errors.password ? true : false}
        helperText={
          formik.touched.password && formik.errors.password
            ? formik.errors.password
            : ""
        }
      />

        </div>

        <div className="col-sm-12 text-center mt-4">
          <Button title="Save" type="submit" className="mr-2" />
          <Button title="Cancel" type="button" className="ml-2 gray-outline" clickEvent={props.closeModal}/>
        </div>
      </form>
    </Fragment>
  );
};

export default SearchDependentEmailForm;

SearchDependentEmailForm.defaultProps = {
  serverErrorMsg: ""
};

SearchDependentEmailForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
