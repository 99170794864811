import {
  NOTIFICATION_NUMBER,
  ONESIGNAL_SUBSCRIPTION,
  APPOINTMENT_ID,
  BOOKING_STATUS,
  APPOINTMENT_DATA,
  VIDEO_CALL_STATUS,
  VIDEO_CALL_TIMER_VALUE,
  CALL_WAIT_TIME,
  WAIT_IF_CALL_END,
  IS_REQUEST_ACCEPTED,
  IS_CALL_MISSED,
  IS_DOCTOR_CALLING,
  CALL_SESSION_DATA,
  IS_DIRECT_CALL,
  TOTAL_PATIENT_CONSULTS,
  PATIENT_QUEUE_WAIT_TIME,
  SCHEDULED_BOOKING
} from "../constants/common";

const initialState = {
  totalNotification: 0,
  bookingStatus: 0,
  appointmentData: null,
  callSessionData: null,
  isCallAccepted: false,
  callTimerVal: 0,
  callWaitTime: null,
  waitIfCallEnd: false,
  isRequestAccepted: false,
  isMissedCall: false,
  isDoctorCalling: false,
  isDirectCall: false,
  totalPatientConsultCount: 0,
  patientQueueWaitTime: 0,
  onlineDocCount: 0,
  scheduledBooking: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PATIENT_QUEUE_WAIT_TIME:
      return {
        ...state,
        patientQueueWaitTime: action.payload.patientQueueWaitTime,
        onlineDocCount: action.payload.onlineDocCount
      };
    case NOTIFICATION_NUMBER:
      return {
        ...state,
        totalNotification: action.payload.totalNotification
      };
    case BOOKING_STATUS:
      return {
        ...state,
        bookingStatus: action.payload
      };
    case APPOINTMENT_ID:
      return {
        ...state,
        appointmentID: action.payload
      };
    case ONESIGNAL_SUBSCRIPTION:
      return {
        ...state,
        oneSignalSubscribed: action.payload
      };
    case APPOINTMENT_DATA:
      return {
        ...state,
        appointmentData: action.payload
      };
    case CALL_SESSION_DATA:
      return {
        ...state,
        callSessionData: action.payload
      };
    case VIDEO_CALL_STATUS:
      return {
        ...state,
        isCallAccepted: action.payload
      };
    case VIDEO_CALL_TIMER_VALUE:
      return {
        ...state,
        callTimerVal: action.payload
      };
    case CALL_WAIT_TIME:
      return {
        ...state,
        callWaitTime: action.payload
      };
    case WAIT_IF_CALL_END:
      return {
        ...state,
        waitIfCallEnd: action.payload
      };
    case IS_REQUEST_ACCEPTED:
      return {
        ...state,
        isRequestAccepted: action.payload
      };
    case IS_CALL_MISSED:
      return {
        ...state,
        isMissedCall: action.payload
      };
    case IS_DOCTOR_CALLING:
      return {
        ...state,
        isDoctorCalling: action.payload
      };
    case IS_DIRECT_CALL:
      return {
        ...state,
        isDirectCall: action.payload
      };
    case TOTAL_PATIENT_CONSULTS:
      return {
        ...state,
        totalPatientConsultCount: action.payload
      };
    case SCHEDULED_BOOKING:
      return {
        ...state,
        scheduledBookings: action.payload
      };
    default:
      return state;
  }
}
