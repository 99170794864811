import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import appRoutes from "../../routes/appRoutes";
import Button from "../../components/button";
import ICInput from "../../components/form/input";
import Label from "../../components/label";
import ICSelect from "../../components/form/selectInput";
import ICDateInput from "../../components/form/dateInput";
import DefaultImage from "../../assets/images/default-user-img.png";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import moment from "moment";
import InputMask from "react-input-mask";
import CustomModal from "../../components/customModal";
import { checkIsMedicareCheckDigitValid } from "../../utils/MedicareCardUtil";
import MedicareLogo from "../../assets/images/medicare-logo.png";
import GovLogo from "../../assets/images/auGovLogo.png";
import RefreshButton from "../../components/refreshButton";
import { publicRequestHIServiceSearchIHIApiRoute } from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import Loader from "../../components/loader";
import HIServiceUtil, {
  searchIHICriteriaType
} from "../../utils/HIServiceUtil";
import { HIServiceType } from "../../constants/constants";
import IHIInfoText from "../../components/ihiInfoText";
import { IHIStates } from "../../utils/common";

Yup.addMethod(Yup.string, "checkAge", function(errorMessage) {
  return this.test(`test-person-age`, errorMessage, function(value) {
    const years = moment().diff(moment(value, "DD-MM-YYYY"), "years", true);
    return years < 18;
  });
});

const EditDependentProfileForm = props => {
  const propsConst = props.dependentDetails ? props.dependentDetails : null;
  const isDisableNames = props.isPatientConsultedBefore;
  const [selectedFile, setSelectedFile] = useState(
    propsConst ? propsConst.profilePic.original : DefaultImage
  );
  const [showErrModal, setShowErrModal] = useState(false);
  const [errModalText, setErrModalText] = useState("");
  const [isMedicareCheckDigitValid, setIsMedicareCheckDigitValid] = useState(
    true
  );
  const [apiCall, setApiCall] = useState();
  const inputFile = useRef(null);
  const [profilePicLargeErr, setProfilePicLargeErr] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [ihiState, setIhiState] = useState("");

  function getIHIText() {
    if (ihiState === "error") return IHIStates.FAILED;
    if (ihiState === "success") return IHIStates.SUCCESS;
    return IHIStates.NOT_LOADED;
  }

  function getIHIClassname() {
    if (ihiState === "error") return "error col-11 my-auto";
    if (ihiState === "success") return "green col-11 my-auto";
    return "col-11 my-auto";
  }

  const onChangeHandler = event => {
    let maxAllowedSize = 800000; //800KB
    if (event.target.files && event.target.files[0]) {
      let fileSizebig = false;
      if (event.target.files[0].size > maxAllowedSize) {
        fileSizebig = true;
        setProfilePicLargeErr("Maximum 800K file size is allowed");
      } else {
        fileSizebig = false;
        setProfilePicLargeErr(null);
      }
      if (fileSizebig === false) {
        setSelectedFile(URL.createObjectURL(event.target.files[0]));
        formik.values.profilePic = event.target.files[0];
        formik.values.isProfilePic = true;
      }
    }
  };

  const handleFileInput = () => {
    inputFile.current.click();
  };

  const cancelImgBtn = () => {
    setSelectedFile(DefaultImage);
    formik.values.isProfilePic = false;
    formik.values.profilePic = null;
  };

  const formik = useFormik({
    initialValues: {
      firstName: propsConst ? propsConst.firstName : "",
      middleName: propsConst ? propsConst.middleName : "",
      lastName: propsConst ? propsConst.lastName : "",
      // email: propsConst ? propsConst.email : "",
      dateOfBirth: propsConst
        ? moment(propsConst.dateOfBirth, "DD-MM-YYYY").format(
            "YYYY-MM-DD[T]HH:mm:ss"
          )
        : "",
      gender: propsConst ? propsConst.gender : "",
      // phoneNumber: propsConst ? propsConst.phoneNumber : "",
      medicareNumber: propsConst ? propsConst.medicareNumber : "",
      IHINumber: propsConst ? propsConst.IHIInfo?.IHINumber : "",
      refNumerOnMedicareCard: propsConst
        ? propsConst.refNumerOnMedicareCard
        : "",
      profilePic: propsConst
        ? propsConst.profilePic.original != "null"
          ? propsConst.profilePic.original
          : null
        : null,
      deviceType: "2",
      deviceToken: "e873e0ba-6f46-44f3-b970-ea262fed1c6b",
      appVersion: "100",
      isProfilePic: false,
      timeZone: moment.tz.guess()
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter the First Name"),
      lastName: Yup.string().required("Please enter the Last Name"),
      dateOfBirth: Yup.string().checkAge(
        "Dependents must be under 18 years of age"
        ).required("Please select the Birth Date"),
      gender: Yup.string()
        .notOneOf(["Select Gender"])
        .required("Please select Gender"),
      medicareNumber: Yup.string().matches(
        /[2-6]{1}[0-9]{3}\s?[0-9]{5}\s?[0-9]{1}/,
        "Please enter 10 digits, with 1st digit ranging from 2 to 6"
      ),
      IHINumber: Yup.string().matches(
        /[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}/,
        "Please enter 16 digits"
      )
      // DVANumber: Yup.string()
    }),
    onSubmit: values => {
      if (isMedicareCheckDigitValid || !formik.values.medicareNumber) {
        props.onFormSubmit(values);
      } else {
        setShowErrModal(true);
        setErrModalText(
          "The check digit of Medicare Card Number is invalid. Please correct it or remove it and contact our staff for help."
        );
      }
    }
  });

  // validate MedicareCard Number check digit
  useEffect(() => {
    if (formik.values.medicareNumber) {
      const medicareNumber = formik.values.medicareNumber;
      if (typeof medicareNumber === "string") {
        const cardNum = medicareNumber.replace(/\s+/g, "");
        if (
          cardNum.length === 10 &&
          parseInt(cardNum).toString().length === 10
        ) {
          const isCheckDigitValid = checkIsMedicareCheckDigitValid(cardNum);
          setIsMedicareCheckDigitValid(isCheckDigitValid);
          if (!isCheckDigitValid) {
            setShowErrModal(true);
            setErrModalText(
              "The check digit of Medicare Card Number is invalid"
            );
          }
        }
      }
    }
  }, [formik.values.medicareNumber]);

  function removeSpecificWordFromHIResponse(str) {
    return str.replace(/\(INFORMATION\)\s*|\(ERROR\)\s*/g, "");
  }

  function refreshIHINumber() {
    const medicareNo = formik.values.medicareNumber.trim();
    const medicareRefNo = formik.values.refNumerOnMedicareCard.trim();

    let searchType = searchIHICriteriaType.IHINumber;
    if (medicareNo && medicareRefNo) {
      searchType = searchIHICriteriaType.Medicare;
    }

    const payload = HIServiceUtil.getSearchIHIPayload(searchType, {
      ...formik.values,
      dob: moment(formik.values.dateOfBirth).format("DD-MM-YYYY")
    });
    payload.serviceType = HIServiceType.SEARCH_IHI;

    setApiLoading(true);
    setApiCall({
      reqType: "POST",
      reqURL: publicRequestHIServiceSearchIHIApiRoute,
      dataObj: payload,
      params: {},
      headers: {},
      successCallback: async res => {
        setApiLoading(false);
        const IHIResp = res.data?.data;
        if (!IHIResp) {
          setIhiState("error");
        } else {
          if (IHIResp.isAlert) {
            setIhiState("error");
          } else {
            formik.setFieldValue("IHINumber", IHIResp.IHINumber);
            formik.setFieldValue("ihiInfo", IHIResp);
            setIhiState("success");
          }
        }
      },
      failureCallback: err => {
        console.error({ err });
        setIhiState("error");
        setApiLoading(false);
      },
      showPopUp: false,
      showErrorRes: false
    });
  }

  return (
    <Fragment>
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        openModal={showErrModal}
        cancelEvent={() => {
          setShowErrModal(false);
          setErrModalText("");
        }}
        modalBodyContent={<p className="text-center mb-0">{errModalText}</p>}
        backdrop="static"
      />
      <form onSubmit={formik.handleSubmit} className="w-100 editProfile">
        <div className="row">
          <div className="col-sm-2 mt-2 offset-sm-3">
            <img
              src={
                selectedFile != "null" || selectedFile != ""
                  ? selectedFile
                  : DefaultImage
              }
              alt="no-img-instant-consult"
              className="profile-pic-size img-edit-profile img-thumbnail rounded-circle border-0"
            />
          </div>
          <div className="col-sm-7 mt-3">
            <i
              className="fas fa-file-upload ic-file-upload-icon float-left"
              onClick={handleFileInput}
            />
            <input
              type="file"
              id="file"
              accept="image/x-png,image/gif,image/jpeg"
              name="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onChangeHandler}
            />
            <Label
              title="Choose File"
              className="gray font-weight-bolder text-shadow mb-0 ml-2"
            />
            <br />
            <Label
              title="JPG, GIF or PNG. max size of 800K"
              className="gray font-weight-bolder text-shadow ml-2"
            />
            {/* <div className="w-100" /> */}
            <div className="w-100 clearfix-both mt-0" />
            {profilePicLargeErr ? (
              <Label title={profilePicLargeErr} className="error mt-0 pt-0" />
            ) : (
              ""
            )}
            <div className="w-100 clearfix-both" />
            <Button
              title="Cancel"
              clickEvent={cancelImgBtn}
              type="button"
              className="gray-outline text-center"
            />
          </div>
        </div>
        <div className="col-sm-12 mt-3 float-left">
          <Label title="Basic Information" className="big gray" />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="First Name" className="font-15 gray" required={true} />
          <ICInput
            id="firstName"
            name="firstName"
            type="text"
            label=""
            placeholder="First"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            disabled={isDisableNames}
            helperText={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""
            }
            error={
              formik.touched.firstName && formik.errors.firstName ? true : false
            }
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Middle Name" className="font-15 gray" />
          <ICInput
            id="middleName"
            name="middleName"
            type="text"
            label=""
            placeholder="Middle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.middleName}
            disabled={isDisableNames}
          />
        </div>
        <div className="col-sm-4 m-0 float-left">
          <Label title="Last Name" className="font-15 gray" required={true} />
          <ICInput
            id="lastName"
            name="lastName"
            type="text"
            label=""
            placeholder="Last"
            disabled={isDisableNames}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""
            }
            error={
              formik.touched.lastName && formik.errors.lastName ? true : false
            }
          />
        </div>
        <div className="clearfix"></div>
        <div className="col-sm-6 mt-5 float-left">
          <Label
            title="Date of Birth"
            className="font-15 gray"
            required={true}
          />
          <ICDateInput
            id="dateOfBirth"
            name="dateOfBirth"
            className="cal-primary-bg pt-6"
            label=""
            maxDate={new Date()}
            defaultDate={formik.values.dateOfBirth}
            handleChange={val => {
              formik.values.dateOfBirth = val;
              formik.setFieldValue(
                "dateOfBirth",
                moment(val).format("DD-MM-YYYY")
              );
              formik.handleChange("dateOfBirth");
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
            error={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? true
                : false
            }
            helperText={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? formik.errors.dateOfBirth
                : ""
            }
          />
          <Label
            className="error small"
            title={
              formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? formik.errors.dateOfBirth
                : ""
            }
          />
        </div>
        <div className="col-sm-6 mt-5 float-left">
          <Label title="Gender" className="font-15 gray" required={true} />
          <ICSelect
            label=""
            placeholder="Select Gender"
            id="gender"
            name="gender"
            useId="_id"
            useValue="val"
            items={[
              { _id: "Male", val: "Male" },
              { _id: "Female", val: "Female" }
            ]}
            value={formik.values.gender}
            onBlur={formik.handleBlur}
            handleChange={val => {
              formik.values.gender = val;
              formik.setFieldValue("gender", val);
              formik.handleChange("gender");
            }}
            error={formik.touched.gender && formik.errors.gender ? true : false}
            helperText={
              formik.touched.gender && formik.errors.gender
                ? formik.errors.gender
                : ""
            }
          />
          {formik.errors.gender && formik.touched.gender ? (
            <Label title="Please select Gender" className="small error" />
          ) : (
            ""
          )}
        </div>

        <div className="clearfix"></div>

        <div className="col-sm-12 float-left">
          <Label title="Medical Information" className="big gray" />
        </div>
        <div className="col-sm-1 mt-4">
          <img
            src={MedicareLogo}
            alt="Medicare Logo"
            className="medicare-logo-size"
          />
        </div>

        <div className="col-sm-6 m-0 float-left">
          <Label title="Medicare Number" className="font-15 gray" />
          <InputMask
            mask="9999 99999 9"
            value={formik.values.medicareNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.medicareNumber && formik.errors.medicareNumber
                ? true
                : false
            }
            helperText={
              formik.touched.medicareNumber && formik.errors.medicareNumber
                ? formik.errors.medicareNumber
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="medicareNumber"
                name="medicareNumber"
                label=""
                placeholder="Medicare Number"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-6 m-0 float-left">
          <Label
            title="Ref. Number on Medicare Card"
            className="font-15 gray"
          />
          <InputMask
            mask="9"
            value={formik.values.refNumerOnMedicareCard}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.refNumerOnMedicareCard &&
              formik.errors.refNumerOnMedicareCard
                ? true
                : false
            }
            helperText={
              formik.touched.refNumerOnMedicareCard &&
              formik.errors.refNumerOnMedicareCard
                ? formik.errors.refNumerOnMedicareCard
                : ""
            }
          >
            {inputProps => (
              <ICInput
                id="refNumerOnMedicareCard"
                name="refNumerOnMedicareCard"
                label=""
                placeholder="Ref. Number on Medicare Card"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-12 m-0 mt-4 float-left">
          <Label
            title="If you have a valid Medicare Card,
                ensure you input the correct number and reference number,
                otherwise you will be unable to obtain:"
            className="green font-14"
          />
        </div>
        <div className="m-0 float-left ml-4">
          <Label title="• PBS Prescriptions" className="green font-14" />
        </div>
        <div className="clearfix" />
        <div className="m-0 float-left ml-4">
          <Label
            title="• Bulk-Billed Referrals & Requests"
            className="green font-14"
          />
        </div>
        <div className="col-sm-12 m-0 float-left">
          <Label
            title="If you do not have a Medicare Card, please skip this field"
            className="green font-14"
          />
        </div>

        <div className="clearfix" />

        <div className="col-sm-1 mt-4">
          <img
            src={GovLogo}
            alt="Australian Government Logo"
            className="gov-logo-size"
          />
        </div>
        <div className="row pl-3">
          <div className="col-sm-6 m-0 mt-4 float-left">
            <Label title="IHI Number" className="font-15 gray" />
            <InputMask
              mask="9999 9999 9999 9999"
              value={formik.values.IHINumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.IHINumber && formik.errors.IHINumber
                  ? true
                  : false
              }
              helperText={
                formik.touched.IHINumber && formik.errors.IHINumber
                  ? formik.errors.IHINumber
                  : ""
              }
            >
              {inputProps => (
                <ICInput
                  id="IHINumber"
                  name="IHINumber"
                  label=""
                  placeholder="Individual Healthcare Identifier Number"
                  {...inputProps}
                />
              )}
            </InputMask>
          </div>

          <div className="col-sm-6 m-0 mt-4 float-right">
            <div className="form-group row">
              <RefreshButton className="col-1" onClick={refreshIHINumber} />
              <p className={getIHIClassname()}>{getIHIText()}</p>
            </div>
          </div>
        </div>
        <div className="d-block col-6 pl-3 pr-1 pt-2">
          {ihiState === "error" && (
            <>
              <IHIInfoText
                title="Please call 1300 003 310 if your Medicare Details are correct, however your IHI Number could not be generated"
                color="#f5ab35"
              />
              <IHIInfoText
                className="d-block col-12"
                title="Please follow steps below if you do not have Medicare Details"
                color="#00A3E9"
              />
            </>
          )}
        </div>

        <div className="col-sm-12 m-0 mt-4 float-left">
          <Label
            title="If you have a valid IHI Number,
                ensure you input the correct number,
                otherwise you will be unable to obtain:"
            className="government-text-color font-14"
          />
        </div>
        <div className="m-0 float-left ml-4">
          <Label
            title="• Electronic Prescriptions (Prescriptions / eTokens)"
            className="government-text-color font-14"
          />
        </div>

        <div className="col-sm-12 m-0 float-left">
          <Label
            title="Not sure what your IHI Number is?"
            className="government-text-color font-14"
          />
        </div>
        <div className="m-0 float-left ml-3">
          <a
            href="https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier"
            className="government-text-color font-15 w-100"
            target="_blank"
          >
            <b>Visit</b>{" "}
            https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier
          </a>
        </div>

        <div className="clearfix" />
        <div className="text-center mt-4">
          <Button title="Save" type="submit" className="mr-2" />
          <NavLink
            to={appRoutes.familyProfile}
            className="text-decoration-none insta-link-secondary"
            href="#"
          >
            <Button
              title="Cancel"
              type="button"
              className="ml-2 gray-outline"
            />
          </NavLink>
        </div>
      </form>
      <MakeAPICall apiParam={apiCall} />
      <Loader loading={apiLoading} />
    </Fragment>
  );
};

export default EditDependentProfileForm;

EditDependentProfileForm.defaultProps = {
  serverErrorMsg: ""
};

EditDependentProfileForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
