import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ICCheckbox = props => (
  <FormControlLabel
    control={
      <Checkbox
        className="ic-checkbox"
        {...props}
        checked={props.value || false}
      />
    }
    label={props.label}
  />
);

export default ICCheckbox;
