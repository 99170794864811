import React, { useRef } from "react";
import ICButton from "../../components/button";

const AddAttachment = props => {
  const [selectedFile, setSelectedFile] = React.useState([]);
  const inputFile = useRef(null);

  const onChangeHandler = event => {
    //console.log(event.target.files[0]);
    if (event && event.target.files && event.target.files[0]) {
      setSelectedFile(
        selectedFile.concat(URL.createObjectURL(event.target.files[0]))
      );
      props.getFiles(event.target.files[0]);
    }
  };

  const handleFileInput = () => {
    inputFile.current.click();
  };

  return (
    <div>
      {/*{handleImages()}*/}

      <div className="clearfix" />
      <input
        type="file"
        id="file"
        name="file"
        accept="image/jpeg,image/gif,image/png,application/pdf"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={onChangeHandler}
      />
      <div className="mt-4">
        <ICButton
          title="Add"
          type="button"
          clickEvent={handleFileInput}
          className="text-center"
        />
      </div>
    </div>
  );
};

export default AddAttachment;
